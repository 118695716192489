import ActionConstants from './../../constants/action';


var initialState = {
    isVitalsLoaded: false,
    vitals: []
};


export default function(state, action) {
    if (action.type === ActionConstants.RPM_SET_VITALS) {
        return Object.assign({}, state, {
            isVitalsLoaded: true,
            vitals: action.payload.vitals
        });
    }

    if (action.type === ActionConstants.RPM_CLEAR_VITALS) {
        return Object.assign({}, state, {
            isVitalsLoaded: false,
            vitals: []
        });
    }

    return state || Object.assign({}, initialState);
}
