import ActionConstants from './../../constants/action';


var initialState = {
    isPatientCarePlanLoaded: false,
    patientCarePlan: null,

    isPatientCarePlanLogsLoaded: false,
    patientCarePlanLogs: [],

    isPatientWeeklyCarePlanLogsLoaded: false,
    patientWeeklyCarePlanLogs: []
};


export default function(state, action) {
    if (action.type === ActionConstants.REPORTS_SET_PATIENT_CARE_PLAN) {
        return Object.assign({}, state, {
            isPatientCarePlanLoaded: true,
            patientCarePlan: action.payload.plan
        });
    }

    if (action.type === ActionConstants.REPORTS_CLEAR_PATIENT_CARE_PLAN) {
        return Object.assign({}, state, {
            isPatientCarePlanLoaded: false,
            patientCarePlan: null
        });
    }

    if (action.type === ActionConstants.REPORTS_SET_PATIENT_CARE_PLAN_LOGS) {
        return Object.assign({}, state, {
            isPatientCarePlanLogsLoaded: true,
            patientCarePlanLogs: action.payload.logs,
            is454: action.payload.is454,
            showTotalTime: action.payload.showTotalTime
        });
    }

    if (action.type === ActionConstants.REPORTS_CLEAR_PATIENT_CARE_PLAN_LOGS) {
        return Object.assign({}, state, {
            isPatientCarePlanLogsLoaded: false,
            patientCarePlanLogs: [],
            is454: false,
            showTotalTime: true
        });
    }

    if (action.type === ActionConstants.REPORTS_SET_PATIENT_CARE_PLAN_LOGS_LOADING) {
        return Object.assign({}, state, {
            isPatientCarePlanLogsLoaded: false
        });
    }

    if (action.type === ActionConstants.REPORTS_SET_PATIENT_WEEKLY_CARE_PLAN_LOGS) {
        return Object.assign({}, state, {
            isPatientWeeklyCarePlanLogsLoaded: true,
            patientWeeklyCarePlanLogs: action.payload.logs
        });
    }

    if (action.type === ActionConstants.REPORTS_CLEAR_PATIENT_WEEKLY_CARE_PLAN_LOGS) {
        return Object.assign({}, state, {
            isPatientWeeklyCarePlanLogsLoaded: false,
            patientWeeklyCarePlanLogs: []
        });
    }

    return state || Object.assign({}, initialState);
}
