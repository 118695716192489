import ActionConstants from './../../constants/action';

const initialState = {
    isCarePlansLoaded: false,
    carePlans: [],

    isCarePlanLoaded: false,
    carePlan: null,

    isCarePlanCommentsLoaded: false,
    carePlanComments: [],

    isDailyCarePlansLoaded: false,
    dailyCarePlans: [],

    isDailyCarePlanLoaded: false,
    dailyCarePlan: null,

    isDailyCarePlanLogsLoaded: false,
    dailyCarePlanLogs: [],

    isDailyCarePlanEntriesLoaded: false,
    dailyCarePlanEntries: [],
    editableVitals: [],
    allWaitingsTodos: [],
    carePlanActions: [],

    isDailyCarePlanAllEntriesLoaded: false,
    dailyCarePlanAllEntries: [],

    isAssignedDailyCarePlansLoaded: false,
    assignedDailyCarePlans: [],

    ECGDetailLoaded: false,
    ECGDetailLoading: false,
    ECGDetailData: null,

    isPrimaryDiagnosesLoaded: false,
    primaryDiagnoses: [],
    isUserMedicationsLoaded: false,
    userMedications: [],

    carePlansFilterType: 'recorded'
};


export default function(state, action) {

    if (action.type === ActionConstants.RPM_CARE_PLANS_FILTER_CHANGE) {
        return Object.assign({}, state, {
            carePlansFilterType: action.payload.carePlansFilterType
        });
    }

    if (action.type === ActionConstants.RPM_SET_CARE_PLANS) {
        return Object.assign({}, state, {
            isCarePlansLoaded: true,
            carePlans: action.payload.carePlans
        });
    }

    if (action.type === ActionConstants.RPM_CLEAR_CARE_PLANS) {
        return Object.assign({}, state, {
            isCarePlansLoaded: false,
            carePlans: []
        });
    }

    if (action.type === ActionConstants.RPM_SET_CARE_PLAN) {
        return Object.assign({}, state, {
            isCarePlanLoaded: true,
            carePlan: action.payload.carePlan
        });
    }

    if (action.type === ActionConstants.RPM_CLEAR_CARE_PLAN) {
        return Object.assign({}, state, {
            isCarePlanLoaded: false,
            carePlan: null
        });
    }

    if (action.type === ActionConstants.RPM_SET_CARE_PLAN_COMMENTS) {
        return Object.assign({}, state, {
            isCarePlanCommentsLoaded: true,
            carePlanComments: action.payload.comments
        });
    }

    if (action.type === ActionConstants.RPM_CLEAR_CARE_PLAN_COMMENTS) {
        return Object.assign({}, state, {
            isCarePlanCommentsLoaded: false,
            carePlanComments: []
        });
    }

    if (action.type === ActionConstants.RPM_SET_DAILY_CARE_PLANS) {
        return Object.assign({}, state, {
            isDailyCarePlansLoaded: true,
            dailyCarePlans: action.payload.dailyCarePlans
        });
    }

    if (action.type === ActionConstants.RPM_SET_CARE_PLAN_HISTORY) {
        return Object.assign({}, state, {
            isDailyCarePlanHistoryLoaded: true,
            dailyCarePlanHistory: action.payload.history
        });
    }

    if (action.type === ActionConstants.RPM_CLEAR_CARE_PLAN_HISTORY) {
        return Object.assign({}, state, {
            isDailyCarePlanHistoryLoaded: false,
            dailyCarePlanHistory: []
        });
    }

    if (action.type === ActionConstants.RPM_CLEAR_DAILY_CARE_PLANS) {
        return Object.assign({}, state, {
            isDailyCarePlansLoaded: false,
            dailyCarePlans: []
        });
    }

    if (action.type === ActionConstants.RPM_SET_DAILY_CARE_PLAN) {
        return Object.assign({}, state, {
            isDailyCarePlanLoaded: true,
            dailyCarePlan: action.payload.dailyCarePlan,
            carePlanActions: action.payload.carePlanActions,
            allWaitingsTodos: action.payload.allWaitingsTodos,

        });
    }

    if (action.type === ActionConstants.RPM_CLEAR_DAILY_CARE_PLAN) {
        return Object.assign({}, state, {
            isDailyCarePlanLoaded: false,
            dailyCarePlan: null,
            carePlanActions: [],
            allWaitingsTodos: [],
            ECGList: null
        });
    }

    if (action.type === ActionConstants.RPM_SET_DAILY_CARE_PLAN_LOGS) {
        return Object.assign({}, state, {
            isDailyCarePlanLogsLoaded: true,
            dailyCarePlanLogs: action.payload.logs
        });
    }

    if (action.type === ActionConstants.RPM_CLEAR_DAILY_CARE_PLAN_LOGS) {
        return Object.assign({}, state, {
            isDailyCarePlanLogsLoaded: false,
            dailyCarePlanLogs: []
        });
    }

    if (action.type === ActionConstants.RPM_SET_DAILY_CARE_PLAN_ENTRIES) {
        return Object.assign({}, state, {
            isDailyCarePlanEntriesLoaded: true,
            dailyCarePlanEntries: action.payload.entries,
            editableVitals: action.payload.editableVitals
        });
    }

    if (action.type === ActionConstants.RPM_CLEAR_DAILY_CARE_PLAN_ENTRIES) {
        return Object.assign({}, state, {
            isDailyCarePlanEntriesLoaded: false,
            dailyCarePlanEntries: [],
            editableVitals: []
        });
    }

    if (action.type === ActionConstants.RPM_SET_DAILY_CARE_PLAN_ALL_ENTRIES) {
        return Object.assign({}, state, {
            isDailyCarePlanAllEntriesLoaded: true,
            dailyCarePlanAllEntries: action.payload.allEntries
        });
    }

    if (action.type === ActionConstants.RPM_CLEAR_DAILY_CARE_PLAN_ALL_ENTRIES) {
        return Object.assign({}, state, {
            isDailyCarePlanAllEntriesLoaded: false,
            dailyCarePlanAllEntries: []
        });
    }

    if (action.type === ActionConstants.RPM_SET_ASSIGNED_DAILY_CARE_PLANS) {
        return Object.assign({}, state, {
            isAssignedDailyCarePlansLoaded: true,
            assignedDailyCarePlans: action.payload.carePlans
        });
    }

    if (action.type === ActionConstants.RPM_CLEAR_ASSIGNED_DAILY_CARE_PLANS) {
        return Object.assign({}, state, {
            isAssignedDailyCarePlansLoaded: false,
            assignedDailyCarePlans: []
        });
    }

    if (action.type === ActionConstants.RPM_SET_REPORTED_DAILY_CARE_PLANS) {
        return Object.assign({}, state, {
            isReportedDailyCarePlansLoaded: true,
            reportedDailyCarePlans: action.payload.carePlans
        });
    }

    if (action.type === ActionConstants.RPM_CLEAR_REPORTED_DAILY_CARE_PLANS) {
        return Object.assign({}, state, {
            isReportedDailyCarePlansLoaded: false,
            reportedDailyCarePlans: []
        });
    }

    if (action.type === ActionConstants.RPM_SET_USER_MEDICATIONS) {
        return Object.assign({}, state, {
            isUserMedicationsLoaded: true,
            userMedications: action.payload.medications
        });
    }

    if (action.type === ActionConstants.RPM_CLEAR_USER_MEDICATIONS) {
        return Object.assign({}, state, {
            isUserMedicationsLoaded: false,
            userMedications: []
        });
    }

    if (action.type === ActionConstants.RPM_SET_USER_PRIMARY_DIAGNOSES) {
        return Object.assign({}, state, {
            isPrimaryDiagnosesLoaded: true,
            primaryDiagnoses: action.payload.diagnoses
        });
    }

    if (action.type === ActionConstants.RPM_CLEAR_USER_PRIMARY_DIAGNOSES) {
        return Object.assign({}, state, {
            isPrimaryDiagnosesLoaded: false,
            primaryDiagnoses: []
        });
    }

    return state || Object.assign({}, initialState);
}
