import React from 'react';

const trash = () => {
    return (
        <img className='icon'
             alt='Trash'
             src='/static/bootstrap/icons/trash.svg' />
    );
};

export default trash;
