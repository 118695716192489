import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import MainConstants from './../../constants/main';
import { getPatientsByName } from './../../actions/patients';
import {
    getCarePlan,
    setCarePlan,
    clearCarePlan,
    checkIfExistsCarePlan,
    updateCarePlan,
    deleteCarePlan
} from './../../actions/rpm/care-plans';
import { dateToRequestString } from './../../lib/date';
import RequestLoader from './../../components/request-loader';
import FormLoader from './../../components/form-loader';
import BackButton from './../../components/back-button';
import TrashIcon from './../../components/icons/trash';
import Form from './../../lib/forms/form';
import Input from './../../lib/forms/input';
import Checkboxes from './../../lib/forms/checkboxes';
import SelectWithSearch from './../../lib/forms/select-with-search';
import SelectWithLoading from './../../lib/forms/select-with-loading';
import DatePicker from './../../lib/forms/date-picker';
import VitalsField from './../../lib/forms/vitals-field';
import { required, requiredEmployee } from './../../lib/forms/field-validators';
import CareplanCreateEmployee from './components/careplan-create-employee';
import { setCollectors, clearCollectors } from './../../actions/rpm/collectors';
import { getDoctors, setDoctors, clearDoctors } from '../../actions/doctors';

class RPMEditCarePlan extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isDoctorsLoaded: false,
            doctors: [],

            isCollectorsLoaded: false,
            collectors: [],

            isUpdating: false,

            isCheckingPatient: false,

            redirectMessagePlanId: '',
            redirectMessagePatientFirstName: '',
            redirectMessagePatientLastName: ''
        };

        this.onCarePlanFormRef = this.onCarePlanFormRef.bind(this);

        this.loadPatientsByName = this.loadPatientsByName.bind(this);
        this.checkPatient = this.checkPatient.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onDelete = this.onDelete.bind(this);
    }

    componentDidMount() {

        const { dispatch, history, clinic } = this.props;

        getDoctors(clinic.id).then(res => {

            this.setState({
                isDoctorsLoaded: true,
                isCollectorsLoaded: true,
                doctors: res.data.doctors,
                collectors: res.data.doctors
            });
        });

        const id = this.getPlanId();

        getCarePlan(clinic.id, id).then((res) => {

            dispatch(
                setCarePlan(
                    res.data.carePlan
                )
            );
        }).catch(() => history.push('/app/rpm/monitoring-plans'));
    }

    componentWillUnmount() {
        this.props.dispatch(clearCarePlan());
    }

    onCarePlanFormRef(ref) {
        if (!ref) {
            return;
        }

        this.carePlanFormRef = ref;

        const { carePlan } = this.props;

        const patient = {
            value: carePlan.patientId,
            label: carePlan.patientFirstName + ' ' + carePlan.patientLastName
        };

        ref.setValues({
            patient,
            doctor: this.getUserValueById(this.state.doctors, carePlan.doctorId),
            date: carePlan.date,
            collector: this.getUserValueById(this.state.collectors, carePlan.collectorId),
            contactName: carePlan.contactName,
            contactPhone: carePlan.contactPhone,
            daysOfWeek: carePlan.daysOfWeek,
            whomToInform: this.getUserValueById(this.state.collectors, carePlan.informDoctorId),
            howToInform: carePlan.informMethods,
            vitals: carePlan.vitals
        });
    }

    loadPatientsByName(name, callback) {
        const { clinic } = this.props;

        getPatientsByName(clinic, name).then((res) => {
            const patients = res.data.patients.map((patient) => {
                let dob = '';

                if (patient.dob !== null) {
                    dob = ', ' + patient.dob;
                }

                return {
                    value: patient.id,
                    label: patient.firstName + ' ' + patient.lastName + dob
                };
            });
            callback(patients);
        });
    }

    getDoctorsValues() {

        return this.props.doctors.map(doctor => {

            return {
                value: doctor.id,
                label: doctor.firstName + ' ' + doctor.lastName
            };
        });
    }

    getCollectorsValues() {
        return this.state.collectors.map((collector) => {

            return {
                value: collector.id,
                label: collector.firstName + ' ' + collector.lastName
            };
        });
    }

    getUserValueById(users, userId) {

        userId = parseInt(userId);

        if (userId === 0) {

            return {
                value: 0,
                label: 'Automatic collection of data'
            };
        } else {

            for (let i = 0; i < users.length; i += 1) {
                const user = users[i];

                if (user.id === userId) {
                    let dob = '';

                    if (user.dob !== null && user.dob) {
                        dob = ', ' + user.dob;
                    }

                    return {
                        value: user.id,
                        label: user.firstName + ' ' + user.lastName + dob
                    };
                }
            }
        }

        return null;
    }

    checkPatient(patient) {
        if (!patient) {
            this.setState({
                redirectMessagePlanId: '',
                redirectMessagePatientFirstName: '',
                redirectMessagePatientLastName: ''
            });
            return;
        }

        this.setState({ isCheckingPatient: true });

        checkIfExistsCarePlan({ patient }).then((res) => {
            let planId = '';
            let firstName = '';
            let lastName = '';

            if (res.result && res.data.exists) {
                planId = res.data.plan;
                firstName = res.data.patientFirstName;
                lastName = res.data.patientLastName;
            }

            this.setState({
                isCheckingPatient: false,
                redirectMessagePlanId: planId,
                redirectMessagePatientFirstName: firstName,
                redirectMessagePatientLastName: lastName
            });
        });
    }

    onSubmit(plan) {
        if (this.state.isUpdating) {
            return;
        }

        plan.id = this.getPlanId();
        plan.date = dateToRequestString(plan.date);

        const vitals = [];

        for (let i = 0; i < plan.vitals.length; i += 1) {
            const v = plan.vitals[i];

            vitals.push({
                vital: v.vital,
                thresholds: v.thresholds,
                partsOfDay: v.daysOfWeek.dayparts,
                daysOfWeek: v.daysOfWeek.weekdays
            });
        }

        plan.vitals = vitals;
        plan.whomToInform = plan.whomToInform === 0 ? null : plan.whomToInform;

        const { clinic } = this.props;

        this.setState({ isUpdating: true }, () => {
            updateCarePlan(clinic.id, plan).then(() => {

                this.props.history.push('/app/rpm/monitoring-plans/' + plan.id);
            }).finally(() => {

                this.setState({ isUpdating: false });
            });
        });
    }

    onDelete() {
        const isConfirmed = window.confirm('Delete plan?');

        if (isConfirmed) {
            const planId = this.getPlanId();
            const { clinic } = this.props;

            deleteCarePlan(clinic.id, planId).then(() => {
                this.props.history.push('/app/rpm/monitoring-plans');
            });
        }
    }

    getPlanId() {
        const planId = parseInt(this.props.match.params.planId, 10);

        if (isNaN(planId)) {
            this.props.history.push('/app/rpm/monitoring-plans');
            return;
        }

        return planId;
    }

    getWhomToInformValues() {

        let values = [];
        values.push({ value: 0, label: 'Automatic collection of data' });
        values.push(...this.getDoctorsValues());

        return values;
    }

    _toggleCreateEmployeePopup = () => {

        this.setState({ showCreateEmployeePopup: !this.state.showCreateEmployeePopup })
    }

    renderRedirectMessage() {
        const {
            redirectMessagePlanId,
            redirectMessagePatientFirstName,
            redirectMessagePatientLastName
        } = this.state;

        if (!redirectMessagePlanId) {
            return null;
        }

        const url = '/app/rpm/monitoring-plans/' + redirectMessagePlanId;
        const fullName = redirectMessagePatientFirstName + ' ' + redirectMessagePatientLastName;

        return (
            <div className='message warning-message'>
                <div>
                    <Link to={url}>Plan</Link> for {fullName} already exists
                </div>
            </div>
        );
    }

    _checkEmployee = (value) => {

        if (typeof value !== 'string') {

            return null;
        }

        this.setState({
            newEmployeeName: value,
            showCreateEmployeePopup: true
        });
    }

    _setCreatedEmployee = () => {

        const { dispatch, currentClinic } = this.props;

        dispatch(clearCollectors());
        dispatch(clearDoctors());

        getDoctors(currentClinic).then(res => {
            this._toggleCreateEmployeePopup();

            dispatch(
                setCollectors(
                    res.data.doctors
                )
            );

            dispatch(
                setDoctors(
                    res.data.doctors
                )
            );

            this.carePlanFormRef.fields.doctor.current.setState({
                value: null,
                label: null
            });
        });
    }

    render() {
        if (!this.props.isCarePlanLoaded ||
            !this.props.isVitalsLoaded ||
            !this.state.isDoctorsLoaded ||
            !this.state.isCollectorsLoaded) {

            return (
                <RequestLoader center/>
            );
        }

        const { newEmployeeName, showCreateEmployeePopup } = this.state;
        const { currentClinic } = this.props;


        const formClasses = [
            'form',
            'care-plan-form'
        ];

        const planId = this.getPlanId();

        const howToInformValues = [];

        for (let i = 0; i < MainConstants.rpmCarePlansInformMethods.length; i += 1) {
            const v = Object.assign({}, MainConstants.rpmCarePlansInformMethods[i]);

            howToInformValues.push({
                name: v.value,
                label: v.name,
                checked: v.defaultChecked ? true : false,
                disabled: v.disabled ? true : false
            });
        }

        let patientLoader = null;

        if (this.state.isCheckingPatient) {
            patientLoader = (
                <div className='form-mini-loader' />
            );
        }

        const { weightInKg, tempInF } = this.props.clinic;

        return (
            <div>
                <CareplanCreateEmployee
                    employeeName={newEmployeeName}
                    toggleCreateEmployeePopup={this._toggleCreateEmployeePopup}
                    showCreateEmployeePopup={showCreateEmployeePopup}
                    carePlanFormRef={this.carePlanFormRef}
                    clinicId={currentClinic}
                    setCreatedEmployee={this._setCreatedEmployee}
                />

                <BackButton to={'/app/rpm/monitoring-plans/' + planId}/>

                <header className='header'>Edit monitoring plan</header>

                {this.renderRedirectMessage()}

                <Form ref={this.onCarePlanFormRef} onSubmit={this.onSubmit} className={formClasses.join(' ')}>
                    <div className='form-horizontal-control'>
                        <SelectWithLoading
                            name='patient'
                            label='Patient'
                            validators={[required('Please select patient')]}
                            inline={true}
                            onChange={this.checkPatient}
                            onLoadMore={this.loadPatientsByName}
                            disabled={true} />

                        <div className='field-loader'>
                            {patientLoader}
                        </div>

                        <SelectWithSearch
                            name='doctor'
                            label='Prescribed by'
                            values={this.getDoctorsValues()}
                            validators={[requiredEmployee('Please select doctor')]}
                            onChange={this._checkEmployee}
                            inline={true}
                            creatable={true}
                            promptTextCreator={label => `Create new employee ${label}`}
                        />

                        <DatePicker
                            name='date'
                            label='Start by'
                            validators={[required('Please select date')]}
                            inline={true}/>
                    </div>

                    <SelectWithSearch
                        name='collector'
                        label='Medical assistant'
                        values={this.getCollectorsValues()}
                        validators={[required('Please select medical assistant')]}
                        inline={true} />

                    <div className='form-horizontal-control'>
                        <Input name='contactName'
                               label='Contact name'
                               inline={true}/>

                        <Input name='contactPhone'
                               maxLength='15'
                               label='Contact phone'
                               inline={true}/>
                    </div>

                    <SelectWithSearch
                        name='whomToInform'
                        label='Whom to inform'
                        values={this.getWhomToInformValues()}
                        inline={true}
                    />

                    <Checkboxes
                        name='howToInform'
                        label='How to inform'
                        values={howToInformValues}
                        inline={true}
                        inlineFields={true}/>

                    <VitalsField
                        name='vitals'
                        vitals={this.props.vitals}
                        weightInKg={weightInKg}
                        tempInF={tempInF} />

                    <div className='buttons'>
                        <button type='submit'
                                disabled={this.state.isUpdating}
                                className='button'>
                            Save
                        </button>

                        <button type='button'
                                disabled={this.state.isUpdating}
                                onClick={this.onDelete}
                                className='button'>
                            <TrashIcon/>
                            Delete
                        </button>

                        {this.state.isUpdating ? <FormLoader /> : null}
                    </div>
                </Form>
            </div>
        );
    }
}


function mapStateToProps(state) {
    const { currentClinic, clinics } = state.user;
    let clinic = null;

    for (let i = 0; i < clinics.length; i += 1) {
        if (currentClinic == clinics[i].id) {
            clinic = clinics[i];
            break;
        }
    }

    return {
        clinic,
        currentClinic,
        isCarePlanLoaded: state.rpmCarePlans.isCarePlanLoaded,
        carePlan: state.rpmCarePlans.carePlan,

        isVitalsLoaded: state.rpmVitals.isVitalsLoaded,
        vitals: state.rpmVitals.vitals,

        isDoctorsLoaded: state.doctors.isDoctorsLoaded,
        doctors: state.doctors.doctors
    }
}


export default connect(mapStateToProps)(RPMEditCarePlan);
