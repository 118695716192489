import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
    clearDailyCarePlans,
    getDailyCarePlans, setCarePlansFilterType,
    setDailyCarePlans
} from '../../../../actions/rpm/care-plans';

class RPMToggleCarePlansList extends Component {
    state = {
        selected: 'recorded',
        loading: true
    }

    render() {
        const { carePlansFilterType } = this.props;

        return (
            <div className='rpm-care-plans-toggle-wrap'>
                <div className='rpm-care-plans-toggle'>
                    <label className='custom-radio'>
                        <input
                            disabled={ !this.props.carePlansLoaded }
                            onChange={ this._toggleList }
                            checked={ carePlansFilterType === 'recorded' }
                            type='radio'
                            name='plans-list'
                            value="recorded"
                        />
                        <span>Recorded daily plans</span>
                    </label>
                    <label className='custom-radio'>
                        <input
                            disabled={ !this.props.carePlansLoaded }
                            onChange={ this._toggleList }
                            checked={ carePlansFilterType === 'not-reporting' }
                            type='radio'
                            name='plans-list'
                            value="not-reporting"
                        />
                        <span>Patients not reporting</span>
                    </label>
                    <label className='custom-radio'>
                        <input
                            disabled={ !this.props.carePlansLoaded }
                            onChange={ this._toggleList }
                            checked={ carePlansFilterType === 'all' }
                            type='radio'
                            name='plans-list'
                            value="all"
                        />
                        <span>All daily plans</span>
                    </label>
                    <label className='custom-radio'>
                        <input
                            disabled={ !this.props.carePlansLoaded }
                            onChange={ this._toggleList }
                            checked={ carePlansFilterType === 'assigned' }
                            type='radio'
                            name='plans-list'
                            value='assigned'
                        />
                        <span>Assigned daily plans</span>
                    </label>
                </div>
            </div>
        );
    }

    _toggleList = option => {
        const { value } = option.target;
        const { dispatch, clinicId } = this.props;

        dispatch(
            setCarePlansFilterType(
                value
            )
        );

        this.props.dispatch(
            clearDailyCarePlans()
        );

        this.setState({
            selected: value,
            loading: true
        }, () => {
            getDailyCarePlans(clinicId, {plans: value}).then((response) => {

                this.props.dispatch(
                    setDailyCarePlans(
                        response.data.dailyCarePlans,
                        value
                    )
                );

                this.setState({
                    loading: false
                });
            });
        });
    }
}

export default connect(state => ({
    clinicId: state.user.currentClinic,
    carePlansLoaded: state.rpmCarePlans.isDailyCarePlansLoaded,
    carePlansFilterType: state.rpmCarePlans.carePlansFilterType
}))(RPMToggleCarePlansList);
