import React from 'react';

export default function FormLoader() {
    return (
        <div className='form-loader'>
            <div className='form-loader-layout'></div>
            <div className='form-loader-img-container'>
                <img
                    src='/static/img/load.gif'
                    alt='ECare21 form loader'
                    width='100'
                />
            </div>
        </div>
    );
}
