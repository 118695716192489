import axios from 'axios';
import ActionConstants from './../../constants/action';
import env from 'react-dotenv';

export function searchTodosByPatient(clinicId, patientId) {

    return axios.get(`${env.API_URL}/api/v1/ccm/todo/patient`, {
        headers: {
            Authorization: `Bearer ${window.getCookie('UserToken')}`
        },
        params: {
            clinicId,
            patientId
        }
    });
}


export function setSearchResult(searchResult) {
    return {
        type: ActionConstants.CCM_SET_SEARCH_RESULT,
        payload: {
            searchResult
        }
    };
}


export function clearSearchResult() {
    return {
        type: ActionConstants.CCM_CLEAR_SEARCH_RESULT
    };
}
