import React from 'react';
import { connect } from 'react-redux';

import {
    getPatientVitals,
    getPatientWeekVitals,
    setPatientWeekVitals,
    addPatientWeekVitals,

    getPatientMonthVitals,
    setPatientMonthVitals,
    addPatientMonthVitals,

    getPatientYearVitals,
    setPatientYearVitals,
    addPatientYearVitals,

    clearPatientAllVitals
} from './../../../actions/charts/patient-vitals';

import RequestLoader from './../../../components/request-loader';
import PatientVitalCharts from './../../../components/patient-vital-charts';

const defaultProps = {
    showCarePlanLink: true
};

class PatientAllVitalCharts extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isPatientWeekFailed: false,
            isPatientMonthFailed: false,
            isPatientYearFailed: false
        };

        this._loadMorePatientVitals = this._loadMorePatientVitals.bind(this);
    }

    componentDidMount() {
        this._loadPatientWeekVitals();
        this._loadPatientMonthVitals();
        this._loadPatientYearVitals();
    }

    componentDidUpdate(prevProps) {
        if (this.props.isChartsUpdate !== prevProps.isChartsUpdate) {
            this._loadPatientWeekVitals();
            this._loadPatientMonthVitals();
            this._loadPatientYearVitals();
        }
    }

    componentWillUnmount() {
        this.props.dispatch(clearPatientAllVitals());
    }

    render() {
        const {
            isPatientWeekFailed,
            isPatientMonthFailed,
            isPatientYearFailed
        } = this.state;

        if (isPatientWeekFailed && isPatientMonthFailed && isPatientYearFailed) {
            return (
                <div className='patient-vital-charts'>
                    <h1 className='patient-vital-charts-header'>
                        Monitoring Data
                    </h1>
                    <div>
                        This patient doesn't have monitoring plans to show
                    </div>
                </div>
            );
        }

        if (!this.props.isPatientWeekVitalsLoaded ||
            !this.props.isPatientMonthVitalsLoaded ||
            !this.props.isPatientYearVitalsLoaded) {
            return (
                <RequestLoader center />
            );
        }

        return (
            <div className='patient-vital-charts'>
                <h1 className='patient-vital-charts-header'>
                    Monitoring Data
                </h1>

                <div className='vitals-wrap'>
                    <PatientVitalCharts
                        weeksVitals={this.props.patientWeekVitals}
                        monthsVitals={this.props.patientMonthVitals}
                        yearsVitals={this.props.patientYearVitals}
                        isChartsUpdate={this.props.isChartsUpdate}
                        onLoad={this._loadMorePatientVitals} />
                </div>
            </div>
        );
    }

    _loadPatientWeekVitals() {
        const { dispatch, patient, clinic } = this.props;

        getPatientVitals(clinic, patient, 'week').then(res => {

            dispatch(
                setPatientWeekVitals(
                    res.data
                )
            );
        }).catch(e => {
            this.setState({
                isPatientWeekFailed: true
            });
        });
    }

    _loadPatientMonthVitals() {

        const {dispatch, patient, clinic} = this.props;

        getPatientVitals(clinic, patient, 'month').then(res => {

            dispatch(
                setPatientMonthVitals(
                    res.data
                )
            );
        }).catch(e => {
            this.setState({
                isPatientMonthFailed: true
            });
        });
    }

    _loadPatientYearVitals() {

        const { dispatch, patient, clinic } = this.props;

        getPatientVitals(clinic, patient, 'year').then(res => {

            dispatch(
                setPatientYearVitals(
                    res.data
                )
            );
        }).catch(e => {
            this.setState({
                isPatientYearFailed: true
            });
        });
    }

    _loadMorePatientVitals(time, date, callback) {
        const { patient } = this.props;

        if (time === 'weeks') {
            const req = {
                patient,
                date
            };

            getPatientWeekVitals(req).then((res) => {
                if (res.result) {
                    this.props.dispatch(addPatientWeekVitals(res.data));
                }

                if (res.result && res.data.vitalsChartData.length > 0) {
                    callback(false);
                } else {
                    callback(true);
                }
            });
        } else if (time === 'months') {
            const req = {
                patient,
                date
            };

            getPatientMonthVitals(req).then((res) => {
                if (res.result) {
                    this.props.dispatch(addPatientMonthVitals(res.data));
                }

                if (res.result && res.data.vitalsChartData.length > 0) {
                    callback(false);
                } else {
                    callback(true);
                }
            });
        } else if (time === 'years') {
            const req = {
                patient,
                date
            };

            getPatientYearVitals(req).then((res) => {
                if (res.result) {
                    this.props.dispatch(addPatientYearVitals(res.data));
                }

                if (res.result && res.data.vitalsChartData.length > 0) {
                    callback(false);
                } else {
                    callback(true);
                }
            });
        }
    }
}

PatientAllVitalCharts.defaultProps = defaultProps;

const mapStateToProps = function (state) {
    const {
        patientWeekVitalsCarePlanId,

        isPatientWeekVitalsLoaded,
        patientWeekVitals,

        isPatientMonthVitalsLoaded,
        patientMonthVitals,

        isPatientYearVitalsLoaded,
        patientYearVitals,

        isChartsUpdate
    } = state.chartsPatientVitals;

    return {
        patientWeekVitalsCarePlanId,

        isPatientWeekVitalsLoaded,
        patientWeekVitals,

        isPatientMonthVitalsLoaded,
        patientMonthVitals,

        isPatientYearVitalsLoaded,
        patientYearVitals,
        isChartsUpdate
    };
};

export default connect(mapStateToProps)(PatientAllVitalCharts);
