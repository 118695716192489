import React, { Component } from 'react';
import { connect } from 'react-redux';
import { clearPrimaryDiagnoses, getPrimaryDiagnoses, setPrimaryDiagnoses } from '../../../../../actions/rpm/care-plans';
import RequestLoader from '../../../../../components/request-loader';

class RPMPatientDiagnoses extends Component {

    state = {
        expanded: false
    }

    componentDidMount() {

        const { dispatch, clinicId, patientId } = this.props;

        getPrimaryDiagnoses(clinicId, patientId).then(result => {

            dispatch(
                setPrimaryDiagnoses(result.data.diagnoses)
            );
        });
    }

    componentWillUnmount() {

        const { dispatch } = this.props;

        dispatch(
            clearPrimaryDiagnoses()
        );
    }

    render() {

        const { isPrimaryDiagnosesLoaded, primaryDiagnoses } = this.props;
        const { expanded } = this.state;

        if (!isPrimaryDiagnosesLoaded) {

            return <RequestLoader center />
        }

        return (
            <div className='diagnoses'>
                <h3 className='title'>Primary diagnoses { primaryDiagnoses.length > 3 ? <span onClick={ this._toggle } className='toggle'>Show { !expanded ? 'more' : 'less' }</span> : '' }</h3>
                <ul>
                    { primaryDiagnoses.length
                        ? primaryDiagnoses.map((diagnos, i) => (i < 3 || expanded ? <li><b>{diagnos.code}</b> {diagnos.display}</li> : null ))
                        : <li>No Primary Diagnoses</li>}
                </ul>
            </div>
        )
    }

    _toggle = () => {

        this.setState(state => ({
            expanded: !state.expanded
        }));
    }
}

export default connect(state => ({
    isPrimaryDiagnosesLoaded: state.rpmCarePlans.isPrimaryDiagnosesLoaded, 
    primaryDiagnoses: state.rpmCarePlans.primaryDiagnoses
}))(RPMPatientDiagnoses);
