import React from 'react';
import {connect} from 'react-redux';

import MainConstants from './../../constants/main';

import RequestLoader from './../../components/request-loader';

import {
    getCarePlan,
    setCarePlan,
    clearCarePlan,
    getCarePlanComments,
    setCarePlanComments,
    clearCarePlanComments,
    createCarePlanComment
} from '../../actions/rpm/care-plans';

import { formatDayDate } from '../../lib/date';
import { lines } from '../../lib/lines';

import WeekdaysWithDayParts from './../../components/weekdays';

import Form from './../../lib/forms/form';
import Textarea from './../../lib/forms/textarea';
import { required } from '../../lib/forms/field-validators';


class RPMShowCarePlan extends React.Component {
    componentDidMount() {
        this.loadCarePlan();
        this.loadCarePlanComments();
    }

    componentWillUnmount() {
        this.props.dispatch(clearCarePlan());
        this.props.dispatch(clearCarePlanComments());
    }

    constructor(props) {
        super(props);

        this.state = {
            isCreatingComment: false
        };

        this.createCommentForm = React.createRef();

        this._goToEditCarePlan = this._goToEditCarePlan.bind(this);
        this._onCommentSubmit = this._onCommentSubmit.bind(this);
    }

    renderCarePlan() {

        return (
            <div className='care-plan-view'>
                <div>
                    <div>Patient</div>
                    <div>{this.props.carePlan.patientFirstName + ' ' + this.props.carePlan.patientLastName}</div>
                </div>
                <div>
                    <div>Contact name</div>
                    <div>{this.props.carePlan.contactName}</div>
                </div>
                <div>
                    <div>Contact phone</div>
                    <div>{this.props.carePlan.contactPhone}</div>
                </div>
                <div>
                    <div>Phone</div>
                    <div>{this.props.carePlan.patientPhoneNumber}</div>
                </div>
                <div>
                    <div>Phone (additional)</div>
                    <div>{this.props.carePlan.patientPhoneNumberAdditional}</div>
                </div>
                <div>
                    <div>Medical assistant</div>
                    <div>{this.props.carePlan.collectorFirstName + ' ' + this.props.carePlan.collectorLastName}</div>
                </div>
                <div>
                    <div>Whom to inform</div>
                    <div>{this.props.carePlan.informDoctorId === 0 ? 'Automatic collection of data' : this.props.carePlan.informDoctorFirstName + ' ' + this.props.carePlan.informDoctorLastName}</div>
                </div>
                <div>
                    <div>How to inform</div>
                    <div>{this.renderHowToInform()}</div>
                </div>
                <div>
                    <div>Prescribed by</div>
                    <div>{this.props.carePlan.doctorFirstName + ' ' + this.props.carePlan.doctorLastName}</div>
                </div>
                <div>
                    <div>Start by</div>
                    <div>{formatDayDate(this.props.carePlan.date)}</div>
                </div>
            </div>
        );
    }

    renderCarePlanVitals() {
        const metricWeight = this.props.carePlan.metricWeight;
        const metricTemp = this.props.carePlan.metricTemp;
        const vitals = this.props.carePlan.vitals.map((vital) => {
        var metricName = '';
            if (vital.vitalId === 17 || vital.vitalId === 37) {
                if (metricTemp === false) {
                    metricName = '(C)'
                } else if(metricTemp === true){
                    metricName = '(F)'
                }
            }
            if (vital.vitalId === 22) {
                if (metricWeight === false) {
                    metricName = '(lb)'
                } else if(metricWeight === true) {
                    metricName = '(kg)'
                }
            }
            return (
                <div id={vital.id} className='vital'>
                    <div>{vital.name} {metricName}</div>
                    <div>
                        <WeekdaysWithDayParts name={'vital' + vital.id}
                                              weekdays={vital.daysOfWeek}
                                              parts={vital.partOfDay}
                                              thresholdsType={vital.thresholdsType}
                                              thresholds={vital.thresholds}/>
                    </div>
                </div>
            );
        });

        return (
            <div className='care-plan-vitals-view'>
                {vitals}
            </div>
        );
    }

    renderHowToInform() {
        const names = {};

        for (let i = 0; i < MainConstants.rpmCarePlansInformMethods.length; i += 1) {
            const v = MainConstants.rpmCarePlansInformMethods[i];
            names[v.value] = v.name;
        }

        const result = [];

        for (let informMethod in this.props.carePlan.informMethods) {
            if (!this.props.carePlan.informMethods[informMethod]) {
                continue;
            }

            if (names[informMethod]) {
                result.push(names[informMethod]);
            } else {
                result.push(informMethod);
            }
        }

        return result.join(', ');
    }

    renderComments() {
        let comments = null;

        if (!this.props.isCarePlanCommentsLoaded) {
            comments = (
                <RequestLoader center/>
            );
        } else {
            if (this.props.carePlanComments.length === 0) {
                comments = (
                    <div className='message'>
                        No comments
                    </div>
                );
            } else {
                comments = this.props.carePlanComments.map((comment) => {
                    return (
                        <div className='care-plan-comment'>
                            <div className='care-plan-comment-info'>
                                <div>{comment.createdByFirstName + ' ' + comment.createdByLastName}</div>
                                <div>{formatDayDate(comment.createdAt)}</div>
                            </div>
                            <div className='care-plan-comment-body'>
                                {lines(comment.comment)}
                            </div>
                        </div>
                    );
                });
            }
        }

        return (
            <div className='care-plan-comments-section'>
                <Form ref={this.createCommentForm} onSubmit={this._onCommentSubmit} className='form'>
                    <Textarea name='comment'
                              label='Comment'
                              rows={2}
                              validators={[required('Please enter comment')]}/>
                    <div className='buttons'>
                        <button disabled={this.state.isCreatingComment} className='button'>
                            Add comment
                        </button>
                    </div>
                </Form>

                <div className='care-plan-comments'>
                    {comments}
                </div>
            </div>
        );
    }

    render() {
        if (!this.props.isCarePlanLoaded) {
            return (
                <RequestLoader center/>
            );
        }

        return (
            <div>
                {this.renderCarePlan()}
                {this.renderCarePlanVitals()}

                <div className='care-plan-report-buttons'>
                    <button onClick={this._goToEditCarePlan} className='button'>
                        Edit
                    </button>
                </div>

                {this.renderComments()}
            </div>
        );
    }

    loadCarePlan() {
        const id = this._getPlanId();

        getCarePlan(this.props.currentClinic, id).then(res => {

            this.props.dispatch(
                setCarePlan(
                    res.data.carePlan
                )
            );
        }).catch(e => {

            this.props.history.push('/app/rpm/monitoring-plans');
        });
    }

    loadCarePlanComments() {
        const planId = this._getPlanId();

        const { dispatch, currentClinic } = this.props;

        getCarePlanComments(currentClinic, planId).then((res) => {

            dispatch(
                setCarePlanComments(
                    res.data.comments
                )
            );
        });
    }

    _getPlanId() {
        const planId = parseInt(this.props.match.params.planId, 10);

        if (isNaN(planId)) {
            this.props.history.push('/app/rpm/monitoring-plans');
            return;
        }

        return planId;
    }

    _goToEditCarePlan() {
        const id = this._getPlanId();
        this.props.history.push('/app/rpm/monitoring-plans/' + id + '/edit');
    }

    _onCommentSubmit(data) {
        const planId = this._getPlanId();
        const { dispatch, currentClinic } = this.props;

        this.setState({ isCreatingComment: true }, () => {
            createCarePlanComment(currentClinic, planId, data.comment).then(res => {
                this.createCommentForm.current.clear();
                dispatch(
                    clearCarePlanComments()
                );
                this.loadCarePlanComments();
                this.setState({
                    isCreatingComment: false
                });
            });
        });
    }
}


function mapStateToProps(state) {

    return {
        currentClinic: state.user.currentClinic,
        isCarePlanLoaded: state.rpmCarePlans.isCarePlanLoaded,
        carePlan: state.rpmCarePlans.carePlan,

        isCarePlanCommentsLoaded: state.rpmCarePlans.isCarePlanCommentsLoaded,
        carePlanComments: state.rpmCarePlans.carePlanComments
    };
}


export default connect(mapStateToProps)(RPMShowCarePlan);
