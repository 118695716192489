import React from 'react';
import moment from 'moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import Lines from './../components/lines';
import {formatShortDate} from './../lib/date';
import userInitials from './../lib/user-initials';
import {setTodos} from './../actions/ccm/todo';

export default class TodosTableWithDayPicker extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hideDone: localStorage.getItem(this.props.name) === 'true' ? true : false,
            selectedDay: undefined
        };

        this._onDayClick = this._onDayClick.bind(this);
        this._onDayClear = this._onDayClear.bind(this);
        this._formatDayPickerDate = this._formatDayPickerDate.bind(this);
    }

    render() {
        if (!this.props.isTodosLoaded) {
            return null;
        }

        let rows = null;

        if (this.props.todos.length === 0) {
            rows = (
                <tr className='empty-table-message'>
                    <td colspan='7'>{this.props.emptyTableMessage}</td>
                </tr>
            );
        } else {
            const todos = [];

            for (let i = 0; i < this.props.todos.length; i += 1) {
                const todo = this.props.todos[i];

                if (todo.status == 'DONE' && this.state.hideDone) {
                    continue;
                }

                todos.push(todo);
            }

            rows = todos.map((todo) => {
                let actionName = todo.actionName;
                let cssName = 'normal';
                   
                if (todo.actionCode) {
                    actionName = `${todo.actionCode} - ${todo.actionName}`;
                }

                if (todo.actionName === 'Warning from RPM' && todo.status === 'WAITING') {
                    cssName = 'warning'
                }

                return (
                    <tr className={cssName} key={todo.id} onClick={() => { this._goToTodo(todo.id) }}>
                        <td>{this.renderStatus(todo)}</td>
                        <td>{userInitials(todo.createdByFirstName, todo.createdByLastName)}</td>
                        <td style={todo.priority == 'Critical' ? { background: 'red' } : null}>{todo.patientFirstName + ' ' + todo.patientLastName}</td>
                        <td>{actionName}</td>
                        <td>{todo.assignedToFirstName + ' ' + todo.assignedToLastName}</td>
                        <td>{this.renderDescription(todo)}</td>
                        <td>{todo.priority}</td>
                        <td>{todo.sms && todo.notification ?
                            <div>SMS <br></br> Notification to app</div>
                            : todo.sms ? 'SMS'
                                : todo.notification ? 'Notification to app' : ''}</td>
                        <td>{formatShortDate(todo.createdAt)}</td>
                    </tr>
                );
            });
        }

        let clearDay = null;

        if (this.state.selectedDay) {
            clearDay = (
                <div>
                    <a href='' onClick={this._onDayClear}>[x]</a>
                </div>
            );
        }

        return (
            <div className='dashboard-table table-with-day-picker'>
                <div className='table-with-day-picker-header'>
                    <header>
                        {this.props.headerName}
                    </header>

                    <div className='table-with-day-picker-filter'>

                        <div className='date-picker-pre-message'>
                            Show items for:
                        </div>

                        <DayPickerInput
                            overlayComponent={CustomOverlay}
                            value={this.state.selectedDay}
                            placeholder='...'
                            inputProps={{readOnly: true}}
                            formatDate={this._formatDayPickerDate}
                            onDayChange={this._onDayClick}
                            dayPickerProps={{
                                selectedDays: this.state.selectedDay,
                                modifiers: {
                                    disabled: {
                                        before: new Date('2017/12/01'),
                                        after: new Date(moment().format('YYYY/MM/DD'))
                                    }
                                }
                            }} />
                        {clearDay}
                    </div>
                </div>

                <table className='table table-row-hover dashboard-todos'>
                    <thead>
                        <tr>
                            <th style={{ cursor: 'pointer' }} onClick={() => this.props.sortFunction('warningStatus', 'todos', setTodos)}>{this._getWaitingTodosCount()}</th>
                            <th className='sortable'>
                                <span onClick={() => this.props.sortFunction('createdByLastName', 'todos', setTodos) }>By</span>
                            </th>
                            <th className='sortable'>
                                <span onClick={() => this.props.sortFunction('patientLastName', 'todos', setTodos) }>Patient</span>
                            </th>
                            <th className='sortable'>
                                <span onClick={() => this.props.sortFunction('actionName', 'todos', setTodos) }>Action</span>
                            </th>
                            <th className='sortable'>
                                <span onClick={() => this.props.sortFunction('assignedToLastName', 'todos', setTodos) }>Assigned to</span>
                            </th>
                            <th>Description / Resolution</th>
                            <th className='priority'>Priority</th>
                            <th className='notification'>Notification</th>
                            <th className='sortable'>
                                <span onClick={() => this.props.sortFunction('unixtime', 'todos', setTodos) }>Created</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows}
                    </tbody>
                </table>
            </div>
        );
    }

    renderStatus(todo){
        if (todo.actionName === 'Warning from RPM') {
            if (todo.status === 'WAITING' || todo.status === 'DONE') {
                return(
                    <img src={`/static/img/icon-warning-${todo.status.toLowerCase()}.png`} alt={todo.status.toUpperCase()} title={todo.status.toUpperCase()}/>
                )
            }
        } else {
            if(todo.status){
                return (
                    <img src={`/static/img/icon-${todo.status.toLowerCase()}.png`} alt={todo.status.toUpperCase()} title={todo.status.toUpperCase()}/>
                )
            } else {
                return null;
            }
        }
    }

    renderDescription(todo) {
        const description = [];

        if (todo.description) {
            description.push((
                <div key={0} className='text-block'>
                    <Lines data={todo.description}/>
                </div>
            ));
        }

        if (todo.resolutions && todo.resolutions.length > 0) {
            for (let i = 0; i < todo.resolutions.length; i += 1) {
                description.push((
                    <div key={i+1} className='text-block'>
                        <Lines data={todo.resolutions[i]}/>
                    </div>
                ));
            }
        }

        return description;
    }

    _getWaitingTodosCount() {
        if (!this.props.isTodosLoaded) {
            return null;
        }

        let count = 0;

        for (let i = 0; i < this.props.todos.length; i += 1) {
            if (this.props.todos[i].status === 'WAITING') {
                count += 1;
            }
        }

        return (
            <span>{count}</span>
        );
    }

    _goToTodo(todoId) {
        this.props.history.push('/app/ccm/todo/' + todoId);
    }

    _onDayClear(evt) {
        evt.preventDefault();
        this.setState({selectedDay: undefined});
        this.props.onDayChange();
    }

    _onDayClick(day) {
        this.setState({selectedDay: day});
        this.props.onDayChange(day);
    }

    _formatDayPickerDate(d) {
        var year = d.getFullYear();
        var month = d.getMonth() + 1;
        if (month < 10) {
            month = '0' + month;
        }
        var day = d.getDate() < 10 ? '0' + d.getDate() : d.getDate();
        return month + '/' + day + '/' + year;
    }
}

function CustomOverlay({classNames, children}) {
    return (
        <div className={classNames.overlayWrapper} style={{marginLeft: -170}}>
            <div className={classNames.overlay}>
                {children}
            </div>
        </div>
    );
}
