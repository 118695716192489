import React from 'react';

const chatSquareDots = () => {
    return (
        <img className='icon'
             alt='Chat'
             src='/static/bootstrap/icons/chat-square-dots.svg' />
    );
}

export default chatSquareDots;
