import React from 'react';

const cameraVideo = () => {
    return (
        <img className='icon'
             alt='Camera video'
             src='/static/bootstrap/icons/camera-video.svg' />
    );
}

export default cameraVideo;
