import axios from 'axios';
import ActionConstants from './../../constants/action';
import env from 'react-dotenv';

export function getVitals(clinicId) {
    
    return axios.get(`${env.API_URL}/api/v1/vitals`, {
        headers: {
            Authorization: `Bearer ${window.getCookie('UserToken')}`
        },
        params: {
            clinicId
        }
    });
}

export function setVitals(vitals) {
    return {
        type: ActionConstants.RPM_SET_VITALS,
        payload: {
            vitals
        }
    };
}

export function clearVitals() {
    return {
        type: ActionConstants.RPM_CLEAR_VITALS
    };
}
