import ActionConstants from './../constants/action';

const initialState = {
    patientsChats: {}
};

function concatMessages(oldMessages, newMessages) {
    const oldMessagesIds = oldMessages.map((msg) => msg.id);

    const ret = [...oldMessages];

    for (let i = 0; i < newMessages.length; i += 1) {
        const msg = newMessages[i];
        if (oldMessagesIds.indexOf(msg.id) === -1) {
            ret.push(msg);
        }
    }

    return ret;
}

export default function(state, action) {
    if (action.type === ActionConstants.CHAT_SET_PATIENT_MESSAGES) {
        const { patientId, messages } = action.payload;

        const patientsChats = {
            ...state.patientChats,
            [patientId]: messages
        };

        return {
            ...state,
            patientsChats
        };
    }

    if (action.type === ActionConstants.CHAT_ADD_PATIENT_MESSAGES) {
        const { patientId, messages } = action.payload;

        const oldMessages = state.patientsChats[patientId] || [];

        const patientsChats = {
            ...state.patientChats,
            [patientId]: concatMessages(messages, oldMessages)
        };

        return {
            ...state,
            patientsChats
        };
    }

    if (action.type === ActionConstants.CHAT_PUSH_PATIENT_MESSAGES) {
        const { patientId, messages } = action.payload;

        const oldMessages = state.patientsChats[patientId] || [];

        const patientsChats = {
            ...state.patientChats,
            [patientId]: concatMessages(oldMessages, messages)
        };

        return {
            ...state,
            patientsChats
        };
    }

    if (action.type === ActionConstants.CHAT_CLEAR_PATIENT_MESSAGES) {
        const { patientId } = action.payload;

        const patientsChats = {
            ...state.patientChats,
            [patientId]: undefined
        };

        return {
            ...state,
            patientsChats
        };
    }

    return state || { ...initialState };
}
