import ActionConstants from './../constants/action';

const initialState = {
    isBlockedSession: false,
    userId: '',
    firstName: '',
    lastName: '',
    clinics: [],
    currentClinic: null
};

const setState = (state, action) => {
    if (action.type === ActionConstants.SET_USER_SESSION_BLOCK) {
        const { isBlockedSession } = action.payload;

        return {
            ...state,
            isBlockedSession
        };
    }

    if (action.type === ActionConstants.SET_USER) {

        return {
            ...state,
            userId: action.payload.user.id,
            firstName: action.payload.user.firstName,
            lastName: action.payload.user.lastName,
            clinics: action.payload.user.clinics,
            currentClinic: action.payload.user.currentClinic
        };
    }

    if (action.type === ActionConstants.SET_USER_CURRENT_CLINIC) {

        return {
            ...state,
            currentClinic: action.payload.clinic
        };
    }

    return state || { ...initialState };
}

export default setState;
