import React from 'react';

const calendar = () => {
    return (
        <img className='icon'
             alt='Calendar'
             src='/static/bootstrap/icons/calendar.svg' />
    );
}

export default calendar;
