import React, { Component } from 'react';
import { connect } from 'react-redux';
import RequestLoader from './../../../../components/request-loader';
import { clearDailyCarePlans, getDailyCarePlans, setDailyCarePlans } from '../../../../actions/rpm/care-plans';
import CommonPopup from '../../../../components/common-popup';
import { getUserCurrentLocalClinic } from '../../../../actions/user';
import ModalChatWrapper from '../../../../containers/modal-chat-wrapper';

class RPMCarePlansTable extends Component
{
    sortStatus = {}

    state = {
        communications: null,
        plan: null
    }

    componentDidMount() {
        const { dispatch, clinicId, carePlansFilterType } = this.props;

        getDailyCarePlans(clinicId, {plans: carePlansFilterType}).then((response) => {

            dispatch(
                setDailyCarePlans(
                    response.data.dailyCarePlans
                )
            );
        });
    }

    componentWillUnmount() {

        this.props.dispatch(
            clearDailyCarePlans()
        );
    }

    _sortByColumn = (column, source, callback) => {

        if (undefined === this.sortStatus[source]) {

            this.sortStatus[source] = {};
        }

        if (column !== this.sortStatus[source].sortColumn) {

            this.sortStatus[source].sortDirection = 'ASC';
            this.sortStatus[source].sortColumn = column;
        }

        const sortDirection = this.sortStatus[source].sortDirection;

        const list = this.props[source] ? this.props[source].sort((a, b) => {

            let columnA = a[column];
            let columnB = b[column];

            if (typeof a[column] === 'string' && typeof b[column] === 'string') {

                columnA = columnA.toUpperCase();
                columnB = columnB.toUpperCase();
            }

            return columnA > columnB
                ? (sortDirection === 'DESC' ? -1 : 1)
                : (columnA < columnB
                    ? (sortDirection === 'DESC' ? 1 : -1)
                    : 0);
        }) : [];

        this.sortStatus[source].sortDirection = this.sortStatus[source].sortDirection === 'ASC' ? 'DESC' : 'ASC';

        this.props.dispatch(
            callback([...list], this.props.carePlansFilterType)
        );
    }

    render() {

        const { carePlanType, dailyCarePlans, carePlansLoaded } = this.props;

        if (!carePlansLoaded) {

            return (
                <div className='assigned-daily-care-plans'>
                    <RequestLoader center />
                </div>
            );
        }

        return (
            <div>
                { this._renderPatientCommunications()}
                <div className='daily-care-plans'>
                    <table className='table table-row-hover care-plans-table'>
                        <thead>
                            <tr>
                                <th style={{ width: '10px', textAlign: 'center' }}>Status</th>
                                <th onClick={() => { this._sortByColumn('patientLastName', 'dailyCarePlans', setDailyCarePlans) }} className="sortable">Patient</th>
                                <th>Phone</th>
                                <th style={{
                                    width: '215px'
                                }}>Vitals</th>
                                <th>Results</th>
                                <th>{carePlanType === 'not-reporting' ? 'Last Reporting Time' : 'Recent Vitals Data'}</th>
                                <th onClick={() => { this._sortByColumn('doctorLastName', 'dailyCarePlans', setDailyCarePlans) }} className="sortable">Care Team</th>
                                <th style={{ width: '190px'}}>Last review date</th>
                                <th style={{
                                    width: '130px'
                                }} />
                            </tr>
                        </thead>
                        <tbody>
                            {dailyCarePlans.length > 0 ? dailyCarePlans.map(plan => {

                                return (
                                    <tr key={plan.PlanId} onClick={() => this._reportDailyCarePlan(plan.planId)}>
                                        <td style={{ textAlign: 'center' }}>
                                            {this._renderPlanStatus(plan.isTodayDoctorReported)}
                                        </td>
                                        <td>{plan.patientFirstName} {plan.patientLastName}</td>
                                        <td>
                                            <span>{plan.contactPhone}</span>
                                            <span>{plan.patientPhoneNumber}</span>
                                            <span>{plan.patientPhoneNumberAdditional}</span>
                                        </td>
                                        <td>{this._renderVitals(plan.metrics)}</td>
                                        <td>{this._renderVitalsValues(plan.metrics)}</td>
                                        <td>{this._renderVitalsReportedTime(plan.metrics)}</td>
                                        <td>{plan.doctorName}</td>
                                        <td>{this._renderResolution(plan.resolution)}</td>
                                        <td>
                                            <div className='icons-wrapper'>
                                                <span className="icon icon-phone" onClick={(e) => {
                                                    e.stopPropagation();
                                                    this._setPatientCommunications('call-patient', plan);
                                                }} />
                                                <span className="icon icon-camera" onClick={(e) => {
                                                    e.stopPropagation();
                                                    this._tryVideoChat(plan)
                                                }} />
                                                <span className="icon icon-chat" onClick={e => {
                                                    e.stopPropagation();
                                                    this._setPatientCommunications('chat-patient', plan);
                                                }} />
                                            </div>
                                        </td>
                                    </tr>
                                );
                            }) : <tr><td style={{ textAlign: 'center', fontWeight: 'bold' }} colSpan='8'>No Records Found</td></tr>}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }

    _renderResolution = resolution => {

        return resolution ? (<div style={{ whiteSpace: 'normal' }}>{resolution.createdAt}<br /><b>Resolution: </b>{resolution.resolution ? resolution.resolution : 'No resolution'}</div>) :
            (<div><b>Resolution: </b> No resolution</div>);
    }

    _reportDailyCarePlan = planId => this.props.history.push('/app/rpm/daily-monitoring-plans/' + planId + '/report');

    _renderWarningClass = level => this.statuses[level-1];

    _renderVitals = metrics => (
        <ul className='vitals-list'>
            { metrics.map(metric => <li className={ metric.isTodayReported && metric.warningLevel !== 1 ? 'warning' : '' }>{ metric.name } { metric.isTodayReported ? <span className='checkmark' /> : '' }</li>) }
        </ul>
    );

    _renderVitalsValues = metrics => (
        <ul className='vitals-list'>
            { metrics.map(metric => <li>{ metric.lastValueCreatedDate ? metric.lastValue : 'N/A' } </li>) }
        </ul>
    );

    _renderVitalsReportedTime = metrics => {
        const { carePlanType } = this.props;

        return (<ul className='vitals-list'>
            { metrics.map(metric => {
                return (
                    <li>{ carePlanType !== 'not-reporting' ? metric.lastValueCreatedDate ? metric.lastValueCreatedDate : 'N/A' : metric.hoursAfterMeasurement >= 8 ? <span> { metric.hoursAfterMeasurement <= 24 ? `${metric.hoursAfterMeasurement} hours ago` : `Last reporting date ${metric.lastValueCreatedDate}` }</span> : '' }</li>
                );
            })}
        </ul>)
    };

    _renderPlanStatus = (isReported) => {

        const checked = isReported ? 'checked' : '';

        return (
            <span className={`checkmark-status ${checked}`}/>
        );
    }

    _setPatientCommunications = (
        communications,
        plan
    ) => {
        this.setState({
            communications,
            plan
        });
    }

    _renderPatientCommunications = ()  => {
        let communicationsActions = null;
        const {
            communications,
            plan
        } = this.state;

        if (communications === 'chat-patient') {
            communicationsActions = (
                <CommonPopup header={`Chat with ${plan.patientFirstName} ${plan.patientLastName}`}
                             onClose={() => { this._setPatientCommunications(null, null); }}>
                    <ModalChatWrapper clinicId={getUserCurrentLocalClinic() || window.projectUser.currentClinic}
                                      patientUserId={ plan.patientId }/>
                </CommonPopup>
            );
        } else if (communications === 'call-patient') {
            communicationsActions = (<CommonPopup header={`Call to ${plan.patientFirstName} ${plan.patientLastName}` }
                         onClose={() => { this._setPatientCommunications(null, null); }}>
                <ul className='call-patient-container'>
                    {!plan.patientPhoneNumber && !plan.patientPhoneNumberAdditional
                        ? <li>No available phone numbers</li>
                        : null }
                    {plan.patientPhoneNumber ? <li><a href={`tel:${plan.patientPhoneNumber}`}> Call to: { plan.patientPhoneNumber }</a></li> : null }
                    {plan.patientPhoneNumberAdditional ? <li><a href={ `tel:${plan.patientPhoneNumberAdditional}`}>Call to: { plan.patientPhoneNumberAdditional } (additional)</a></li> : null }
                </ul>
            </CommonPopup>)
        }

        return communicationsActions;
    }

    _tryVideoChat = (plan) => {

        const params = [
            `patientUserId=${plan.patientId}`,
            `employeeUserId=${plan.currentDoctorId}`,
            `clinicId=${this.props.clinicId}`
        ];

        const url = `${window.env.Video_Call_Url}?${params.join('&')}`;
        const win = window.open(url, '_blank');
        win.focus();
    }
}

export default connect(state => {

    return {
        clinicId: state.user.currentClinic,
        dailyCarePlans: state.rpmCarePlans.dailyCarePlans,
        carePlansFilterType: state.rpmCarePlans.carePlansFilterType,
        carePlansLoaded: state.rpmCarePlans.isDailyCarePlansLoaded
    };
})(RPMCarePlansTable);
