const keyMirror = (keys) => {
    var result = {};

    for (let i in keys) {
        let key = keys[i];
        result[key] = key;
    }

    return result;
}

export default keyMirror;
