import React from 'react';

import { rpmLayoutRoutes } from './../../routes';


export default class RPMLayout extends React.Component {
    render() {
        return (
            <div className='rpm'>
                { rpmLayoutRoutes }
            </div>
        );
    }
}
