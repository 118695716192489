import React, { Component } from 'react';
import { connect } from 'react-redux';
import RequestLoader from '../../../../../components/request-loader';

class RPMContactInfo extends Component {

    render() {

        const { isDailyCarePlanLoaded } = this.props;

        if (!isDailyCarePlanLoaded) {

            return <RequestLoader center />
        }

        const { dailyCarePlan } = this.props;

        return (
            <div className='contact-info'>
                <h1 className='title'>{dailyCarePlan.patientFirstName} {dailyCarePlan.patientLastName}</h1>
                <div className='contact-block'>
                    <div className='name'>Contact name</div>
                    <div className='value'>{ dailyCarePlan.contactName }</div>
                </div>
                <div className='contact-block'>
                    <div className='name'>Contact phone</div>
                    <div className='value'>{dailyCarePlan.contactPhone}</div>
                </div>
                <div className='contact-block'>
                    <div className='name'>Phone</div>
                    <div className='value'>{dailyCarePlan.patientPhoneNumber}</div>
                </div>
                <div className='contact-block'>
                    <div className='name'>Phone (additional)</div>
                    <div className='value'>{dailyCarePlan.patientPhoneNumberAdditional}</div>
                </div>
                <div className='contact-block'>
                    <div className='name'>Medical assistant</div>
                    <div className='value'>{dailyCarePlan.collectorFirstName} {dailyCarePlan.collectorLastName }</div>
                </div>
                <div className='contact-block'>
                    <div className='name'>Whom to inform</div>
                    <div className='value'>{dailyCarePlan.informDoctorId ? `${dailyCarePlan.informDoctorFirstName} ${dailyCarePlan.informDoctorLastName}` : `Automatic collection of data`}</div>
                </div>
                <div className='contact-block'>
                    <div className='name'>Prescribed by</div>
                    <div className='value'>{dailyCarePlan.doctorFirstName} {dailyCarePlan.doctorLastName}</div>
                </div>
                <div className='contact-block'>
                    <div className='name'>Start by</div>
                    <div className='value'>{dailyCarePlan.startDate}</div>
                </div>
            </div>
        )
    }
}

export default connect((state) => {
    return {
        isDailyCarePlanLoaded: state.rpmCarePlans.isDailyCarePlanLoaded,
        dailyCarePlan: state.rpmCarePlans.dailyCarePlan
    };
})(RPMContactInfo);