import axios from 'axios';
import ActionConstants from './../../constants/action';
import env from 'react-dotenv';

export function getAssignedTodos(clinicId) {

    return axios.get(`${env.API_URL}/api/v1/ccm/todo/assigned`, {
        headers: {
            Authorization: `Bearer ${window.getCookie('UserToken')}`
        },
        params: {
            clinicId
        }
    });
}

export function getAssignedTodosByDate(clinicId, assignedDate) {

    return axios.get(`${env.API_URL}/api/v1/ccm/todo/assigned`, {
        headers: {
            Authorization: `Bearer ${window.getCookie('UserToken')}`
        },
        params: {
            clinicId,
            assignedDate
        }
    });
}

export function getTodos(clinicId, date) {

    return axios.get(`${env.API_URL}/api/v1/ccm/todo/all`, {
        headers: {
            Authorization: `Bearer ${window.getCookie('UserToken')}`
        },
        params: {
            clinicId,
            date
        }
    });
}

export function getTodo(clinicId, todoId) {

    return axios.get(`${env.API_URL}/api/v1/ccm/todo`, {
        headers: {
            Authorization: `Bearer ${window.getCookie('UserToken')}`
        },
        params: {
            clinicId,
            todoId
        }
    });
}

export function createTodo(clinicId, todo) {

    return axios.post(`${env.API_URL}/api/v1/ccm/todo`, { clinicId, ...todo }, {
        headers: {
            Authorization: `Bearer ${window.getCookie('UserToken')}`
        }
    });
}

export function createTodoResolution(data) {

    const waitingToClose = [];

    for (const [key, value] of Object.entries(data.waitingToClose)) {
        if (value) {
            waitingToClose.push(
                parseInt(key)
            );
        }
    }

    data.waitingToClose = waitingToClose;

    return axios.post(`${env.API_URL}/api/v1/ccm/todo/resolution`, data, {
        headers: {
            Authorization: `Bearer ${window.getCookie('UserToken')}`
        }
    });
}

export function deleteTodo(clinicId, todoId) {

    return axios.delete(`${env.API_URL}/api/v1/ccm/todo/delete`, {
        headers: {
            Authorization: `Bearer ${window.getCookie('UserToken')}`
        },
        params: {
            clinicId,
            todoId
        }
    });
}

export function setAssignedTodos(assignedTodos) {
    return {
        type: ActionConstants.CCM_SET_ASSIGNED_TODOS,
        payload: {
            assignedTodos
        }
    };
}

export function clearAssignedTodos() {
    return {
        type: ActionConstants.CCM_CLEAR_ASSIGNED_TODOS
    };
}

export function setTodos(todos) {
    return {
        type: ActionConstants.CCM_SET_TODOS,
        payload: {
            todos
        }
    };
}

export function clearTodos() {
    return {
        type: ActionConstants.CCM_CLEAR_TODOS
    };
}

export function setTodo(todo) {

    return {
        type: ActionConstants.CCM_SET_TODO,
        payload: {
            todo
        }
    };
}

export function clearTodo() {
    return {
        type: ActionConstants.CCM_CLEAR_TODO
    };
}

export function removeReport(clinicId, journalId, reasonToStrikeThrough) {

    return axios.delete(`${env.API_URL}/api/v1/ccm/report`, {
        headers: {
            Authorization: `Bearer ${window.getCookie('UserToken')}`
        },
        data: {
            clinicId,
            journalId,
            reasonToStrikeThrough
        }
    });
}
