import ActionConstants from './../../constants/action';
import axios from 'axios';
import env from 'react-dotenv';
import { getWithClinic } from './../../lib/rest';

export function getPatientVitals(
    clinicId,
    patientId,
    type,
    date
) {

    return axios.get(`${env.API_URL}/api/v1/patient/charts`, {
        headers: {
            Authorization: `Bearer ${window.getCookie('UserToken')}`
        },
        params: {
            clinicId,
            patientId,
            type,
            date
        }
    });
}

export function getPatientWeekVitals(
    clinicId,
    patientId,
    type
) {

    return axios.get(`${env.API_URL}/api/v1/patient/charts/getCharts`, {
        headers: {
            Authorization: `Bearer ${window.getCookie('UserToken')}`
        },
        params: {
            clinicId,
            patientId,
            type
        }
    });

    // return getWithClinic('/api/charts/patient-week-vitals.php', data);
}


export function getPatientMonthVitals(data) {
    return getWithClinic('/api/charts/patient-month-vitals.php', data);
}


export function getPatientYearVitals(data) {
    return getWithClinic('/api/charts/patient-year-vitals.php', data);
}


export function setPatientWeekVitals(data) {
    return {
        type: ActionConstants.CHARTS_SET_PATIENT_WEEK_VITALS,
        payload: data
    };
}


export function addPatientWeekVitals(data) {
    return {
        type: ActionConstants.CHARTS_ADD_PATIENT_WEEK_VITALS,
        payload: data
    };
}


export function setPatientMonthVitals(data) {
    return {
        type: ActionConstants.CHARTS_SET_PATIENT_MONTH_VITALS,
        payload: data
    };
}


export function addPatientMonthVitals(data) {
    return {
        type: ActionConstants.CHARTS_ADD_PATIENT_MONTH_VITALS,
        payload: data
    };
}


export function setPatientYearVitals(data) {
    return {
        type: ActionConstants.CHARTS_SET_PATIENT_YEAR_VITALS,
        payload: data
    };
}


export function addPatientYearVitals(data) {
    return {
        type: ActionConstants.CHARTS_ADD_PATIENT_YEAR_VITALS,
        payload: data
    };
}


export function clearPatientAllVitals() {
    return {
        type: ActionConstants.CHARTS_CLEAR_PATIENT_ALL_VITALS
    };
}

export function isChartsUpdate() {
    return {
        type: ActionConstants.IS_CHARTS_UPDATE
    };
}

export function setChartsVisibility(data) {
    return {
        type: ActionConstants.CHARTS_SET_VISIBILITY,
        payload: data
    };
}
export function clearChartsVisibility() {
    return {
        type: ActionConstants.CHARTS_CLEAR_VISIBILITY
    };
}