import ActionConstants from './../../constants/action';


var initialState = {
    isMonthlyExceptionsLoaded: false,
    monthlyExceptions: [],

    isReportExceptionsLoaded: false,
    reportExceptions: [],
    reportViews: []
};


export default function(state, action) {
    if (action.type === ActionConstants.REPORTS_SET_MONTHLY_EXCEPTIONS) {
        return Object.assign({}, state, {
            isMonthlyExceptionsLoaded: true,
            monthlyExceptions: action.payload.exceptions
        });
    }

    if (action.type === ActionConstants.REPORTS_CLEAR_MONTHLY_EXCEPTIONS) {
        return Object.assign({}, state, {
            isMonthlyExceptionsLoaded: false,
            monthlyExceptions: []
        });
    }

    if (action.type === ActionConstants.REPORTS_SET_REPORT_EXCEPTIONS) {
        return Object.assign({}, state, {
            isReportExceptionsLoaded: true,
            reportExceptions: action.payload.exceptions,
            reportViews: action.payload.views
        });
    }

    if (action.type === ActionConstants.REPORTS_CLEAR_REPORT_EXCEPTIONS) {
        return Object.assign({}, state, {
            isReportExceptionsLoaded: false,
            reportExceptions: [],
            reportViews: []
        });
    }

    return state || Object.assign({}, initialState);
}
