import env from "react-dotenv";

export default class IdleMonitor {
    constructor(callbacks) {
        this.callbacks = callbacks || {
            block: () => { },
            unblock: () => { },
            logout: () => { }
        };

        const idleTimer = window.getCookie('idleTimer') ? parseInt(window.getCookie('idleTimer')) : 30;

        this.blockAfterMinutes = idleTimer - 5;
        this.logoutAfterMinutes = idleTimer;
        this.blockAfterMS = this.blockAfterMinutes * 60 * 1000;
        this.logoutAfterMS = this.logoutAfterMinutes * 60 * 1000;

        this.userActivityIntervalSeconds = 60;
        this.userActivityThrottlerSeconds = 10;
        this.userActivityIntervalMS = this.userActivityIntervalSeconds * 1000;
        this.userActivityThrottlerTimeoutMS = this.userActivityThrottlerSeconds * 1000;

        this.userActivityInterval = null;
        this.userActivityThrottlerTimeout = null;

        this.userActivityThrottler = this.userActivityThrottler.bind(this);
        this.inactiveUserAction = this.inactiveUserAction.bind(this);
/*        console.log(this);*/
    }

    debug(inactive) {
        console.log(`[idleMonitor: user ${inactive} MS inactive]`); // eslint-disable-line no-console
    }

    userActivityThrottler() {
        if (!this.userActivityThrottlerTimeout) {
            this.userActivityThrottlerTimeout = setTimeout(() => {

                const corsIframe = document.getElementById('corsIframe');

                if (corsIframe) {
                    corsIframe.contentWindow.postMessage({
                        from: 'journal',
                        lastOnline: new Date().getTime()
                    }, '*');
                }

                clearInterval(this.userActivityInterval);
                this.userActivityInterval = setInterval(() => {
                    this.inactiveUserAction();
                }, this.userActivityIntervalMS);

                //localStorage.setItem('lastOnline', new Date().getTime());
                window.setCookies('lastOnline', new Date().getTime(), 30);

                clearTimeout(this.userActivityThrottlerTimeout);
                this.userActivityThrottlerTimeout = null;
            }, this.userActivityThrottlerTimeoutMS);
        }
    }

    inactiveUserAction() {
        //const lastOnline = parseInt(localStorage.getItem('lastOnline'));
        const lastOnline = parseInt(window.getCookie('lastOnline'));

        const currentDate = new Date().getTime();

        this.debug(currentDate - lastOnline);

        if (currentDate - lastOnline >= this.blockAfterMS
            && currentDate - lastOnline <= this.logoutAfterMS) {
            this.callbacks.block();
        } else if (currentDate - lastOnline > this.logoutAfterMS) {
            this.logout();
        }
    }

    startEventListeners() {
        window.addEventListener('mousemove', this.userActivityThrottler);
        window.addEventListener('scroll', this.userActivityThrottler);
        window.addEventListener('keydown', this.userActivityThrottler);
        window.addEventListener('resize', this.userActivityThrottler);
        window.addEventListener('visibilitychange', this.userActivityThrottler);
    }

    start() {
        this.startEventListeners();
        this.userActivityThrottler();
    }

    reset() {
        //localStorage.setItem('lastOnline', new Date().getTime());
        window.setCookies('lastOnline', new Date().getTime(), 30);

        this.callbacks.unblock();
    }

    logout() {
        //localStorage.removeItem('lastOnline');
        window.eraseCookie('lastOnline');
        this.callbacks.logout();
    }
}
