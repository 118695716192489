import React from 'react';
import { connect } from 'react-redux';

import {
    getChat,
    getChatLastMessage,
    getChatLastMessages,
    createChatMessage,
    setChatPatientMessages,
    addChatPatientMessages,
    pushChatPatientMessages,
    clearChatPatientMessages
} from './../actions/chat';

import ModalChat from './../base/components/modal-chat';


class ModalChatWrapper extends React.Component {
    constructor(props) {
        super(props);

        this.onLoadChat = this.onLoadChat.bind(this);
        this.onLoadChatOffset = this.onLoadChatOffset.bind(this);
        this.onLoadChatLastMessage = this.onLoadChatLastMessage.bind(this);
        this.onLoadChatLastMessages = this.onLoadChatLastMessages.bind(this);
        this.onCloseChat = this.onCloseChat.bind(this);
        this.onCreateChatMessage = this.onCreateChatMessage.bind(this);
    }

    onLoadChat(callback) {
        const {
            dispatch,
            clinicId,
            patientUserId
        } = this.props;

        getChat(
            clinicId,
            patientUserId
        ).then(res => {
            dispatch(
                setChatPatientMessages(
                    patientUserId,
                    res.data.messages
                )
            );

            if (callback) {
                callback();
            }
        });
    }

    onLoadChatOffset(offset, callback) {
        const {
            dispatch,
            clinicId,
            patientUserId
        } = this.props;

        getChat(
            clinicId,
            patientUserId,
            offset
        ).then(res => {

            const { messages } = res.data;
            dispatch(
                addChatPatientMessages(
                    patientUserId, messages
                )
            );

            callback(res);
        });
    }

    onLoadChatLastMessage(callback) {
        const {
            clinicId,
            patientUserId
        } = this.props;

        getChatLastMessage(
            clinicId,
            patientUserId
        ).then(res => {
            callback(res);
        });
    }

    onLoadChatLastMessages(lastMessageId, callback) {
        const {
            dispatch,
            clinicId,
            patientUserId
        } = this.props;

        getChatLastMessages(
            clinicId,
            patientUserId,
            lastMessageId
        ).then((res) => {
            dispatch(pushChatPatientMessages(patientUserId, res.data.messages));

            callback();
        });
    }

    onCloseChat() {
        const { dispatch, patientUserId } = this.props;
        dispatch(clearChatPatientMessages(patientUserId));
    }

    onCreateChatMessage(values, callback) {
        const {
            clinicId,
            patientUserId
        } = this.props;

        createChatMessage(
            clinicId,
            patientUserId,
            values.message
        ).then(res => {
            callback(res);
        }).finally(() => {
            callback(null);
        });
    }

    render() {
        const { patientUserId, patientsChats } = this.props;

        return (
            <ModalChat
                patientUserId={patientUserId}
                patientsChats={patientsChats}
                onLoadChat={this.onLoadChat}
                onLoadChatOffset={this.onLoadChatOffset}
                onLoadChatLastMessage={this.onLoadChatLastMessage}
                onLoadChatLastMessages={this.onLoadChatLastMessages}
                onCloseChat={this.onCloseChat}
                onCreateChatMessage={this.onCreateChatMessage} />
        );
    }
}

function mapStateToProps(state) {
    const { patientsChats } = state.chats;

    return {
        patientsChats
    };
}

export default connect(mapStateToProps)(ModalChatWrapper);
