import React from 'react';
import { connect } from 'react-redux';

import PatientVitalChart from './patient-vital-chart';
import { setChartsVisibility, clearChartsVisibility } from './../actions/charts/patient-vitals';

class PatientVitalCharts extends React.Component {
    constructor(props) {
        super(props);

        this.charts = {};
        const chartsVisibility = [];

        for (let i = 0; i < this.props.weeksVitals.length; i += 1) {
            const vitalData = this.props.weeksVitals[i];
            this.charts[vitalData.vital] = React.createRef();
            chartsVisibility[vitalData.vital] = false;
        }

        this.state = {
            chartsVisibility,

            time: 'weeks',

            indexes: {
                weeks: 0,
                months: 0,
                years: 0
            },

            maxIndexes: {
                weeks: 0,
                months: 0,
                years: 0
            },

            isLoading: false
        };

        this.toggleAllCharts = this.toggleAllCharts.bind(this);
        this.onVisibilityChange = this.onVisibilityChange.bind(this);

        this.setTime = this.setTime.bind(this);

        this.prevDate = this.prevDate.bind(this);
        this.nextDate = this.nextDate.bind(this);
    }

    componentDidMount() {
        if (this.props.isChartsUpdate === true && this.props.chartsVisible.length > 0) {
            const activeChart = this.props.chartsVisible;
            Object.keys(activeChart).forEach((key) => {
                if (activeChart[key]) {
                    this.onVisibilityChange(key, true)
                }
            });
        }
    }

    renderCharts() {
        const charts = [];

        for (let i = 0; i < this.props.weeksVitals.length; i += 1) {
            const weekData = this.props.weeksVitals[i];
            const monthData = this.props.monthsVitals[i];
            const yearData = this.props.yearsVitals[i];

            if (!this.state.chartsVisibility[weekData.vital]) {
                charts.push(
                    <div className='patient-vital-chart-toggle' onClick={this.showChart(weekData.vital)}>
                        <img src='/static/img/ecare21-chart-toggle.png' alt='Toggle' title='Toggle' />
                        <div>Show graph for "{weekData.label}"</div>
                    </div>
                );
                continue;
            }

            charts.push(
                <PatientVitalChart
                    key={weekData.vital}
                    ref={this.charts[weekData.vital]}
                    metricID={weekData.metric_id}
                    vital={weekData.vital}
                    label={weekData.label}
                    labelPostfix={weekData.labelPostfix}
                    isLoading={this.state.isLoading}
                    time={this.state.time}
                    weeksIndex={this.state.indexes.weeks}
                    monthsIndex={this.state.indexes.months}
                    yearsIndex={this.state.indexes.years}
                    maxWeeksIndex={this.state.maxIndexes.weeks}
                    maxMonthsIndex={this.state.maxIndexes.months}
                    maxYearsIndex={this.state.maxIndexes.years}
                    isChartUpdate={this.props.isChartsUpdate}
                    weeks={weekData.datasets}
                    months={monthData.datasets}
                    years={yearData.datasets}
                    onVisibilityChange={this.onVisibilityChange}
                    setTime={this.setTime}
                    prevDate={this.prevDate}
                    nextDate={this.nextDate} />
            );
        }

        return charts;
    }

    render() {
        const charts = this.renderCharts();

        if (charts.length === 0) {
            return (
                <div>
                    No data for graphs
                </div>
            );
        }

        const toggleText = this.isAnyChartVisible() ? 'Hide all' : 'Show all';

        let showAll = null;

        if (charts.length > 0) {
            showAll = (
                <div className='patient-vital-chart-toggle' onClick={this.toggleAllCharts}>
                    <img src='/static/img/ecare21-chart-toggle.png' alt='Toggle' title='Toggle' />
                    <div>{toggleText}</div>
                </div>
            );
        }

        return (
            <div>
                {showAll}

                {charts}
            </div>
        );
    }

    toggleAllCharts() {
        if (this.isAnyChartVisible()) {
            const { chartsVisibility } = this.state;

            Object.keys(chartsVisibility).forEach((key) => {
                chartsVisibility[key] = false;
            });

            this.setState({ chartsVisibility });
            this.props.dispatch(clearChartsVisibility());
            this.props.dispatch(setChartsVisibility(this.state.chartsVisibility));
            return;
        }

        const { chartsVisibility } = this.state;

        Object.keys(chartsVisibility).forEach((key) => {
            chartsVisibility[key] = true;
        });

        this.setState({ chartsVisibility });
        this.props.dispatch(clearChartsVisibility());
        this.props.dispatch(setChartsVisibility(this.state.chartsVisibility));
    }

    showChart(vital) {
        return () => {
            this.onVisibilityChange(vital, true);
        };
    }

    onVisibilityChange(vital, isVisible) {
        const { chartsVisibility } = this.state;
        chartsVisibility[vital] = isVisible;
        this.setState({ chartsVisibility });
        this.props.dispatch(clearChartsVisibility());
        this.props.dispatch(setChartsVisibility(this.state.chartsVisibility));
    }

    isAnyChartVisible() {
        const { chartsVisibility } = this.state;

        let isVisible = false;

        Object.keys(chartsVisibility).forEach((key) => {
            if (chartsVisibility[key]) {
                isVisible = true;
            }
        });

        return isVisible;
    }

    setTime(time) {
        this.setState({ time });
    }

    prevDate() {
        if (this.state.indexes[this.state.time] === undefined) {
            return;
        }

        const { indexes } = this.state;
        let index = indexes[this.state.time];
        index += 1;

        if (this.hasDataset(index)) {
            indexes[this.state.time] = index;
            this.setState({ indexes });
            return;
        }

        this.setState({ isLoading: true });

        const date = this.getLastDate();

        this.props.onLoad(this.state.time, date, (isLastData) => {
            if (isLastData) {
                const { maxIndexes } = this.state;
                maxIndexes[this.state.time] = index;

                this.setState({
                    isLoading: false,
                    maxIndexes
                });
            } else {
                this.setState({ isLoading: false });

                indexes[this.state.time] = index;
                this.setState({ indexes });
            }
        });
    }

    nextDate() {
        if (this.state.indexes[this.state.time] === undefined) {
            return;
        }

        const { indexes } = this.state;
        let index = indexes[this.state.time];

        if (index > 0) {
            index -= 1;
        }

        indexes[this.state.time] = index;

        this.setState({ indexes });
    }

    hasDataset(index) {
        const { time } = this.state;

        let vitals = [];
        let hasData = false;

        if (time === 'weeks') {
            vitals = this.props.weeksVitals;
        } else if (time === 'months') {
            vitals = this.props.monthsVitals;
        } else if (time === 'years') {
            vitals = this.props.yearsVitals;
        }

        for (let i = 0; i < vitals.length; i += 1) {
            const vitalData = vitals[i];

            if (index < vitalData.datasets.length) {
                hasData = true;
                break;
            }
        }

        return hasData;
    }

    getLastDate() {
        const { time } = this.state;

        let vitals = [];

        if (time === 'weeks') {
            vitals = this.props.weeksVitals;
        } else if (time === 'months') {
            vitals = this.props.monthsVitals;
        } else if (time === 'years') {
            vitals = this.props.yearsVitals;
        }

        for (let i = 0; i < vitals.length; i += 1) {
            const vitalData = vitals[i];

            if (vitalData.datasets.length === 0) {
                continue;
            }

            const lastDataset = vitalData.datasets[vitalData.datasets.length - 1];

            for (let j = 0; j < lastDataset.length; j += 1) {
                if (lastDataset[j].data.length > 0) {
                    return lastDataset[j].data[0].date;
                }
            }
        }

        return '';
    }
}

PatientVitalCharts.defaultProps = {
    weeksVitals: [],
    monthsVitals: [],
    yearsVitals: [],
    chartsVisible: [],
    onLoad() { }
};

function mapStateToProps(state) {

    const chartsVisible = typeof state.chartsPatientVitals !== 'undefined' ? state.chartsPatientVitals.chartsVisible : true;

    return {
        chartsVisible
    }
}

export default connect(mapStateToProps)(PatientVitalCharts);
