import React from 'react';


export default class Form extends React.Component {
    constructor(props) {
        super(props);

        this.fields = {};

        this._onSubmit = this._onSubmit.bind(this);
    }

    render() {
        var children = React.Children.map(this.props.children, (child) => {
            if (child === null) {
                return child;
            }

            var name = child.props.name;

            if (!name) {
                return this.getChildren(child);
            }

            if (this.fields[name] === undefined) {
                this.fields[name] = React.createRef();
            }

            return React.cloneElement(child, {
                ref: this.fields[name]
            });
        });

        return (
            <form onSubmit={this._onSubmit}
                  className={this.props.className}
                  noValidate>

                {children}
            </form>
        );
    }

    getChildren(child) {
        if (typeof(child) === 'string') {
            return child;
        }

        var cn = React.Children.map(child.props.children, (cd) => {
            if (!React.isValidElement(cd)) {
                return cd;
            }

            var name = cd.props.name;

            if (!name) {
                return this.getChildren(cd);
            }

            if (this.fields[name] === undefined) {
                this.fields[name] = React.createRef();
            }

            return React.cloneElement(cd, {
                ref: this.fields[name]
            });
        });

        return React.cloneElement(child, {
            children: cn
        });
    }

    isValid() {
        var isValid = true;

        for (var refName in this.fields) {
            var ref = this.fields[refName];

            if (ref.current && ref.current.isValid && !ref.current.isValid()) {
                isValid = false;
            }
        }

        return isValid;
    }

    setValues(values) {
        for (var refName in this.fields) {
            if (refName in values) {
                var value = values[refName];

                var ref = this.fields[refName];

                if (ref.current && ref.current.setValue) {
                    ref.current.setValue(value);
                }
            }
        }
    }

    getValues() {
        let values = {};

        for (let refName in this.fields) {
            let ref = this.fields[refName];
            if (ref.current) {
                values[refName] = ref.current.getValue();
            }
        }

        return values;
    }

    setErrors(errors) {
        errors = errors || [];

        for (var refName in this.fields) {
            var ref = this.fields[refName];
            var error = errors[refName] || '';

            if (ref.current && ref.current.setError) {
                ref.current.setError(error);
            }
        }
    }

    clear() {
        for (var refName in this.fields) {
            var ref = this.fields[refName];

            if (!ref.current) {
                continue;
            }

            if (ref.current.setError) {
                ref.current.setError('');
            }

            if (ref.current.clearValue) {
                ref.current.clearValue();
            }
        }
    }

    _onSubmit(evt) {
        evt.preventDefault();

        if (this.isValid()) {
            var data = this._getData();
            this.props.onSubmit(data);
        }
    }

    _getData() {
        let data = {};

        for (let refName in this.fields) {
            let ref = this.fields[refName];

            if (ref.current) {
                data[refName] = ref.current.getValue();
            }
        }

        return data;
    }
}
