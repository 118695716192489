import ActionConstants from './../constants/action';


const initialState = {
    isDoctorsLoaded: false,
    doctors: []
};


export default function(state, action) {
    if (action.type === ActionConstants.SET_DOCTORS) {
        return Object.assign({}, state, {
            isDoctorsLoaded: true,
            doctors: action.payload.doctors
        });
    }

    if (action.type === ActionConstants.CLEAR_DOCTORS) {
        return Object.assign({}, state, {
            isDoctorsLoaded: false,
            doctors: []
        });
    }

    return state || Object.assign({}, initialState);
}
