import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import MainConstants from './../../../constants/main';
import { getPatientById } from './../../../actions/patients';
import { setCollectors, clearCollectors } from './../../../actions/rpm/collectors';
import { createCarePlan, checkIfExistsCarePlan } from './../../../actions/rpm/care-plans';
import { dateToRequestString } from './../../../lib/date';
import Form from './../../../lib/forms/form';
import Input from './../../../lib/forms/input';
import Checkboxes from './../../../lib/forms/checkboxes';
import SelectWithSearch from './../../../lib/forms/select-with-search';
import DatePicker from './../../../lib/forms/date-picker';
import VitalsField from './../../../lib/forms/vitals-field';
import { required, requiredEmployee } from './../../../lib/forms/field-validators';
import { clearPreloadedPatient, setPreloadedPatient } from '../../../actions/rpm/create-care-plan';
import { getDoctors, setDoctors, clearDoctors } from '../../../actions/doctors';
import { getVitals, setVitals } from '../../../actions/rpm/vitals';
import RequestLoader from '../../../components/request-loader';
import CareplanCreateEmployee from '../../../views/rpm/components/careplan-create-employee';

class RPMCreateCarePlan extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isCheckingPatient: false,
            isCreating: false,
            showCreateEmployeePopup: false,
            newEmployeeName: '',
            redirectMessagePlanId: '',
            redirectMessagePatientFirstName: '',
            redirectMessagePatientLastName: ''
        };

        this.carePlanForm = React.createRef();

        this.checkPatient = this.checkPatient.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        const { dispatch, clinicId, patientId } = this.props;

        getVitals(clinicId).then(res => {

            dispatch(
                setVitals(
                    res.data.vitals
                )
            );
        });

        getPatientById(clinicId, patientId).then(res => {

            dispatch(
                setPreloadedPatient(
                    res.data.patient
                )
            );
        });

        getDoctors(clinicId).then(res => {

            dispatch(
                setCollectors(
                    res.data.doctors
                )
            );

            dispatch(
                setDoctors(
                    res.data.doctors
                )
            );
        });
    }

    componentWillUnmount() {
        const { dispatch } = this.props;

        dispatch(clearCollectors());
        dispatch(clearPreloadedPatient());
    }

    getWhomToInformValues() {
        let values = [];
        values.push({ value: 0, label: 'Automatic collection of data' });
        values.push(...this.getDoctorsValues());

        return values;
    }

    getDoctorsValues() {
        return this.props.doctors.map((doctor) => {
            return {
                value: doctor.id,
                label: doctor.firstName + ' ' + doctor.lastName
            };
        });
    }

    getCollectorsValues() {

        return this.props.collectors.map(collector => {
            return {
                value: collector.id,
                label: collector.firstName + ' ' + collector.lastName
            };
        });
    }

    checkPatient(patient) {

        if (!patient) {
            this.setState({
                redirectMessagePlanId: '',
                redirectMessagePatientFirstName: '',
                redirectMessagePatientLastName: ''
            });

            return;
        }

        const { currentClinic } = this.props;

        this.setState({ isCheckingPatient: true });

        let planId = '';
        let firstName = '';
        let lastName = '';

        checkIfExistsCarePlan(currentClinic, patient).then(res => {
            planId = res.data.carePlan.id;
            firstName = res.data.carePlan.patientFirstName;
            lastName = res.data.carePlan.patientLastName;
        }).finally(() => {

            this.setState({
                isCheckingPatient: false,
                redirectMessagePlanId: planId,
                redirectMessagePatientFirstName: firstName,
                redirectMessagePatientLastName: lastName
            });
        });
    }

    onSubmit(plan) {

        if (this.state.isCreating) {
            return;
        }

        const { clinicId, patientId } = this.props;

        plan.patient = patientId;
        
        plan.date = dateToRequestString(plan.date);
        
        const vitals = [];

        for (let i = 0; i < plan.vitals.length; i += 1) {
            const v = plan.vitals[i];

            vitals.push({
                vital: v.vital,
                thresholds: v.thresholds,
                partsOfDay: v.daysOfWeek.dayparts,
                daysOfWeek: v.daysOfWeek.weekdays
            });
        }

        plan.vitals = vitals;
        plan.whomToInform = plan.whomToInform === 0 ? null : plan.whomToInform;

        this.setState({ isCreating: true }, () => {

            window.parent.postMessage(JSON.stringify({
                from: 'journalFormDischarge',
                status: 'saving'
            }), '*');

            createCarePlan(clinicId, plan).then(() => {

                window.parent.postMessage(JSON.stringify({
                    from: 'journalFormDischarge',
                    status: 'saved'
                }), '*');
            });
        });
    }

    renderRedirectMessage() {
        const {
            redirectMessagePlanId,
            redirectMessagePatientFirstName,
            redirectMessagePatientLastName
        } = this.state;

        if (!redirectMessagePlanId) {
            return null;
        }

        const url = '/app/rpm/monitoring-plans/' + redirectMessagePlanId;
        const fullName = redirectMessagePatientFirstName + ' ' + redirectMessagePatientLastName;

        return (
            <div className='message warning-message'>
                <div>
                    <Link to={url}>Plan</Link> for {fullName} already exists
                </div>
            </div>
        );
    }

    _onCarePlanFormRef = (ref) => {

        if (!ref) {
            return;
        }

        this.carePlanForm = ref;

        if (this.props.isPreloadedPatientLoaded) {

            const patient = {
                value: this.props.preloadedPatient.patient.id,
                label: this.props.preloadedPatient.patient.firstName + ' ' + this.props.preloadedPatient.patient.lastName
            };

            ref.setValues({
                patient
            })
        }
    }

    _toggleCreateEmployeePopup = () => {

        this.setState({ showCreateEmployeePopup: !this.state.showCreateEmployeePopup })
    }

    _setCreatedEmployee = () => {

        const { dispatch } = this.props;

        const { clinics, clinicId } = this.props;
        let currentClinic = null;

        for (let i in clinics) {

            if (clinics[i].id === parseInt(clinicId)) {

                currentClinic = clinics[i];
            }
        }

        dispatch(clearCollectors());
        dispatch(clearDoctors());
        getDoctors(currentClinic.id).then(res => {
            this._toggleCreateEmployeePopup();

            dispatch(
                setCollectors(
                    res.data.doctors
                )
            );

            dispatch(
                setDoctors(
                    res.data.doctors
                )
            );

            this.carePlanForm.current.fields.doctor.current.setState({
                value: null,
                label: null
            });
        });
    }

    _checkEmployee = (value) => {

        if (typeof value !== 'string') {

            return null;
        }

        this.setState({
            newEmployeeName: value,
            showCreateEmployeePopup: true
        });
    }

    render() {

        const { isDoctorsLoaded, isVitalsLoaded, isCollectorsLoaded } = this.props;
        const { newEmployeeName, showCreateEmployeePopup } = this.state;
        let renderReady;

        if (!isDoctorsLoaded || !isVitalsLoaded || !isCollectorsLoaded) {

            renderReady = false;
        } else {

            renderReady = true;
        }

        const howToInformValues = [];

        for (let i = 0; i < MainConstants.rpmCarePlansInformMethods.length; i += 1) {

            const v = Object.assign({}, MainConstants.rpmCarePlansInformMethods[i]);

            howToInformValues.push({
                name: v.value,
                label: v.name,
                checked: v.defaultChecked ? true : false,
                disabled: v.disabled ? true : false
            });
        }

        window.parent.postMessage(JSON.stringify({
            from: 'journalFormDischarge',
            status: 'loaded'
        }), '*');

        const { clinics, clinicId } = this.props;
        let currentClinic = null;

        for (let i in clinics) {

            if (clinics[i].id === parseInt(clinicId)) {

                currentClinic = clinics[i];
            }
        }

        const { tempInF, weightInKg } = currentClinic;

        return (
            <div className='rpm-iframe'>
                <CareplanCreateEmployee
                    employeeName={newEmployeeName}
                    toggleCreateEmployeePopup={this._toggleCreateEmployeePopup}
                    showCreateEmployeePopup={showCreateEmployeePopup}
                    carePlanFormRef={this.carePlanForm}
                    clinicId={currentClinic.id}
                    setCreatedEmployee={this._setCreatedEmployee}
                />
                <div style={{ display: renderReady ? 'none' : 'block' }}>
                    <RequestLoader center />;
                </div>
                <div style={{ display: renderReady ? 'block' : 'none' }}>
                {!this.state.isCreating ?
                    <Form ref={this.carePlanForm} onSubmit={this.onSubmit} className='form care-plan-form'>
                        <div className='form-horizontal-control'>
                                <SelectWithSearch
                                    name='doctor'
                                    label='Prescribed by'
                                    values={this.getDoctorsValues()}
                                    validators={[requiredEmployee('Please select doctor')]}
                                    onChange={this._checkEmployee}
                                    inline={true}
                                    creatable={true}
                                    promptTextCreator={label => `Create new employee ${label}`}
                                />

                            <DatePicker name='date'
                                label='Start by'
                                validators={[required('Please select date')]}
                                inline={true} />
                        </div>

                        <SelectWithSearch
                            name='collector'
                            label='Medical assistant'
                            values={this.getCollectorsValues()}
                            validators={[required('Please select medical assistant')]}
                            inline={true} />

                        <div className='form-horizontal-control'>
                            <Input name='contactName'
                                label='Contact name'
                                inline={true} />

                            <Input name='contactPhone'
                                maxLength='15'
                                label='Contact phone'
                                inline={true} />
                        </div>

                        <SelectWithSearch
                            name='whomToInform'
                            label='Whom to inform'
                            defaultValue={0}
                            values={this.getWhomToInformValues()}
                            inline={true}
                        />

                        <Checkboxes name='howToInform'
                            label='How to inform'
                            values={howToInformValues}
                            inline={true}
                            inlineFields={true} />

                        <VitalsField name='vitals'
                            vitals={this.props.vitals}
                            weightInKg={weightInKg}
                            tempInF={tempInF} />

                        <div className='buttons'>
                            <button className='button'>
                                Save
                            </button>
                            <span style={{ padding: '12px 0 0 5px', color: 'red', fontWeight: 'bold' }}>Please save changes in the monitoring plan.</span>
                        </div>
                    </Form> : <h2 style={{ textAlign: 'center' }}>Monitoring plan created successfully</h2>}
                </div>
            </div>
        );
    }
}

export default connect(state => ({
    clinics: state.user.clinics,
    clinic: state.user,
    isDoctorsLoaded: state.doctors.isDoctorsLoaded,
    doctors: state.doctors.doctors,

    isCollectorsLoaded: state.rpmCollectors.isCollectorsLoaded,
    collectors: state.rpmCollectors.collectors,

    isVitalsLoaded: state.rpmVitals.isVitalsLoaded,
    vitals: state.rpmVitals.vitals,

    isPreloadedPatientLoaded: state.rpmCreateCarePlan.isPreloadedPatientLoaded,
    preloadedPatient: state.rpmCreateCarePlan.preloadedPatient
}))(RPMCreateCarePlan);
