import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import MainConstants from './../../constants/main';
import {getPatientById, getPatientInfo, getPatientsByName} from './../../actions/patients';
import {setCollectors, clearCollectors} from './../../actions/rpm/collectors';
import { createCarePlan, checkIfExistsCarePlan } from './../../actions/rpm/care-plans';
import { dateToRequestString } from './../../lib/date';
import FormLoader from './../../components/form-loader';
import PatientTooltip from './../../components/patient-tooltip';
import Form from './../../lib/forms/form';
import Input from './../../lib/forms/input';
import Checkboxes from './../../lib/forms/checkboxes';
import SelectWithSearch from './../../lib/forms/select-with-search';
import SelectWithLoading from './../../lib/forms/select-with-loading';
import DatePicker from './../../lib/forms/date-picker';
import VitalsField from './../../lib/forms/vitals-field';
import { required, requiredEmployee } from './../../lib/forms/field-validators';
import { clearPreloadedPatient, setPreloadedPatient } from '../../actions/rpm/create-care-plan';
import { getDoctors, setDoctors, clearDoctors } from '../../actions/doctors';
import RequestLoader from '../../components/request-loader';
import CareplanCreateEmployee from './components/careplan-create-employee';

class RPMCreateCarePlan extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isCheckingPatient: false,
            isCreating: false,
            showCreateEmployeePopup: false,
            newEmployeeName: '',
            redirectMessagePlanId: '',
            redirectMessagePatientFirstName: '',
            redirectMessagePatientLastName: '',
        };

        this.carePlanForm = React.createRef();

        this.loadPatientsByName = this.loadPatientsByName.bind(this);
        this.checkPatient = this.checkPatient.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        const patientId = this.props.match.params.patientId;
        const { dispatch, currentClinic } = this.props;

        getDoctors(currentClinic).then(res => {
            dispatch(
                setCollectors(
                    res.data.doctors
                )
            );
        });

        if (patientId) {

            getPatientInfo(currentClinic, patientId).then(res => {

                if (res.data.contactInfo.discloseMedicalInformationPrimary) {

                    this.carePlanForm.fields.contactName.current.setValue(res.data.contactInfo.primaryContactName);
                    this.carePlanForm.fields.contactPhone.current.setValue(res.data.contactInfo.primaryContactPhone);
                } else {

                    this.carePlanForm.fields.contactName.current.setValue(`${res.data.contactInfo.firstName} ${res.data.contactInfo.lastName}`);
                    this.carePlanForm.fields.contactPhone.current.setValue(res.data.contactInfo.phoneNumber);
                }
                if (res.data.contactInfo.primaryPhysicianUserId !== null)
                    this.carePlanForm.fields.whomToInform.current.setValue(res.data.contactInfo.primaryPhysicianUserId);
                else
                    this.carePlanForm.fields.whomToInform.current.clearValue();
            });

            getPatientById(currentClinic, patientId).then(res => {

                dispatch(
                    setPreloadedPatient(
                        res.data.patient
                    )
                );

                const patient = {
                    value: this.props.preloadedPatient.patient.id,
                    label: this.props.preloadedPatient.patient.firstName + ' ' + this.props.preloadedPatient.patient.lastName
                };

                this.carePlanForm.setValues({
                    patient
                });
            });
        }
    }

    componentWillUnmount() {
        const { dispatch } = this.props;

        dispatch(clearCollectors());
        dispatch(clearPreloadedPatient());
    }

    loadPatientsByName(name, callback) {
        const { clinic } = this.props;

        getPatientsByName(clinic.id, name).then((res) => {
            const patients = res.data.patients.map((patient) => {
                let dob = '';

                if (patient.dob !== null) {
                    dob = ', ' + patient.dob;
                }

                return {
                    value: patient.id,
                    label: patient.firstName + ' ' + patient.lastName + dob
                };
            });
            callback(patients);
        });
    }

    getDoctorsValues() {

        return this.props.doctors.map((doctor) => {
            return {
                value: doctor.id,
                label: doctor.firstName + ' ' + doctor.lastName
            };
        });
    }

    getWhomToInformValues() {
        let values = [];
        values.push({ value: 0, label: 'Automatic collection of data' });
        values.push(...this.getDoctorsValues());

        return values;
    }

    getCollectorsValues() {

        return this.props.collectors.map(collector => {
            return {
                value: collector.id,
                label: collector.firstName + ' ' + collector.lastName
            };
        });
    }

    checkPatient(patient) {

        if (!patient) {
            this.setState({
                redirectMessagePlanId: '',
                redirectMessagePatientFirstName: '',
                redirectMessagePatientLastName: ''
            });

            return;
        }

        const { currentClinic } = this.props;

        this.setState({isCheckingPatient: true});

        let planId = '';
        let firstName = '';
        let lastName = '';

        checkIfExistsCarePlan(currentClinic, patient).then(res => {
            planId = res.data.carePlan.id;
            firstName = res.data.carePlan.patientFirstName;
            lastName = res.data.carePlan.patientLastName;

        }).catch(() => {
            getPatientInfo(currentClinic, patient).then(res => {

                if (res.data.contactInfo.discloseMedicalInformationPrimary) {

                    this.carePlanForm.fields.contactName.current.setValue(res.data.contactInfo.primaryContactName);
                    this.carePlanForm.fields.contactPhone.current.setValue(res.data.contactInfo.primaryContactPhone);
                } else {

                    this.carePlanForm.fields.contactName.current.setValue(`${res.data.contactInfo.firstName} ${res.data.contactInfo.lastName}`);
                    this.carePlanForm.fields.contactPhone.current.setValue(res.data.contactInfo.phoneNumber);
                }
                if (res.data.contactInfo.primaryPhysicianUserId !== null)
                    this.carePlanForm.fields.whomToInform.current.setValue(res.data.contactInfo.primaryPhysicianUserId);
                else
                    this.carePlanForm.fields.whomToInform.current.clearValue();
            });
        }).finally(() => {

            this.setState({
                isCheckingPatient: false,
                redirectMessagePlanId: planId,
                redirectMessagePatientFirstName: firstName,
                redirectMessagePatientLastName: lastName
            });
        });
    }

    onSubmit(plan) {

        if (this.state.isCreating) {
            return;
        }

        const { clinic } = this.props;

        plan.date = dateToRequestString(plan.date);

        const vitals = [];

        for (let i = 0; i < plan.vitals.length; i += 1) {
            const v = plan.vitals[i];

            vitals.push({
                vital: v.vital,
                thresholds: v.thresholds,
                partsOfDay: v.daysOfWeek.dayparts,
                daysOfWeek: v.daysOfWeek.weekdays
            });
        }

        plan.vitals = vitals;

        plan.whomToInform = plan.whomToInform === 0 ? null : plan.whomToInform;

        this.setState({ isCreating: true }, () => {

            createCarePlan(clinic.id, plan).then(res => {

                this.props.history.push(`/app/rpm/monitoring-plans/${res.data.planId}`);
            }).catch(err => {

                this.carePlanForm.setErrors(err.response.data.errors);
            }).finally(() => {

                this.setState({ isCreating: false });
            });
        });
    }

    renderRedirectMessage() {
        const {
            redirectMessagePlanId,
            redirectMessagePatientFirstName,
            redirectMessagePatientLastName
        } = this.state;

        if (!redirectMessagePlanId) {
            return null;
        }

        const url = '/app/rpm/monitoring-plans/' + redirectMessagePlanId;
        const fullName = redirectMessagePatientFirstName + ' ' + redirectMessagePatientLastName;

        return (
            <div className='message warning-message'>
                <div>
                    <Link to={url}>Plan</Link> for {fullName} already exists
                </div>
            </div>
        );
    }

    _onCarePlanFormRef = (ref) => {

        if (!ref) {
            return;
        }

        this.carePlanForm = ref;
    }

    _checkEmployee = (value) => {

        if (typeof value !== 'string') {

            return null;
        }

        this.setState({
            newEmployeeName: value,
            showCreateEmployeePopup: true
        });
    }

    renderForm() {

        const { isDoctorsLoaded, isVitalsLoaded, isCollectorsLoaded } = this.props;
        let renderReady;

        if (!isDoctorsLoaded || !isVitalsLoaded || !isCollectorsLoaded) {

            renderReady = false;
        } else {

            renderReady = true;
        }

        const howToInformValues = [];

        for (let i = 0; i < MainConstants.rpmCarePlansInformMethods.length; i += 1) {

            const v = Object.assign({}, MainConstants.rpmCarePlansInformMethods[i]);

            howToInformValues.push({
                name: v.value,
                label: v.name,
                checked: v.defaultChecked ? true : false,
                disabled: v.disabled ? true : false
            });
        }

        let patientLoader = null;

        if (this.state.isCheckingPatient) {
            patientLoader = (
                <div className='form-mini-loader'/>
            );
        }

        const { weightInKg, tempInF } = this.props.clinic;

        return (
            <div>
                <div style={{ display: renderReady ? 'none' : 'block' }}>
                    <RequestLoader center />
                </div>
                <div style={{ display: renderReady ? 'block' : 'none' }}>
                    <header className='header'>Create monitoring plan</header>

                    {this.renderRedirectMessage()}

                    <Form ref={this._onCarePlanFormRef} onSubmit={this.onSubmit} className='form care-plan-form'>
                        <div className='form-horizontal-control'>
                            <SelectWithLoading
                                name='patient'
                                label='Patient'
                                validators={[required('Please select patient')]}
                                inline={true}
                                onChange={this.checkPatient}
                                onLoadMore={this.loadPatientsByName}
                                tooltip={<PatientTooltip/>}
                                disabled={this.props.isPreloadedPatientLoaded}
                            />

                            <div className='field-loader'>
                                {patientLoader}
                            </div>

                            <SelectWithSearch
                                name='doctor'
                                label='Prescribed by'
                                values={this.getDoctorsValues()}
                                validators={[requiredEmployee('Please select doctor')]}
                                onChange={this._checkEmployee}
                                inline={true}
                                creatable={true}
                                promptTextCreator={label => `Create new employee ${label}`}
                            />

                            <DatePicker
                                name='date'
                                label='Start by'
                                validators={[required('Please select date')]}
                                inline={true}/>
                        </div>

                        <SelectWithSearch
                            name='collector'
                            label='Medical assistant'
                            values={this.getCollectorsValues()}
                            validators={[required('Please select medical assistant')]}
                            inline={true}/>

                        <div className='form-horizontal-control'>
                            <Input
                                name='contactName'
                                label='Contact name'
                                inline={true}/>

                            <Input name='contactPhone'
                                maxLength='15'
                                label='Contact phone'
                                inline={true} />
                        </div>

                        <SelectWithSearch
                            name='whomToInform'
                            label='Whom to inform'
                            defaultValue={ 0 }
                            values={this.getWhomToInformValues()}
                            inline={true}
                        />

                        <Checkboxes
                            name='howToInform'
                            label='How to inform'
                            values={howToInformValues}
                            inline={true}
                            inlineFields={true}
                        />

                        <VitalsField
                            name='vitals'
                            vitals={this.props.vitals}
                            weightInKg={weightInKg}
                            tempInF={tempInF}/>

                        <div className='buttons'>
                            <button disabled={this.state.isCreating} className='button'>
                                Save
                            </button>
                            {this.state.isCreating ? <FormLoader /> : null}
                        </div>
                    </Form>
                </div>
            </div>
        );
    }

    _toggleCreateEmployeePopup = () => {

        this.setState({ showCreateEmployeePopup: !this.state.showCreateEmployeePopup })
    }

    _setCreatedEmployee = () => {

        const { dispatch, currentClinic } = this.props;

        dispatch(clearCollectors());
        dispatch(clearDoctors());
        getDoctors(currentClinic).then(res => {
            this._toggleCreateEmployeePopup();

            dispatch(
                setCollectors(
                    res.data.doctors
                )
            );

            dispatch(
                setDoctors(
                    res.data.doctors
                )
            );

            this.carePlanForm.fields.doctor.current.setState({
                value: null,
                label: null
            });
        });
    }

    render() {

        const { newEmployeeName, showCreateEmployeePopup } = this.state;
        const { currentClinic } = this.props;

        return (
            <div>
                <CareplanCreateEmployee
                    employeeName={newEmployeeName}
                    toggleCreateEmployeePopup={this._toggleCreateEmployeePopup}
                    showCreateEmployeePopup={showCreateEmployeePopup}
                    carePlanFormRef={this.carePlanForm}
                    clinicId={currentClinic}
                    setCreatedEmployee={this._setCreatedEmployee}
                />

                { this.renderForm() }
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { currentClinic, clinics } = state.user;
    let clinic = null;

    for (let i = 0; i < clinics.length; i += 1) {
        if (currentClinic == clinics[i].id) {
            clinic = clinics[i];
            break;
        }
    }

    return {
        clinic,
        currentClinic,

        isDoctorsLoaded: state.doctors.isDoctorsLoaded,
        doctors: state.doctors.doctors,

        isCollectorsLoaded: state.rpmCollectors.isCollectorsLoaded,
        collectors: state.rpmCollectors.collectors,

        isVitalsLoaded: state.rpmVitals.isVitalsLoaded,
        vitals: state.rpmVitals.vitals,

        isPreloadedPatientLoaded: state.rpmCreateCarePlan.isPreloadedPatientLoaded,
        preloadedPatient: state.rpmCreateCarePlan.preloadedPatient
    }
}

export default connect(mapStateToProps)(RPMCreateCarePlan);
