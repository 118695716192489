const initialState = {
    lastPathname: '',
    currentPathname: ''
};

export default function(state, action) {
    if (action.type === '@@router/LOCATION_CHANGE') {

        const { pathname } = action.payload.location;

        return Object.assign({}, state, {
            lastPathname: state.currentPathname,
            currentPathname: pathname
        });
    }

    return state || Object.assign({}, initialState, {
        currentPathname: window.location.hash.slice(1)
    });
}
