import React from 'react';

export default class Input extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: this.props.defaultValue,
            error: ''
        };
    }

    render() {
        var classes = ['form-field'];
        if (this.state.error) {
            classes.push('form-field-error');
        }

        if (this.props.inline) {
            return (
                <div className={classes.join(' ')}>
                    <div className='form-inline-input-field'>
                        <label htmlFor={this.props.name}>{this.props.label}</label>
                        <div className='form-input-block'>
                            <input
                                id={this.props.name}
                                disabled={ this.props.disabled ? 'disabled' : '' }
                                name={this.props.name}
                                type={this.props.type}
                                placeholder={this.props.placeholder}
                                value={this.state.value}
                                onChange={this._onChange}
                                maxLength={this.props.maxLength}
                                className='form-input'/>
                            {this.renderError()}
                        </div>
                    </div>
                </div>
            );
        }

        if (this.props.horizontal) {
            classes.push('form-field-horizontal');

            return (
                <div className={classes.join(' ')}>
                    <div className='line-field'>
                        <label htmlFor={this.props.name}>{this.props.label}</label>

                        <input
                            id={this.props.name}
                            placeholder={this.props.placeholder}
                            disabled={ this.props.disabled ? 'disabled' : '' }
                            name={this.props.name}
                            type={this.props.type}
                            value={this.state.value}
                            onChange={this._onChange}
                            maxLength={this.props.maxLength}
                            className='form-input'/>
                    </div>
                    {this.renderError()}
                </div>
            );
        }

        return (
            <div className={classes.join(' ')}>
                <label htmlFor={this.props.name}>{this.props.label}</label>

                <input
                    placeholder={this.props.placeholder}
                    id={this.props.name}
                    name={this.props.name}
                    disabled={ this.props.disabled ? 'disabled' : '' }
                    type={this.props.type}
                    value={this.state.value}
                    onChange={this._onChange}
                    maxLength={this.props.maxLength}
                    className='form-input'/>

                {this.renderError()}
            </div>
        );
    }

    renderError() {
        if (!this.state.error) {
            return null;
        }

        return (
            <span className='form-error'>{this.state.error}</span>
        );
    }

    _onChange = (evt) => {
        var value = evt.target.value;

        this.setState({value});
        this._validate(value);
    }

    _validate(value) {

        for (let i in this.props.validators) {
            const v = this.props.validators[i];

            const error = v(value);

            if (error) {
                this.setError(error);
                return false;
            }
        }

        this.setState({error: ''});
        return true;
    }

    setError(error) {
        this.setState({error});
    }

    getValue() {
        return this.state.value;
    }

    setValue(value) {
        this.setState({value});
        this._validate(value);
    }

    clearValue() {
        this.setState({
            value: this.props.defaultValue,
            error: ''
        });
    }

    isValid() {
        return this._validate(this.getValue());
    }
}

Input.defaultProps = {
    defaultValue: '',
    inline: false,
    horizontal: false,
    validators: [],
    disabled: false,
    maxLength: 255,
    type: 'text',
    placeholder: ''
};
