import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
    getPatientJournal,
    setPatientJournal,
    clearPatientJournal,
    getJournalPatient,
    setJournalPatient,
    clearJournalPatient
} from '../../actions/ccm/patient-journal';
import { removeReport } from '../../actions/ccm/todo';

import {
    getPatientWeeklyCarePlansLogs,
    setPatientWeeklyCarePlansLogs,
    clearPatientWeeklyCarePlansLogs
} from '../../actions/reports/patient-care-plans';
import CommonPopup from '../../components/common-popup';

import RequestLoader from '../../components/request-loader';
import TodosWithRemoveTable from '../../tables/todos-with-remove';

class ReportsShowPatient extends React.Component {
    sortStatus = {};

    state = {
        removePopupIsOpen: false,
        reportIsRemoving: false,
        reasonToStrikeThrought: ''
    }

    componentDidMount() {
        this._loadPatient();
        this._loadJournal();
        this._loadPatientCarePlansLogs();
    }

    componentWillUnmount() {
        this.props.dispatch(clearJournalPatient());
        this.props.dispatch(clearPatientJournal());
        this.props.dispatch(clearPatientWeeklyCarePlansLogs());
    }

    renderJournalPatient() {
        if (!this.props.isJournalPatientLoaded) {
            return (
                <RequestLoader/>
            );
        }

        return (
            <div className='patient-info'>
                <div style={{ display: "flex" }}>
                    <div>
                        <Link to='/app/reports?keep_selection=true' className="button">
                            <img class="icon" alt="Arrow bar left" src="/static/bootstrap/icons/arrow-bar-left.svg" />Back</Link>
                        </div>
                <div>
                <table>
                    <tr>
                        <th>Last name</th>
                        <td>{this.props.journalPatient.lastName}</td>
                    </tr>

                    <tr>
                        <th>First name</th>
                        <td>{this.props.journalPatient.firstName}</td>
                    </tr>

                    <tr>
                        <th>DOB</th>
                        <td>{this.props.journalPatient.dob}</td>
                    </tr>
                    </table>
                </div></div>
            </div>
        );
    }

    renderPatientJournal() {
        return (
            <div>
                <TodosWithRemoveTable
                    setReportIdToRemove={this._setReportIdToRemove}
                    showTotalTime={true}
                    sortFunction={this._sortByColumn}
                    history={this.props.history}
                    headerName={'CCM journal'}
                    isTodosLoaded={this.props.isPatientJournalLoaded}
                    todos={this.props.assignedTodos}
                    showDoneAt={true}
                    emptyTableMessage={'Empty journal'}/>
            </div>
        );
    }

    render() {
        return (
            <div className='reports'>
                { this.renderJournalPatient() }
                { this.renderPatientJournal() }
                { this._renderRemovePopup() }
            </div>
        );
    }

    _renderRemovePopup = () => {

        const { reportIsRemoving, removePopupIsOpen } = this.state;

        if (!removePopupIsOpen) {

            return null;
        }

        return (
            <CommonPopup
                classNames={['mark-measurement-as-wrong-popup']}
                header='Enter reason to remove'
                onClose={() => { this._cancelStrikeThrough() }}>
                <textarea
                    disabled={reportIsRemoving}
                    className='form-textarea'
                    onChange={({ target: { value } }) => {
                        this.setState({
                            reasonToStrikeThrought: value
                        });
                    }}
                    value={this.state.reasonToStrikeThrought} />
                <div className='buttons'>
                    <button disabled={reportIsRemoving} className='confirm' onClick={() => this._cancelStrikeThrough()}>Cancel</button>
                    <button disabled={reportIsRemoving} className='cancel' onClick={() => this._confirmStrikeThrough()}>Remove</button>
                </div>
            </CommonPopup>
        );
    }

    _loadPatient() {
        const patientId = this._getPatientId();
        const { dispatch, currentClinic } = this.props;

        getJournalPatient(currentClinic, patientId).then(res => {
            dispatch(
                setJournalPatient(
                    res.data.patient
                )
            );
        });
    }

    _loadJournal() {
        const patient = this._getPatientId();
        const year = this._getSelectedYear();
        const month = this._getSelectedMonth();
        const { dispatch, currentClinic } = this.props;

        getPatientJournal(currentClinic, patient, year, month).then(res => {
            dispatch(
                setPatientJournal(
                    res.data.journal
                )
            );
        });
    }

    _loadPatientCarePlansLogs() {
        const patient = this._getPatientId();

        getPatientWeeklyCarePlansLogs({patient}).then((res) => {
            if (res.result) {
                this.props.dispatch(setPatientWeeklyCarePlansLogs(res.data.logs));
            }
        });
    }

    _getSelectedYear() {
        return parseInt(this.props.match.params.year, 10);
    }

    _getSelectedMonth() {
        return parseInt(this.props.match.params.month, 10);
    }

    _getPatientId() {
        const patientId = parseInt(this.props.match.params.patientId, 10);

        if (isNaN(patientId)) {
            this._goBack();
            return;
        }

        return patientId;
    }

    _goBack() {
        this.props.history.push('/app/reports/patients');
    }

    _goToWeeklyPlan(plan, date) {
        this.props.history.push('/app/rpm/daily-monitoring-plans/' + plan + '/report/' + date);
    }

    _sortByColumn = (column, source) => {

        if (undefined === this.sortStatus[source]) {

            this.sortStatus[source] = {};
        }

        if (column !== this.sortStatus[source].sortColumn) {

            this.sortStatus[source].sortDirection = 'ASC';
            this.sortStatus[source].sortColumn = column;
        }

        const sortDirection = this.sortStatus[source].sortDirection;

        const list = this.props[source].sort((a, b) => {

            let columnA = a[column];
            let columnB = b[column];

            if (typeof a[column] === 'string' && typeof b[column] === 'string') {

                columnA = columnA.toUpperCase();
                columnB = columnB.toUpperCase();
            }

            return columnA > columnB
                ? (sortDirection === 'DESC' ? -1 : 1)
                : (columnA < columnB
                    ? (sortDirection === 'DESC' ? 1 : -1)
                    : 0);
        });

        this.sortStatus[source].sortDirection = this.sortStatus[source].sortDirection === 'ASC' ? 'DESC' : 'ASC';

        this.props.dispatch(
            setPatientJournal([...list])
        );
    }

    _setReportIdToRemove = id => {

        this.setState({
            reportIdToRemove: id,
            historyPopupIsOpen: false,
            removePopupIsOpen: true
        });
    }

    _cancelStrikeThrough = () => {

        this.setState({
            reportIdToRemove: null,
            removePopupIsOpen: false,
            reasonToStrikeThrought: ''
        });
    }

    _confirmStrikeThrough = () => {

        const { reportIdToRemove, reasonToStrikeThrought } = this.state;
        const { dispatch, currentClinic } = this.props;

        const patient = this._getPatientId();
        const year = this._getSelectedYear();
        const month = this._getSelectedMonth();

        this.setState({
            reportIsRemoving: true
        });

        removeReport(currentClinic, reportIdToRemove, reasonToStrikeThrought).then(() => {

            this.setState({ isPatientsLoading: true });

            dispatch(clearPatientJournal());

            getPatientJournal(currentClinic, patient, year, month).then(res => {

                this.setState({
                    reportIdToRemove: null,
                    removePopupIsOpen: false,
                    reasonToStrikeThrought: '',
                    reportIsRemoving: false
                });

                dispatch(
                    setPatientJournal(
                        res.data.journal
                    )
                );
            });
        });
    }
}

function mapStateToProps(state) {
    return {
        currentClinic: state.user.currentClinic,
        isJournalPatientLoaded: state.ccmPatientJournal.isJournalPatientLoaded,
        journalPatient: state.ccmPatientJournal.journalPatient,

        isPatientJournalLoaded: state.ccmPatientJournal.isPatientJournalLoaded,
        assignedTodos: state.ccmPatientJournal.patientJournal,

        isPatientWeeklyCarePlansLogsLoaded: state.reportsPatientCarePlans.isPatientWeeklyCarePlanLogsLoaded,
        patientWeeklyCarePlansLogs: state.reportsPatientCarePlans.patientWeeklyCarePlanLogs
    };
}

export default connect(mapStateToProps)(ReportsShowPatient);
