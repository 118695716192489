import React from 'react';

import Select from 'react-select';


export default class SelectWithLoading extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isFocused: false,
            value: null,
            error: ''
        };

        this.minInputLength = 2;

        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);

        this.onChange = this.onChange.bind(this);
        this.loadOptions = this.loadOptions.bind(this);
    }

    onFocus() {
        this.setState({
            isFocused: true
        });
    }

    onBlur() {
        this.setState({
            isFocused: false
        });
    }

    loadOptions(input, callback) {
        const data = {
            options: [],
            complete: false
        };

        const name = input.trim();

        if (name.length < this.minInputLength) {
            callback(null, data);
            return;
        }

        this.props.onLoadMore(name, (options) => {
            data.options = options;
            callback(null, { ...data });
        })
    }

    onChange(value) {
        this.setState({
            value,
            isFocused: false
        });
        if (value) {
            this.validate(value.value);
            this.props.onChange && this.props.onChange(value.value);
        } else {
            this.validate('');
            this.props.onChange && this.props.onChange('');
        }
    }

    validate(value) {
        for (let i = 0; i < this.props.validators.length; i += 1) {
            const v = this.props.validators[i];
            const error = v(value);

            if (error) {
                this.setError(error);
                return false;
            }
        }

        this.setState({error: ''});
        return true;
    }

    setError(error) {
        this.setState({error});
    }

    getValue() {
        if (this.state.value) {
            return this.state.value.value;
        }

        return '';
    }

    setValue(value) {
        this.setState({value});
    }

    clearValue() {
        this.setState({value: ''});
    }

    isValid() {
        return this.validate(this.getValue());
    }

    renderTooltip() {
        const { tooltip } = this.props;
        const { isFocused } = this.state;

        if (!tooltip) {
            return null;
        }

        if (!isFocused) {
            return null;
        }

        return (
            <div className='tooltip'>
                <div className='tooltip-content'>
                    {tooltip}
                </div>
                <div className='tooltip-arrow' />
            </div>
        );
    }

    renderError() {
        if (!this.state.error) {
            return null;
        }

        return (
            <span className='form-error'>{this.state.error}</span>
        );
    }

    render() {
        const classes = ['form-field'];

        if (this.state.error) {
            classes.push('form-field-error');
        }

        let select = (
            <Select.Async
                name={this.props.name}
                value={this.state.value}
                onChange={this.onChange}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                disabled={this.props.disabled}
                loadOptions={this.loadOptions}
                filterOption={this.props.filterOption}
                placeholder={this.props.placeholder ? this.props.placeholder : ''}
                searchPromptText='Type 2 or more letters'
                clearable />
        );

        if (this.props.inline) {
            return (
                <div className={classes.join(' ')}>
                    {this.renderTooltip()}
                    <div className='form-inline-select-field'>
                        <label htmlFor={this.props.name}>{this.props.label}</label>
                        <div className='form-select-block'>
                            {select}
                            {this.renderError()}
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className={classes.join(' ')}>
                {this.renderTooltip()}
                <label htmlFor={this.props.name}>{this.props.label}</label>
                {select}
                {this.renderError()}
            </div>
        );
    }
}


SelectWithLoading.defaultProps = {
    validators: [],
    inline: false,
    disabled: false,
    onLoadMore: () => {},
    tooltip: null
};
