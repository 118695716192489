import ActionConstants from './../../constants/action';

const initialState = {
    isPreloadedPatientLoaded: false,
    preloadedPatient: null
}

export default (state, action) => {

    switch (action.type) {

        case ActionConstants.RPM_CREATE_CARE_PLAN_SET_PRELOADED_PATIENT:

            return {
                ...state,
                ...{
                    isPreloadedPatientLoaded: true,
                    preloadedPatient: action.payload
                }
            }

        case ActionConstants.RPM_CREATE_CARE_PLAN_CLEAR_PRELOADED_PATIENT:
            return {
                ...state,
                ...{
                    isPreloadedPatientLoaded: false,
                    preloadedPatient: null
                }
            }

        default:
            return state || Object.assign({}, initialState);
    }
}