import React from 'react';

const clipboard = () => {
    return (
        <img className='icon'
             alt='Clipboard'
             src='/static/bootstrap/icons/clipboard.svg' />
    );
}

export default clipboard;
