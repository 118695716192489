import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { clearPhoneCodes, getPhoneCodes, setPhoneCodes } from '../../../../actions/total';
import CommonPopup from '../../../../components/common-popup';
import Form from './../../../../lib/forms/form';
import Input from './../../../../lib/forms/input';
import SelectWithSearch from './../../../../lib/forms/select-with-search';
import { required } from './../../../../lib/forms/field-validators';
import InputMask from './../../../../lib/forms/input-mask';
import { createNewEmployee } from '../../../../actions/rpm/care-plans';
import RequestLoader from '../../../../components/request-loader';

class CareplanCreateEmployee extends Component {

    constructor(props) {
        super(props);

        this.createEmployeeForm = createRef();
        this.phoneNumberRef = createRef();

        this.state = {
            phoneMask: null,
            phoneCode: null,
            selectedPhonyCountry: null,
            firstName: null,
            lastName: null,
            creating: false
        }
    }
    componentDidMount() {

        const { dispatch } = this.props;

        getPhoneCodes().then(res => {

            dispatch(
                setPhoneCodes(res.data)
            );
        });
    }

    componentWillUnmount() {

        const { dispatch } = this.props;

        dispatch(clearPhoneCodes());
    }

    _cancel = () => {
        const { toggleCreateEmployeePopup } = this.props;
        const { creating } = this.state;

        if (creating) {

            return null;
        }

        toggleCreateEmployeePopup();
    }

    _getCountryCodes = () => {
        const { phoneCodes } = this.props;
        
        return phoneCodes.map(i => ({
            value: i.id,
            label: `${i.name} (+${i.code})`
        }));
    }

    _changeContryCode = selectedId => {
        const { phoneCodes } = this.props;
        const candidate = phoneCodes.filter(i => {
            return i.id === selectedId;
        });

        const { code, mask } = candidate[0];
        const phoneMask = `+${code.replace(/9/, '\\9')}${mask}`;

        this.setState({
            phoneMask,
            phoneCode: code,
            selectedPhonyCountry: candidate[0]
        });
    }

    _onSubmit = form => {

        const { phoneCode, creating } = this.state;
        const { setCreatedEmployee, clinicId } = this.props;

        if (creating) {

            return null;
        }

        form.phoneNumber = form.phoneNumber.replace(`+${phoneCode}`, '').replace(/\D/g, '');
        form.clinicId = clinicId;

        this.setState({
            creating: true
        }, () => {
            createNewEmployee(form).then(res => {
                const { employeeId } = res.data;

                setCreatedEmployee(
                    employeeId,
                    `${form.firstName} ${form.lastName}`
                );
            }).catch(err => {

                let { errors } = err.response.data;

                for (let e in errors) {

                    errors[e.toLowerCase()] = errors[e][0];
                }

                this.createEmployeeForm.setErrors(
                    errors
                );
            }).finally(() => {

                this.setState({
                    creating: false
                });
            });
        });
    }

    _onCreateEmployeeFormRef = (ref) => {

        if (ref) {

            this.createEmployeeForm = ref;
        }
    }


    render() {

        const { showCreateEmployeePopup, phoneCodesLoaded, employeeName } = this.props;
        const { phoneMask, creating } = this.state;

        if (!showCreateEmployeePopup || !phoneCodesLoaded) {

            return null;
        }

        const employeeNames = employeeName.split(' ');
        const displayStyleForm = creating ? 'none' : 'block';
        const displayStyleLoader = creating ? 'block' : 'none'

        return (
            <CommonPopup classNames={['care-plan-create-employee-popup']} header='Add New Employee' onClose={() => { this._cancel() }}>
                <div className='wrapper'>
                    <div style={{ display: displayStyleLoader }}><RequestLoader center /></div>
                    <div style={{ display: displayStyleForm }}>
                        <Form className='form care-plan-create-employee-form' ref={this._onCreateEmployeeFormRef} onSubmit={this._onSubmit}>
                            <div className='row'>
                                <div className='cell'>
                                    <Input label='First name' disabled={creating} name='firstName' placeholder='First Name' defaultValue={employeeNames[0] ? employeeNames[0] : ''} validators={[required('Please type first name')]} />
                                </div>
                                <div className='cell'>
                                    <Input label='Last name' disabled={creating} name='lastName' placeholder='Last Name' defaultValue={employeeNames[1] ? employeeNames[1] : ''} validators={[required('Please type last name')]} />
                                </div>
                            </div>
                            <div className='form-field'>
                                <Input validators={[required('Please type email')]} label='Email' disabled={creating} type='email' name='email' placeholder='Email' />
                            </div>
                            <div className='phone-wrapper'>
                                <div className='phone-code'>
                                    <SelectWithSearch disabled={creating} validators={[required('Please select country')]} label='Select country' name='countryPhoneCodeId' placeholder='Country phone code' onChange={this._changeContryCode} values={this._getCountryCodes()} />
                                </div>
                                <div className='phone'>
                                    <InputMask validators={[required('Please type phone number')]} label='Phone' type='text' name='phoneNumber' mask={phoneMask} disabled={!phoneMask || creating} className='form-input' placeholder='Mobile phone' />
                                </div>
                            </div>
                            <div className='form-field'>
                                <Input label='Password' validators={[required('Please type password')]} disabled={creating} type='password' name='password' placeholder='Password' />
                            </div>
                            <div className='form-field'>
                                <Input label='Confirm' validators={[required('Please confirm password')]} disabled={creating} type='password' name='confirmpassword' placeholder='Confirm password' />
                            </div>
                            <div className='buttons'>
                                <button className='button' disabled={creating}>
                                    Save
                                </button>
                            </div>
                        </Form>
                    </div>
                </div>
            </CommonPopup>
        );
    }
}

export default connect(state => ({
    phoneCodesLoaded: state.total.phoneCodesLoaded,
    phoneCodes: state.total.phoneCodes
}))(CareplanCreateEmployee);
