import React from 'react';

import './../css/care-plan.css';
import './../css/form.css';
import './../css/global.css';

import RPMCreateCarePlan from './../components/create-care-plan';

export default class Index extends React.Component {
    
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <RPMCreateCarePlan
                clinicId={this.props.match.params.clinicId}
                patientId={this.props.match.params.patientId}
            />
        );
    }
}