import React, { Component } from 'react';
import { connect } from 'react-redux';

import RPMToggleCarePlansList from '../rpm-toggle-care-plans-list';
import RPMPatientSearch from '../rpm-patient-search';
import Form from '../../../../lib/forms/form';

class RPMCarePlansFilters extends Component
{
    render() {

        return (
            <div className='rpm-care-plans-filter'>
                <Form onSubmit={() => {}} className='care-plan-filter-form'>
                    <RPMToggleCarePlansList />
                    <RPMPatientSearch />
                </Form>
            </div>
        );
    }
}

export default connect(state => {

    return {
        doctorId: state.user.userId,
        dailyCarePlans: state.rpmCarePlans.dailyCarePlans,
        carePlansLoaded: state.rpmCarePlans.isDailyCarePlansLoaded
    };
})(RPMCarePlansFilters);
