import ActionConstants from './../../constants/action';

const initialState = {
    isAddonsLoaded: false,
    isAddonLoaded: false,
    addon: null
};

const setState = (state, action) => {
    if (action.type === ActionConstants.CCM_SET_ADDONS) {
        return Object.assign({}, state, {
            isAddonsLoaded: true,
            addons: action.payload.addons
        });
    }

    if (action.type === ActionConstants.CCM_CLEAR_ADDONS) {
        return Object.assign({}, state, {
            isAddonsLoaded: false,
            addons: []
        });
    }

    if (action.type === ActionConstants.CCM_SET_ADDON) {
        return Object.assign({}, state, {
            isAddonLoaded: true,
            addon: action.payload.addon
        });
    }

    if (action.type === ActionConstants.CCM_CLEAR_ADDON) {
        return Object.assign({}, state, {
            isAddonLoaded: false,
            addon: null
        });
    }

    return state || Object.assign({}, initialState);
}

export default setState;
