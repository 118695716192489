import React, { Component, createRef } from 'react';
import {connect} from 'react-redux';
import {
    clearECGDetail, clearECGList,
    getECGDetail,
    getECGList,
    setECGDetailedData, setECGDetailedLoading,
    setECGList,
    setECGListLoading
} from '../../actions/rpm/care-plans';
import Chart from 'chart.js';
import CommonPopup from '../common-popup';
import RequestLoader from '../request-loader';
import moment from 'moment';

class ECGList extends Component {

    constructor(props) {
        super(props);

        this.canvasRef = createRef();
    }

    componentWillUnmount() {

        const { dispatch } = this.props;

        dispatch(
            clearECGDetail()
        );

        dispatch(
            clearECGList()
        )
    }

    _renderECGDetailModal = () => {

        const { ECGDetailLoaded, ECGDetailLoading } = this.props;
        
        return (<CommonPopup style={{
            display: ECGDetailLoaded || ECGDetailLoading ? 'block' : 'none'
        }} header='ECG Detail' onClose={() => { this._clearECGDetail() }}>
            { ECGDetailLoading ? <RequestLoader center/> : '' }
            <div className='canvas-container'>
                <canvas ref={ this.canvasRef } />
            </div>
        </CommonPopup>)
    }

    _renderECGListModal = () => {
        const { ECGListLoaded, ECGListLoading, ECGList } = this.props;

        return (<CommonPopup style={{
            display: ECGListLoaded || ECGListLoading ? 'block' : 'none'
        }} header='ECG List' classNames={['ecg-list-container']}  onClose={() => { this._clearECGList() }}>
            <div>
                { ECGListLoading ?
                    <RequestLoader center/> :
                    <ul>
                        {ECGList !== null && ECGList.length>0 ? ECGList.map(i => {
                            let startDateTs = moment(i.startDate).valueOf()
                            let endDateTs = moment(i.endDate).valueOf()
                            let diff = endDateTs - startDateTs;
                            let duration = moment(diff).format('mm:ss');
                            
                            return (
                                <li>
                                    <u onClick={() => this._loadECGDetail(i.measurementId)}>{moment(i.startDate).format('MMMM DD, hh:mm A')}</u>
                                    <div>
                                        Duration {duration}
                                    </div>
                                </li>
                            );
                        }) : 'No ECG available' }
                    </ul>
                }
            </div>
        </CommonPopup>)
    }

    _renderECGDetail = () => {

        const { ECGDetailLoaded, ECGDetail, ECGDetailLoading } = this.props;

        let ctx = null;

        if (this.canvasRef.current) {

            ctx = this.canvasRef.current.getContext('2d');
        }

        if (ECGDetailLoading) {
            if (ctx) {
                ctx.canvas.parentNode.style.width = '0';
                ctx.canvas.parentNode.style.height = '0';
            }

            if (this.ECGChart) {
                this.ECGChart.destroy();
            }
        }

        if (!ECGDetailLoaded) {

            return null;
        }

        const data = ECGDetail.split(';')
        const canvasWidth = data.length * .8;

        ctx.canvas.parentNode.style.width = `${canvasWidth}px`;
        ctx.canvas.parentNode.style.height = '500px';

        const labels = Array.from(Array(data.length).keys());

        this.ECGChart = new Chart(ctx, {
            type: 'line',
            data: {
                labels: labels,
                datasets: [{
                    borderColor: 'rgb(0, 0, 0)',
                    data: data,
                    fill: false,
                    pointRadius: 0,
                    pointHoverRadius: 0,
                    borderWidth: 0,
                    backgroundColor: null
                }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    datasetLabel: {
                        display: false,
                        font: {
                            weight: 'bold',
                            size: 11
                        }
                    }
                },
                tooltips: {
                    enabled: false
                },
                legend: {
                    display: false
                },
                title: {
                    display: true,
                    text: ''
                },
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                suggestedMax: Math.max(...data),
                                suggestedMin: Math.min(...data),
                                display: true
                            },
                            gridLines: {
                                color: 'rgba(231, 53, 71, 0.2)',
                                zeroLineWidth: 2
                            }
                        }
                    ],
                    xAxes: [
                        {
                            ticks: {
                                display: false
                            },
                            gridLines: {
                                color: 'rgba(231, 53, 71, 0.2)'
                            }
                        }
                    ]
                }
            }
        });
    }

    _loadECGList = () => {

        const { patient } = this.props;

        this.props.dispatch(
            setECGListLoading()
        )

        getECGList({ patientId: patient }).then(e => {
            this.props.dispatch(
                setECGList(
                    e.data.results
                )
            )
        });
    }

    _loadECGDetail = (measurement) => {

        this.props.dispatch(
            setECGDetailedLoading()
        )

        getECGDetail({ measurementId: measurement }).then(e => {
            this.props.dispatch(
                setECGDetailedData(
                    e.data
                )
            )
        })
    }

    _clearECGDetail = () => {

        this.props.dispatch(
            clearECGDetail()
        );
    }

    _clearECGList = () => {

        this.props.dispatch(
            clearECGList()
        );
    }

    render() {

        return(
            <div className='ecg-container'>
                <h2><span onClick={() => { this._loadECGList() }}>Show patient ECG</span></h2>
                <div>
                    { this._renderECGListModal() }
                </div>
                <div className='ecg-detail'>
                    { this._renderECGDetailModal() }
                    { this._renderECGDetail() }
                </div>
            </div>
        )
    }
}

export default connect(state => ({
    ECGListLoaded: state.ecg.ECGListLoaded,
    ECGListLoading: state.ecg.ECGListLoading,
    ECGList: state.ecg.ECGList,
    ECGDetailLoaded: state.ecg.ECGDetailLoaded,
    ECGDetailLoading: state.ecg.ECGDetailLoading,
    ECGDetail: state.ecg.ECGDetail
}))(ECGList);
