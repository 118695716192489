import { combineReducers, applyMiddleware, createStore, compose } from 'redux';
import { createHashHistory } from 'history';
import { routerReducer, syncHistoryWithStore } from 'react-router-redux';

import thunk from 'redux-thunk';

import router from './router';

import user from './user';
import patients from './patients';
import doctors from './doctors';
import chats from './chats';
import ccmActions from './ccm/actions';
import ccmTodo from './ccm/todo';
import ccmAddon from './ccm/addon';
import ccmPatientJournal from './ccm/patient-journal';
import ccmSearch from './ccm/search';
import rpmCollectors from './rpm/collectors';
import rpmVitals from './rpm/vitals';
import rpmCarePlans from './rpm/care-plans';
import rpmCreateCarePlan from './rpm/create-care-plan';
import ecg from './ecg';
import rpmPatientCarePlansLogs from './rpm/patient-care-plans-logs';
import chartsPatientVitals from './charts/patient-vitals';
import reportsMonthly from './reports/monthly';
import reportsPatientCarePlans from './reports/patient-care-plans';
import reportsExceptions from './reports/exceptions';
import total from './total';

const reducers = combineReducers({
    routing: routerReducer,
    router,
    user,
    patients,
    doctors,
    chats,
    ccmActions,
    ccmTodo,
    ccmAddon,
    ccmPatientJournal,
    ccmSearch,
    rpmCollectors,
    rpmVitals,
    rpmCarePlans,
    rpmCreateCarePlan,
    rpmPatientCarePlansLogs,
    ecg,
    chartsPatientVitals,
    reportsMonthly,
    reportsPatientCarePlans,
    reportsExceptions,
    total
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)));

export const history = syncHistoryWithStore(createHashHistory(), store);
export default store;
