import React from 'react';


class WeightThreshold extends React.Component {
    constructor(props) {
        super(props);

        this.initialState = {
            warning: this.props.defaultWarning,
            critical: this.props.defaultCritical,
            days: this.props.defaultDays || 1,

            error: ''
        };

        this.state = {...this.initialState};

        this._onValueChange = this._onValueChange.bind(this);
    }

    setError(error) {
        this.setState({ error });
    }

    getValue() {
        const { warning, critical, days } = this.state;

        return {
            warningLow: 0,
            warningHigh: 0,
            criticalLow: warning,
            criticalHigh: critical,
            days
        };
    }

    setValue(value) {
        this.setState(value);
        this._validate(value);
    }

    clearValue() {
        this.setState({...this.initialState});
    }

    isValid() {
        return this._validate(this.getValue());
    }

    _onValueChange(evt, valueName) {
        const value = evt.target.value;

        this.setState({
            [valueName]: value
        }, () => {
            this._validate(this.getValue());
        });
    }

    _validate(value) {
        for (let i = 0; i < this.props.validators.length; i+= 1) {
            const v = this.props.validators[i];
            const error = v(value);

            if (error) {
                this.setError(error);
                return false;
            }
        }

        this.setState({ error: '' });
        return true;
    }

    renderError() {
        if (!this.state.error) {
            return null;
        }

        return (
            <span className='form-error'>
                {this.state.error}
            </span>
        );
    }

    render() {
        const classes = ['form-field', 'weight-threshold-field'];

        if (this.state.error) {
            classes.push('form-field-error');
        }

        return (
            <div className={classes.join(' ')}>
                <fieldset>
                    <legend>{this.props.label}</legend>

                    <div className='weight-threshold-containers'>
                        <div className='weight-threshold-container'>
                            <label htmlFor={this.props.name + '-warning'}>Warning</label>

                            <input id={this.props.name + '-warning'}
                                   name={this.props.name + '-warning'}
                                   type='text'
                                   value={this.state.warning}
                                   onChange={(evt) => { this._onValueChange(evt, 'warning'); }}
                                   maxLength='255'
                                   className='form-input'/>
                        </div>

                        <div className='weight-threshold-container'>
                            <label htmlFor={this.props.name + '-critical'}>Critical</label>

                            <input id={this.props.name + '-critical'}
                                   name={this.props.name + '-critical'}
                                   type='text'
                                   value={this.state.critical}
                                   onChange={(evt) => { this._onValueChange(evt, 'critical'); }}
                                   maxLength='255'
                                   className='form-input'/>
                        </div>

                        <div className='weight-threshold-container'>
                            <label htmlFor={this.props.name + '-days'}>Days</label>

                            <input id={this.props.name + '-days'}
                                   name={this.props.name + '-days'}
                                   type='text'
                                   value={this.state.days}
                                   onChange={(evt) => { this._onValueChange(evt, 'days'); }}
                                   maxLength='255'
                                   className='form-input'/>
                        </div>
                    </div>
                </fieldset>

                {this.renderError()}
            </div>
        );
    }
}

WeightThreshold.defaultProps = {
    defaultWarning: 0,
    defaultCritical: 0,
    defaultDays: 1,
    validators: []
};

export default WeightThreshold;
