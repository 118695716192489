import ReactDOM from 'react-dom';
import $ from 'jquery';
import axios from 'axios';
import IdleMonitor from './idle-monitor';
import store from './store';
import routes from './routes';
import MainConstants from './constants/main';
import {
    setUserSessionBlock,
    setUser,
    updateUserCurrentClinic,
    getUserCurrentLocalClinic,
    setUserCurrentLocalClinic
} from './actions/user';
import 'react-select/dist/react-select.css';
import 'react-day-picker/lib/style.css';
import './less/main.css';
import env from 'react-dotenv';


window.setCookies = (name, value, days) => {
    let expires = '';

    if (days) {
        let date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
    }
    console.log(name + "=" + value + expires + ";domain=" + env.COOKIE_DOMAIN + "; path=/");
    document.cookie = name + "=" + value + expires + ";domain=" + env.COOKIE_DOMAIN + "; path=/";
}

window.getCookie = name => {

    const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));

    return match ? match[2] : null;
}

window.eraseCookie = (name, domain, path = '/') => {

    document.cookie = `${name}=; domain=${domain}; path=${path}; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}

function setTitle() {

    const els = document.getElementsByTagName('title');

    if (els.length > 0) {

        els[0].textContent = MainConstants.siteName;
    }
}

async function authorize() {

    let response = null;

    try {

        response = await axios.get(`${env.API_URL}/api/v1/auth/user`, {
            headers: {
                Authorization: `Bearer ${window.getCookie('UserToken')}`
            }
        });
    } catch (e) {

        window.location.href = env.LOGIN_URL;

        return false;
    }

    window.projectUser = response.data;
    const { clinics } = window.projectUser;
    const clinickIdFromCookies = parseInt(window.getCookie('ClinicId'));

    let currentClinic = null;

    if (clinickIdFromCookies) {

        currentClinic = clinickIdFromCookies;

        window.eraseCookie('ClinicId', env.COOKIE_DOMAIN);
    } else {

        const clinicId = getUserCurrentLocalClinic();

        if (clinicId) {

            currentClinic = clinicId;
        } else {

            if (clinics.length > 0) {

                currentClinic = clinics[0].id;
            }
        }
    }

    let isValidClinic = false;

    for (let i = 0; i < clinics.length; i += 1) {

        const clinic = clinics[i];

        if (clinic.id === currentClinic) {

            isValidClinic = true;

            break;
        }
    }

    if (!isValidClinic && clinics.length > 0) {

        currentClinic = clinics[0].id;
    }

    const clinicInfo = window.projectUser.clinics.filter(c => c.id == currentClinic);
    const idleTimer = clinicInfo[0].idleTimeOut;

    window.projectUser.currentClinic = parseInt(currentClinic);
    window.projectUser.idleTimer = parseInt(idleTimer);

    window.setCookies('idleTimer', window.projectUser.idleTimer, 30);

    setUserCurrentLocalClinic(
        currentClinic
    );

    store.dispatch(
        updateUserCurrentClinic(
            currentClinic
        )
    );
    
    store.dispatch(
        setUser(
            window.projectUser
        )
    );
}

function hideAppLoader() {
    $('.app-loader').fadeOut();
}

async function main() {
    setTitle();
    await authorize();

    window.idleMonitor = new IdleMonitor({
        block: () => {
            store.dispatch(setUserSessionBlock(true));
        },
        unblock: () => {
            store.dispatch(setUserSessionBlock(false));
        },
        logout: () => {
            window.eraseCookie('UserToken', env.COOKIE_DOMAIN);
            window.location.reload();
        }
    });

    window.idleMonitor.start();

    const rootElement = document.getElementById('app');

    ReactDOM.render(routes, rootElement, () => {
        hideAppLoader();
    });
}

main();
