import ActionConstants from './../../constants/action';
import axios from 'axios';
import env from 'react-dotenv';
import { getWithClinic } from './../../lib/rest';

export function getCollectors(clinicId) {

    return axios.get(`${env.API_URL}/api/v1/clinic/doctors`, {
        headers: {
            Authorization: `Bearer ${window.getCookie('UserToken')}`
        },
        params: {
            clinicId
        }
    });

    //return getWithClinic('/api/rpm/collectors/all.php');
}

export function getAllCollectors() {

    return getWithClinic('/api/rpm/collectors/all.php', {
        inactive: true
    });
}

export function setCollectors(collectors) {

    return {
        type: ActionConstants.RPM_SET_COLLECTORS,
        payload: {
            collectors
        }
    };
}

export function clearCollectors() {
    return {
        type: ActionConstants.RPM_CLEAR_COLLECTORS
    };
}
