import React from 'react';


export default function RequestLoader(props) {
    const loader = (
        <div className='request-loader'>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );

    if (props.center) {
        return (
            <div className='request-loader-center'>
                {loader}
            </div>
        );
    }

    return loader;
}
