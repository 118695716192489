import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
    header: PropTypes.string,
    onClose: PropTypes.func,
    children: PropTypes.node
};

const defaultProps = {
    header: '',
    onClose: () => {},
    children: null,
    style: {},
    classNames: []
};

class CommonPopup extends React.Component {
    render() {
        const {
            onClose,
            header,
            children,
            classNames
        } = this.props;

        const popupClasses = [
            'common-popup',
            ...classNames
        ];
        let _classNames = popupClasses.join(' ');

        return (
            <div className={_classNames} style={this.props.style}>
                <div className='popup-container' onClick={(evt) => { evt.stopPropagation(); }}>
                    <div className='popup-header'>
                        <div className='popup-header-content'>
                            <div className='popup-name'>
                                {header}
                            </div>
                            <div
                                    className='popup-close'
                                    onClick={onClose}
                                    onKeyPress={onClose}
                                    role='button'
                                    tabIndex='-1'
                            />
                        </div>
                    </div>
                    <div className='popup-content-container'>
                        <div className='popup-content'>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

CommonPopup.propTypes = propTypes;
CommonPopup.defaultProps = defaultProps;

export default CommonPopup;
