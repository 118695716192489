import React from 'react';
import { Link } from 'react-router-dom';

import ArrowBarLeft from './icons/arrow-bar-left';

const back = (props) => {
    return (
        <nav className='site-content-navigation'>
            <Link to={props.to} className='button'>
                <ArrowBarLeft/>
                Back
            </Link>
        </nav>
    );
}

export default back;
