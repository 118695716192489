import ActionConstants from './../constants/action';

export function setUserSessionBlock(isBlockedSession) {

    return {
        type: ActionConstants.SET_USER_SESSION_BLOCK,
        payload: {
            isBlockedSession
        }
    };
}

export function setUser(user) {

    return {
        type: ActionConstants.SET_USER,
        payload: {
            user
        }
    };
}

export function updateUserCurrentClinic(clinic) {
    return {
        type: ActionConstants.SET_USER_CURRENT_CLINIC,
        payload: {
            clinic
        }
    };
}

export function getUserCurrentLocalClinic() {
    if (window.localStorage) {
        let clinicId = window.localStorage.getItem('currentClinic');

        if (clinicId) {
            clinicId = parseInt(clinicId, 10);
        }

        return clinicId;
    }

    return null;
}

export function setUserCurrentLocalClinic(clinicId) {
    if (window.localStorage) {
        window.localStorage.setItem('currentClinic', clinicId);
    }
}
