
export function matchTodoMeetingId(text) {
    const pattern= /(?:\[0-9]+)/;
    const match = pattern.exec(text || '');
    return match;
}

export function getTodoMeetingId(text) {
    const match = matchTodoMeetingId(text || '');

    if (!match) {
        return null;
    }

    return match[0].slice(1);
}
