import ActionConstants from './../constants/action';

const initialState = {
    phoneCodesLoaded: false,
    phoneCodes: [],
    companyLogoUrl: null
};

export default (state, action) => {

    switch (action.type) {
        case ActionConstants.COMPANY_LOGO_LOADING:
        case ActionConstants.COMPANY_LOGO_CLEAR:
            return {
                ...state,
                ...{
                    companyLogoUrl: null
                }
            };
        case ActionConstants.COMPANY_LOGO_LOADED:
            return {
                ...state,
                ...{
                    companyLogoUrl: action.payload.companyLogoUrl,
                }
            };
        case ActionConstants.PHONE_CODES_SET:

            return {
                ...state,
                ...{
                    phoneCodesLoaded: true,
                    phoneCodes: action.payload.phoneCodes
                }
            };

        case ActionConstants.PHONE_CODES_CLEAR:

            return {
                ...state,
                ...{
                    phoneCodesLoaded: false,
                    phoneCodes: []
                }
            };

        default:
            return state || { ...initialState };
    }
}
