import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getPatientsByName } from '../../../../actions/patients';
import SelectWithLoading from '../../../../lib/forms/select-with-loading';
import PatientTooltip from '../../../../components/patient-tooltip';
import {
    clearDailyCarePlans,
    getDailyCarePlans,
    setCarePlansFilterType,
    setDailyCarePlans
} from '../../../../actions/rpm/care-plans';

class RPMPatientSearch extends Component {

    render() {

        return (
            <div className='care-plan-search-form-wrap'>
                <div className='table-title'>Daily Monitoring</div>

                <div className='form care-plan-search-form'>
                    <div className='form-horizontal-control'>
                        <SelectWithLoading
                            disabled={!this.props.carePlansLoaded}
                            name='patient'
                            placeholder='Search by patient'
                            filterOption={() => true}
                            onLoadMore={ this._loadPatientsByName }
                            onChange={ this._onPatientChange }
                            tooltip={ <PatientTooltip /> }
                            inline={true} />
                    </div>
                </div>
            </div>
        )
    }

    _loadPatientsByName = (name, callback) => {

        const { currentClinic } = this.props;

        getPatientsByName(currentClinic, name).then((res) => {

            const patients = res.data.patients.map((patient) => {

                let dob = '';

                if (patient.dob !== null) {
                    dob = `, ${patient.dob}`;
                }

                return {
                    value: patient.id,
                    label: patient.firstName + ' ' + patient.lastName + dob
                };
            });

            callback(patients);
        });
    }

    _onPatientChange = (patient) => {

        const { dispatch, currentClinic } = this.props;

        dispatch(
            clearDailyCarePlans()
        );

        const data = {};

        if (!patient) {
            data.plans = 'recorded'
        } else {
            data.patientId = patient;
        }

        getDailyCarePlans(currentClinic, { ...data }).then(res => {


            dispatch (
                setCarePlansFilterType(
                    null
                )
            );

            dispatch(
                setDailyCarePlans(
                    res.data.dailyCarePlans
                )
            );

        });
    }
}

export default connect(state => {

    return {
        currentClinic: state.user.currentClinic,
        carePlansLoaded: state.rpmCarePlans.isDailyCarePlansLoaded
    };
})(RPMPatientSearch);
