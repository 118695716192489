import React from 'react';

const phone = () => {
    return (
        <img className='icon'
             alt='Phone'
             src='/static/bootstrap/icons/phone.svg' />
    );
}

export default phone;
