import ActionConstants from './../../constants/action';

const initialState = {
    isCollectorsLoaded: false,
    collectors: []
};

const setState = (state, action) => {
    if (action.type === ActionConstants.RPM_SET_COLLECTORS) {
        return Object.assign({}, state, {
            isCollectorsLoaded: true,
            collectors: action.payload.collectors
        });
    }

    if (action.type === ActionConstants.RPM_CLEAR_COLLECTORS) {
        return Object.assign({}, state, {
            isCollectorsLoaded: false,
            collectors: []
        });
    }

    return state || Object.assign({}, initialState);
}

export default setState;
