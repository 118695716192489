import moment from 'moment';

export const nowDate = function() {
    return moment();
}

export const yesterdayDate = function() {
    const m = moment();
    m.subtract(1, 'days');
    return m;
}

export const newDate = function(dateStr) {
    const date = new Date(dateStr);

    if (date.toString() !== 'Invalid Date') {
        return date;
    }

    return new Date(dateStr.replace(/-/g, '/'));
}

export const isToday = function(dateStr) {
    const date = moment(dateStr);
    const now = moment();

    return (date.year() === now.year()
            && date.month() === now.month()
            && date.date() === now.date());
}


export const formatDate = function(str) {
    if (!str) {
        return '';
    }

    return moment(str).format('HH:mm, M/D/YYYY');
}

export const formatShortDate = function(str, exactTime = true) {
    if (!str) {
        return '';
    }

    return exactTime ? moment(str).format('HH:mm, M/D/YY') : moment(str).format('M/D/YY');
}

export const formatTimeDate = function(str) {
    if (!str) {
        return '';
    }

    return moment(str).format('HH:mm');
}

export const formatDayDate = function(str) {
    if (!str) {
        return '';
    }

    return moment(str).format('MM/DD/YY');
}

export const formatMonthDate = function(str) {
    if (!str) {
        return '';
    }

    return moment(str).format('MMMM YYYY');
}

export const formatYearDate = function(str) {
    if (!str) {
        return '';
    }

    return moment(str).format('YYYY');
}

export const formatDayRequestDate = function(str) {
    if (!str) {
        return '';
    }

    return moment(str).format('YYYY-MM-DD');
}

export const formatWeekdayName = function(str) {
    if (!str) {
        return '';
    }

    return moment(str).format('dddd');
}

export const formatChartDayDate = function(str) {
    if (!str) {
        return '';
    }

    const date = moment(str);
    const day = date.format('dddd');

    return day[0] + ' ' + date.format('M/D');
}

export const formatChartMonthDate = function(str) {
    if (!str) {
        return '';
    }

    return moment(str).format('MMM');
}

export const getYears = function() {
    const minYear = 2017;
    const maxYear = parseInt(moment().format('Y'), 10) + 1;

    const years = [];
    for (let i = minYear; i <= maxYear; i += 1) {
        years.push(i);
    }

    return years;
}

export const getYearsValues = function (currentValue) {
    const years = getYears();
    const values = [];
    const currentYear = currentValue ?? moment().year();

    for (let i = 0; i < years.length; i += 1) {
        values.push({
            value: years[i],
            default: years[i] === currentYear
        });
    }

    return values;
}

export const getMonths = function() {
    return ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
}

export const getMonthsValues = function (currentValue) {
    const months = getMonths();
    const values = [];
    const currentMonth = currentValue ?? moment().month();

    for (let i = 0; i < months.length; i += 1) {
        values.push({
            value: i,
            name: months[i],
            default: i === currentMonth
        });
    }

    return values;
}

export const dateToRequestString = function(date) {
    if (!date) {
        return '';
    }

    const year = date.getFullYear();

    let month = date.getMonth() + 1;

    if (month < 10) {
        month = '0' + month;
    }

    let day = date.getDate();

    if (day < 10) {
        day = '0' + day;
    }

    return year + '-' + month + '-' + day;
}

export const getWeekdays = function() {
    return [
        'sunday',
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday'
    ];
}

export const getShortWeekdays = function() {
    return [
        'Sun',
        'Mon',
        'Tue',
        'Wed',
        'Thu',
        'Fri',
        'Sat'
    ];
}

export const getDayparts = function() {
    return [
        'morning',
        'noon',
        'evening'
    ];
}

export const getWeekdaysNames = function(daysOfWeek) {
    const weekdays = getWeekdays();

    const result = [];

    for (let i in weekdays) {
        const weekday = weekdays[i];
        if (daysOfWeek[weekday]) {
            result.push(weekday);
        }
    }

    return result;
}

export const getFirstDayOfWeek = function() {
    return moment().startOf('week');
}

export const getWeekdaysDates = function() {
    const weekStart = moment().startOf('week');
    const weekdays = getWeekdays();

    const result = {};

    for (let i in weekdays) {
        result[weekdays[i]] = moment(weekStart.format());
        weekStart.add(1, 'day');
    }

    return result;
}

export const getDaysOfWeekByDate = function(date) {
    const weekdays = getWeekdays();

    const result = {};

    for (let i in weekdays) {
        result[weekdays[i]] = moment(date.format());
        date.add(1, 'day');
    }

    return result;
}

export const datesToWeekdaysDates = function(dates) {
    const weekdays = {};

    for (let i in dates) {
        const d = moment(dates[i]);
        const weekday = d.format('dddd').toLowerCase();
        weekdays[weekday] = d.format('MM/DD/YY');
    }

    return weekdays;
}

export const getTodayWeekday = function() {
    const weekday = moment().format('d');
    return parseInt(weekday, 10);
}

export const isOldWeek = function(dates) {
    const weekdays = {};

    for (let i in dates) {
        const d = moment(dates[i]);
        const weekday = d.format('dddd').toLowerCase();
        weekdays[weekday] = d;
    }

    let date = weekdays.sunday;
    const ws = getWeekdays();

    if (!date) {
        for (let i = 0; i < ws.length; i += 1) {
            const day = ws[i];
            if (weekdays[day]) {
                date = weekdays[day].subtract(i, 'day');
                break;
            }
        }
    }

    const saturday = date.add(6, 'day');
    return saturday < moment();
}
