import React, { Component } from 'react';
import { connect } from 'react-redux';
import { formatShortDate } from '../../../../../lib/date';
import CommonPopup from '../../../../../components/common-popup';
import { lines } from '../../../../../lib/lines';

class RPMPatientLogs extends Component {

    state = {
        popupOpen: false
    }

    render() {
        const { dailyCarePlanLogs, isDailyCarePlanLogsLoaded } = this.props;

        return (
            <div>
                { this._renderPopup() }
                <div className='logs-title'>
                    <h3 className='title'>
                        { dailyCarePlanLogs.length
                            ? <span onClick={ this._toggle }>Show patient logs</span>
                            : isDailyCarePlanLogsLoaded
                                ? <span>Show patient logs [no logs found]</span>
                                : <span>Show patient logs [loading...]</span>}
                    </h3>
                </div>
            </div>
        );
    }

    _renderPopup = () => {

        const { popupOpen } = this.state;
        const { dailyCarePlanLogs } = this.props;

        if (!popupOpen) {

            return null;
        }

        return (
            <CommonPopup classNames={['patient-logs-popup']}  header='Logs' onClose={() => { this._toggle() }}>
                <div className='patient-logs'>
                    { dailyCarePlanLogs.map(log => {
                        return (
                            <div key={log.id} className='care-plan-log'>
                                <div className='wrap'>
                                    <div>Commented by</div>
                                    <div>{`${log.createdByFirstName} ${log.createdByLastName}`}</div>
                                </div>

                                <div className='wrap'>
                                    <div>Created at</div>
                                    <div>{formatShortDate(log.createdAt)}</div>
                                </div>

                                <div className='wrap' style={{
                                    display: log.reportingTime ? 'block' : 'none'
                                }}>
                                    <div>Reporting Time</div>
                                    <div>{log.reportingTime}</div>
                                </div>
                                <div className='wrap' style={{
                                    display: log.cptCode || log.actionName ? 'block' : 'none'
                                }}>
                                    <div>Action</div>
                                    <div>{log.actionName} { log.cptCode ? ` - ${log.cptCode}` : '' }</div>
                                </div>
                                <div className='wrap'>
                                    <div>Resolution</div>
                                    <div>{log.resolution ? lines(log.resolution) : '-'}</div>
                                </div>
                                <div className='wrap'>
                                    <div>Description</div>
                                    <div>{log.comment ? lines(log.comment): '-'}</div>
                                </div>
                            </div>
                        )
                    }) }
                </div>
            </CommonPopup>
        )
    }

    _toggle = () => {

        this.setState(state => ({
            popupOpen: !state.popupOpen
        }))
    }
}

export default connect((state) => ({
    isDailyCarePlanLogsLoaded: state.rpmCarePlans.isDailyCarePlanLogsLoaded,
    dailyCarePlanLogs: state.rpmCarePlans.dailyCarePlanLogs
}))(RPMPatientLogs);
