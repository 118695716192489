import React from 'react';

import TrashIcon from './../../components/icons/trash';

import SelectWithSearch from './select-with-search';
import DaysChooser from './days-chooser';
import Threshold from './threshold';
import WeightThreshold from './weight-threshold';
import LowThreshold from './low-threshold';
import HighThreshold from './high-threshold';


import {
    required,
    daysChooserDayRequired,
    daysChooserDayPartRequired,
    positiveNumberThreshold,
    warningAndCriticalThreshold,
    minMaxNumberThreshold,
    positiveNumberWeightThreshold,
    warningAndCriticalWeightThreshold,
    dayWeightThreshold,
    warningAndCriticalLowThreshold,
    minMaxNumberLowThreshold,
    warningAndCriticalHighThreshold,
    minMaxNumberHighThreshold
} from './field-validators';


export default class Vital extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: '',
            disabled: false
        };

        this.vital = React.createRef();
        this.daysOfWeek = React.createRef();
        this.thresholds = {};

        this.onChange = this.onChange.bind(this);
    }

    setValues(values) {
        if ('vital' in values) {
            this.vital.current.setValue(values.vital);

            if ('thresholds' in values.vital) {
                this.setState({ value: values.vital });
            }
        }

        if ('daysOfWeek' in values) {
            this.daysOfWeek.current.setValue(values.daysOfWeek);
        }
    }

    getValues() {
        const thresholds = [];

        Object.keys(this.thresholds).forEach((thId) => {
            const ref = this.thresholds[thId];

            if (ref.current) {
                const value = ref.current.getValue();
                value.id = thId;

                thresholds.push(value);
            }
        });

        return {
            vital: this.vital.current.getValue(),
            daysOfWeek: this.daysOfWeek.current.getValue(),
            thresholds
        };
    }

    setErrors(errors) {
        if ('vital' in errors) {
            this.vital.current.setError(errors.vital);
        }

        if ('daysOfWeek' in errors) {
            this.daysOfWeek.current.setError(errors.daysOfWeek);
        }

        if ('thresholds' in errors) {
            for (let i = 0; i < errors.thresholds.length; i += 1) {
                const error = errors.thresholds[i];

                const thKeys = Object.keys(this.thresholds);

                for (let j = 0; j < thKeys.length; j += 1) {
                    const thId = thKeys[j];

                    if (error.id !== thId) {
                        continue;
                    }

                    const ref = this.thresholds[thId];

                    if (ref.current && ref.current.setValue) {
                        ref.current.setValue(error.error);
                        break;
                    }
                }
            }
        }
    }

    setDisabled() {
        this.setState({ disabled: true });
    }

    isValid() {
        let isValid = true;

        if (this.vital.current.isValid && !this.vital.current.isValid()) {
            isValid = false;
        }

        if (this.daysOfWeek.current.isValid && !this.daysOfWeek.current.isValid()) {
            isValid = false;
        }

        Object.keys(this.thresholds).forEach((thId) => {
            const ref = this.thresholds[thId];

            if (ref.current && !ref.current.isValid()) {
                isValid = false;
            }
        });

        return isValid;
    }

    onChange(value) {
        const { values } = this.props;
        let isEmpty = true;

        for (let i = 0; i < values.length; i += 1) {
            const vital = values[i];

            if (vital.value === value) {
                isEmpty = false;
                this.setState({ value: vital });
                break;
            }
        }

        if (isEmpty) {
            this.setState({ value: null });
        }

        this.props.onChange(value);
    }

    renderLowThreshold(value) {
        const thresholds = [];

        for (let i = 0; i < value.thresholds.length; i += 1) {
            const thold = value.thresholds[i];
            const limits = value.limits[i];

            const vs = [
                positiveNumberThreshold('Please enter number'),
                warningAndCriticalLowThreshold('Critical low value should be lower than warning low')
            ];

            if (limits) {
                vs.push(minMaxNumberLowThreshold(thold.name, limits));
            }

            this.thresholds[thold.id] = React.createRef();

            thresholds.push(
                <LowThreshold key={thold.id}
                              ref={this.thresholds[thold.id]}
                              name={thold.id}
                              label={thold.name}
                              defaultCriticalLow={thold.criticalLow}
                              defaultWarningLow={thold.warningLow}
                              validators={vs}/>
            );
        }

        return thresholds;
    }

    renderHighThreshold(value) {
        const thresholds = [];

        for (let i = 0; i < value.thresholds.length; i += 1) {
            const thold = value.thresholds[i];
            const limits = value.limits[i];

            const vs = [
                positiveNumberThreshold('Please enter number'),
                warningAndCriticalHighThreshold('Warning high value should be lower than critical high')
            ];

            if (limits) {
                vs.push(minMaxNumberHighThreshold(thold.name, limits));
            }

            this.thresholds[thold.id] = React.createRef();

            thresholds.push(
                <HighThreshold key={thold.id}
                               ref={this.thresholds[thold.id]}
                               name={thold.id}
                               label={thold.name}
                               defaultWarningHigh={thold.warningHigh}
                               defaultCriticalHigh={thold.criticalHigh}
                               validators={vs}/>
            );
        }

        return thresholds;
    }

    renderWeightThreshold(value) {
        const { weightInKg } = this.props;

        const thresholds = [];

        const unitStr = weightInKg ? '(kg)' : '(lbs)';

        const vs = [
            positiveNumberWeightThreshold('Please enter positive numbers'),
            warningAndCriticalWeightThreshold('Warning value should be lower than critical'),
            dayWeightThreshold('Days should be in range 1-30', 1, 30)
        ];

        for (let i = 0; i < value.thresholds.length; i += 1) {
            const thold = value.thresholds[i];

            this.thresholds[thold.id] = React.createRef();

            thresholds.push(
                <WeightThreshold key={thold.id}
                                 ref={this.thresholds[thold.id]}
                                 name={thold.id}
                                 label={'Weight change ' + unitStr}
                                 defaultWarning={thold.criticalLow}
                                 defaultCritical={thold.criticalHigh}
                                 defaultDays={thold.days}
                                 validators={vs}/>
            );
        }

        return thresholds;
    }

    renderThresholds() {
        const thresholds = [];

        const { value } = this.state;

        if (!value) {
            return thresholds;
        }

        const { tempInF } = this.props;

        this.thresholds = {};

        if (value.label === 'Weight') {
            return (
                <div className='vital-thresholds'>
                    <div className='thresholds-header'>Warning Thresholds</div>
                    {this.renderWeightThreshold(value)}
                </div>
            );
        }

        if (value.thresholdsType === 'low') {
            return (
                <div className='vital-thresholds'>
                    <div className='thresholds-header'>Warning Thresholds</div>
                    {this.renderLowThreshold(value)}
                </div>
            );
        }

        if (value.thresholdsType === 'high') {
            return (
                <div className='vital-thresholds'>
                    <div className='thresholds-header'>Warning Thresholds</div>
                    {this.renderHighThreshold(value)}
                </div>
            );
        }

        for (let i = 0; i < value.thresholds.length; i += 1) {
            const thold = value.thresholds[i];
            const limits = value.limits[i];

            const validators = [
                positiveNumberThreshold('Please enter number'),
                warningAndCriticalThreshold({
                    criticalLow: 'Critical low should be lower than warning low',
                    warningLow: 'Warning low should be lower than warning high',
                    warningHigh: 'Warning high should be lower than critical high'
                })
            ];

            if (limits) {
                validators.push(minMaxNumberThreshold(thold.name, limits));
            }

            this.thresholds[thold.id] = React.createRef();

            let tholdLabel = thold.name;
            if (value.label === 'Body Temperature' || value.label === 'Skin Temperature') {
                const unitStr = tempInF ? '(F)' : '(C)';
                tholdLabel += ` ${unitStr}`
            }

            thresholds.push(
                <Threshold
                    key={thold.id}
                    ref={this.thresholds[thold.id]}
                    name={thold.id}
                    label={tholdLabel}
                    defaultWarningLow={thold.warningLow}
                    defaultCriticalLow={thold.criticalLow}
                    defaultWarningHigh={thold.warningHigh}
                    defaultCriticalHigh={thold.criticalHigh}
                    validators={validators}/>
            );
        }

        return (
            <div className='vital-thresholds'>
                <div className='thresholds-header'>Warning Thresholds</div>
                {thresholds}
            </div>
        );
    }

    render() {
        let deleteView = null;

        if (this.props.showDelete) {
            deleteView = (
                <div className='fieldset-delete'>
                    <span onClick={ this.props.onDelete }>
                        <TrashIcon/>
                    </span>
                </div>
            );
        }

        const { uniqueId } = this.props;

        return (
            <div className='form-list-block'>
                {deleteView}

                <div>
                    <SelectWithSearch
                        ref={this.vital}
                        name='vital'
                        label='Vital parameter'
                        values={this.props.values}
                        onChange={this.onChange}
                        inline={true}
                        disabled={this.state.disabled}
                        validators={[required('Please select vital')]} />

                    <div className='form-horizontal-control'>
                        <DaysChooser
                            ref={this.daysOfWeek}
                            uniqueId={uniqueId}
                            name='daysOfWeek'
                            label='When to measure'
                            validators={[
                                daysChooserDayRequired('Please check day'),
                                daysChooserDayPartRequired('Please check part of day')
                            ]}
                            dayparts={true}
                            inline={true}/>
                        {this.renderThresholds()}
                    </div>
                </div>
            </div>
        );
    }
}
