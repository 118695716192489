import axios from 'axios';
import ActionConstants from './../constants/action';
import { getWithClinic } from './../lib/rest';
import env from 'react-dotenv';

export function getPatients() {
    return getWithClinic('/api/patients/all.php');
}

export function getPatientById(clinicId, patientId) {

    return axios.get(`${env.API_URL}/api/v1/patient`, {
        headers: {
            Authorization: `Bearer ${window.getCookie('UserToken')}`
        },
        params: {
            clinicId,
            patientId
        }
    });
}

export function getPatientsByName(clinicId, name) {

    return axios.get(`${env.API_URL}/api/v1/user/patients`, {
        headers: {
            Authorization: `Bearer ${window.getCookie('UserToken')}`
        },
        params: {
            clinicId,
            name
        }
    });
}

export function getPatientTodos(clinicId, patientId) {

    return axios.get(`${env.API_URL}/api/v1/ccm/todo/patient`, {
        headers: {
            Authorization: `Bearer ${window.getCookie('UserToken')}`
        },
        params: {
            clinicId,
            patientId
        }
    });
}

export function setPatients(patients) {
    return {
        type: ActionConstants.SET_PATIENTS,
        payload: {
            patients
        }
    };
}


export function clearPatients() {
    return {
        type: ActionConstants.CLEAR_PATIENTS
    };
}

export function setPatientTodos(todos) {
    return {
        type: ActionConstants.CCM_SET_PATIENT_TODOS,
        payload: {
            todos
        }
    };
}

export function clearPatientTodos() {
    return {
        type: ActionConstants.CCM_CLEAR_PATIENT_TODOS
    };
}

export const getPatientInfo = (clinicId, patientId) => {

    return axios.get(`${env.API_URL}/api/v1/patient/contactinfo`, {
        headers: {
            Authorization: `Bearer ${window.getCookie('UserToken')}`
        },
        params: {
            clinicId,
            patientId
        }
    });
}