import React from 'react';


export default class Checkbox extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: this.props.defaultChecked,
            error: ''
        };

        this._onChange = this._onChange.bind(this);
    }

    render() {
        var classes = ['form-field'];
        if (this.state.error) {
            classes.push('form-field-error');
        }

        return (
            <div className={classes.join(' ')}>
                <div className='form-field-checkbox'>
                    <label htmlFor={this.props.name}>
                        <input id={this.props.name}
                               name={this.props.name}
                               type='checkbox'
                               checked={this.state.value}
                               onChange={this._onChange}
                               className='form-checkbox'/>

                        <span className='form-checkbox-text'>{this.props.label}</span>
                    </label>
                </div>

                {this.renderError()}
            </div>
        );
    }

    renderError() {
        if (!this.state.error) {
            return null;
        }

        return (
            <span className='form-error'>{this.state.error}</span>
        );
    }

    _onChange(evt) {
        var value = evt.target.checked;
        this.setState({value});
        this._validate(value);

        if (this.props.onChange) {
            this.props.onChange(value);
        }
    }

    _validate(value) {
        for (var i in this.props.validators) {
            var v = this.props.validators[i];
            var error = v(value);

            if (error) {
                this.setError(error);
                return false;
            }
        }

        this.setState({error: ''});
        return true;
    }

    setError(error) {
        this.setState({error});
    }

    getValue() {
        return this.state.value;
    }

    clearValue() {
        this.setState({
            value: this.props.defaultChecked,
            error: ''
        });
    }

    isValid() {
        return this._validate(this.getValue());
    }
}


Checkbox.defaultProps = {
    defaultChecked: false,
    validators: [],
    onChange: null
};
