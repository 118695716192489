import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import MainConstants from './../../constants/main';

import {
    getDailyCarePlan,
    setDailyCarePlan,
    clearDailyCarePlan
} from './../../actions/rpm/care-plans';

import {
    getCarePlanLogsByDate,
    setDailyCarePlanLogs,
    clearDailyCarePlanLogs,
    setDailyCarePlanEntries,
    clearDailyCarePlanEntries
} from './../../actions/rpm/care-plans-logs';

import RequestLoader from './../../components/request-loader';

import {
    formatDayDate,
    formatShortDate,
    getWeekdays,
    getDaysOfWeekByDate,
    formatWeekdayName
} from './../../lib/date';

import { lines } from '../../lib/lines';


class RPMShowCarePlanReport extends React.Component {
    componentDidMount() {
        var planId = this._getPlanId();
        var date = this._getReportDate();

        this._loadCarePlan(planId);
        this._loadCarePlanLogs(
            planId,
            date
        );
    }

    componentWillUnmount() {
        this.props.dispatch(clearDailyCarePlan());
        this.props.dispatch(clearDailyCarePlanLogs());
        this.props.dispatch(clearDailyCarePlanEntries());
    }

    renderCarePlanInfo() {
        if (!this.props.isDailyCarePlanLoaded) {
            return null;
        }

        return (
            <div className='daily-care-plan-view'>
                <div className='block-info'>
                    <div>
                        <div>Patient</div>
                        <div>{this.props.dailyCarePlan.patientFirstName + ' ' + this.props.dailyCarePlan.patientLastName}</div>
                    </div>
                    <div>
                        <div>Contact name</div>
                        <div>{this.props.dailyCarePlan.contactName}</div>
                    </div>
                    <div>
                        <div>Contact phone</div>
                        <div>{this.props.dailyCarePlan.contactPhone}</div>
                    </div>
                    <div>
                        <div>Phone</div>
                        <div>{this.props.dailyCarePlan.patientPhoneNumber}</div>
                    </div>
                    <div>
                        <div>Phone (additional)</div>
                        <div>{this.props.dailyCarePlan.patientPhoneNumberAdditional}</div>
                    </div>
                </div>
                <div className='block-info'>
                    <div>
                        <div>Medical assistant</div>
                        <div>{this.props.dailyCarePlan.collectorFirstName + ' ' + this.props.dailyCarePlan.collectorLastName}</div>
                    </div>
                    <div>
                        <div>Whom to inform</div>
                        <div>{this.props.dailyCarePlan.informDoctorFirstName + ' ' + this.props.dailyCarePlan.informDoctorLastName}</div>
                    </div>
                    <div>
                        <div>How to inform</div>
                        <div>{this.renderHowToInform()}</div>
                    </div>
                    <div>
                        <div>Prescribed by</div>
                        <div>{this.props.dailyCarePlan.doctorFirstName + ' ' + this.props.dailyCarePlan.doctorLastName}</div>
                    </div>
                    <div>
                        <div>Start by</div>
                        <div>{formatDayDate(this.props.dailyCarePlan.date)}</div>
                    </div>
                </div>
            </div>
        );
    }

    renderHowToInform() {
        var names = {};

        for (var i = 0; i < MainConstants.rpmCarePlansInformMethods.length; i++) {
            var v = MainConstants.rpmCarePlansInformMethods[i];
            names[v.value] = v.name;
        }

        var result = [];

        for (var informMethod in this.props.dailyCarePlan.informMethods) {
            if (!this.props.dailyCarePlan.informMethods[informMethod]) {
                continue;
            }

            if (names[informMethod]) {
                result.push(names[informMethod]);
            } else {
                result.push(informMethod);
            }
        }

        return result.join(', ');
    }

    renderEntries() {
        if (!this.props.isDailyCarePlanLoaded || !this.props.isDailyCarePlanEntriesLoaded) {
            return null;
        }

        var weekdays = getWeekdays();
        var weekVitalValues = this._getWeekVitalsValues();

        var vitalRows = [];

        for (let i = 0; i < this.props.dailyCarePlan.vitals.length; i++) {
            const vital = this.props.dailyCarePlan.vitals[i];

            const dayPartRows = [];

            for (let part in vital.partOfDay) {
                if (!vital.partOfDay[part]) {
                    continue;
                }

                const dayRow = [];

                for (var j = 0; j < weekdays.length; j++) {
                    const day = weekdays[j];

                    if (weekVitalValues[vital.id] && weekVitalValues[vital.id][day] && weekVitalValues[vital.id][day][part]) {
                        dayRow.push(<div>{weekVitalValues[vital.id][day][part]}</div>);
                    } else {
                        dayRow.push(<div/>);
                    }
                }

                dayPartRows.push(
                    <div className='part-of-day'>
                        <div className='part-of-day-name'>{part}</div>
                        <div className='entries-data'>
                            {dayRow}
                        </div>
                    </div>
                );
            }

            vitalRows.push(
                <div key={i} className='vital'>
                    <div className='vital-name'>{vital.name}</div>
                    <div className='week-entries'>
                        {dayPartRows}
                    </div>
                </div>
            );
        }

        if (vitalRows.length === 0) {
            return null;
        }

        var dates = [];
        var weekDates = this._getWeekVitalsDates();

        for (var i = 0; i < weekdays.length; i++) {
            dates.push(
                <div key={i} className='day'>
                    <div className='day-name'>
                        {weekdays[i]}
                    </div>
                    <div className='day-date'>
                        {weekDates[weekdays[i]]}
                    </div>
                </div>
            );
        }

        return (
            <div className='daily-care-plan-entries-view'>
                <div className='days-of-week'>
                    {dates}
                </div>
                {vitalRows}
            </div>
        );
    }

    renderTotalTime(totalTime) {
        var minutes = 'minutes';

        if (totalTime === 1) {
            minutes = 'minute';
        }

        return (
            <div>
                <strong>Total time this month:</strong> {totalTime + ' ' + minutes}
            </div>
        );
    }

    renderLogs() {
        if (!this.props.isDailyCarePlanLogsLoaded) {
            return (
                <div className='daily-care-plan-logs-loader'>
                    <RequestLoader center/>
                </div>
            );
        }

        var logs = [];
        var totalTime = 0;

        for (var i = 0; i < this.props.dailyCarePlanLogs.length; i++) {
            var log = this.props.dailyCarePlanLogs[i];
            totalTime += log.reportingTime;

            logs.push(
                <div key={log.id} className='care-plan-log'>
                    <div>
                        <div>Commented by</div>
                        <div>{log.createdByFirstName + ' ' + log.createdByLastName}</div>
                    </div>

                    <div>
                        <div>Created at</div>
                        <div>{formatShortDate(log.createdAt)}</div>
                    </div>

                    <div>
                        <div>Reporting time</div>
                        <div>{log.reportingTime}</div>
                    </div>

                    <div>
                        <div>Comment</div>
                        <div>{lines(log.comment)}</div>
                    </div>
                </div>
            );
        }

        return (
            <div className='daily-care-plan-logs'>
                {this.renderTotalTime(totalTime)}

                {logs}
            </div>
        );
    }

    render() {
        return (
            <div>
                {this.renderCarePlanInfo()}
                {this.renderEntries()}
                {this.renderLogs()}
            </div>
        );
    }

    _getPlanId() {
        var planId = parseInt(this.props.match.params.planId, 10);

        if (isNaN(planId)) {
            this.props.history.push('/app/rpm');
            return;
        }

        return planId;
    }

    _getReportDate() {
        var date = this.props.match.params.reportDate;
        return date;
    }

    _loadCarePlan(planId) {
        getDailyCarePlan({id: planId}).then((res) => {
            if (res.result) {
                this.props.dispatch(setDailyCarePlan(res.data.carePlan));
            }
        });
    }

    _loadCarePlanLogs(plan, date) {
        getCarePlanLogsByDate({plan, date, sign: true}).then((res) => {
            if (res.result) {
                this.props.dispatch(setDailyCarePlanLogs(res.data.logs));
                this.props.dispatch(setDailyCarePlanEntries(res.data.entries));
            }
        });
    }

    _getWeekVitalsValues() {
        var weekVitalsValues = {};

        for (var i = 0; i < this.props.dailyCarePlanEntries.length; i++) {
            var entry = this.props.dailyCarePlanEntries[i];

            if (!weekVitalsValues[entry.vital]) {
                weekVitalsValues[entry.vital] = {};
            }

            var weekdayName = formatWeekdayName(entry.reportedDay).toLowerCase();

            if (!weekVitalsValues[entry.vital][weekdayName]) {
                weekVitalsValues[entry.vital][weekdayName] = {};
            }

            weekVitalsValues[entry.vital][weekdayName][entry.reportedPartOfDay] = entry.reportedValue;
        }

        return weekVitalsValues;
    }

    _getWeekVitalsDates() {
        var date = this._getReportDate();
        var dates = getDaysOfWeekByDate(moment(date));

        var result = {};

        for (var day in dates) {
            result[day] = formatDayDate(dates[day]);
        }

        return result;
    }
}



function mapStateToProps(state) {
    return {
        isDailyCarePlanLoaded: state.rpmCarePlans.isDailyCarePlanLoaded,
        dailyCarePlan: state.rpmCarePlans.dailyCarePlan,

        isDailyCarePlanLogsLoaded: state.rpmCarePlans.isDailyCarePlanLogsLoaded,
        dailyCarePlanLogs: state.rpmCarePlans.dailyCarePlanLogs,

        isDailyCarePlanEntriesLoaded: state.rpmCarePlans.isDailyCarePlanEntriesLoaded,
        dailyCarePlanEntries: state.rpmCarePlans.dailyCarePlanEntries
    };
}


export default connect(mapStateToProps)(RPMShowCarePlanReport);
