import React, { Component } from 'react';
import { connect } from 'react-redux';
import { clearUserMedications, getUserMedication, setUserMedications } from '../../../../../actions/rpm/care-plans';
import RequestLoader from '../../../../../components/request-loader';

class RPMPatientMedications extends Component {

    state = {
        expanded: false
    }

    partOfDay = {
        1: 'morning',
        2: 'noon',
        3: 'evening',
        4: 'night'
    };

    componentDidMount() {

        const { dispatch, clinicId, patientId } = this.props;

        getUserMedication(clinicId, patientId).then(response => {

            dispatch(
                setUserMedications(response.data.medications)
            );
        });
    }

    componentWillUnmount() {

        const { dispatch } = this.props;

        dispatch(
            clearUserMedications()
        );
    }

    render() {

        const { isUserMedicationsLoaded, userMedications } = this.props;
        const { expanded } = this.state;

        if (!isUserMedicationsLoaded) {

            return <RequestLoader center />
        }

        return (
            <div className='medications'>
                <h3 className='title'>Daily Medications {userMedications.length > 0 ? <span onClick={this._toggle} className='toggle'>Show {!expanded ? 'more' : 'less'}</span> : ''}</h3>
                <ul>
                    {userMedications.length ? userMedications.map((medication, i) => {

                        return i < 0 || expanded ? (
                            <li>
                                { medication.MedicationNickName
                                    ? <div className='medication-nick-name'><b>Generic :</b>{ medication.MedicationNickName}</div>
                                    : null }
                                { medication.MedicationCodeName
                                    ? <div className='medication-name'><b>Name :</b> { medication.MedicationCodeName}</div>
                                    : null }
                                { medication.Description
                                    ? <div className='medication-description'><b>Description: </b> { medication.Description }</div>
                                    : null }
                                <div className='medication-schedule'>
                                    <b>Schedule: </b>
                                    {medication.TakeInfo.map((takeInfo , i, arr) => {

                                        return takeInfo.Dosage ? <span>{this.partOfDay[takeInfo.PartOfTheDay]}{i != (arr.length - 1) ? ',' : ''}</span> : null
                                    })}
                                </div>
                            </li>
                        ) : null;
                    }) : <li>No Medications</li>}
                </ul>
            </div>
        )
    }

    _toggle = () => {

        this.setState(state => ({
            expanded: !state.expanded
        }));
    }
}

export default connect((state) => {
    return {
        isUserMedicationsLoaded: state.rpmCarePlans.isUserMedicationsLoaded,
        userMedications: state.rpmCarePlans.userMedications
    };
})(RPMPatientMedications);