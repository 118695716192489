import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
    getMonthlyReport,
    setMonthlyReport,
    clearMonthlyReport
} from './../../actions/reports';
import { getMonthsValues, getYearsValues } from './../../lib/date';
import Form from './../../lib/forms/form';
import Select from './../../lib/forms/select';
import RequestLoader from './../../components/request-loader';

class ReportsShowReports extends React.Component {
    educationCPT = '99453';
    RPMDeviceSupplyCPT = '99454';
    RTMDeviceSupplyCPT = '98976/98977';
    sortDirection = {}

    constructor(props) {
        super(props);

        this.monthChooserForm = React.createRef();
        this.onChangeDate = this.onChangeDate.bind(this);
    }

    componentDidMount() {
        this.loadReport();
    }

    componentWillUnmount() {
        this.props.dispatch(clearMonthlyReport());
    }


    onChangeDate() {
        this.loadReport();
    }

    loadReport() {
        if (!this.monthChooserForm.current) {
            return;
        }

        var { year, month } = this.monthChooserForm.current.getValues();
        month = parseInt(month) + 1;
        year = parseInt(year);

        this.props.dispatch(
            clearMonthlyReport()
        );

        getMonthlyReport(this.props.currentClinic, year, month).then((res) => {
            this.props.dispatch(
                setMonthlyReport(
                    { ...res.data, period: { year, month } }
                )
            );
        });
    }

    roundNumber(number) {
        const num = Number(number).toFixed(1);

        if (num === 'NaN') {
            return number;
        }

        if (parseInt(num, 0) === 0) {
            return '';
        }

        return num;
    }

    renderReport() {

        if (!this.monthChooserForm.current) {
            return null;
        }

        if (!this.props.isMonthlyReportLoaded) {
            return (
                <div className='reports-loading-block'>
                    <RequestLoader />
                </div>
            );
        }

        const { actionsType2 } = this.props;

        const values = this.monthChooserForm.current.getValues();
        const month = parseInt(values.month, 10) + 1;

        const rows = [];

        if (this.props.monthlyReport.length === 0) {
            const colspan = 6 + actionsType2.length;
            rows.push(
                <tr className='empty-table-message'>
                    <td colSpan={colspan}>No report available for this month.</td>
                </tr>
            );
        } else {
            this.props.monthlyReport.forEach((report, i) => {
                let ccmTotalTime = this.roundNumber(report.ccmTotalTime);
                let ccmLink = `/app/reports/patients/${report.patientId}/care-plan-logs/ccm/${values.year}/${month}`;

                const actionsTds = [];

                for (let j = 0; j < actionsType2.length; j += 1) {
                    const action = actionsType2[j];

                    let detailLink = `/app/reports/patients/${report.patientId}/care-plan-logs/rpm/${values.year}/${month}/${action.id}/1`;
                    //RTM 98976/98977 should show the same details as RPM 99454
                    let rpm454ActiondId = Object.keys(this.props.cptcodes).map((key) => [Number(key), this.props.cptcodes[key]]).find(x => x[1] == "99454")[0];
                    let detailLinkfoRTM = `/app/reports/patients/${report.patientId}/care-plan-logs/rpm/${values.year}/${month}/${rpm454ActiondId}/1`;

                    let value = '';
                    let valueAdditional = '';

                    if (this.props.cptcodes[action.id] === this.RPMDeviceSupplyCPT) {
                        if (this.props.is99454available && this.props.is99454available[report.patientId] && this.props.is99454available[report.patientId]['available']
                            && !this.props.is99454available[report.patientId].isRtm) {
                            const period = this.props.is99454available[report.patientId]['period'];
                            value =
                                <Link title={period ? `Period ${period['startDate'][0]} - ${period['endDate'][0]}` : ''} to={detailLink}>
                                    <span className='rpmreportcheckmark' />
                                </Link>;

                            if (period['startDate'][1] !== undefined) {
                                let detailLinkfoRTMSeconPeriod = `/app/reports/patients/${report.patientId}/care-plan-logs/rpm/${values.year}/${month}/${rpm454ActiondId}/2`;
                                valueAdditional =
                                    <Link title={period ? `Period ${period['startDate'][1]} - ${period['endDate'][1]}` : ''} to={detailLinkfoRTMSeconPeriod}>
                                        <span className='rpmreportcheckmark' />
                                    </Link>;
                            }
                        }
                    }
                    else if (this.props.cptcodes[action.id] === this.RTMDeviceSupplyCPT) {
                        if (this.props.is99454available && this.props.is99454available[report.patientId]
                            && this.props.is99454available[report.patientId]['available']
                            && this.props.is99454available[report.patientId].isRtm) {
                            const period = this.props.is99454available[report.patientId]['period'];

                            value =
                                <Link title={period ? `Period ${period['startDate'][0]} - ${period['endDate'][0]}` : ''} to={detailLinkfoRTM}>
                                    <span className='rpmreportcheckmark' />
                                </Link>;
                        }
                    }
                    else {
                        if (this.props.cptcodes[action.id] === this.educationCPT) {

                            if (Number.isInteger(report.ccmTotalTimeType2[action.id])) {

                                value = <Link to={detailLink}>
                                    <span className='rpmreportcheckmark' />
                                </Link>
                            }
                        } else if (report.ccmTotalTimeType2[action.id] && parseInt(report.ccmTotalTimeType2[action.id]) !== 0) {

                            value = <Link to={detailLink}>{report.ccmTotalTimeType2[action.id]}</Link>
                        }
                    }

                    actionsTds.push(
                        <td>{value}{valueAdditional}</td>
                    );
                }

                rows.push(
                    <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{`${report.patientFirstName} ${report.patientLastName}`}</td>
                        <td>{report.dob}</td>
                        <td>
                            <Link to={ccmLink}>{ccmTotalTime}</Link>
                        </td>
                        {actionsTds}
                    </tr>
                );
            });
        }

        const actionsThs = [];

        for (let i = 0; i < actionsType2.length; i += 1) {
            const action = actionsType2[i];

            let title = `${action.name}`;

            let th = (<th
                className='sortable'
                onClick={() => this._sortByAction(
                    action.code ? action.code : action.name
                )}
                title={title} >
                {action.code ? action.code : action.name.slice(0, 5) + '...'}
            </th>);

            actionsThs.push(
                th
            );
        }

        return (
            <table className='table'>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Patient</th>
                        <th>DOB</th>
                        <th className='sortable' onClick={() => this._sortByAction('ccmMinutes')}
                        >CCM Minutes</th>
                        {actionsThs}
                    </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </table>
        );
    }

    _sortByAction = cptCode => {

        const {
            dispatch,
            monthlyReport,
            actionsType2,
            cptcodes,
            is99454available,
            reportsActionsWOTime,
            period
        } = this.props;

        let cptid = null;

        if (cptCode === 'ccmMinutes') {

            cptid = cptCode;
        } else {

            for (let i in actionsType2) {

                if (actionsType2[i].code === cptCode || (actionsType2[i].code === null && actionsType2[i].name === cptCode)) {

                    cptid = actionsType2[i].id;
                }
            }
        }

        if (!cptid) {

            return null;
        }

        if (!this.sortDirection[cptid]) {

            this.sortDirection[cptid] = 'ask';
        } else {

            this.sortDirection[cptid] = this.sortDirection[cptid] === 'ask' ? 'desc' : 'ask';
        }

        const sortDirection = this.sortDirection[cptid];

        const report = monthlyReport.sort((a, b) => {

            let _a, _b = null;

            if (cptCode === '99454') {

                _a = (typeof is99454available[a.patientId] === 'undefined' || typeof is99454available[a.patientId]['available'] === 'undefined') ? false : is99454available[a.patientId]['available'];
                _b = (typeof is99454available[b.patientId] === 'undefined' || typeof is99454available[b.patientId]['available'] === 'undefined') ? false : is99454available[b.patientId]['available'];

                _a = _a ? 1 : 0;
                _b = _b ? 1 : 0;
            } else if (cptCode === 'ccmMinutes') {

                _a = a.ccmTotalTime;
                _b = b.ccmTotalTime;
            } else {

                _a = a.ccmTotalTimeType2[cptid];
                _b = b.ccmTotalTimeType2[cptid];

                if (cptCode === '99453') {

                    _a = _a === null ? 0 : 1;
                    _b = _b === null ? 0 : 1;
                } else {

                    _a = typeof _a === 'undefined' ? 0 : _a;
                    _b = typeof _b === 'undefined' ? 0 : _b;
                }
            }

            return (
                sortDirection === 'ask'
                    ? (_a > _b ? -1 : (_a < _b ? 1 : 0))
                    : (_a > _b ? 1 : (_a < _b ? -1 : 0))
            );

        });

        const data = {
            report,
            actionsType2,
            cptcodes,
            is99454available,
            reportsActionsWOTime,
            period
        };

        dispatch(
            clearMonthlyReport()
        );

        dispatch(
            setMonthlyReport({ ...data })
        );
    }

    render() {
        return (
            <div className='reports'>
                <div className='report-month-chooser'>
                    <div className='report-month-chooser-header'>Summary for:</div>

                    <Form ref={this.monthChooserForm} onSubmit={() => { }} className='form report-month-chooser-form'>
                        <div className='form-horizontal-control'>
                            <Select name='year'
                                values={getYearsValues(this.props?.period?.year)}
                                onChange={this.onChangeDate}
                                emptyAllowed={false}
                                inline={true} />

                            <Select name='month'
                                values={getMonthsValues(!this.props.period ? null : this.props.period.month - 1)}
                                onChange={this.onChangeDate}
                                emptyAllowed={false}
                                inline={true} />
                        </div>
                    </Form>
                </div>

                {this.renderReport()}
            </div>
        );
    }
}


function mapStateToProps(state) {
    const {
        isMonthlyReportLoaded,
        monthlyReport,
        actionsType2,
        cptcodes,
        is99454available,
        reportsActionsWOTime,
        period
    } = state.reportsMonthly;

    const {
        currentClinic
    } = state.user;

    const keepSelection = window.location.href.indexOf("keep_selection=true") > 0;
    return {
        currentClinic,
        isMonthlyReportLoaded,
        monthlyReport,
        actionsType2,
        cptcodes,
        is99454available,
        reportsActionsWOTime,
        period: (!keepSelection || !period) ? null : period
    };
}


export default connect(mapStateToProps)(ReportsShowReports);
