import axios from 'axios';
import ActionConstants from './../constants/action';
import { getWithClinic } from './../lib/rest';
import env from 'react-dotenv';

export function getDoctors(clinicId) {

    return axios.get(`${env.API_URL}/api/v1/clinic/doctors`, {
        headers: {
            Authorization: `Bearer ${window.getCookie('UserToken')}`
        },
        params: {
            clinicId
        }
    });
}

export function getAllDoctors() {
    return getWithClinic('/api/doctors/all.php', {
        inactive: true
    });
}


export function setDoctors(doctors) {
    return {
        type: ActionConstants.SET_DOCTORS,
        payload: {
            doctors
        }
    };
}


export function clearDoctors() {
    return {
        type: ActionConstants.CLEAR_DOCTORS
    };
}
