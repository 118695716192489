const settings = {
    siteName: 'MED',

    rpmCarePlansInformMethods: [
        {value: 'ccm', name: 'CCM', defaultChecked: true, disabled: true},
        {value: 'sms', name: 'SMS (only critical)', defaultChecked: false}
    ]
};

export default settings;
