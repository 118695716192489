import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getCompanyLogo, setCompanyLogo } from '../actions/total';

class CompanyLogo extends Component {
    componentDidMount() {
        const { dispatch, clinicId } = this.props;

        getCompanyLogo(clinicId).then(({ data: { companyLogo } }) => dispatch(setCompanyLogo(companyLogo)));
    }

    componentDidUpdate() {
        const { dispatch, clinicId } = this.props;

        getCompanyLogo(clinicId).then(({ data: { companyLogo } }) => dispatch(setCompanyLogo(companyLogo)));
    }

    render() {
        const { companyLogoUrl } = this.props;

        return (
            <img className='logo' src={companyLogoUrl ? companyLogoUrl : '/static/img/ecare21-logo.png'} alt='logo' />
        );
    }
}

export default connect(state => ({
    companyLogoUrl: state.total.companyLogoUrl,
    clinicId: state.user.currentClinic
}))(CompanyLogo);