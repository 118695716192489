import React from 'react';
import {connect} from 'react-redux';
import MainConstants from './../../constants/main';
import {
    getPatientCarePlan,
    setPatientCarePlan,
    clearPatientCarePlan,
    getPatientCarePlanLogs,
    setPatientCarePlanLogs,
    clearPatientCarePlanLogs,
    setPatientCarePlanLogsLoading
} from './../../actions/reports/patient-care-plans';
import RequestLoader from './../../components/request-loader';
import {formatDayDate, formatShortDate} from './../../lib/date';
import {lines} from './../../lib/lines';
import {getMonthsValues, getYearsValues} from './../../lib/date';
import Form from './../../lib/forms/form';
import Select from './../../lib/forms/select';

class ReportsShowPatientCarePlanLogs extends React.Component {
    componentDidMount() {
        const patient = this._getPatient();
        const year = this._getYear();
        const month = this._getMonth() + 1;
        const action = this._getAction();
        const numberOfRPMPeriod = this._getNumberOfRPMPeriod();

        this._loadPatientCarePlan(patient);
        this._loadPatientCarePlanLogs(
            this.props.currentClinic,
            patient,
            action,
            year,
            month,
            numberOfRPMPeriod
        );
    }

    componentWillUnmount() {
        this.props.dispatch(clearPatientCarePlan());
        this.props.dispatch(clearPatientCarePlanLogs());
    }

    constructor(props) {
        super(props);

        this.dateChooserForm = React.createRef();

        this._onChangeDate = this._onChangeDate.bind(this);
    }

    renderCarePlanInfo() {
        if (!this.props.isPatientCarePlanLoaded) {
            return null;
        }

        return (
            this.props.patientCarePlan ?
                <div className='care-plan-view'>
                    <div className='block-info'>
                        <div>
                            <div>Patient</div>
                            <div>{this.props.patientCarePlan.patientFirstName + ' ' + this.props.patientCarePlan.patientLastName}</div>
                        </div>
                        <div>
                            <div>Contact name</div>
                            <div>{this.props.patientCarePlan.contactName}</div>
                        </div>
                        <div>
                            <div>Contact phone</div>
                            <div>{this.props.patientCarePlan.contactPhone}</div>
                        </div>
                        <div>
                            <div>Phone</div>
                            <div>{this.props.patientCarePlan.patientPhoneNumber}</div>
                        </div>
                        <div>
                            <div>Phone (additional)</div>
                            <div>{this.props.patientCarePlan.patientPhoneNumberAdditional}</div>
                        </div>

                    </div>
                    <div className='block-info'>
                        <div>
                            <div>Medical assistant</div>
                            <div>{this.props.patientCarePlan.collectorFirstName + ' ' + this.props.patientCarePlan.collectorLastName}</div>
                        </div>
                        <div>
                            <div>Whom to inform</div>
                            <div>{this.props.patientCarePlan.informDoctorId === 0 ? 'Automatic collection of data' : this.props.patientCarePlan.informDoctorFirstName + ' ' + this.props.patientCarePlan.informDoctorLastName}</div>
                        </div>
                        <div>
                            <div>How to inform</div>
                            <div>{this.renderHowToInform()}</div>
                        </div>
                        <div>
                            <div>Prescribed by</div>
                            <div>{this.props.patientCarePlan.doctorFirstName + ' ' + this.props.patientCarePlan.doctorLastName}</div>
                        </div>
                        <div>
                            <div>Start by</div>
                            <div>{formatDayDate(this.props.patientCarePlan.startDate)}</div>
                        </div>
                    </div>
                </div>
                : <div />
        );
    }

    renderHowToInform() {
        var names = {};

        for (var i = 0; i < MainConstants.rpmCarePlansInformMethods.length; i++) {
            var v = MainConstants.rpmCarePlansInformMethods[i];
            names[v.value] = v.name;
        }

        var result = [];

        for (var informMethod in this.props.patientCarePlan.informMethods) {
            if (!this.props.patientCarePlan.informMethods[informMethod]) {
                continue;
            }

            if (names[informMethod]) {
                result.push(names[informMethod]);
            } else {
                result.push(informMethod);
            }
        }

        return result.join(', ');
    }

    renderDateManager() {
        if (!this.props.isPatientCarePlanLoaded) {
            return null;
        }

        var year = this._getYear();
        var month = this._getMonth();

        var yearsValues = getYearsValues();
        var monthsValues = getMonthsValues();

        for (var i = 0; i < yearsValues.length; i++){
            if (yearsValues[i].value === year) {
                yearsValues[i].default = true;
            } else {
                yearsValues[i].default = false;
            }
        }

        for (var i = 0; i < monthsValues.length; i++){
            if (monthsValues[i].value === month) {
                monthsValues[i].default = true;
            } else {
                monthsValues[i].default = false;
            }
        }

        return (
            <div className='care-plan-date-chooser'>
                <Form ref={this.dateChooserForm} onSubmit={() => {}} className='form'>
                    <div className='form-horizontal-control'>
                        <Select name='year'
                                values={yearsValues}
                                onChange={this._onChangeDate}
                                emptyAllowed={false}
                                inline={true}/>

                        <Select name='month'
                                values={monthsValues}
                                onChange={this._onChangeDate}
                                emptyAllowed={false}
                                inline={true}/>
                    </div>
                </Form>

                { this.props.showTotalTime ?
                    <div className='total-time'>
                        <div><strong>Total month time:</strong> {this._getTotalLogsTime()}</div>
                    </div>
                    : '' }

            </div>
        );
    }

    renderLogs() {
        if (!this.props.isPatientCarePlanLogsLoaded) {
            return (
                <div className='care-plan-logs-loader'>
                    <RequestLoader center/>
                </div>
            );
        }

        if (this.props.patientCarePlanLogs.length === 0) {
            return (
                <div className='message'>
                    No care plan logs
                </div>
            );
        }

        let htmlLogs = [];

        for (let i = 0; i < this.props.patientCarePlanLogs.length; i += 1) {

            const log = this.props.patientCarePlanLogs[i];

            if (this.props.is454) {
                const { metrics } = log;

                metrics.map(i => {

                    i.logs.map(i => {

                        const { vitalName, reportedValue , partOfDay } = i;

                        htmlLogs.push(
                            <div className='care-plan-log'>
                                <div>{ partOfDay } | { vitalName }: { reportedValue }</div>
                            </div>
                        );
                    });
                });
            } else {

                htmlLogs.push(
                    <div key={log.id} className='care-plan-log'>
                        <div>
                            <div>Commented by</div>
                            <div>{log.doneByName}</div>
                        </div>

                        { log.actionName ?
                            <div>
                                <div>Action Name</div>
                                <div>{ log.actionName } - { log.actionCode }</div>
                            </div>
                            : '' }
                        <div>
                            <div>Done at</div>
                            <div>{formatShortDate(log.doneAt, false)}</div>
                        </div>

                        { this.props.showTotalTime ?
                        <div>
                            <div>Reporting time</div>
                            <div>{log.reportingTime}</div>
                        </div>
                        : '' }

                        <div>
                            <div>Resolution</div>
                            <div>{log.resolution ? lines(log.resolution) : '-'}</div>
                        </div>

                        <div>
                            <div>Description</div>
                            <div>{log.description ? lines(log.description) : '-'}</div>
                        </div>
                    </div>
                );
            }
        }

        return (
            <div className='care-plan-logs'>
                { htmlLogs }
            </div>
        );
    }

    render() {
        return (
            <div className='reports'>
                {this.renderCarePlanInfo()}
                {this.renderDateManager()}
                {this.renderLogs()}
            </div>
        );
    }

    _getPatient() {
        var patientId = parseInt(this.props.match.params.patientId, 10);

        if (isNaN(patientId)) {
            this.props.history.push('/app/reports');
            return;
        }

        return patientId;
    }

    _getYear() {
        const year = this.props.match.params.year;
        return parseInt(year, 10);
    }

    _getMonth() {
        const month = this.props.match.params.month;
        return parseInt(month, 10) - 1;
    }

    _getAction() {
        return this.props.match.params.actionId;
    }

    _getNumberOfRPMPeriod() {
        return this.props.match.params.numberOfRPMPeriod;
    }

    _loadPatientCarePlan(patient) {
        getPatientCarePlan(this.props.currentClinic, patient).then(res => {
            this.props.dispatch(
                setPatientCarePlan(
                    res.data.carePlan
                )
            );
        }).catch(err => {
        });
    }

    _loadPatientCarePlanLogs(clinicId, patientId, actionId, year, month, numberOfRPMPeriod) {
        getPatientCarePlanLogs(clinicId, patientId, actionId, year, month, numberOfRPMPeriod).then(res => {
            this.props.dispatch(setPatientCarePlanLogs(
                res.data.logs,
                res.data.is454,
                res.data.showTotalTime
            ));
        });
    }

    _onChangeDate() {
        const patient = this._getPatient();
        const values = this.dateChooserForm.current.getValues();
        const month = parseInt(values.month, 10) + 1;

        let url = '/app/reports/patients/' + patient + '/care-plan-logs/rpm/' + values.year + '/' + month;

        if (this._getAction()) {
            url += '/' + this._getAction();
        }

        this.props.history.push(url);

        this.props.dispatch(
            setPatientCarePlanLogsLoading()
        );

        this._loadPatientCarePlanLogs(
            this.props.currentClinic,
            patient,
            this._getAction(),
            values.year,
            month
        );
    }

    _getTotalLogsTime() {
        if (!this.props.isPatientCarePlanLogsLoaded) {
            return '...';
        }

        var time = 0;

        for (let i = 0; i < this.props.patientCarePlanLogs.length; i++) {
            let log = this.props.patientCarePlanLogs[i];
            time += log.reportingTime;
        }

        if (time === 1) {
            return 1 + ' minute';
        }

        if (time === 0) {
            return '0 minutes';
        }

        return Number(time).toFixed(1) + ' minutes';
    }
}

const mapStateToProps = function(state) {
    return {
        isPatientCarePlanLoaded: state.reportsPatientCarePlans.isPatientCarePlanLoaded,
        patientCarePlan: state.reportsPatientCarePlans.patientCarePlan,
        currentClinic: state.user.currentClinic,

        isPatientCarePlanLogsLoaded: state.reportsPatientCarePlans.isPatientCarePlanLogsLoaded,
        patientCarePlanLogs: state.reportsPatientCarePlans.patientCarePlanLogs,
        is454: state.reportsPatientCarePlans.is454,
        showTotalTime: state.reportsPatientCarePlans.showTotalTime
    };
};

export default connect(mapStateToProps)(ReportsShowPatientCarePlanLogs);
