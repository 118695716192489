import React from 'react';

export default class Index extends React.Component {

    componentDidMount() {

        const { type } = this.props.match.params;

        if (typeof type === 'undefined') {

            this.props.history.push('/app');
        }
    }

    render() {

        return <div />
    }
}
