import React from 'react';

export default class WeekdaysWithDayParts extends React.Component {
    static defaultProps = {
        weekdays: {
            sunday: false,
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false
        },
        parts: {},
        thresholds: []
    }

    renderWeekdays() {
        const { weekdays } = this.props;

        return (
            <div className='weekdays'>
                <div className='weekdays-day'>
                    <div>S</div>
                    <div className={weekdays.sunday ? 'active' : ''}></div>
                </div>
                <div className='weekdays-day'>
                    <div>M</div>
                    <div className={weekdays.monday ? 'active' : ''}></div>
                </div>
                <div className='weekdays-day'>
                    <div>T</div>
                    <div className={weekdays.tuesday ? 'active' : ''}></div>
                </div>
                <div className='weekdays-day'>
                    <div>W</div>
                    <div className={weekdays.wednesday ? 'active' : ''}></div>
                </div>
                <div className='weekdays-day'>
                    <div>T</div>
                    <div className={weekdays.thursday ? 'active' : ''}></div>
                </div>
                <div className='weekdays-day'>
                    <div>F</div>
                    <div className={weekdays.friday ? 'active' : ''}></div>
                </div>
                <div className='weekdays-day'>
                    <div>S</div>
                    <div className={weekdays.saturday ? 'active' : ''}></div>
                </div>
            </div>
        );
    }

    renderDayParts() {
        const { parts } = this.props;

        if (Object.keys(parts).length === 0) {
            return null;
        }

        return (
            <div className='day-parts'>
                <div className='day-part'>
                    <div>Morning</div>
                    <div className={parts.morning ? 'active' : ''}></div>
                </div>
                <div className='day-part'>
                    <div>Noon</div>
                    <div className={parts.noon ? 'active' : ''}></div>
                </div>
                <div className='day-part'>
                    <div>Evening</div>
                    <div className={parts.evening ? 'active' : ''}></div>
                </div>
            </div>
        );
    }

    renderLowThreshold(th) {
        return (
            <div key={th.id} className='vital-threshold'>
                <div className='threshold-name'>{th.name}</div>
                <div className='threshold-values'>
                    <div className='threshold-value'>
                        <div>Warning low</div>
                        <div>{th.warningLow}</div>
                    </div>

                    <div className='threshold-value'>
                        <div>Critical low</div>
                        <div>{th.criticalLow}</div>
                    </div>
                </div>
            </div>
        );
    }

    renderHighThreshold(th) {
        return (
            <div key={th.id} className='vital-threshold'>
                <div className='threshold-name'>{th.name}</div>
                <div className='threshold-values'>
                    <div className='threshold-value'>
                        <div>Warning high</div>
                        <div>{th.warningHigh}</div>
                    </div>

                    <div className='threshold-value'>
                        <div>Critical high</div>
                        <div>{th.criticalHigh}</div>
                    </div>
                </div>
            </div>
        );
    }

    renderFullThreshold(th) {
        return (
            <div key={th.id} className='vital-threshold'>
                <div className='threshold-name'>{th.name}</div>
                <div className='threshold-values'>
                    <div className='threshold-value'>
                        <div>Warning low</div>
                        <div>{th.warningLow}</div>
                    </div>

                    <div className='threshold-value'>
                        <div>Critical low</div>
                        <div>{th.criticalLow}</div>
                    </div>

                    <div className='threshold-value'>
                        <div>Warning high</div>
                        <div>{th.warningHigh}</div>
                    </div>

                    <div className='threshold-value'>
                        <div>Critical high</div>
                        <div>{th.criticalHigh}</div>
                    </div>
                </div>
            </div>
        );
    }

    renderWeightThreshold(th) {
        return (
            <div key={th.id} className='vital-threshold'>
                <div className='threshold-name'>Weight change</div>
                <div className='threshold-values'>
                    <div className='threshold-value'>
                        <div>Warning</div>
                        <div>{th.criticalLow}</div>
                    </div>

                    <div className='threshold-value'>
                        <div>Critical</div>
                        <div>{th.criticalHigh}</div>
                    </div>

                    <div className='threshold-value'>
                        <div>Days</div>
                        <div>{th.days}</div>
                    </div>
                </div>
            </div>
        );
    }

    renderThresholds() {
        const { thresholds, thresholdsType } = this.props;

        if (thresholds.length === 0) {
            return null;
        }

        const ths = [];

        for (let i = 0; i < thresholds.length; i += 1) {
            const th = thresholds[i];

            if (th.warningLow === ''
                && th.criticalLow === ''
                && th.warningHigh === ''
                && th.criticalHigh === '') {
                continue;
            }

            if (th.name === 'Weight') {
                ths.push(this.renderWeightThreshold(th));
                continue;
            }

            if (thresholdsType === 'low') {
                ths.push(this.renderLowThreshold(th));
                continue;
            }

            if (thresholdsType === 'high') {
                ths.push(this.renderHighThreshold(th));
                continue;
            }

            ths.push(this.renderFullThreshold(th));
        }

        return (
            <div className='vital-thresholds'>
                {ths}
            </div>
        );
    }

    render() {
        return (
            <div className='weekdays-with-day-parts'>
                {this.renderWeekdays()}
                {this.renderDayParts()}
                {this.renderThresholds()}
            </div>
        );
    }
}
