import React from 'react';
import { connect } from 'react-redux';
import FormLoader from '../../components/form-loader';
import { getActions, setActions, clearActions } from './../../actions/ccm/actions';
import { getPatientsByName } from './../../actions/patients';
import {
    getAssignedTodos,
    setAssignedTodos,
    clearAssignedTodos,
    getTodos,
    setTodos,
    clearTodos,
    createTodo,
    getAssignedTodosByDate
} from './../../actions/ccm/todo';
import userInitials from './../../lib/user-initials';
import TodosTableWithDayPicker from './../../tables/todos-with-day-picker';
import PatientTooltip from './../../components/patient-tooltip';
import Form from './../../lib/forms/form';
import SelectWithSearch from './../../lib/forms/select-with-search';
import SelectWithLoading from './../../lib/forms/select-with-loading';
import Textarea from './../../lib/forms/textarea';
import { required } from './../../lib/forms/field-validators';
import DatePicker from '../../lib/forms/date-picker';
import { dateToRequestString } from '../../lib/date';
import AssignedTodosTableWithDayPicker from '../../tables/assigned-todos-with-day-picker';
import PrioritiesList from '../../constants/priority'
import Dropdown from '../../lib/forms/dropdown';
import TaskInformOptions from '../../constants/taskInformOptions'
import Checkboxes from '../../lib/forms/checkboxes';

class CCMTodo extends React.Component {

    sortStatus = {};

    state = {
        isCreatingTodo: false,
        descriptionValidator: []
    };

    constructor(props) {
        super(props);

        this.todoForm = React.createRef();

        this._onSubmit = this._onSubmit.bind(this);
        this._loadTodosByDate = this._loadTodosByDate.bind(this);
        this._loadPatientsByName = this._loadPatientsByName.bind(this);
    }

    componentDidMount() {
        this._loadActions();
        this._loadAssignedTodos();
        this._loadTodos();
    }

    componentWillUnmount() {
        this.props.dispatch(clearAssignedTodos());
        this.props.dispatch(clearTodos());
        this.props.dispatch(clearActions());
    }

    render() {

        return (
            <div className='dashboard-todo'>
                <div className='dashboard-todo-form'>
                    { this.renderTodoForm() }
                </div>

                <AssignedTodosTableWithDayPicker
                    sortFunction={this._sortByColumn}
                    name='todos-table'
                    history={this.props.history}
                    headerName={'Assigned to You'}
                    isTodosLoaded={this.props.isAssignedTodosLoaded}
                    todos={this.props.assignedTodos}
                    emptyTableMessage={'No assigned to do items for you'}
                    onDayChange={this._loadAssignedTodosByDate} />

                <TodosTableWithDayPicker
                    sortFunction={this._sortByColumn}
                    name='todos-table'
                    history={this.props.history}
                    headerName={'All ToDo items'}
                    isTodosLoaded={this.props.isTodosLoaded}
                    todos={this.props.todos}
                    emptyTableMessage={'No to do items to display'}
                    onDayChange={this._loadTodosByDate}/>
            </div>
        );
    }

    _sortByColumn = (column, source, callback) => {
        if (undefined === this.sortStatus[source]) {

            this.sortStatus[source] = {};
        }

        if (column !== this.sortStatus[source].sortColumn) {

            this.sortStatus[source].sortDirection = 'ASC';
            this.sortStatus[source].sortColumn = column;
        }

        const sortDirection = this.sortStatus[source].sortDirection;

        const list = this.props[source].sort((a, b) => {

            let columnA = a[column];
            let columnB = b[column];

            if (typeof a[column] === 'string' && typeof b[column] === 'string') {

                columnA = columnA.toUpperCase();
                columnB = columnB.toUpperCase();
            }

            return columnA > columnB
                ? (sortDirection === 'DESC' ? -1 : 1)
                : (columnA < columnB
                    ? (sortDirection === 'DESC' ? 1 : -1)
                    : 0);
        });

        this.sortStatus[source].sortDirection = this.sortStatus[source].sortDirection === 'ASC' ? 'DESC' : 'ASC';

        this.props.dispatch(
            callback([...list])
        );
    }

    renderTodoForm() {
        const { actions, isActionsLoaded } = this.props;
        const { setState } = this;

        if (!isActionsLoaded) {
            return null;
        }

        const dischargedAction = actions.find(e => e.name.toLowerCase() === 'discharged from service');

        return (
            <Form ref={this.todoForm} onSubmit={this._onSubmit} className='form'>
                <div className='dashboard-form-control'>
                    <div className='form-horizontal-control'>
                        <SelectWithLoading
                            name='patient'
                            label='Patient'
                            validators={[required('Please select patient')]}
                            onLoadMore={this._loadPatientsByName}
                            inline={true}
                            filterOption={() => true}
                            tooltip={<PatientTooltip/>} />

                        <SelectWithSearch
                            name='doctor'
                            label='Assigned to'
                            validators={[required('Please select assigned to')]}
                            values={this._getDoctorOptions()}
                            inline={true} />

                        <SelectWithSearch
                            onChange={ e => this.setState({
                                descriptionValidator: e === dischargedAction?.id ? [required('Type description')] : []
                            })}
                            name='action'
                            label='Action'
                            values={this._getActionOptions()}
                            validators={[required('Please select action')]}
                            inline={true}
                            creatable={true}
                            promptTextCreator={label => `Create action "${label}"`} />
                    </div>

                    <div className='form-horizontal-control'>
                    <div className='assigned-date-field'>
                        <DatePicker
                            name='date'
                            disabledBefore={new Date()}
                            label='Date of service'
                            inline={true} />
                        </div>

                        <Dropdown
                            name='priority'
                            options={PrioritiesList}
                            label='Priority'
                            defaultValue={PrioritiesList.find(x => x.label === 'Normal').value}
                            inline={true} />

                        <Checkboxes
                            name='taskInform'
                            values={TaskInformOptions}
                            inline={true}
                            inlineFields={true} />
                    </div>

                    <Textarea
                        validators={ this.state.descriptionValidator }
                        name='description'
                        label='Description'
                        rows={2}
                        inline={true} />
                </div>

                <div className='buttons'>
                    <button disabled={this.state.isCreatingTodo} className='button'>
                        Add
                    </button>
                    {this.state.isCreatingTodo ? <FormLoader /> : null}
                </div>
            </Form>
        );
    }

    _loadActions() {

        const { currentClinic } = this.props;

        getActions(currentClinic).then((res) => {

            this.props.dispatch(setActions(res.data.actions));
        });
    }

    _loadAssignedTodos() {

        const { dispatch, currentClinic } = this.props;

        getAssignedTodos(currentClinic).then(res => {
            const { data: { todos } }  = res;

            for(let i = 0; i < todos.length; i += 1) {

                todos[i].patientName = `${todos[i].patientFirstName} ${todos[i].patientLastName}`;
                todos[i].doctorName = userInitials(
                    todos[i].createdByFirstName,
                    todos[i].createdByLastName
                );
                todos[i].assignedTo = `${todos[i].assignedToFirstName} ${todos[i].assignedToLastName}`;
                todos[i].warningStatus = todos[i].actionName === 'Warning from RPM';
            }

            dispatch(
                setAssignedTodos(
                    todos
                )
            );
        });
    }

    _loadAssignedTodosByDate = (d) => {
        if (!d) {
            this._loadAssignedTodos();
            return;
        }

        const year = d.getFullYear();

        let month = d.getMonth() + 1;

        if (month < 10) {
            month = '0' + month;
        }

        const day = d.getDate() < 10 ? '0' + d.getDate() : d.getDate();
        const date = year + '-' + month + '-' + day;
        const clinicId = this.props.currentClinic;

        getAssignedTodosByDate(clinicId, date).then(res => {

            const { data: { todos } } = res;

            for (let i = 0; i < todos.length; i += 1) {

                todos[i].patientName = `${todos[i].patientFirstName} ${todos[i].patientLastName}`;
                todos[i].doctorName = userInitials(
                    todos[i].createdByFirstName,
                    todos[i].createdByLastName
                );
                todos[i].assignedTo = `${todos[i].assignedToFirstName} ${todos[i].assignedToLastName}`;
                todos[i].warningStatus = todos[i].actionName === 'Warning from RPM';
            }

            this.props.dispatch(
                setAssignedTodos(
                    todos
                )
            );
        });
    }

    _loadTodos() {
        const { dispatch, currentClinic } = this.props;

        getTodos(currentClinic).then(res => {
            const { data: { todos } } = res
            for(let i = 0; i < todos.length; i += 1) {
                todos[i].patientName = `${todos[i].patientFirstName} ${todos[i].patientLastName}`;
                todos[i].doctorName = userInitials(
                    todos[i].createdByFirstName,
                    todos[i].createdByLastName
                );
                todos[i].assignedTo = `${todos[i].assignedToFirstName} ${todos[i].assignedToLastName}`;
                todos[i].warningStatus = todos[i].actionName === 'Warning from RPM';
            }

            dispatch(
                setTodos(
                    todos
                )
            );
        });
    }

    _loadPatientsByName(name, callback) {
        const { currentClinic } = this.props;

        getPatientsByName(currentClinic, name).then((res) => {
            const patients = res.data.patients.map((patient) => {
                let dob = '';

                if (patient.dob !== null) {
                    dob = ', ' + patient.dob;
                }

                return {
                    value: patient.id,
                    label: patient.firstName + ' ' + patient.lastName + dob
                };
            });
            callback(patients);
        });
    }

    _loadTodosByDate = (d) => {
        if (!d) {
            this._loadTodos();
            return;
        }

        const year = d.getFullYear();

        let month = d.getMonth() + 1;

        if (month < 10) {
            month = '0' + month;
        }

        const day = d.getDate() < 10 ? '0' + d.getDate() : d.getDate();
        const date = year + '-' + month + '-' + day;
        const clinicId = this.props.currentClinic;

        getTodos(clinicId, date).then((res) => {

            if (res.data.todos) {

                this.props.dispatch(
                    setTodos(
                        res.data.todos
                    )
                );
            }
        });
    }

    _getDoctorOptions() {
        return this.props.doctors.map((doctor) => {
            return {
                value: doctor.id,
                label: doctor.firstName + ' ' + doctor.lastName
            };
        });
    }

    _getActionOptions() {
        return this.props.actions.map((action) => {
            let label = action.name;
            if (action.cptCode) {
                label = `${action.cptCode} - ${action.name}`;
            }

            return {
                value: action.id,
                label
            };
        });
    }

    _onSubmit(todo) {
        if (this.state.isCreatingTodo) {
            return;
        }

        const { currentClinic } = this.props;

        let newTodo = {};

        if (typeof(todo.action) === 'number') {
            newTodo = {
                patientId: todo.patient,
                doctorId: todo.doctor,
                actionid: todo.action,
                description: todo.description,
                assignedTime: dateToRequestString(todo.date),
                priority: todo.priority,
                notification: todo.taskInform.notification,
                sms: todo.taskInform.sms 
            };
        } else {
            newTodo = {
                patientId: todo.patient,
                doctorId: todo.doctor,
                actionname: todo.action,
                description: todo.description,
                assignedTime: dateToRequestString(todo.date),
                priority: todo.priority,
                notification: todo.taskInform.notification,
                sms: todo.taskInform.sms 
            };
        }

        if (todo.doctor) {
            const doctor = this.props.doctors.find(doctor => doctor.id === todo.doctor);

            if (newTodo.description) {
                newTodo.description += ' / ';
            }

            newTodo.description += `Assigned to: ${doctor.firstName} ${doctor.lastName}`;
        }

        this.setState({ isCreatingTodo: true }, () => {
            createTodo(currentClinic, newTodo).then(() => {
                if (this.todoForm.current) {
                    this.todoForm.current.clear();
                }

                this._loadActions();
                this._loadAssignedTodos();
                this._loadTodos();
                //Set default value to dropdown (current.clear() is not working)
                this.todoForm.current.fields.priority.current.state.value.value =
                    PrioritiesList.find(x => x.label === 'Normal').value;
                this.todoForm.current.fields.priority.current.state.value.label ='Normal';
                //Set default value to checkboxes (current.clear() is not working)
                this.todoForm.current.fields.taskInform.current.state.values.sms = false;
                this.todoForm.current.fields.taskInform.current.state.values.notification = false;

                this.setState({ isCreatingTodo: false });
            });
        });
    }
}

function mapStateToProps(state) {

    return {
        userId: state.user.userId,
        currentClinic: state.user.currentClinic,
        isDoctorsLoaded: state.doctors.isDoctorsLoaded,
        doctors: state.doctors.doctors,

        isActionsLoaded: state.ccmActions.isActionsLoaded,
        actions: state.ccmActions.actions,

        isAssignedTodosLoaded: state.ccmTodo.isAssignedTodosLoaded,
        assignedTodos: state.ccmTodo.assignedTodos,

        isTodosLoaded: state.ccmTodo.isTodosLoaded,
        todos: state.ccmTodo.todos
    };
}

export default connect(mapStateToProps)(CCMTodo);
