import React, { Component } from 'react';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

export default class DropdownBase extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: this._getDefaultValue(props)
        };

        this._onChange = this._onChange.bind(this);
    }

    render() {
        const classes = ['form-field-non-flex'];

        let dropdown = (
            <Dropdown
                options={this.props.options}
                onChange={this._onChange}
                value={this.state.value}
                disabled={this.props.disabled} />
            );

        if (this.props.inline) {
            return (
                <div className={classes.join(' ')}>
                    <div className='form-inline-select-field'>
                <label htmlFor={this.props.name}>{this.props.label}</label>
                {dropdown}
                    </div>
                </div>
            );
        }
        
        return (
            <div className={classes.join(' ')}>
                <label htmlFor={this.props.name}>{this.props.label}</label>
                {dropdown}
            </div>
        );
    }

    _getDefaultValue(props) {
        let value = null;

        if (null !== props.defaultValue) {
            for (const element of props.options) {
                const val = element;

                if (val.value === props.defaultValue) {
                    value = { ...val };
                    break;
                }
            }
        }
        return value
    }

    _onChange(value) {
        this.setState({
            value
        });

        if (value) {
            this.props.onChange && this.props.onChange(value.value);
        } else {
            this.props.onChange && this.props.onChange('');
        }
    }

    getValue() {
        if (this.state.value) {
            return this.state.value.value;
        }

        return '';
    }
}

DropdownBase.defaultProps = {
    defaultValue: null,
    options: [],
    inline: false,
    disabled: false,
    placeholder: ''
};