import React, { Component } from 'react';
import PhoneIcon from '../../../../components/icons/phone';
import ChatSquareDotsIcon from '../../../../components/icons/chat-square-dots';
import CameraVideoIcon from '../../../../components/icons/camera-video';
import ClipboardIcon from '../../../../components/icons/clipboard';
import CommonPopup from '../../../../components/common-popup';
import ModalChatWrapper from '../../../../containers/modal-chat-wrapper';

export default class PatientCommunications extends Component {

    state = {
        patientCommunications: ''
    }

    _tryVideoChat = () => {
        const { clinicId, userId, carePlan } = this.props;

        const params = [
            `patientUserId=${carePlan.patientId}`,
            `employeeUserId=${userId}&clinicId=${clinicId}`
        ];

        const url = `${window.env.Video_Call_Url}?${params.join('&')}`;
        const win = window.open(url, '_blank');
        win.focus();
    }

    _setPatientCommunications = (patientCommunications) => {
        this.setState({ patientCommunications });
    }

    _renderCommunicationsActions = () => {

        const { clinicId, carePlan } = this.props;
        const { patientCommunications } = this.state;

        let communicationsActions = null;

        if (patientCommunications === 'call-patient') {

            communicationsActions = (<CommonPopup header={`Call to ${carePlan.patientFirstName} ${carePlan.patientLastName}` }
                                                  onClose={() => { this._setPatientCommunications(null, null); }}>
                <ul className='call-patient-container'>
                    { !carePlan.patientPhoneNumber && !carePlan.patientPhoneNumberAdditional
                        ? <li>No available phone numbers</li>
                        : null }
                    { carePlan.patientPhoneNumber
                        ? <li><a href={ `tel:${carePlan.patientPhoneNumber}`}> Call to: { carePlan.patientPhoneNumber }</a></li>
                        : null }
                    { carePlan.patientPhoneNumberAdditional
                        ? <li><a href={ `tel:${carePlan.patientPhoneNumberAdditional}`}>Call to: { carePlan.patientPhoneNumberAdditional } (additional)</a></li>
                        : null }
                </ul>

            </CommonPopup>)
        } else if (patientCommunications === 'chat-patient') {

            communicationsActions = (
                <CommonPopup header={carePlan.patientFirstName + ' ' + carePlan.patientLastName}
                             onClose={() => { this._setPatientCommunications(''); }}>
                    <ModalChatWrapper clinicId={clinicId}
                                      patientUserId={carePlan.patientId}/>
                </CommonPopup>
            );
        }

        return communicationsActions;
    }

    render() {
        const { patientCommunications } = this.state;

        const isCall = patientCommunications === 'call-patient';
        const isChat = patientCommunications === 'chat-patient';

        const callPatientClasses = ['button'];
        if (isCall) {
            callPatientClasses.push('button-pressed');
        }

        const chatPatientClasses = ['button'];
        if (isChat) {
            chatPatientClasses.push('button-pressed');
        }

        return (
            <div className='communications-panel'>
                <div className='communication-buttons'>
                    <button className={callPatientClasses.join(' ')}
                            onClick={() => { this._setPatientCommunications(isCall ? '' : 'call-patient'); }}>
                        <PhoneIcon/>
                        Call patient
                    </button>
                    <button className={chatPatientClasses.join(' ')}
                            onClick={() => { this._setPatientCommunications(isChat ? '' : 'chat-patient'); }}>
                        <ChatSquareDotsIcon/>
                        Text patient
                    </button>
                    <button className='button'
                            onClick={() => { this._tryVideoChat(); }}>
                        <CameraVideoIcon/>
                        Start video call
                    </button>
                    <button className='button' disabled={true}>
                        <ClipboardIcon/>
                        Escalate
                    </button>
                </div>
                { this._renderCommunicationsActions() }
            </div>
        );
    }
}