import React from 'react';


const WEEKDAYS = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday'
];

const DAYPARTS = [
    'morning',
    'noon',
    'evening'
];


export default class DaysChooser extends React.Component {
    constructor(props) {
        super(props);

        const weekdays = {
            all: false
        };

        for (let i in WEEKDAYS) {
            weekdays[WEEKDAYS[i]] = true;
        }

        const dayparts = {};

        for (let i in DAYPARTS) {
            dayparts[DAYPARTS[i]] = true;
        }

        this.state = {
            weekdays,
            dayparts,
            error: ''
        };

        this.onAllDaysAndDaypartsChange = this.onAllDaysAndDaypartsChange.bind(this);
        this.onDayChange = this.onDayChange.bind(this);
        this.onChangeAllDays = this.onChangeAllDays.bind(this);
        this.onPartChange = this.onPartChange.bind(this);
    }

    checkAll() {
        const value = this.getValue();

        let isAllWeekdays = true;
        let isAllDayparts = true;

        for (let i in value.weekdays) {
            if (value.weekdays[i] === false) {
                isAllWeekdays = false;
                break;
            }
        }

        for (let i in value.dayparts) {
            if (value.dayparts[i] === false) {
                isAllDayparts = false;
            }
        }

        this.setState({
            allSelected: isAllWeekdays && isAllDayparts,
            weekdays: {
                ...this.state.weekdays,
                all: isAllWeekdays
            }
        });
    }

    onAllDaysAndDaypartsChange(evt) {
        const value = evt.target.checked;
        const { weekdays, dayparts } = this.state;

        Object.keys(weekdays).forEach((day) => {
            weekdays[day] = value;
        });

        Object.keys(dayparts).forEach((part) => {
            dayparts[part] = value;
        });

        this.setState({
            allSelected: value,
            weekdays: { ...weekdays },
            dayparts: { ...dayparts }
        }, () => {
            this.validate(this.getValue());
        });
    }

    onDayChange(evt, day) {
        const value = evt.target.checked;

        if (!(day in this.state.weekdays)) {
            return;
        }

        const { weekdays } = this.state;
        weekdays[day] = value;

        this.setState({ weekdays }, () => {
            // this.checkAll();

            const val = this.getValue();
            this.validate(val);

            if (this.props.onChange) {
                this.props.onChange(val);
            }
        });
    }

    onChangeAllDays(evt) {
        const value = evt.target.checked;
        const { weekdays } = this.state;

        for (let day in weekdays) {
            weekdays[day] = value;
        }

        this.setState({ weekdays }, () => {
            this.validate(this.getValue());
        });
    }

    onPartChange(evt, part) {
        const value = evt.target.checked;

        if (!(part in this.state.dayparts)) {
            return;
        }

        const { dayparts } = this.state;
        dayparts[part] = value;

        this.setState({ dayparts }, () => {
            // this.checkAll();

            const val = this.getValue();
            this.validate(val);

            if (this.props.onChange) {
                this.props.onChange(val);
            }
        });
    }

    validate(value) {
        for (let i in this.props.validators) {
            const v = this.props.validators[i];
            const error = v(value);

            if (error) {
                this.setError(error);
                return false;
            }
        }

        this.setState({ error: '' });
        return true;
    }

    setError(error) {
        this.setState({ error });
    }

    getValue() {
        const weekdays = {};
        const dayparts = {};

        for (let day in this.state.weekdays) {
            if (WEEKDAYS.indexOf(day) !== -1) {
                weekdays[day] = this.state.weekdays[day];
            }
        }

        for (let part in this.state.dayparts) {
            if (DAYPARTS.indexOf(part) !== -1) {
                dayparts[part] = this.state.dayparts[part];
            }
        }

        if (Object.keys(dayparts).length === 0) {
            for (let i in DAYPARTS) {
                dayparts[DAYPARTS[i]] = false;
            }
        }

        return {
            weekdays,
            dayparts
        };
    }

    setValue(value) {
        const { weekdays, dayparts } = this.state;

        for (let day in weekdays) {
            if (value[day] !== undefined) {
                weekdays[day] = value[day] ? true : false;
            }
        }

        for (let part in dayparts) {
            if (value[part] !== undefined) {
                dayparts[part] = value[part] ? true : false;
            }
        }

        this.setState({ weekdays, dayparts }, () => {
            // this.checkAll();
        });
    }

    clearValue() {
        const { weekdays, dayparts } = this.state;

        for (let day in weekdays) {
            weekdays[day] = false;
        }

        for (let part in dayparts) {
            dayparts[part] = false;
        }

        this.setState({
            allSelected: false,
            weekdays,
            dayparts,
            error: ''
        });
    }

    isValid() {
        return this.validate(this.getValue());
    }

    renderAllDaysAndDayparts() {
        const { uniqueId } = this.props;

        return (
            <div className='days-chooser-days-and-dayparts'>
                <label htmlFor={'all-days-' + uniqueId}>Check all</label>
                <input id={'all-days-' + uniqueId}
                       name={'all-days-' + uniqueId}
                       checked={this.state.allSelected}
                       onChange={this.onAllDaysAndDaypartsChange}
                       type='checkbox'
                       className='form-checkbox'/>
            </div>
        );
    }

    renderDays() {
        const { name, uniqueId } = this.props;

        const sundayName = name + '-sunday-' + uniqueId;
        const mondayName = name + '-monday-' + uniqueId;
        const tuesdayName = name + '-tuesday-' + uniqueId;
        const wednesdayName = name + '-wednesday-' + uniqueId;
        const thursdayName = name + '-thursday-' + uniqueId;
        const fridayName = name + '-friday-' + uniqueId;
        const saturdayName = name + '-saturday-' + uniqueId;

        return (
            <div className='days-chooser-days'>
                <div className='days-chooser-day'>
                    <label htmlFor={sundayName}>S</label>
                    <input id={sundayName}
                           name={sundayName}
                           checked={this.state.weekdays.sunday}
                           onChange={(evt) => { this.onDayChange(evt, 'sunday') }}
                           type='checkbox'
                           className='form-checkbox'/>
                </div>
                <div className='days-chooser-day'>
                    <label htmlFor={mondayName}>M</label>
                    <input id={mondayName}
                           name={mondayName}
                           checked={this.state.weekdays.monday}
                           onChange={(evt) => { this.onDayChange(evt, 'monday') }}
                           type='checkbox'
                           className='form-checkbox'/>
                </div>
                <div className='days-chooser-day'>
                    <label htmlFor={tuesdayName}>T</label>
                    <input id={tuesdayName}
                           name={tuesdayName}
                           checked={this.state.weekdays.tuesday}
                           onChange={(evt) => { this.onDayChange(evt, 'tuesday') }}
                           type='checkbox'
                           className='form-checkbox'/>
                </div>
                <div className='days-chooser-day'>
                    <label htmlFor={wednesdayName}>W</label>
                    <input id={wednesdayName}
                           name={wednesdayName}
                           checked={this.state.weekdays.wednesday}
                           onChange={(evt) => { this.onDayChange(evt, 'wednesday') }}
                           type='checkbox'
                           className='form-checkbox'/>
                </div>
                <div className='days-chooser-day'>
                    <label htmlFor={thursdayName}>T</label>
                    <input id={thursdayName}
                           name={thursdayName}
                           checked={this.state.weekdays.thursday}
                           onChange={(evt) => { this.onDayChange(evt, 'thursday') }}
                           type='checkbox'
                           className='form-checkbox'/>
                </div>
                <div className='days-chooser-day'>
                    <label htmlFor={fridayName}>F</label>
                    <input id={fridayName}
                           name={fridayName}
                           checked={this.state.weekdays.friday}
                           onChange={(evt) => { this.onDayChange(evt, 'friday') }}
                           type='checkbox'
                           className='form-checkbox'/>
                </div>
                <div className='days-chooser-day'>
                    <label htmlFor={saturdayName}>S</label>
                    <input id={saturdayName}
                           name={saturdayName}
                           checked={this.state.weekdays.saturday}
                           onChange={(evt) => { this.onDayChange(evt, 'saturday') }}
                           type='checkbox'
                           className='form-checkbox'/>
                </div>
            </div>
        );
    }

    renderDayParts() {
        const { name, uniqueId } = this.props;

        const morningName = name + '-morning-' + uniqueId;
        const noonName = name + '-noon-' + uniqueId;
        const eveningName = name + '-evening-' + uniqueId;

        return (
            <div className='days-chooser-day-parts'>
                <div className='days-chooser-day-part'>
                    <label htmlFor={morningName}>Morning</label>
                    <input id={morningName}
                           name={morningName}
                           checked={this.state.dayparts.morning}
                           onChange={(evt) => { this.onPartChange(evt, 'morning') }}
                           type='checkbox'
                           className='form-checkbox'/>
                </div>
                <div className='days-chooser-day-part'>
                    <label htmlFor={noonName}>Noon</label>
                    <input id={noonName}
                           name={noonName}
                           checked={this.state.dayparts.noon}
                           onChange={(evt) => { this.onPartChange(evt, 'noon') }}
                           type='checkbox'
                           className='form-checkbox'/>
                </div>
                <div className='days-chooser-day-part'>
                    <label htmlFor={eveningName}>Evening</label>
                    <input id={eveningName}
                           name={eveningName}
                           checked={this.state.dayparts.evening}
                           onChange={(evt) => { this.onPartChange(evt, 'evening') }}
                           type='checkbox'
                           className='form-checkbox'/>
                </div>
            </div>
        );
    }

    renderError() {
        if (!this.state.error) {
            return null;
        }

        return (
            <span className='form-error'>{this.state.error}</span>
        );
    }

    render() {
        const classes = ['day-chooser', 'form-field'];

        if (this.state.error) {
            classes.push('form-field-error');
        }

        let allDaysAndDayparts = null;
        let dayparts = null;

        if (this.props.dayparts) {
            // allDaysAndDayparts = this.renderAllDaysAndDayparts();
            dayparts = this.renderDayParts();
        }

        if (this.props.inline) {
            return (
                <div className={classes.join(' ')}>
                    <div className='form-inline-field'>
                        <label htmlFor={this.props.name}>
                            {this.props.label}
                        </label>
                        <div>
                            {allDaysAndDayparts}
                            {this.renderDays()}
                            {dayparts}
                            {this.renderError()}
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className={classes.join(' ')}>
                <label htmlFor={this.props.name}>
                    {this.props.label}
                </label>
                {allDaysAndDayparts}
                {this.renderDays()}
                {dayparts}
                {this.renderError()}
            </div>
        );
    }
}

DaysChooser.defaultProps = {
    inline: false,
    dayparts: false,
    validators: []
};
