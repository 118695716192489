import ActionConstants from './../../constants/action';


const initialState = {
    isMonthlyReportLoaded: false,
    monthlyReport: [],
    actionsType2: []
};


export default function(state, action) {
    if (action.type === ActionConstants.SET_MONTHLY_REPORT) {
        return Object.assign({}, state, {
            isMonthlyReportLoaded: true,
            monthlyReport: action.payload.report,
            actionsType2: action.payload.actionsType2,
            cptcodes: action.payload.cptcodes,
            is99454available: action.payload.is99454available,
            reportsActionsWOTime: action.payload.reportsActionsWOTime,
            period:action.payload.period
        });
    }

    if (action.type === ActionConstants.CLEAR_MONTHLY_REPORT) {
        return Object.assign({}, state, {
            isMonthlyReportLoaded: false,
            monthlyReport: [],
            actionsType2: [],
            cptcodes: [],
            is99454available: [],
            reportsActionsWOTime: [],
            period: action.payload.clearPeriod ? null: state.period
        });
    }

    return state || Object.assign({}, initialState);
}
