import ActionConstants from './../constants/action';


var initialState = {
    isPatientsLoaded: false,
    patients: [],

    isPatientTodosLoaded: false,
    patientTodos: []
};


export default function(state, action) {
    if (action.type === ActionConstants.SET_PATIENTS) {
        return Object.assign({}, state, {
            isPatientsLoaded: true,
            patients: action.payload.patients
        });
    }

    if (action.type === ActionConstants.CLEAR_PATIENTS) {
        return Object.assign({}, state, {
            isPatientsLoaded: false,
            patients: []
        });
    }

    if (action.type === ActionConstants.CCM_SET_PATIENT_TODOS) {
        return Object.assign({}, state, {
            isPatientTodosLoaded: true,
            patientTodos: action.payload.todos
        });
    }

    if (action.type === ActionConstants.CCM_CLEAR_PATIENT_TODOS) {
        return Object.assign({}, state, {
            isPatientTodosLoaded: false,
            patientTodos: []
        });
    }

    return state || Object.assign({}, initialState);
}
