import React from 'react';

import RequestLoader from './../../components/request-loader';

import Form from './../../lib/forms/form';
import Textarea from './../../lib/forms/textarea';

import { isToday, formatShortDate, formatTimeDate } from './../../lib/date';
import { lines } from './../../lib/lines';

export default class ModalChat extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isScrollBlocked: false,
            hasMoreMessages: true,

            hasMessage: false,
            isSendingMessage: false
        };

        this.monitorId = null;
        this.monitorTickPerSeconds = 10;

        this.messagesRef = React.createRef();
        this.formRef = React.createRef();

        this.scrollMessages = this.scrollMessages.bind(this);
        this.scrollMessagesTo = this.scrollMessagesTo.bind(this);
        this.onMessagesScroll = this.onMessagesScroll.bind(this);
        this.onMessageChange = this.onMessageChange.bind(this);
        this.onMessageSubmit = this.onMessageSubmit.bind(this);
    }

    componentDidMount() {
        this.loadChat();
        this.startMonitor();
    }

    componentWillUnmount() {
        this.props.onCloseChat();
        this.stopMonitor();
    }

    scrollMessages() {
        if (this.messagesRef && this.messagesRef.current) {
            const { scrollHeight } = this.messagesRef.current;
            this.messagesRef.current.scroll(0, scrollHeight);
        }
    }

    scrollMessagesTo(y) {
        if (this.messagesRef && this.messagesRef.current) {
            this.messagesRef.current.scroll(0, y);
        }
    }

    getMessages() {
        const { patientUserId, patientsChats } = this.props;

        let messages = [];
        if (patientsChats[patientUserId]) {
            messages = patientsChats[patientUserId] || [];
        }

        return messages;
    }

    getLastMessage() {
        const messages = this.getMessages();

        let lastMessage = null;

        if (messages.length !== 0) {
            lastMessage = messages[messages.length - 1];
        }

        return lastMessage;
    }

    loadChat(callback) {
        this.props.onLoadChat(() => {
            if (callback) {
                callback();
            }

            this.scrollMessages();
        });
    }

    autoLoadChat(lastMessageId) {
        this.props.onLoadChatLastMessages(lastMessageId, () => {
            this.scrollMessages();
        });
    }

    startMonitor() {
        this.monitorId= setInterval(() => {
            const { patientUserId } = this.props;

            if (!patientUserId) {
                this.stopMonitor();
                return;
            }

            const lastMessage = this.getLastMessage();

            this.props.onLoadChatLastMessage((res) => {

                let newLastMessage = null;
                if (res.data && res.data.message) {
                    newLastMessage = res.data.message;
                }

                if (!lastMessage && !newLastMessage) {
                    return;
                }

                if (!lastMessage && newLastMessage) {
                    this.autoLoadChat(null);
                    return;
                }

                if (lastMessage && newLastMessage && lastMessage.createdAt !== newLastMessage.createdAt) {
                    this.autoLoadChat(lastMessage.id);
                    return;
                }
            });
        }, this.monitorTickPerSeconds * 1000);
    }

    stopMonitor() {
        clearInterval(this.monitorId);
    }

    onMessagesScroll(evt) {
        const { target } = evt;
        const { scrollTop } = evt.target;

        if (scrollTop !== 0) {
            return;
        }

        if (this.state.isScrollBlocked || !this.state.hasMoreMessages) {
            return;
        }

        this.setState({ isScrollBlocked: true });

        this.props.onLoadChatOffset(this.getMessages().length, (res) => {
            const { messages } = res.data;
            const hasMoreMessages = messages.length !== 0;
            const prevScrollHeight = target.scrollHeight;

            this.setState({
                isScrollBlocked: false,
                hasMoreMessages
            }, () => {
                this.scrollMessagesTo(target.scrollHeight - prevScrollHeight);
            });
        });
    }

    onMessageChange(value) {
        const hasMessage = value ? true : false;
        this.setState({ hasMessage });
    }

    onMessageSubmit(values) {
        if (!values.message) {
            return;
        }

        if (this.formRef && this.formRef.current) {
            this.formRef.current.clear();
        }

        this.setState({
            hasMessage: false,
            isSendingMessage: true
        });

        this.props.onCreateChatMessage(values, (res) => {
            this.loadChat(() => {
                this.setState({ isSendingMessage: false });
            });
        })
    }

    renderMessages() {
        const { patientUserId, patientsChats } = this.props;

        let messages = [];
        if (patientsChats[patientUserId]) {
            messages = patientsChats[patientUserId];
        }

        if (messages.length === 0) {
            return (
                <div className='modal-chat-no-messages'>
                    No messages
                </div>
            );
        }

        return messages.map((msg) => {
            const classes = ['modal-chat-message'];
            const contentClasses = ['modal-chat-message-content'];

            if (msg.fromUserId === patientUserId) {
                classes.push('modal-chat-message-from');
            } else {
                classes.push('modal-chat-message-to');
            }

            let msgDate = '';

            if (isToday(msg.createdAt)) {
                msgDate = formatTimeDate(msg.createdAt);
            } else {
                msgDate = formatShortDate(msg.createdAt);
            }

            return (
                <div key={msg.id} className={classes.join(' ')}>
                    <div className={contentClasses.join(' ')}>
                        {lines(msg.message.trim())}
                        <div className='modal-chat-message-date'>{msgDate}</div>
                    </div>
                </div>
            );
        });
    }

    render() {
        const { patientUserId, patientsChats } = this.props;

        if (!patientsChats[patientUserId]) {
            return (
                <div className='modal-chat'>
                    <div className='modal-chat-loader'>
                        <RequestLoader/>
                    </div>
                </div>
            );
        }

        let noMoreMessages = null;

        if (!this.state.hasMoreMessages) {
            noMoreMessages = (
                <div className='modal-chat-no-messages'>
                    No more messages
                </div>
            );
        }

        let moreMessagesLoader = null;

        if (this.state.isScrollBlocked) {
            moreMessagesLoader = (
                <div className='modal-chat-loading-more-messages'>
                    <RequestLoader/>
                </div>
            );
        }

        let messagesLoader = null;

        if (this.state.isSendingMessage) {
            messagesLoader = (
                <div className='modal-chat-sending-message'>
                    <RequestLoader/>
                </div>
            );
        }

        return (
            <div className='modal-chat'>
                <div ref={this.messagesRef}
                     className='modal-chat-messages'
                     onScroll={this.onMessagesScroll}>
                    {noMoreMessages}
                    {moreMessagesLoader}
                    {this.renderMessages()}
                    {messagesLoader}
                </div>

                <Form ref={this.formRef}
                      onSubmit={this.onMessageSubmit}
                      className='form modal-chat-form'>
                    <div className='chat-message-textarea'>
                        <Textarea name='message'
                                  label=''
                                  inline={true}
                                  onChange={this.onMessageChange}/>
                    </div>

                    <div className='chat-message-control'>
                        <button className='button' disabled={!this.state.hasMessage}>
                            Send
                        </button>
                    </div>
                </Form>
            </div>
        );
    }
}
