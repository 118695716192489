import ActionConstants from './../../constants/action';
import {getWithClinic, postWithClinic} from '../../lib/rest';
import axios from 'axios';
import env from 'react-dotenv';

export function getCarePlans() {
    return getWithClinic('/api/rpm/care-plans/all.php');
}

export function getCarePlan(clinicId, planId) {

    return axios.get(`${env.API_URL}/api/v1/careplan`, {
        headers: {
            Authorization: `Bearer ${window.getCookie('UserToken')}`
        },
        params: {
            clinicId,
            planId
        }
    });
}

export function checkIfExistsCarePlan(clinicId, patientId) {

    return axios.get(`${env.API_URL}/api/v1/careplan/search`, {
        headers: {
            Authorization: `Bearer ${window.getCookie('UserToken')}`
        },
        params: {
            clinicId,
            patientId
        }
    });
}

export function createCarePlan(clinicId, plan) {

    const form = {
        clinicId,
        doctorId: plan.doctor,
        collectorId: plan.collector,
        patientId: plan.patient,
        whomToInformId: plan.whomToInform,
        contactName: plan.contactName,
        contactPhone: plan.contactPhone,
        date: plan.date,
        howToInform: plan.howToInform,
        vitals: plan.vitals
    };

    return axios.post(`${env.API_URL}/api/v1/careplan`, form, {
        headers: {
            Authorization: `Bearer ${window.getCookie('UserToken')}`
        },
    });
}

export function updateCarePlan(clinicId, plan) {

    const form = {
        clinicId,
        planId: plan.id,
        doctorId: plan.doctor,
        collectorId: plan.collector,
        patientId: plan.patient,
        whomToInformId: plan.whomToInform,
        contactName: plan.contactName,
        contactPhone: plan.contactPhone,
        date: plan.date,
        howToInform: plan.howToInform,
        vitals: plan.vitals
    };

    return axios.put(`${env.API_URL}/api/v1/careplan`, form, {
        headers: {
            Authorization: `Bearer ${window.getCookie('UserToken')}`
        },
    });
}

export function deleteCarePlan(clinicId, planId) {

    return axios.delete(`${env.API_URL}/api/v1/careplan`, {
        headers: {
            Authorization: `Bearer ${window.getCookie('UserToken')}`
        },
        params: {
            clinicId,
            planId
        }
    });
}

export function createCarePlanComment(clinicId, planId, comment) {

    const form = new FormData();

    form.append('clinicId', clinicId);
    form.append('planId', planId);
    form.append('comment', comment);

    return axios.post(`${env.API_URL}/api/v1/CarePlan/ChangeLog`, form, {
        headers: {
            Authorization: `Bearer ${window.getCookie('UserToken')}`
        }
    });
}

export function getCarePlanComments(clinicId, planId) {

    return axios.get(`${env.API_URL}/api/v1/CarePlan/ChangeLog`, {
        headers: {
            Authorization: `Bearer ${window.getCookie('UserToken')}`
        },
        params: {
            clinicId,
            planId
        }
    });
    //return getWithClinic('/api/rpm/care-plans/all-comments.php', data);
}

export function getReportedDailyCarePlans(data) {
    return getWithClinic('/api/rpm/care-plans/all-daily-reported.php', data);
}

export function getDailyCarePlans(clinicId, data) {

    return axios.get(`${env.API_URL}/api/v1/rpm/plans`, {
        headers: {
            Authorization: `Bearer ${window.getCookie('UserToken')}`
        },
        params: {
            clinicId,
            ...data
        }
    });
}

export function getAssignedDailyCarePlans() {
    return getWithClinic('/api/rpm/care-plans/all-daily.php', {
        'assigned': true
    });
}

export function getECGList(data) {
    return axios.get(`${env.API_URL}/api/v1/rpm/ECG`, {
        headers: {
            Authorization: `Bearer ${window.getCookie('UserToken')}`
        },
        params: {
            ...data
        }
    });
}

export function getDailyCarePlan(params) {

    return axios.get(`${env.API_URL}/api/v1/rpm/plan`, {
        headers: {
            Authorization: `Bearer ${window.getCookie('UserToken')}`
        },
        params: {
            ...params
        }
    });
}

export function getECGDetail(data) {
    return axios.get(`${env.API_URL}/api/v1/rpm/ECGDetail`, {
        headers: {
            Authorization: `Bearer ${window.getCookie('UserToken')}`
        },
        params: {
            ...data
        }
    });
}

export function getDailyCarePlanHistory(params) {

    return axios.get(`${env.API_URL}/api/v1/rpm/history`, {
        headers: {
            Authorization: `Bearer ${window.getCookie('UserToken')}`
        },
        params: {
            ...params
        }
    });
}

export function setCarePlanHistory(history) {
    return {
        type: ActionConstants.RPM_SET_CARE_PLAN_HISTORY,
        payload: {
            history
        }
    }
}

export function setCarePlans(carePlans) {
    return {
        type: ActionConstants.RPM_SET_CARE_PLANS,
        payload: {
            carePlans
        }
    };
}

export function clearCarePlans() {
    return {
        type: ActionConstants.RPM_CLEAR_CARE_PLANS
    };
}

export function setCarePlansFilterType(carePlansFilterType) {
    return {
        type: ActionConstants.RPM_CARE_PLANS_FILTER_CHANGE,
        payload: {
            carePlansFilterType
        }
    };
}

export function setCarePlan(carePlan) {
    return {
        type: ActionConstants.RPM_SET_CARE_PLAN,
        payload: {
            carePlan
        }
    };
}

export function clearCarePlan() {
    return {
        type: ActionConstants.RPM_CLEAR_CARE_PLAN
    }
}

export function setCarePlanComments(comments) {
    return {
        type: ActionConstants.RPM_SET_CARE_PLAN_COMMENTS,
        payload: {
            comments
        }
    };
}

export function clearCarePlanComments() {
    return {
        type: ActionConstants.RPM_CLEAR_CARE_PLAN_COMMENTS
    };
}

export function setDailyCarePlans(dailyCarePlans, carePlanType = 'all') {
    return {
        type: ActionConstants.RPM_SET_DAILY_CARE_PLANS,
        payload: {
            dailyCarePlans,
            carePlanType
        }
    };
}

export function clearDailyCarePlans() {
    return {
        type: ActionConstants.RPM_CLEAR_DAILY_CARE_PLANS
    };
}

export function setRPMAssignedDailyCarePlans(carePlans) {
    return {
        type: ActionConstants.RPM_SET_ASSIGNED_DAILY_CARE_PLANS,
        payload: {
            carePlans
        }
    }
}

export function clearRPMReportedDailyCarePlans() {
    return {
        type: ActionConstants.RPM_CLEAR_REPORTED_DAILY_CARE_PLANS
    };
}

export function setRPMReportedDailyCarePlans(carePlans) {
    return {
        type: ActionConstants.RPM_SET_REPORTED_DAILY_CARE_PLANS,
        payload: {
            carePlans
        }
    }
}

export function clearRPMAssignedDailyCarePlans() {
    return {
        type: ActionConstants.RPM_CLEAR_ASSIGNED_DAILY_CARE_PLANS
    };
}

export function setAssignedDailyCarePlans(dailyCarePlans) {
    return {
        type: ActionConstants.RPM_SET_ASSIGNED_DAILY_CARE_PLANS,
        payload: {
            dailyCarePlans
        }
    };
}

export function clearAssignedDailyCarePlans() {
    return {
        type: ActionConstants.RPM_CLEAR_ASSIGNED_DAILY_CARE_PLANS
    };
}

export function setDailyCarePlan(dailyCarePlan, carePlanActions, allWaitingsTodos) {

    return {
        type: ActionConstants.RPM_SET_DAILY_CARE_PLAN,
        payload: {
            dailyCarePlan,
            carePlanActions,
            allWaitingsTodos
        }
    };
}

export function setDailyCarePlanHistory(history) {
    return {
        type: ActionConstants.RPM_SET_CARE_PLAN_HISTORY,
        payload: {
            history
        }
    }
}

export function clearDailyCarePlanHistory() {

    return {
        type: ActionConstants.RPM_CLEAR_CARE_PLAN_HISTORY
    }
}

export function clearDailyCarePlan() {

    return {
        type: ActionConstants.RPM_CLEAR_DAILY_CARE_PLAN
    };
}

export function setReportedDailyCarePlans(plans) {
    return {
        type: ActionConstants.RPM_SET_REPORTED_DAILY_CARE_PLANS,
        payload: {
            plans
        }
    };
}

export function setECGDetailedData(ECGDetailData) {

    return {
        type: ActionConstants.RPM_ECG_DETAIL_SET,
        payload: {
            ECGDetailData
        }
    }
}

export function setECGDetailedLoading() {

    return {
        type: ActionConstants.RPM_ECG_DETAIL_LOADING
    }
}

export function clearECGDetail() {

    return {
        type: ActionConstants.RPM_ECG_DETAIL_CLEAR
    }
}

export function setECGList(ECGList) {

    return {
        type: ActionConstants.RPM_ECG_LIST_SET,
        payload: {
            ECGList
        }
    }
}

export function setECGListLoading() {

    return {
        type: ActionConstants.RPM_ECG_LIST_LOADING_SET
    }
}

export function clearECGList() {

    return {
        type: ActionConstants.RPM_ECG_LIST_CLEAR
    }
}

export function markMeasurementAsWrong(clinicId, data) {

    return axios.delete(`${env.API_URL}/api/v1/rpm/logs/measurement`, {
        headers: {
            Authorization: `Bearer ${window.getCookie('UserToken')}`
        },
        data: {
            clinicId,
            ...data
        }
    });
}

export const getUserMedication = (clinicId, patientId) => (
    axios.get(`${env.API_URL}/api/v1/rpm/medications`, {
        headers: {
            Authorization: `Bearer ${window.getCookie('UserToken')}`
        },
        params: {
            clinicId,
            patientId
        }
    })
);

export const setUserMedications = medications => ({
    type: ActionConstants.RPM_SET_USER_MEDICATIONS,
    payload: {
        medications
    }
});

export const clearUserMedications = () => ({
    type: ActionConstants.RPM_CLEAR_USER_MEDICATIONS
});

export const getPrimaryDiagnoses = (clinicId, patientId) => (

    axios.get(`${env.API_URL}/api/v1/rpm/primarydiagnoses`, {
        headers: {
            Authorization: `Bearer ${window.getCookie('UserToken')}`
        },
        params: {
            clinicId,
            patientId
        }
    })
);

export const setPrimaryDiagnoses = diagnoses => ({
    type: ActionConstants.RPM_SET_USER_PRIMARY_DIAGNOSES,
    payload: {
        diagnoses
    }
});

export const clearPrimaryDiagnoses = () => ({
    type: ActionConstants.RPM_CLEAR_USER_PRIMARY_DIAGNOSES
});

export const createNewEmployee = (form) => {

    return axios.post(`${env.API_URL}/api/v1/employee`, form, {
        headers: {
            Authorization: `Bearer ${window.getCookie('UserToken')}`
        }
    });
};
