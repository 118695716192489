import ActionConstants from './../../constants/action';


var initialState = {
    isPatientCarePlansLogsLoaded: false,
    patientCarePlansLogs: []
};


export default function(state, action) {
    if (action.type === ActionConstants.RPM_SET_PATIENT_CARE_PLANS_LOGS) {
        return Object.assign({}, state, {
            isPatientCarePlansLogsLoaded: true,
            patientCarePlansLogs: action.payload.logs
        });
    }

    if (action.type === ActionConstants.RPM_CLEAR_PATIENT_CARE_PLANS_LOGS) {
        return Object.assign({}, state, {
            isPatientCarePlansLogsLoaded: false,
            patientCarePlansLogs: []
        });
    }

    return state || Object.assign({}, initialState);
}
