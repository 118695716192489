import ActionConstants from './../constants/action';
import axios from 'axios';
import env from 'react-dotenv';

export function getMonthlyReport(clinicId, year, month) {

    return axios.get(`${env.API_URL}/api/v1/reports/current`, {
        headers: {
            Authorization: `Bearer ${window.getCookie('UserToken')}`
        },
        params: {
            clinicId,
            year,
            month
        }
    });
}

export function setMonthlyReport(data) {
    return {
        type: ActionConstants.SET_MONTHLY_REPORT,
        payload: {
            report: data.report,
            actionsType2: data.actionsType2,
            cptcodes: data.cptcodes,
            is99454available: data.is99454available,
            reportsActionsWOTime: data.reportsActionsWOTime,
            period:data.period
        }
    };
}

export function clearMonthlyReport(clearPeriod) {
    return {
        type: ActionConstants.CLEAR_MONTHLY_REPORT,
        payload: {
            clearPeriod
        }
    };
}
