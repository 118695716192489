import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    clearDailyCarePlanHistory,
    getDailyCarePlanHistory,
    setDailyCarePlanHistory
} from '../../../../../actions/rpm/care-plans';
import CommonPopup from "../../../../../components/common-popup";
import {formatShortDate} from "../../../../../lib/date";
import Lines from "../../../../../components/lines";
import { removeReport } from '../../../../../actions/ccm/todo';

class RPMPatientHistory extends Component {
    state = {
        historyPopupIsOpen: false,
        removePopupIsOpen: false,
        reportIsRemoving: false,
        reportIdToRemove: null,
        reasonToStrikeThrought: ''
    }

    componentDidMount() {

        const { dispatch, patientId, clinicId } = this.props;

        getDailyCarePlanHistory({ patientId, clinicId }).then(res => {

            const { history } = res.data;
            dispatch(
                setDailyCarePlanHistory(
                    history
                )
            );
        });
    }

    componentWillUnmount() {

        const { dispatch } = this.props;

        dispatch(
            clearDailyCarePlanHistory()
        );
    }

    render() {
        const { isDailyCarePlanHistoryLoaded } = this.props;

        return (
            <div>
                { this._renderPopup() }
                { this._renderRemovePopup() }
                <div className='history-title'>
                    <h3 className='title'>
                        { isDailyCarePlanHistoryLoaded
                            ? <span onClick={ this._toggleHistoryPopup }>Show patient history</span>
                            : <span>Loading patient history...</span> }
                    </h3>
                </div>
            </div>
        );
    }

    _renderRemovePopup = () => {

        const { reportIsRemoving, removePopupIsOpen } = this.state;

        if (!removePopupIsOpen) {

            return null;
        }

        return (
            <CommonPopup
                classNames={['mark-measurement-as-wrong-popup']}
                header='Enter reason to remove'
                onClose={() => { this._cancelStrikeThrough() }}>
                <textarea
                    disabled={ reportIsRemoving }
                    className='form-textarea'
                    onChange={({ target: { value } }) => {
                        this.setState({
                            reasonToStrikeThrought: value
                        });
                    }}
                    value={this.state.reasonToStrikeThrought} />
                <div className='buttons'>
                    <button disabled={ reportIsRemoving } className='confirm' onClick={() => this._cancelStrikeThrough()}>Cancel</button>
                    <button disabled={ reportIsRemoving } className='cancel' onClick={() => this._confirmStrikeThrough()}>Remove</button>
                </div>
            </CommonPopup>
        );
    }

    _renderPopup = () => {

        const { historyPopupIsOpen, removePopupIsOpen } = this.state;
        const { dailyCarePlanHistory, isDailyCarePlanHistoryLoaded } = this.props;

        if (!historyPopupIsOpen || removePopupIsOpen) {

            return null;
        }

        return (
            <CommonPopup classNames={['patient-history-popup']}  header='History' onClose={() => { this._toggleHistoryPopup() }}>
                <div className='rpm-history-wrap'>
                    <div className='detailed-data'>
                        <table className='table rpm-history'>
                            <thead>
                                <tr>
                                    <th>
                                    <span className='waiting-count'>
                                        { this._getWaitingTodosCount() }
                                    </span>
                                    </th>
                                    <th>By</th>
                                    <th>Patient</th>
                                    <th>Action</th>
                                    <th>Assigned</th>
                                    <th>Description/Resolution</th>
                                    <th>Created</th>
                                    <th>Remove</th>
                                </tr>
                            </thead>
                            <tbody>
                            {isDailyCarePlanHistoryLoaded
                                ? dailyCarePlanHistory.length
                                    ? (dailyCarePlanHistory.map((i, k) => {
                                        return (
                                            <tr key={ k }>
                                                <td>
                                                    <span className={ i.status === 'DONE' ? 'checkmark-status' : '' }/>
                                                </td>
                                                <td>
                                                    {`${i.createdByFirstName[0]}${i.createdByLastName[0]}`}
                                                </td>
                                                <td>
                                                    {`${i.patientFirstName} ${i.patientLastName}`}
                                                </td>
                                                <td>
                                                    {`${i.actionName}`}
                                                </td>
                                                <td>
                                                    {i.assignedToFirstName ? `${i.assignedToFirstName} ${i.assignedToLastName}` : ''}
                                                </td>
                                                <td>
                                                    <Lines data={`${i.description}\r\n${i.resolution ? i.resolution : ""}` } />
                                                </td>
                                                <td>
                                                    {formatShortDate(i.createdAt)}
                                                </td>
                                                <td>
                                                    {i.actionName === 'Warning from RPM' || i.status !== 'DONE'
                                                        ? null
                                                        : i.removed
                                                            ? 'Removed'
                                                            : <button
                                                                className="strike-throught"
                                                                onClick={() => this._setReportIdToRemove(i.id)} title="Remove">+</button>
                                                    }
                                                </td>
                                            </tr>
                                        );
                                    }))
                                    : <tr><td colSpan='6'>No data for display</td></tr>
                                : <tr><td colSpan='6'>Loading data...</td></tr>}
                            </tbody>
                        </table>
                    </div>
                </div>
            </CommonPopup>
        )
    }

    _confirmStrikeThrough = () => {

        const { reportIdToRemove, reasonToStrikeThrought } = this.state;
        const { dispatch, clinicId, patientId } = this.props;

        this.setState({
            reportIsRemoving: true
        });

        removeReport(clinicId, reportIdToRemove, reasonToStrikeThrought).then(() => {

            dispatch(clearDailyCarePlanHistory());

            getDailyCarePlanHistory({ patientId, clinicId }).then(res => {

                const { history } = res.data;

                dispatch(
                    setDailyCarePlanHistory(
                        history
                    )
                );

                this.setState({
                    reportIdToRemove: null,
                    historyPopupIsOpen: true,
                    removePopupIsOpen: false,
                    reasonToStrikeThrought: '',
                    reportIsRemoving: false
                });
            });

        });
    }

    _cancelStrikeThrough = () => {

        this.setState({
            reportIdToRemove: null,
            historyPopupIsOpen: true,
            removePopupIsOpen: false,
            reasonToStrikeThrought: ''
        });
    }

    _setReportIdToRemove = id => {

        this.setState({
            reportIdToRemove: id,
            historyPopupIsOpen: false,
            removePopupIsOpen: true
        });
    }

    _toggleHistoryPopup = () => {

        this.setState(state => ({
            historyPopupIsOpen: !state.historyPopupIsOpen
        }))
    }

    _getWaitingTodosCount = () => {
        return this.props.isDailyCarePlanHistoryLoaded
            ? this.props.dailyCarePlanHistory
                ? this.props.dailyCarePlanHistory.filter(i => i.status === 'WAITING').length
                : 0
            : 0;
    }
}

export default connect(state => ({
    clinicId: state.user.currentClinic,
    isDailyCarePlanHistoryLoaded: state.rpmCarePlans.isDailyCarePlanHistoryLoaded,
    dailyCarePlanHistory: state.rpmCarePlans.dailyCarePlanHistory
}))(RPMPatientHistory);
