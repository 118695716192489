import React from 'react';
import Chart from 'chart.js';

import {
    formatDayDate,
    formatMonthDate,
    formatYearDate,
    formatChartDayDate,
    formatChartMonthDate
} from '../lib/date'
import moment from 'moment';

export default class PatientVitalChart extends React.Component {
    componentDidMount() {
        this.initChart();

        const index = this.getIndex();
        this.updateData(this.props.time, index);
    }

    shouldComponentUpdate(nextProps) {
        if (this.isNeedUpdate(nextProps)) {
            const index = this.getIndexFromProps(nextProps);
            this.updateData(nextProps.time, index);
        }

        return true;
    }

    constructor(props) {
        super(props);

        this.state = {
            isVisibleNoDataMessage: false
        };

        this.ref = React.createRef();

        this.showChart = this.showChart.bind(this);
        this.hideChart = this.hideChart.bind(this);

        this.setTime = this.setTime.bind(this);

        this.prevDate = this.prevDate.bind(this);
        this.nextDate = this.nextDate.bind(this);

        this.lineChart = null;

        this.criticalPointColor = 'rgb(255, 51, 0)';
        this.warningPointColor = 'rgb(231, 210, 74)';
        this.normalPointColor = 'rgb(112, 173, 71)';
    }

    renderControl() {
        const currentDate = this.getCurrentDate();

        const prevDateClasses = [
            'patient-vital-chart-dates-arrow',
            'patient-vital-chart-dates-arrow-left'
        ];

        if (this.props.isLoading || !this.hasMoreData()) {
            prevDateClasses.push('patient-vital-chart-dates-arrow-left-disabled');
        }

        const nextDateClasses = [
            'patient-vital-chart-dates-arrow',
            'patient-vital-chart-dates-arrow-right'
        ];

        if (this.isNextDateDisabled() || this.props.isLoading) {
            nextDateClasses.push('patient-vital-chart-dates-arrow-right-disabled');
        }

        return (
            <div className='patient-vital-chart-control'>
                <div className='patient-vital-chart-timerange'>
                    <div>
                        <div onClick={this.setTime('weeks')} className={this.props.time === 'weeks' ? 'time-button time-button-active' : 'time-button'}>Week</div>
                        <div onClick={this.setTime('months')} className={this.props.time === 'months' ? 'time-button time-button-active' : 'time-button'}>Month</div>
                        <div onClick={this.setTime('years')} className={this.props.time === 'years' ? 'time-button time-button-active' : 'time-button'}>Year</div>
                    </div>
                </div>
                <div className='patient-vital-chart-dates'>
                    <div>
                        <div onClick={this.prevDate} className={prevDateClasses.join(' ')} />
                        <div className='patient-vital-chart-date'>{currentDate}</div>
                        <div onClick={this.nextDate} className={nextDateClasses.join(' ')} />
                    </div>
                </div>
            </div>
        );
    }

    renderMessage() {
        if (!this.state.isVisibleNoDataMessage || this.props.isLoading) {
            return null;
        }

        return (
            <div className='chart-no-data-message'>
                <div>No data for this timerange</div>
            </div>
        );
    }

    renderLoader() {
        if (!this.props.isLoading) {
            return null;
        }

        return (
            <div className='chart-request-loader-container'>
                <div className='chart-request-loader'>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        );
    }

    render() {

        return (
            <div className='patient-vital-chart'>
                <div className='patient-vital-chart-toggle' onClick={this.hideChart}>
                    <img src='/static/img/ecare21-chart-toggle.png' alt='Toggle' title='Toggle' />
                    <div>Hide graph for "{this.props.label}"</div>
                </div>

                <header>
                    {this.props.label}
                </header>

                {this.renderControl()}
                {this.renderMessage()}
                {this.renderLoader()}

                <canvas ref={this.ref} width='400' height='400'></canvas>
            </div>
        );
    }

    isNeedUpdate(nextProps) {
        if (this.props.time !== nextProps.time ||
            this.props.weeksIndex !== nextProps.weeksIndex ||
            this.props.monthsIndex !== nextProps.monthsIndex ||
            this.props.yearsIndex !== nextProps.yearsIndex) {
            return true;
        }

        return false;
    }

    setTime(time) {
        return (evt) => {
            evt.preventDefault();

            if (this.props.isLoading) {
                return;
            }

            this.props.setTime(time);
        };
    }

    showChart() {
        const index = this.getIndex();

        this.initChart();
        this.updateData(this.props.time, index);

        this.props.onVisibilityChange(this.props.vital, true);
    }

    hideChart() {
        this.props.onVisibilityChange(this.props.vital, false);
    }

    prevDate() {
        if (this.props.isLoading || !this.hasMoreData()) {
            return;
        }

        this.props.prevDate();
    }

    nextDate() {
        if (this.props.isLoading) {
            return;
        }

        this.props.nextDate();
    }

    hasData(index) {
        let hasData = false;

        if (index < this.props[this.props.time].length) {
            hasData = true;
        }

        return hasData;
    }

    initChart() {

        if (!this.ref.current) {

            return;
        }

        const ctx = this.ref.current.getContext('2d');
        const metricID = this.props.metricID;

        const data = {
            labels: [],
            datasets: []
        };

        const options = {
            layout: {
                padding: {
                    top: 30
                }
            },
            responsive: true,
            maintainAspectRatio: true,
            showAllTooltips: true,
            legend: {
                display: true,
                position: 'bottom'
            },
            scales: {
                xAxes: [{
                    gridLines: {
                        display: true,
                        color: 'rgb(219, 219, 219)'
                    }
                }],
                yAxes: [{
                    scaleOverride: true,
                    gridLines: {
                        display: true,
                        color: 'rgb(219, 219, 219)'
                    },
                    ticks: {
                        callback: (value) => {
                            if (this.props.labelPostfix) {
                                return value + ' ' + this.props.labelPostfix;
                            }

                            return value;
                        }
                    }
                }]
            },

            events: false,
            tooltips: {
                enabled: false
            },
            hover: {
                animationDuration: 0
            },
            animation: {
                duration: 1,
                onComplete: function () {
                    const ctx = this.chart.ctx;

                    ctx.font = Chart.helpers.fontString(13, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
                    ctx.fillStyle = 'black';
                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'bottom';

                    this.data.datasets.forEach((dataset, i) => {
                        const meta = this.chart.controller.getDatasetMeta(i);

                        meta.data.forEach((bar, index) => {
                            var data = dataset.data[index];
                            if (metricID === 10) {
                                const timeArray = [];
                                dataset.data.forEach((value, index) => {
                                    if (value) {
                                        timeArray[index] = moment(value).format('HH:mm');
                                    } else {
                                        timeArray[index] = null;
                                    }
                                })
                                data = timeArray[index];
                            }
                            ctx.fillText(data, bar._model.x, bar._model.y - 10);
                        });
                    });
                }
            }
        };

        this.lineChart = new Chart(ctx, {
            type: 'line',
            data: data,
            options: options
        });

        this.updatePointColors();
        this.lineChart.update();
    }

    updatePointColors(currentData) {
        for (let i = 0; i < this.lineChart.data.datasets.length; i += 1) {
            const pointBackgroundColors = [];

            const data = currentData[i];
            for (let j = 0; j < this.lineChart.data.datasets[i].data.length; j += 1) {
                const value = parseFloat(this.lineChart.data.datasets[i].data[j]);
                if (data.thresholdsType === 'default') {
                    if (data.criticalHigh !== '' && value >= data.criticalHigh) {
                        pointBackgroundColors.push(this.criticalPointColor);
                    } else if (data.warningHigh !== '' && value >= data.warningHigh) {
                        pointBackgroundColors.push(this.warningPointColor);
                    } else if (data.criticalLow !== '' && value <= data.criticalLow) {
                        pointBackgroundColors.push(this.criticalPointColor);
                    } else if (data.warningLow !== '' && value <= data.warningLow) {
                        pointBackgroundColors.push(this.warningPointColor);
                    } else {
                        pointBackgroundColors.push(this.normalPointColor);
                    }
                } else if (data.thresholdsType === 'low') {
                    if (data.criticalLow !== '' && value <= data.criticalLow) {
                        pointBackgroundColors.push(this.criticalPointColor);
                    } else if (data.warningLow !== '' && value <= data.warningLow) {
                        pointBackgroundColors.push(this.warningPointColor);
                    } else {
                        pointBackgroundColors.push(this.normalPointColor);
                    }
                } else if (data.thresholdsType === 'high') {
                    if (data.criticalHigh !== '' && value >= data.criticalHigh) {
                        pointBackgroundColors.push(this.criticalPointColor);
                    } else if (data.warningHigh !== '' && value >= data.warningHigh) {
                        pointBackgroundColors.push(this.warningPointColor);
                    } else {
                        pointBackgroundColors.push(this.normalPointColor);
                    }
                }
            }

            this.lineChart.data.datasets[i].pointBackgroundColor = pointBackgroundColors;
        }
    }

    updateData(time, index) {
        if (!this.lineChart) {
            return;
        }

        this.lineChart.data.labels = [];
        this.lineChart.data.datasets = [];

        const data = this.getCurrentData(time, index);
        const metricID = this.props.metricID;

        let minValue = undefined;
        let maxValue = 0;


        for (let i = 0; i < data.length; i += 1) {
            this.lineChart.data.labels = data[i].labels;
            const ds = this.getDataset(i, data[i].label, data[i].values);
            this.lineChart.data.datasets.push(ds);

            const numValues = data[i].values.filter((val) => !isNaN(parseInt(val, 10)));

            const datasetMinValue = numValues.length > 0 ? Math.min(...numValues) : 0;
            const datasetMaxValue = numValues.length > 0 ? Math.max(...numValues) : 0;

            if (minValue === undefined) {
                minValue = datasetMinValue;
            }

            if (datasetMinValue < minValue) {
                minValue = datasetMinValue;
            }

            if (datasetMaxValue > maxValue) {
                maxValue = datasetMaxValue;
            }
        }

        if (minValue === 0 && maxValue === 0) {
            // NOTE: empty chart
            this.lineChart.options.scales.yAxes[0].ticks.min = 0;
            this.lineChart.options.scales.yAxes[0].ticks.max = 40;
        } else {
            let minY = undefined;
            if (minValue - 1 >= 0) {
                minY = minValue - 1;
            }

            let maxYOffset = Math.round(maxValue * 0.05);
            if (maxYOffset < 1) {
                maxYOffset = 1;
            }

            if (metricID === 10) {
                this.lineChart.options.scales.yAxes[0].ticks.min = moment('1970-02-01 00:00:00').valueOf();
                this.lineChart.options.scales.yAxes[0].ticks.max = moment('1970-02-01 23:59:59').valueOf();
            } else {
                this.lineChart.options.scales.yAxes[0].ticks.min = minY;
                this.lineChart.options.scales.yAxes[0].ticks.max = maxValue + maxYOffset;
            }
        }

        if (this.dataHasValues(data)) {
            this.setState({ isVisibleNoDataMessage: false });
        } else {
            this.setState({ isVisibleNoDataMessage: true });
        }
        this.updatePointColors(data);
        for (let k = 0; k < 3; k += 1) {
            const pointDataset = this.getDatasetPoints(k);
            this.lineChart.data.datasets.push(pointDataset);
        }
        this.lineChart.update();
    }

    getCurrentDate() {
        let date = '';

        const index = this.getIndex();

        if (index === undefined) {
            return date;
        }

        if (index < this.props[this.props.time].length) {
            const linesData = this.props[this.props.time][index];

            for (let j = 0; j < linesData.length; j += 1) {
                const lineData = linesData[j];
                date = this.formatDate(lineData.data);
            }
        }

        return date;
    }

    formatDate(data) {
        if (this.props.time === 'weeks') {
            const start = formatDayDate(data[0].date);
            const end = formatDayDate(data[data.length - 3].date);
            return start + ' - ' + end;
        } else if (this.props.time === 'months') {
            return formatMonthDate(data[0].date);
        } else if (this.props.time === 'years') {
            return formatYearDate(data[0].date);
        }

        return '';
    }

    formatLabelDate(time, date) {
        if (time === 'weeks') {
            return formatChartDayDate(date);
        } else if (time === 'months') {
            return formatChartDayDate(date);
        } else if (time === 'years') {
            return formatChartMonthDate(date);
        }

        return '';
    }

    hasMoreData() {
        const index = this.getIndex();

        if (this.props.time === 'weeks') {
            if (this.props.maxWeeksIndex === 0) {
                return true;
            }

            return this.props.maxWeeksIndex > index + 1;
        } else if (this.props.time === 'months') {
            if (this.props.maxMonthsIndex === 0) {
                return true;
            }

            return this.props.maxMonthsIndex > index + 1;
        } else if (this.props.time === 'years') {
            if (this.props.maxYearsIndex === 0) {
                return true;
            }

            return this.props.maxYearsIndex > index + 1;
        }

        return true;
    }

    dataHasValues(data) {
        for (let i = 0; i < data.length; i += 1) {
            const vitalData = data[i];

            for (let j = 0; j < vitalData.values.length; j += 1) {
                if (vitalData.values[j] !== null) {
                    return true;
                }
            }
        }

        return false;
    }

    getCurrentData(time, index) {
        const result = [];
        const metricID = this.props.metricID;

        if (index < this.props[time].length) {
            const linesData = this.props[time][index];

            for (let j = 0; j < linesData.length; j += 1) {
                const lineData = linesData[j];

                const labels = [];
                const values = [];

                if (metricID === 10) {
                    var prevVal = '';

                    lineData.data.forEach(point => {

                        if (point.date && point.date.length > 0) {
                            prevVal = point.date;
                        } else {
                            point.date = prevVal;
                        }
                        labels.push(moment(point.date).valueOf());
                        if (point.date.length > 0 && point.value) {
                            values.push(moment('1970-02-01 ' + point.value).valueOf());
                        } else {
                            values.push(null);
                        }
                    });

                    this.lineChart.options.scales.xAxes[0].type = 'time';
                    const timeOptions = {
                        ticks: {
                            beginAtZero: false,
                            stepSize: 3.6e+6,
                            callback: (value) => {
                                let date = moment(value);
                                if (date.diff(moment('1970-02-01 23:59'), 'minutes') === 0) {
                                    return null;
                                }
                                return date.format('HH:mm');
                            }
                        }
                    }
                    const xTimeOptions = {
                        time: {
                            unit: 'day',
                            displayFormats: {
                                day: 'YYYY-DD-MM'
                            }
                        },
                        ticks: {
                            source: 'labels',
                            parser: 'YYYY-DD-MM',
                            callback: (value) => {
                                const stringToMoment = moment(value, 'YYYY-DD-MM');
                                return this.formatLabelDate(time, stringToMoment);
                            }
                        }
                    }
                    Object.assign(this.lineChart.options.scales.yAxes[0], timeOptions);
                    Object.assign(this.lineChart.options.scales.xAxes[0], xTimeOptions);
                } else {
                    lineData.data.forEach((point) => {
                        labels.push(this.formatLabelDate(time, point.date));
                        values.push(point.value);
                    });
                }
                if (labels.length === 0 || values.length === 0) {
                    continue;
                }

                const datum = {
                    label: lineData.label,
                    thresholdsType: lineData.thresholdsType,
                    labels,
                    values
                };

                if (datum.label === 'Weight') {
                    datum.warningLow = '';
                    datum.warningHigh = '';
                    datum.criticalLow = '';
                    datum.criticalHigh = '';
                } else if (metricID === 10) {
                    datum.warningHigh = moment('1970-02-01 ' + this.numberToTime(lineData.warningHigh)).valueOf()
                    datum.criticalLow = moment('1970-02-01 ' + this.numberToTime(lineData.criticalLow)).valueOf()
                    datum.criticalHigh = moment('1970-02-01 ' + this.numberToTime(lineData.criticalHigh)).valueOf()
                    datum.warningLow = moment('1970-02-01 ' + this.numberToTime(lineData.warningLow)).valueOf()
                } else {
                    datum.warningLow = lineData.warningLow;
                    datum.warningHigh = lineData.warningHigh;
                    datum.criticalLow = lineData.criticalLow;
                    datum.criticalHigh = lineData.criticalHigh;
                }

                result.push(datum);
            }
        }

        return result;
    }

    numberToTime(number) {
        return moment(number, 'h').format('HH:mm')
    }

    getDatasetPoints(i) {
        const pointLabels = [
            'Critical',
            'Warning',
            'Good'
        ];
        const pointLabel = pointLabels[i];
        const legendPointColors = [
            'rgb(255, 51, 0)',
            'rgb(231, 210, 74)',
            'rgb(112, 173, 71)'
        ]
        const legendPointColor = legendPointColors[i];
        return {
            label: pointLabel,
            backgroundColor: legendPointColor,
            borderWidth: 0,
            fill: false,
            showLine: false,
            spanGaps: true
        }
    }

    getDataset(i, label, data) {
        const borderColors = [
            'rgb(110, 181, 247)',
            'rgb(10, 105, 189)',
            'rgb(9, 60, 109)'
        ];

        const borderColor = borderColors[i % 3];

        return {
            label: label,
            data: data,
            pointBackgroundColor: [],
            fill: false,
            lineTension: 0.3,
            borderColor: borderColor,
            borderWidth: 4,
            pointRadius: 8,
            pointBorderWidth: 0,
            pointHoverBackgroundColor: 'rgb(112, 173, 71)',
            pointHoverBorderWidth: 0,
            pointHoverRadius: 8,
            spanGaps: true
        };
    }

    isNextDateDisabled() {
        return this.getIndex() === 0;
    }

    getIndex() {
        if (this.props.time === 'weeks') {
            return this.props.weeksIndex;
        } else if (this.props.time === 'months') {
            return this.props.monthsIndex;
        } else if (this.props.time === 'years') {
            return this.props.yearsIndex;
        }

        return 0;
    }

    getIndexFromProps(props) {
        if (props.time === 'weeks') {
            return props.weeksIndex;
        } else if (props.time === 'months') {
            return props.monthsIndex;
        } else if (props.time === 'years') {
            return props.yearsIndex;
        }

        return 0;
    }
}


PatientVitalChart.defaultProps = {
    label: '',
    labelPostfix: '',

    weeksIndex: 0,
    monthsIndex: 0,
    yearsIndex: 0,

    maxWeeksIndex: 0,
    maxMonthsIndex: 0,
    maxYearsIndex: 0,

    weeks: [],
    months: [],
    years: [],

    setTime() { },
    prevDate() { },
    nextDate() { }
};