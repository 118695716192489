import React from 'react';


class LowThreshold extends React.Component {
    constructor(props) {
        super(props);

        this.initialState = {
            warningLow: this.props.defaultWarningLow,
            criticalLow: this.props.defaultCriticalLow,

            error: ''
        };

        this.state = {
            ...this.initialState
        };

        this.onValueChange = this.onValueChange.bind(this);
    }

    setError(error) {
        this.setState({ error });
    }

    getValue() {
        const { warningLow, criticalLow } = this.state;

        return {
            warningLow,
            warningHigh: 0,
            criticalLow,
            criticalHigh: 0
        };
    }

    setValue(value) {
        this.setState(value);
        this.validate(value);
    }

    clearValue() {
        this.setState({ ...this.initialState });
    }

    isValid() {
        return this.validate(this.getValue());
    }

    onValueChange(evt, valueName) {
        const value = evt.target.value;

        this.setState({
            [valueName]: value
        }, () => {
            this.validate(this.getValue());
        });
    }

    validate(value) {
        for (let i = 0; i < this.props.validators.length; i+= 1) {
            const v = this.props.validators[i];
            const error = v(value);

            if (error) {
                this.setError(error);
                return false;
            }
        }

        this.setState({ error: '' });
        return true;
    }

    renderError() {
        if (!this.state.error) {
            return null;
        }

        return (
            <span className='form-error'>
                {this.state.error}
            </span>
        );
    }

    render() {
        const classes = ['form-field', 'low-threshold-field'];

        if (this.state.error) {
            classes.push('form-field-error');
        }

        return (
            <div className={classes.join(' ')}>
                <fieldset>
                    <legend>{this.props.label}</legend>

                    <div className='low-threshold-containers'>
                        <div className='low-threshold-container'>
                            <label htmlFor={this.props.name + '-warning'}>Warning low</label>

                            <input id={this.props.name + '-warning'}
                                   name={this.props.name + '-warning'}
                                   type='text'
                                   value={this.state.warningLow}
                                   onChange={(evt) => { this.onValueChange(evt, 'warningLow'); }}
                                   maxLength='255'
                                   className='form-input'/>
                        </div>

                        <div className='low-threshold-container'>
                            <label htmlFor={this.props.name + '-critical'}>Critical low</label>

                            <input id={this.props.name + '-critical'}
                                   name={this.props.name + '-critical'}
                                   type='text'
                                   value={this.state.criticalLow}
                                   onChange={(evt) => { this.onValueChange(evt, 'criticalLow'); }}
                                   maxLength='255'
                                   className='form-input'/>
                        </div>
                    </div>
                </fieldset>

                {this.renderError()}
            </div>
        );
    }
}

LowThreshold.defaultProps = {
    defaultWarningLow: 0,
    defaultCriticalLow: 0,
    validators: []
};

export default LowThreshold;
