import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import store, { history } from './store/index';
import BaseLayout from './views/base-layout';
import RedirectLayout from './views/redirect-layout';
import Index from './views/index';
import CCMLayout from './views/ccm/layout';
import CCMTodo from './views/ccm/todo';
import CCMShowTodo from './views/ccm/show-todo';
import CCMShowTodoIframe from './iframes/todo/view';
import MonitoringPlanIframe from './iframes/monitoringplan/view';
import CCMAddon from './views/ccm/addon';
import CCMShowAddon from './views/ccm/show-addon';
import CCMSearch from './views/ccm/search';
import RPMLayout from './views/rpm/layout';
import RPMCreateCarePlan from './views/rpm/create-care-plan';
import RPMShowCarePlan from './views/rpm/show-care-plan';
import RPMEditCarePlan from './views/rpm/edit-care-plan';
import RPMDailyCarePlans from './views/rpm/daily-care-plans';
import RPMReportDailyCarePlan from './views/rpm/components/report-daily-care-plan';
import RPMCarePlans from './views/rpm/care-plans';
import RPMShowCarePlanReport from './views/rpm/show-care-plan-report';
import ReportsLayout from './views/reports/layout';
import ReportsShowReports from './views/reports/show-reports';
import ReportsShowPatients from './views/reports/show-patients';
import ReportsShowPatient from './views/reports/show-patient';
import ReportsShowPatientCarePlanLogs from './views/reports/show-patient-care-plan-logs';

export default (
    <Provider store={store}>
        <Router history={history}>
            <div>
                <Route path='/:type?' component={RedirectLayout} />
                <Route exact path='/iframe/todo/:todoId/:clinicId/videocall' component={CCMShowTodoIframe} />
                <Route exact path='/iframe/monitoringplan/:patientId/:clinicId/registration' component={MonitoringPlanIframe} />
                <Route path='/app' component={ BaseLayout }/>
            </div>
        </Router>
    </Provider>
);

export const baseLayoutRoutes = (
    <Switch>
        <Route exact path='/app' component={ Index }/>
        <Route path='/app/ccm' component={ CCMLayout }/>
        <Route path='/app/rpm' component={ RPMLayout }/>
        <Route path='/app/reports' component={ ReportsLayout }/>
    </Switch>
);

export const ccmLayoutRoutes = (
    <Switch>
        <Route exact path='/app/ccm' component={CCMTodo} />
        <Route path='/app/ccm/todo/:todoId' component={CCMShowTodo} />
        <Route path='/app/ccm/addon/:addonId' component={ CCMShowAddon }/>
        <Route path='/app/ccm/addon' component={ CCMAddon }/>
        <Route path='/app/ccm/search' component={ CCMSearch }/>
    </Switch>
);

export const rpmLayoutRoutes = (
    <Switch>
        <Route exact path='/app/rpm' component={ RPMDailyCarePlans }/>
        <Route path='/app/rpm/create-monitoring-plan/:patientId?' component={ RPMCreateCarePlan }/>
        <Route path='/app/rpm/daily-monitoring-plans/:planId/report/:reportDate' component={ RPMShowCarePlanReport }/>
        <Route path='/app/rpm/daily-monitoring-plans/:planId/report' component={ RPMReportDailyCarePlan }/>

        <Route path='/app/rpm/monitoring-plans/:planId/report' component={RPMEditCarePlan}/>
        <Route path='/app/rpm/monitoring-plans/:planId/edit' component={RPMEditCarePlan}/>
        <Route path='/app/rpm/monitoring-plans/:planId' component={RPMShowCarePlan}/>
        <Route path='/app/rpm/monitoring-plans' component={RPMCarePlans}/>
    </Switch>
);

export const reportsLayoutRoutes = (
    <Switch>
        <Route exact path='/app/reports' component={ReportsShowReports}/>
        <Route path='/app/reports/patients/:patientId/care-plan-logs/rpm/:year/:month/:actionId/:numberOfRPMPeriod' component={ ReportsShowPatientCarePlanLogs } />
        <Route path='/app/reports/patients/:patientId/care-plan-logs/ccm/:year/:month' component={ ReportsShowPatient } />
        <Route path='/app/reports/patients' component={ ReportsShowPatients } />
    </Switch>
);
