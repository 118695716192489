import React from 'react';
import { connect } from 'react-redux';

import { getPatientsByName } from './../../actions/patients';
import { getActions, setActions, clearActions } from './../../actions/ccm/actions';
import { getAddons, setAddons, clearAddons, createAddon } from './../../actions/ccm/addons';
import { formatDayDate } from './../../lib/date';
import userInitials from './../../lib/user-initials';
import { dateToRequestString } from './../../lib/date';

import PatientTooltip from './../../components/patient-tooltip';

import Form from './../../lib/forms/form';
import SelectWithSearch from './../../lib/forms/select-with-search';
import SelectWithLoading from './../../lib/forms/select-with-loading';
import Input from './../../lib/forms/input';
import Textarea from './../../lib/forms/textarea';
import DatePicker from './../../lib/forms/date-picker';
import {required, checkReportingTime} from './../../lib/forms/field-validators';


class CCMAddon extends React.Component {

    state = {
        isCreatingAddon: false,
        reportingTimeActive: false,
        descriptionValidator: []
    };

    constructor(props) {
        super(props);

        this.addonForm = React.createRef();

        this._onSubmit = this._onSubmit.bind(this);
        this._loadPatientsByName = this._loadPatientsByName.bind(this);
        this._onActionChange = this._onActionChange.bind(this);
    }

    componentDidMount() {
        this._loadActions();
        this._loadAddons();
    }

    componentWillUnmount() {
        this.props.dispatch(clearActions());
        this.props.dispatch(clearAddons());
    }

    render() {
        return (
            <div className='dashboard-addons'>
                <div className='dashboard-addons-form'>
                    { this.renderForm() }
                </div>
                <div>
                    { this.renderTable() }
                </div>
            </div>
        );
    }

    renderTable() {
        if (!this.props.isAddonsLoaded) {
            return null;
        }

        let rows = null;

        if (this.props.addons.length === 0) {
            rows = (
                <tr className='empty-table-message'>
                    <td colspan='7'>Empty table</td>
                </tr>
            );
        } else {
            rows = this.props.addons.map((addon) => {
                let actionName = addon.actionName;

                if (addon.actionCode) {
                    actionName = `${addon.actionCode} - ${addon.actionName}`;
                }

                return (
                    <tr key={addon.id} onClick={() => { this._goToAddon(addon.id) }}>
                        <td>{userInitials(addon.createdByFirstName, addon.createdByLastName)}</td>
                        <td>{userInitials(addon.doneByFirstName, addon.doneByLastName)}</td>
                        <td>{addon.patientFirstName + ' ' + addon.patientLastName}</td>
                        <td>{actionName}</td>
                        <td>{addon.resolution}</td>
                        <td>{addon.totalTime}</td>
                        <td>{formatDayDate(addon.doneAt)}</td>
                    </tr>
                );
            });
        }

        return (
            <table className='table table-row-hover dashboard-addons'>
                <thead>
                <tr>
                    <th>By</th>
                    <th>Done by</th>
                    <th>Patient</th>
                    <th>Action</th>
                    <th>Resolution</th>
                    <th>Time</th>
                    <th>Done at</th>
                </tr>
                </thead>
                <tbody>
                {rows}
                </tbody>
            </table>
        );
    }

    renderForm() {
        const { isDoctorsLoaded, isActionsLoaded } = this.props;
        const { descriptionValidator } = this.state;

        if (!isDoctorsLoaded || !isActionsLoaded) {
            return null;
        }

        return (
            <Form ref={this.addonForm} onSubmit={this._onSubmit} className='form'>
                <div className='dashboard-form-control'>
                    <div className='form-horizontal-control form-patient-control'>
                        <SelectWithLoading
                            name='patient'
                            label='Patient'
                            values={[]}
                            validators={[required('Please select patient')]}
                            onLoadMore={this._loadPatientsByName}
                            inline={true}
                            filterOption={() => true}
                            tooltip={<PatientTooltip/>}/>

                        <SelectWithSearch
                            name='action'
                            label='Action'
                            values={this._getActionOptions()}
                            validators={[required('Please select action')]}
                            inline={true}
                            creatable={true}
                            onChange={this._onActionChange}
                            promptTextCreator={(label) => {
                                return 'Create action "' + label + '"';
                            }}/>

                        <Input
                            disabled={ !this.state.reportingTimeActive }
                            name='time'
                            label='Time'
                            inline={true}
                            validators={[
                                checkReportingTime('Please enter positive number', {
                                    active: this.state.reportingTimeActive
                                })
                            ]}/>
                    </div>

                    <div className='form-horizontal-control form-doctor-control'>
                        <SelectWithSearch
                            name='doctor'
                            label='Done by'
                            defaultValue={this.props.userId}
                            validators={[required('Please select valid doctor')]}
                            values={this._getDoctorOptions()}
                            inline={true} />

                        <DatePicker
                            name='date'
                            label='Date of service'
                            validators={[required('Please select date')]}
                            disabledAfter={new Date()}
                            inline={true}/>
                    </div>

                    <Textarea
                        validators={descriptionValidator}
                        name='resolution'
                        label='Resolution'
                        rows={2}
                        inline={true}/>
                </div>

                <div className='buttons'>
                    <button disabled={this.state.isCreatingAddon} className='button'>
                        Add
                    </button>
                </div>
            </Form>
        );
    }

    _loadPatientsByName(name, callback) {

        const { currentClinic } = this.props;

        getPatientsByName(currentClinic, name).then((res) => {
            const patients = res.data.patients.map(patient => {
                let dob = '';

                if (patient.dob !== null) {
                    dob = ', ' + patient.dob;
                }

                return {
                    value: patient.id,
                    label: patient.firstName + ' ' + patient.lastName + dob
                };
            });
            callback(patients);
        });
    }

    _loadActions() {

        const { dispatch, currentClinic } = this.props;

        getActions(currentClinic).then(res => {

            dispatch(
                setActions(
                    res.data.actions,
                    res.data.withOutTime
                )
            );
        });
    }

    _loadAddons() {

        const { dispatch, currentClinic } = this.props;

        getAddons(currentClinic).then(res => {
            dispatch(
                setAddons(
                    res.data.addons
                )
            );
        });
    }

    _goToAddon(addonId) {

        this.props.history.push('/app/ccm/addon/' + addonId);
    }

    _getDoctorOptions() {

        return this.props.doctors.map((doctor) => {

            return {
                value: doctor.id,
                label: doctor.firstName + ' ' + doctor.lastName
            };
        });
    }

    _getActionOptions() {

        return this.props.actions.map((action) => {
            let label = action.name;

            if (action.cptCode) {
                label = `${action.cptCode} - ${action.name}`;
            }

            return {
                value: action.id,
                label
            };
        });
    }

    _onActionChange(value) {

        const { actions } = this.props;
        const dischargedAction = actions.find(e => e.name.toLowerCase() === 'discharged from service');

        this.setState({
            descriptionValidator: value === dischargedAction?.id ? [required('Type description')] : []
        });

        if (typeof(value) === 'string') {

            this.addonForm.current.setValues({ time: '' });
            this.setState({ reportingTimeActive: true });
        } else {
            for (let i in this.props.actionsWOTime) {
                const actionID = this.props.actionsWOTime[i].id;

                if (value === actionID) {
                    this.setState({ reportingTimeActive: false });

                    return;
                }
            }

            this.setState({ reportingTimeActive: true });

            for (let i in this.props.actions) {
                const action = this.props.actions[i];

                if (action.id === value) {
                    this.addonForm.current.setValues({ time: action.duration });
                    return;
                }
            }
        }
    }

    _onSubmit(addon) {

        if (this.state.isCreatingAddon) {
            return;
        }

        const { currentClinic } = this.props;

        const newAddon = {
            doctorId: addon.doctor,
            patientId: addon.patient,
            date: dateToRequestString(addon.date),
            resolution: addon.resolution,
            time: addon.time ? parseInt(addon.time) : 0
        };

        if (typeof(addon.action) === 'number') {

            newAddon.actionId = addon.action;
        } else {

            newAddon.actionName = addon.action;
        }

        this.setState({ isCreatingAddon: true }, () => {
            createAddon(currentClinic, newAddon).then(() => {
                this.addonForm.current.clear();
                this._loadActions();
                this._loadAddons();

                this.setState({ isCreatingAddon: false });
            });
        });
    }
}


function mapStateToProps(state) {

    return {
        userId: state.user.userId,
        currentClinic: state.user.currentClinic,
        isDoctorsLoaded: state.doctors.isDoctorsLoaded,
        doctors: state.doctors.doctors,
        isActionsLoaded: state.ccmActions.isActionsLoaded,
        actions: state.ccmActions.actions,
        actionsWOTime: state.ccmActions.actionsWOTime,
        isAddonsLoaded: state.ccmAddon.isAddonsLoaded,
        addons: state.ccmAddon.addons
    };
}

export default connect(mapStateToProps)(CCMAddon);
