import ActionConstants from './../../constants/action';
import copy from './../../base/lib/copy';

const initialState = {
    isPatientWeekVitalsLoaded: false,
    patientWeekVitalsCarePlanId: null,
    patientWeekVitals: [],
    isPatientMonthVitalsLoaded: false,
    patientMonthVitalsCarePlanId: null,
    patientMonthVitals: [],
    isPatientYearVitalsLoaded: false,
    patientYearVitalsCarePlanId: null,
    patientYearVitals: [],
    isChartsUpdate: false,
    chartsVisible: []
};

export default function(state, action) {

    if (action.type === ActionConstants.IS_CHARTS_UPDATE) {
        return Object.assign({}, state, {
            isChartsUpdate: true
        });
    }

    if (action.type === ActionConstants.CHARTS_SET_VISIBILITY) {
        return Object.assign({}, state, {
            chartsVisible: action.payload
        });
    }
    if (action.type === ActionConstants.CHARTS_CLEAR_VISIBILITY) {
        return Object.assign({}, state, {
            chartsVisible: []
        });
    }

    if (action.type === ActionConstants.CHARTS_SET_PATIENT_WEEK_VITALS) {
        return Object.assign({}, state, {
            isPatientWeekVitalsLoaded: true,
            patientWeekVitalsCarePlanId: action.payload.planId,
            patientWeekVitals: action.payload.vitalsChartData
        });
    }

    if (action.type === ActionConstants.CHARTS_ADD_PATIENT_WEEK_VITALS) {
        var patientWeekVitals = copy(state.patientWeekVitals);

        for (var i = 0; i < patientWeekVitals.length; i++) {
            for (var j = 0; j < action.payload.vitalsChartData.length; j++) {
                if (patientWeekVitals[i].vital === action.payload.vitalsChartData[j].vital) {
                    patientWeekVitals[i].datasets = patientWeekVitals[i].datasets.concat(action.payload.vitalsChartData[j].datasets);
                    break;
                }
            }
        }

        return Object.assign({}, state, {
            patientWeekVitals
        });
    }

    if (action.type === ActionConstants.CHARTS_SET_PATIENT_MONTH_VITALS) {
        return Object.assign({}, state, {
            isPatientMonthVitalsLoaded: true,
            patientMonthVitalsCarePlanId: action.payload.planId,
            patientMonthVitals: action.payload.vitalsChartData
        });
    }

    if (action.type === ActionConstants.CHARTS_ADD_PATIENT_MONTH_VITALS) {
        var patientMonthVitals = copy(state.patientMonthVitals);

        for (var i = 0; i < patientMonthVitals.length; i++) {
            for (var j = 0; j < action.payload.vitalsChartData.length; j++) {
                if (patientMonthVitals[i].vital === action.payload.vitalsChartData[j].vital) {
                    patientMonthVitals[i].datasets = patientMonthVitals[i].datasets.concat(action.payload.vitalsChartData[j].datasets);
                    break;
                }
            }
        }

        return Object.assign({}, state, {
            patientMonthVitals
        });
    }

    if (action.type === ActionConstants.CHARTS_SET_PATIENT_YEAR_VITALS) {
        return Object.assign({}, state, {
            isPatientYearVitalsLoaded: true,
            patientYearVitalsCarePlanId: action.payload.planId,
            patientYearVitals: action.payload.vitalsChartData
        });
    }

    if (action.type === ActionConstants.CHARTS_ADD_PATIENT_YEAR_VITALS) {
        var patientYearVitals = copy(state.patientYearVitals);

        for (var i = 0; i < patientYearVitals.length; i++) {
            for (var j = 0; j < action.payload.vitalsChartData.length; j++) {
                if (patientYearVitals[i].vital === action.payload.vitalsChartData[j].vital) {
                    patientYearVitals[i].datasets = patientYearVitals[i].datasets.concat(action.payload.vitalsChartData[j].datasets);
                    break;
                }
            }
        }

        return Object.assign({}, state, {
            patientYearVitals
        });
    }

    if (action.type === ActionConstants.CHARTS_CLEAR_PATIENT_ALL_VITALS) {
        return Object.assign({}, state, {
            isPatientWeekVitalsLoaded: false,
            patientWeekVitalsCarePlanId: null,
            patientWeekVitals: [],

            isPatientMonthVitalsLoaded: false,
            patientMonthVitalsCarePlanId: null,
            patientMonthVitals: [],

            isPatientYearVitalsLoaded: false,
            patientYearVitalsCarePlanId: [],
            patientYearVitals: [],

            isChartsUpdate: false
        });
    }

    return state || { ...initialState };
}
