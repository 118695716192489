import React from 'react';

export default class Textarea extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: this.props.defaultValue,
            error: ''
        };

        this._onChange = this._onChange.bind(this);
    }

    render() {
        const classes = ['form-field'];
        if (this.state.error) {
            classes.push('form-field-error');
        }

        const textarea = (
            <textarea id={this.props.name}
                      name={this.props.name}
                      value={this.state.value}
                      onChange={this._onChange}
                      disabled={this.props.disabled}
                      rows={this.props.rows}
                      className='form-textarea'>
            </textarea>
        );

        if (this.props.inline) {
            return (
                <div className={classes.join(' ')}>
                    <div className='form-inline-textarea-field'>
                        <label htmlFor={this.props.name}>{this.props.label}</label>
                        <div className='form-textarea-block'>
                            {textarea}
                            {this.renderError()}
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className={classes.join(' ')}>
                <label htmlFor={this.props.name}>{this.props.label}</label>
                {textarea}
                {this.renderError()}
            </div>
        );
    }

    renderError() {
        if (!this.state.error) {
            return null;
        }

        return (
            <span className='form-error'>{this.state.error}</span>
        );
    }

    _onChange(evt) {
        const value = evt.target.value;
        this.setState({value});
        this._validate(value);

        if (this.props.onChange) {
            this.props.onChange(value);
        }
    }

    _validate(value) {
        for (let i in this.props.validators) {
            const v = this.props.validators[i];
            const error = v(value);

            if (error) {
                this.setError(error);
                return false;
            }
        }

        this.setState({error: ''});
        return true;
    }

    setError(error) {
        this.setState({error});
    }

    getValue() {
        return this.state.value;
    }

    clearValue() {
        this.setState({
            value: this.props.defaultValue,
            error: ''
        });
    }

    isValid() {
        return this._validate(this.getValue());
    }
}

Textarea.defaultProps = {
    validators: [],
    defaultValue: '',
    rows: 5,
    inline: false,
    disabled: false,
    onChange: null
};
