import React from 'react';

import { reportsLayoutRoutes } from './../../routes';


export default class ReportsLayout extends React.Component {
    render() {
        return (
            <div className='dashboard'>
                <div className='dashboard-content'>
                    {reportsLayoutRoutes}
                </div>
            </div>
        );
    }
}
