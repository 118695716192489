import $ from 'jquery';

import store from './../store';

export function getWithClinic(url, data = {}) {
    const state = store.getState();

    return $.ajax({
        url: url,
        type: 'GET',
        data: {
            clinic: state.user.currentClinic,
            ...data
        }
    }).then((res) => {
        if (res.result === 'Authorization required') {
            window.location = '/';
        }

        return Promise.resolve(res);
    });
}

export function getCarePlanHistory(url, data = {}) {
    const state = store.getState();

    return $.ajax({
        url: url,
        type: 'GET',
        data: {
            clinic: state.user.currentClinic,
            ...data
        }
    }).then((res) => {
        if (res.result === 'Authorization required') {
            window.location = '/';
        }

        return Promise.resolve(res);
    });
}

export function postWithClinic(url, data = {}) {
    const state = store.getState();

    return $.ajax({
        url: url,
        type: 'POST',
        data: {
            clinic: state.user.currentClinic,
            ...data
        }
    }).then((res) => {
        if (res.result === 'Authorization required') {
            window.location = '/';
        }

        return Promise.resolve(res);
    });
}
