import axios from 'axios';
import env from 'react-dotenv';
import ActionConstants from './../constants/action';

export function getChat(clinicId, patientId, offset = 0) {

    return axios.get(`${env.API_URL}/api/v1/chat`, {
        headers: {
            Authorization: `Bearer ${window.getCookie('UserToken')}`
        },
        params: {
            clinicId,
            patientId,
            offset
        }
    });
}

export function getChatLastMessage(clinicId, patientId) {

    return axios.get(`${env.API_URL}/api/v1/chat/lastmessage`, {
        headers: {
            Authorization: `Bearer ${window.getCookie('UserToken')}`
        },
        params: {
            clinicId,
            patientId
        }
    });
}

export function getChatLastMessages(clinicId, patientId, messageId = null) {

    return axios.get(`${env.API_URL}/api/v1/chat/lastmessages`, {
        headers: {
            Authorization: `Bearer ${window.getCookie('UserToken')}`
        },
        params: {
            clinicId,
            patientId,
            messageId
        }
    });
}

export function createChatMessage(clinicId, patientId, message) {

    return axios.post(`${env.API_URL}/api/v1/chat`, {
        clinicId,
        patientId,
        message
    }, {
        headers: {
            Authorization: `Bearer ${window.getCookie('UserToken')}`
        }
    });
}

export function setChatPatientMessages(patientId, messages) {
    return {
        type: ActionConstants.CHAT_SET_PATIENT_MESSAGES,
        payload: {
            patientId,
            messages
        }
    };
}

export function addChatPatientMessages(patientId, messages) {
    return {
        type: ActionConstants.CHAT_ADD_PATIENT_MESSAGES,
        payload: {
            patientId,
            messages
        }
    };
}

export function pushChatPatientMessages(patientId, messages) {
    return {
        type: ActionConstants.CHAT_PUSH_PATIENT_MESSAGES,
        payload: {
            patientId,
            messages
        }
    };
}

export function clearChatPatientMessages(patientId) {
    return {
        type: ActionConstants.CHAT_CLEAR_PATIENT_MESSAGES,
        payload: {
            patientId
        }
    };
}
