import ActionConstants from './../../constants/action';
import { getWithClinic } from './../../lib/rest';
import axios from 'axios';
import env from 'react-dotenv';

export function getPatientCarePlan(clinicId, patientId) {

    return axios.get(`${env.API_URL}/api/v1/careplan/search`, {
        headers: {
            Authorization: `Bearer ${window.getCookie('UserToken')}`
        },
        params: {
            clinicId,
            patientId
        }
    });
}

export function getPatientCarePlanLogs(clinicId, patientId, actionId, year, month, numberOfRPMPeriod) {

    return axios.get(`${env.API_URL}/api/v1/patient/careplan/logs`, {
        headers: {
            Authorization: `Bearer ${window.getCookie('UserToken')}`
        },
        params: {
            clinicId,
            patientId,
            actionId,
            year,
            month,
            numberOfRPMPeriod
        }
    });
}

export function getPatientWeeklyCarePlansLogs(data) {
    return getWithClinic('/api/rpm/care-plans-logs/all-weeks-by-patient.php', data);
}

export function setPatientCarePlan(plan) {
    return {
        type: ActionConstants.REPORTS_SET_PATIENT_CARE_PLAN,
        payload: {
            plan
        }
    };
}

export function clearPatientCarePlan() {
    return {
        type: ActionConstants.REPORTS_CLEAR_PATIENT_CARE_PLAN
    };
}

export function setPatientCarePlanLogs(logs, is454 = false, showTotalTime = true) {
    return {
        type: ActionConstants.REPORTS_SET_PATIENT_CARE_PLAN_LOGS,
        payload: {
            logs,
            is454,
            showTotalTime
        }
    };
}

export function clearPatientCarePlanLogs() {
    return {
        type: ActionConstants.REPORTS_CLEAR_PATIENT_CARE_PLAN_LOGS
    };
}

export function setPatientCarePlanLogsLoading() {
    return {
        type: ActionConstants.REPORTS_SET_PATIENT_CARE_PLAN_LOGS_LOADING
    };
}

export function setPatientWeeklyCarePlansLogs(logs) {
    return {
        type: ActionConstants.REPORTS_SET_PATIENT_WEEKLY_CARE_PLAN_LOGS,
        payload: {
            logs
        }
    };
}

export function clearPatientWeeklyCarePlansLogs() {
    return {
        type: ActionConstants.REPORTS_CLEAR_PATIENT_WEEKLY_CARE_PLAN_LOGS
    };
}
