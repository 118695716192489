import React from 'react';


export default class Threshold extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            warningLow: this.props.defaultWarningLow,
            criticalLow: this.props.defaultCriticalLow,
            warningHigh: this.props.defaultWarningHigh,
            criticalHigh: this.props.defaultCriticalHigh,

            error: ''
        };

        this.onValueChange = this.onValueChange.bind(this);
    }

    getValue() {
        const {
            warningLow,
            criticalLow,
            warningHigh,
            criticalHigh
        } = this.state;

        return {
            warningLow,
            criticalLow,
            warningHigh,
            criticalHigh
        };
    }

    setValue(value) {
        this.setState(value);
        this.validate(value);
    }

    clearValue() {
        this.setState({
            warningLow: '',
            criticalLow: '',
            warningHigh: '',
            criticalHigh: '',
            error: ''
        });
    }

    isValid() {
        return this.validate(this.getValue());
    }

    onValueChange(evt, valueName) {
        const { value } = evt.target;

        this.setState({
            [valueName]: value
        }, () => {
            this.validate(this.getValue());
        });
    }

    validate(value) {
        for (let i = 0; i < this.props.validators.length; i += 1) {
            const v = this.props.validators[i];
            const error = v(value);

            if (error) {
                this.setError(error);
                return false;
            }
        }

        this.setState({ error: '' });
        return true;
    }

    renderError() {
        if (!this.state.error) {
            return null;
        }

        return (
            <span className='form-error'>
                {this.state.error}
            </span>
        );
    }

    setError(error) {
        this.setState({ error });
    }

    render() {
        const classes = ['form-field', 'threshold-field'];
        if (this.state.error) {
            classes.push('form-field-error');
        }

        return (
            <div className={classes.join(' ')}>
                <fieldset>
                    <legend>{this.props.label}</legend>

                    <div className='threshold-containers'>
                        <div className='threshold-container'>
                            <label htmlFor={this.props.name + '-warning-low'}>Warning low</label>

                            <input id={this.props.name + '-warning-low'}
                                   name={this.props.name + '-warning-low'}
                                   type='text'
                                   value={this.state.warningLow}
                                   onChange={(evt) => { this.onValueChange(evt, 'warningLow'); }}
                                   maxLength='255'
                                   className='form-input'/>
                        </div>

                        <div className='threshold-container'>
                            <label htmlFor={this.props.name + '-critical-low'}>Critical low</label>

                            <input id={this.props.name + '-critical-low'}
                                   name={this.props.name + '-critical-low'}
                                   type='text'
                                   value={this.state.criticalLow}
                                   onChange={(evt) => { this.onValueChange(evt, 'criticalLow'); }}
                                   maxLength='255'
                                   className='form-input'/>
                        </div>

                        <div className='threshold-container'>
                            <label htmlFor={this.props.name + '-warning-high'}>Warning high</label>

                            <input id={this.props.name + '-warning-high'}
                                   name={this.props.name + '-warning-high'}
                                   type='text'
                                   value={this.state.warningHigh}
                                   onChange={(evt) => { this.onValueChange(evt, 'warningHigh'); }}
                                   maxLength='255'
                                   className='form-input'/>
                        </div>

                        <div className='threshold-container'>
                            <label htmlFor={this.props.name + '-critical-high'}>Critical high</label>

                            <input id={this.props.name + '-critical-high'}
                                   name={this.props.name + '-critical-high'}
                                   type='text'
                                   value={this.state.criticalHigh}
                                   onChange={(evt) => { this.onValueChange(evt, 'criticalHigh'); }}
                                   maxLength='255'
                                   className='form-input'/>
                        </div>
                    </div>
                </fieldset>

                {this.renderError()}
            </div>
        );
    }
}


Threshold.defaultProps = {
    defaultWarningLow: '',
    defaultCriticalLow: '',
    defaultWarningHigh: '',
    defaultCriticalHigh: '',
    inline: false,
    horizontal: false,
    validators: []
};
