import React from 'react';


export default function Lines({data}) {
    if (typeof data !== 'string') {
        return data;
    }

    return data.split(/\n|\\n/).map((line) => {
        return (
            <p>{line}</p>
        );
    });
}
