import React from 'react';
import { connect } from 'react-redux';

import { getPatientsByName } from './../../actions/patients';
import {
    searchTodosByPatient,
    setSearchResult,
    clearSearchResult
} from './../../actions/ccm/search';

import TodosWithRemoveTable from './../../tables/todos-with-remove';

import RequestLoader from './../../components/request-loader';
import PatientTooltip from './../../components/patient-tooltip';

import Form from './../../lib/forms/form';
import SelectWithLoading from './../../lib/forms/select-with-loading';
import CommonPopup from '../../components/common-popup';
import { removeReport } from '../../actions/ccm/todo';

class CCMSearch extends React.Component {
    sortStatus = {};

    state = {
        isPatientsLoading: false,
        selectedPatientId: null,
        removePopupIsOpen: false,
        reportIsRemoving: false,
        reportIdToRemove: null,
        reasonToStrikeThrought: ''
    }

    constructor(props) {
        super(props);

        this.onPatientChange = this.onPatientChange.bind(this);
        this.loadPatientsByName = this.loadPatientsByName.bind(this);
    }

    componentWillUnmount() {
        this.props.dispatch(clearSearchResult());
    }

    loadPatientsByName(name, callback) {

        const { currentClinic } = this.props;

        getPatientsByName(currentClinic, name).then(res => {

            const patients = res.data.patients.map((patient) => {
                let dob = '';

                if (patient.dob !== null) {
                    dob = ', ' + patient.dob;
                }

                return {
                    value: patient.id,
                    label: patient.firstName + ' ' + patient.lastName + dob
                };
            });
            callback(patients);
        });
    }

    onPatientChange(patient) {

        const { dispatch, currentClinic } = this.props;

        if (patient === '') {
            dispatch(clearSearchResult());
        } else {
            dispatch(clearSearchResult());

            this.setState({ isPatientsLoading: true });

            searchTodosByPatient(currentClinic, patient).then(res => {
                dispatch(
                    setSearchResult(
                        res.data.todos
                    )
                );

                this.setState({
                    isPatientsLoading: false,
                    selectedPatientId: patient
                });
            });
        }
    }

    _sortByColumn = (column, source) => {

        if (undefined === this.sortStatus[source]) {

            this.sortStatus[source] = {};
        }

        if (column !== this.sortStatus[source].sortColumn) {

            this.sortStatus[source].sortDirection = 'ASC';
            this.sortStatus[source].sortColumn = column;
        }

        const sortDirection = this.sortStatus[source].sortDirection;

        const list = this.props.searchResult.sort((a, b) => {

            let columnA = a[column];
            let columnB = b[column];

            if (typeof a[column] === 'string' && typeof b[column] === 'string') {

                columnA = columnA.toUpperCase();
                columnB = columnB.toUpperCase();
            }

            return columnA > columnB
                ? (sortDirection === 'DESC' ? -1 : 1)
                : (columnA < columnB
                    ? (sortDirection === 'DESC' ? 1 : -1)
                    : 0);
        });

        this.sortStatus[source].sortDirection = this.sortStatus[source].sortDirection === 'ASC' ? 'DESC' : 'ASC';

        this.props.dispatch(
            setSearchResult([...list])
        );
    }

    renderSearchForm() {
        return (
            <Form onSubmit={() => {}} className='form'>
                <div className='dashboard-form-control'>
                    <div className='form-horizontal-control'>
                        <SelectWithLoading
                            name='patient'
                            label='Patient'
                            onChange={this.onPatientChange}
                            onLoadMore={this.loadPatientsByName}
                            inline={true}
                            filterOption={() => true}
                            tooltip={<PatientTooltip/>}/>
                    </div>
                </div>
            </Form>
        );
    }

    render() {
        let todos = null;

        if (this.state.isPatientsLoading) {
            todos = (
                <div className='request-loader-center'>
                    <RequestLoader/>
                </div>
            );
        } else {
            todos = (
                <TodosWithRemoveTable
                    setReportIdToRemove={this._setReportIdToRemove}
                    sortFunction={this._sortByColumn}
                    history={this.props.history}
                    isTodosLoaded={this.props.isSearchResultLoaded}
                    todos={this.props.searchResult}
                    emptyTableMessage='No patient data'/>
            );
        }

        return (
            <div className='search'>
                <div>
                    { this.renderSearchForm() }
                </div>

                <div>
                    { this._renderRemovePopup() }
                    {todos}
                </div>
            </div>
        );
    }

    _renderRemovePopup = () => {

        const { reportIsRemoving, removePopupIsOpen } = this.state;

        if (!removePopupIsOpen) {

            return null;
        }

        return (
            <CommonPopup
                classNames={['mark-measurement-as-wrong-popup']}
                header='Enter reason to remove'
                onClose={() => { this._cancelStrikeThrough() }}>
                <textarea
                    disabled={reportIsRemoving}
                    className='form-textarea'
                    onChange={({ target: { value } }) => {
                        this.setState({
                            reasonToStrikeThrought: value
                        });
                    }}
                    value={this.state.reasonToStrikeThrought} />
                <div className='buttons'>
                    <button disabled={reportIsRemoving} className='confirm' onClick={() => this._cancelStrikeThrough()}>Cancel</button>
                    <button disabled={reportIsRemoving} className='cancel' onClick={() => this._confirmStrikeThrough()}>Remove</button>
                </div>
            </CommonPopup>
        );
    }

    _renderRemovePopup = () => {

        const { reportIsRemoving, removePopupIsOpen } = this.state;

        if (!removePopupIsOpen) {

            return null;
        }

        return (
            <CommonPopup
                classNames={['mark-measurement-as-wrong-popup']}
                header='Enter reason to remove'
                onClose={() => { this._cancelStrikeThrough() }}>
                <textarea
                    disabled={reportIsRemoving}
                    className='form-textarea'
                    onChange={({ target: { value } }) => {
                        this.setState({
                            reasonToStrikeThrought: value
                        });
                    }}
                    value={this.state.reasonToStrikeThrought} />
                <div className='buttons'>
                    <button disabled={reportIsRemoving} className='confirm' onClick={() => this._cancelStrikeThrough()}>Cancel</button>
                    <button disabled={reportIsRemoving} className='cancel' onClick={() => this._confirmStrikeThrough()}>Remove</button>
                </div>
            </CommonPopup>
        );
    }

    _setReportIdToRemove = id => {

        this.setState({
            reportIdToRemove: id,
            historyPopupIsOpen: false,
            removePopupIsOpen: true
        });
    }

    _cancelStrikeThrough = () => {

        this.setState({
            reportIdToRemove: null,
            removePopupIsOpen: false,
            reasonToStrikeThrought: ''
        });
    }

    _confirmStrikeThrough = () => {

        const { reportIdToRemove, reasonToStrikeThrought, selectedPatientId } = this.state;
        const { dispatch, currentClinic } = this.props;

        this.setState({
            reportIsRemoving: true
        });

        removeReport(currentClinic, reportIdToRemove, reasonToStrikeThrought).then(() => {

            this.setState({ isPatientsLoading: true });
            dispatch(clearSearchResult());

            searchTodosByPatient(currentClinic, selectedPatientId).then(res => {
                dispatch(
                    setSearchResult(
                        res.data.todos
                    )
                );

                this.setState({
                    reportIdToRemove: null,
                    isPatientsLoading: false,
                    removePopupIsOpen: false,
                    reasonToStrikeThrought: '',
                    reportIsRemoving: false
                });
            });
        });
    }
}


function mapStateToProps(state) {
    return {
        currentClinic: state.user.currentClinic,
        isSearchResultLoaded: state.ccmSearch.isSearchResultLoaded,
        searchResult: state.ccmSearch.searchResult
    };
}


export default connect(mapStateToProps)(CCMSearch);
