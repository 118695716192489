import keyMirror from './../lib/key-mirror';

export default keyMirror([
    'SET_USER_SESSION_BLOCK',
    'SET_USER',
    'SET_USER_CURRENT_CLINIC',

    'SET_ACTIONS',
    'CLEAR_ACTIONS',

    'SET_PATIENTS',
    'CLEAR_PATIENTS',

    'SET_DOCTORS',
    'CLEAR_DOCTORS',

    /* --- */

    'CHAT_SET_PATIENT_MESSAGES',
    'CHAT_ADD_PATIENT_MESSAGES',
    'CHAT_PUSH_PATIENT_MESSAGES',
    'CHAT_CLEAR_PATIENT_MESSAGES',

    /* --- */

    'CCM_SET_PATIENT_TODOS',
    'CCM_CLEAR_PATIENT_TODOS',

    'CCM_SET_ASSIGNED_TODOS',
    'CCM_CLEAR_ASSIGNED_TODOS',
    'CCM_SET_TODOS',
    'CCM_CLEAR_TODOS',
    'CCM_SET_TODO',
    'CCM_CLEAR_TODO',

    'CCM_SET_ADDONS',
    'CCM_CLEAR_ADDONS',
    'CCM_SET_ADDON',
    'CCM_CLEAR_ADDON',

    'CCM_SET_JOURNAL',
    'CCM_CLEAR_JOURNAL',
    'CCM_SET_JOURNAL_PATIENT',
    'CCM_CLEAR_JOURNAL_PATIENT',

    'CCM_SET_SEARCH_RESULT',
    'CCM_CLEAR_SEARCH_RESULT',

    /* --- */

    'RPM_SET_COLLECTORS',
    'RPM_CLEAR_COLLECTORS',

    'RPM_SET_VITALS',
    'RPM_CLEAR_VITALS',

    'RPM_SET_CARE_PLANS',
    'RPM_CLEAR_CARE_PLANS',

    'RPM_SET_CARE_PLAN',
    'RPM_CLEAR_CARE_PLAN',

    'RPM_SET_CARE_PLAN_COMMENTS',
    'RPM_CLEAR_CARE_PLAN_COMMENTS',

    'RPM_SET_DAILY_CARE_PLANS',
    'RPM_CLEAR_DAILY_CARE_PLANS',

    'RPM_SET_DAILY_CARE_PLAN',
    'RPM_CLEAR_DAILY_CARE_PLAN',

    'RPM_SET_REPORTED_DAILY_CARE_PLANS',
    'RPM_CLEAR_REPORTED_DAILY_CARE_PLANS',

    'RPM_SET_ASSIGNED_DAILY_CARE_PLANS',
    'RPM_CLEAR_ASSIGNED_DAILY_CARE_PLANS',

    'RPM_SET_DAILY_CARE_PLAN_LOGS',
    'RPM_CLEAR_DAILY_CARE_PLAN_LOGS',

    'RPM_SET_DAILY_CARE_PLAN_ENTRIES',
    'RPM_CLEAR_DAILY_CARE_PLAN_ENTRIES',

    'RPM_SET_DAILY_CARE_PLAN_ALL_ENTRIES',
    'RPM_CLEAR_DAILY_CARE_PLAN_ALL_ENTRIES',

    'RPM_SET_REPORTED_DAILY_CARE_PLANS',
    'RPM_CLEAR_REPORTED_DAILY_CARE_PLANS',

    'RPM_SET_PATIENT_CARE_PLANS_LOGS',
    'RPM_CLEAR_PATIENT_CARE_PLANS_LOGS',

    'RPM_SET_CARE_PLAN_HISTORY',
    'RPM_CLEAR_CARE_PLAN_HISTORY',

    /* --- */

    'CHARTS_SET_PATIENT_WEEK_VITALS',
    'CHARTS_ADD_PATIENT_WEEK_VITALS',

    'CHARTS_SET_PATIENT_MONTH_VITALS',
    'CHARTS_ADD_PATIENT_MONTH_VITALS',

    'CHARTS_SET_PATIENT_YEAR_VITALS',
    'CHARTS_ADD_PATIENT_YEAR_VITALS',

    'CHARTS_CLEAR_PATIENT_ALL_VITALS',

    'IS_CHARTS_UPDATE',
    'CHARTS_SET_VISIBILITY',
    'CHARTS_CLEAR_VISIBILITY',
    /* --- */

    'SET_MONTHLY_REPORT',
    'CLEAR_MONTHLY_REPORT',

    'REPORTS_SET_PATIENT_CARE_PLAN',
    'REPORTS_CLEAR_PATIENT_CARE_PLAN',

    'REPORTS_SET_PATIENT_CARE_PLAN_LOGS',
    'REPORTS_CLEAR_PATIENT_CARE_PLAN_LOGS',
    'REPORTS_SET_PATIENT_CARE_PLAN_LOGS_LOADING',

    'REPORTS_SET_PATIENT_WEEKLY_CARE_PLAN_LOGS',
    'REPORTS_CLEAR_PATIENT_WEEKLY_CARE_PLAN_LOGS',

    'REPORTS_SET_MONTHLY_EXCEPTIONS',
    'REPORTS_CLEAR_MONTHLY_EXCEPTIONS',
    'REPORTS_SET_REPORT_EXCEPTIONS',
    'REPORTS_CLEAR_REPORT_EXCEPTIONS',

    'RPM_ECG_DETAIL_SET',
    'RPM_ECG_DETAIL_CLEAR',
    'RPM_ECG_DETAIL_LOADING',
    'RPM_ECG_LIST_SET',
    'RPM_ECG_LIST_CLEAR',
    'RPM_ECG_LIST_LOADING_SET',

    'RPM_CREATE_CARE_PLAN_SET_PRELOADED_PATIENT',
    'RPM_CREATE_CARE_PLAN_CLEAR_PRELOADED_PATIENT',

    'RPM_CARE_PLANS_FILTER_CHANGE',

    'RPM_SET_USER_PRIMARY_DIAGNOSES',
    'RPM_CLEAR_USER_PRIMARY_DIAGNOSES',
    'RPM_SET_USER_MEDICATIONS',
    'RPM_CLEAR_USER_MEDICATIONS',

    'PHONE_CODES_SET',
    'PHONE_CODES_CLEAR',

    'COMPANY_LOGO_LOADING',
    'COMPANY_LOGO_LOADED',
    'COMPANY_LOGO_CLEAR',
]);
