import React from 'react';


export default class Select extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: this._getDefaultValue(),
            error: ''
        };

        this._onChange = this._onChange.bind(this);
    }

    render() {
        var classes = ['form-field'];
        if (this.state.error) {
            classes.push('form-field-error');
        }

        var label = this.props.label ? <label htmlFor={this.props.name}>{this.props.label}</label> : null;

        var select = (
            <select id={this.props.name}
                    placeholder={this.props.placeholder}
                    name={this.props.name}
                    value={this.state.value}
                    onChange={this._onChange}
                    className='form-select'>
                {this.props.emptyAllowed ? <option value="" disabled selected>{this.props.placeholder}</option> : null}
                {this.props.values.map((value, i) => {
                    var name = value.name ? value.name : value.value;

                    return (
                        <option key={i} value={value.value}>{name}</option>
                    );
                })}
            </select>
        );

        if (this.props.inline) {
            return (
                <div className={classes.join(' ')}>
                    <div className='form-inline-select-field'>
                        {label}
                        <div className='form-select-block'>
                            {select}
                            {this.renderError()}
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className={classes.join(' ')}>
                {label}
                {select}
                {this.renderError()}
            </div>
        );
    }

    renderError() {
        if (!this.state.error) {
            return null;
        }

        return (
            <span className='form-error'>{this.state.error}</span>
        );
    }

    _getDefaultValue() {
        var defaultValue = '';

        for (var i in this.props.values) {
            var val = this.props.values[i];

            if (val.default) {
                defaultValue = val.value;
                break;
            }
        }

        return defaultValue;
    }

    _onChange(evt) {
        var value = evt.target.value;

        this.setState({value}, () => {
            if (this.props.onChange) {
                this.props.onChange(value);
            }
        });

        this._validate(value);
    }

    _validate(value) {
        for (var i in this.props.validators) {
            var v = this.props.validators[i];
            var error = v(value);

            if (error) {
                this.setError(error);
                return false;
            }
        }

        this.setState({error: ''});
        return true;
    }

    setError(error) {
        this.setState({error});
    }

    getValue() {
        return this.state.value;
    }

    clearValue() {
        this.setState({
            value: '',
            error: ''
        });
    }

    isValid() {
        return this._validate(this.getValue());
    }
}


Select.defaultProps = {
    label: '',
    values: [],
    validators: [],
    emptyAllowed: true,
    placeholder: ''
};
