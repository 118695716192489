import ActionConstants from './../../constants/action';


var initialState = {
    isSearchResultLoaded: false,
    searchResult: []
};


export default function(state, action) {
    if (action.type === ActionConstants.CCM_SET_SEARCH_RESULT) {
        return Object.assign({}, state, {
            isSearchResultLoaded: true,
            searchResult: action.payload.searchResult
        });
    }

    if (action.type === ActionConstants.CCM_CLEAR_SEARCH_RESULT) {
        return Object.assign({}, state, {
            isSearchResultLoaded: false,
            searchResult: []
        });
    }

    return state || Object.assign({}, initialState);
}
