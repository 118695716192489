import axios from 'axios';
import ActionConstants from './../../constants/action';
import env from 'react-dotenv';

export function getActions(clinicId) {

    return axios.get(`${env.API_URL}/api/v1/company/actions`, {
        headers: {
            Authorization: `Bearer ${window.getCookie('UserToken')}`
        },
        params: {
            clinicId
        }
    });
}

export function setActions(actions, actionsWOTime = []) {
    return {
        type: ActionConstants.SET_ACTIONS,
        payload: {
            actions, actionsWOTime
        }
    };
}

export function clearActions() {
    return {
        type: ActionConstants.CLEAR_ACTIONS
    };
}
