
function isValidInt(str) {
    return /^[0-9]+$/.test(str);
}

function isValidFloat(str) {
    return /^[0-9]+(\.[0-9]{1})?$/.test(str);
}

function isValidFloatWith3(str) {
    return /^[0-9]+(\.[0-9]{1,3})?$/.test(str);
}

export function required(message) {
    return function(value) {
        if (!value) {
            return message;
        }

        return '';
    };
}

export function requiredEmployee(message) {
    return function (value) {

        if (!value) {
            return message;
        } else if (typeof value !== 'number') {
            return message;
        }

        return '';
    };
}

export function checkReportingTime(message, options) {

    return (value) => {

        if (options.active) {

            if (/^[0-9]+(\.[0-9]{1,2})?$/.test(value)) {

                if (value <= 0 ) {

                    return message;
                }
            } else {

                return message;
            }
        }

        return '';
    };
}

export function positiveInt(message) {
    return function(value) {
        if (/^[0-9]+$/.test(value)) {
            if (value > 0) {
                return '';
            }
        }

        return message;
    };
}


export function positiveDouble(message) {
    return function(value) {
        if (/^[0-9]+(\.[0-9]{1,2})?$/.test(value)) {
            if (value > 0) {
                return '';
            }
        }

        return message;
    };
}

export function zeroAndMoreInt(message) {
    return function(value) {
        if (/^[0-9]+$/.test(value)) {
            if (value >= 0) {
                return '';
            }
        }

        return message;
    };
}

export function phoneNumber(message) {
    return function(value) {
        if (!value) {
            return '';
        }

        if (/^(\+[0-9]{1,3}-)?[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(value)) {
            return '';
        }

        return message;
    };
}

export function daysChooserDayRequired(message) {
    return function(value) {
        if (!value) {
            return message;
        }

        if (!value.weekdays) {
            return message;
        }

        if (Object.keys(value.weekdays).length === 0) {
            return message;
        }

        let allfalse = true;

        for (let i in value.weekdays) {
            if (value.weekdays[i]) {
                allfalse = false;
                break;
            }
        }

        if (allfalse) {
            return message;
        }

        return '';
    };
}

export function daysChooserDayPartRequired(message) {
    return function(value) {
        if (!value) {
            return message;
        }

        if (!value.dayparts) {
            return message;
        }

        if (Object.keys(value.dayparts).length === 0) {
            return message;
        }

        let allfalse = true;

        for (let i in value.dayparts) {
            if (value.dayparts[i]) {
                allfalse = false;
                break;
            }
        }

        if (allfalse) {
            return message;
        }

        return '';
    };
}

export function weekVitalsAllRequired(message) {
    return function(value) {
        let isEmpty = true;

        for (let vitalId in value) {
            if (Object.keys(value[vitalId]).length !== 0) {
                isEmpty = false;
                break;
            }
        }

        if (isEmpty) {
            return message;
        }

        let hasValue = false;

        for (let vitalId in value) {
            const vital = value[vitalId];

            for (let day in vital) {
                for (let part in vital[day]) {
                    if (vital[day][part] !== '') {
                        hasValue = true;
                    }
                }
            }
        }

        if (!hasValue) {
            return message;
        }

        return '';
    };
}

export function weekVitalsAllNumbers(message, vitalsMap, options) {
    return function(value) {
        for (let vitalId in value) {
            const vital = value[vitalId];

            let isValidValue = isValidFloatWith3;

            if (!options.weightInKg && vitalsMap[vitalId] === 'Weight') {
                isValidValue = isValidFloat;
            }

            if (options.tempInF && (vitalsMap[vitalId] === 'Body Temperature' || vitalsMap[vitalId] === 'Skin Temperature')) {
                isValidValue = isValidFloat;
            }

            for (let day in vital) {
                for (let part in vital[day]) {
                    if (vital[day][part] === '') {
                        continue;
                    }

                    let valueSubs = [];

                    if (typeof vital[day][part] === 'string') {
                        valueSubs = vital[day][part].split('/');
                    }

                    if (valueSubs.length > 1) {
                        for (let i = 0; i < valueSubs.length; i += 1) {
                            let valStr = valueSubs[i];

                            if (!isValidValue(valStr)) {
                                return message;
                            }

                            const val = parseFloat(valueSubs[i]);

                            if (isNaN(val)) {
                                return message;
                            }

                            if (val <= 0) {
                                vital[day][part] = 0;
                            }
                        }

                        continue;
                    }

                    let vitalValue = vital[day][part];

                    if (typeof vitalValue !== 'string') {
                        vitalValue = vitalValue.toString();
                    }

                    if (!isValidValue(vitalValue)) {
                        return message;
                    }

                    const fieldValue = parseFloat(vital[day][part]);

                    if (isNaN(fieldValue)) {
                        return message;
                    }

                    if (fieldValue <= 0) {
                        return message;
                    }
                }
            }
        }

        return '';
    };
}

export function weekVitalsAllNumbersWithZero(message, vitalsMap, options) {
    return function(value) {
        for (let vitalId in value) {
            const vital = value[vitalId];

            let isValidValue = isValidFloatWith3;

            if (!options.weightInKg && vitalsMap[vitalId] === 'Weight') {
                isValidValue = isValidFloat;
            }

            if (options.tempInF && (vitalsMap[vitalId] === 'Body Temperature' || vitalsMap[vitalId] === 'Skin Temperature')) {
                isValidValue = isValidFloat;
            }

            for (let day in vital) {
                for (let part in vital[day]) {
                    if (vital[day][part] === '') {
                        continue;
                    }

                    let valueSubs = [];

                    if (typeof vital[day][part] === 'string') {
                        valueSubs = vital[day][part].split('/');
                    }

                    if (valueSubs.length > 1) {
                        for (let i = 0; i < valueSubs.length; i += 1) {
                            let valStr = valueSubs[i];

                            if (!isValidValue(valStr)) {
                                return message;
                            }

                            const val = parseFloat(valueSubs[i]);

                            if (isNaN(val)) {
                                return message;
                            }

                            if (val <= 0) {
                                vital[day][part] = 0;
                            }
                        }

                        continue;
                    }

                    let vitalValue = vital[day][part];

                    if (typeof vitalValue !== 'string') {
                        vitalValue = vitalValue.toString();
                    }

                    if (!isValidValue(vitalValue)) {
                        return message;
                    }

                    const fieldValue = parseFloat(vital[day][part]);

                    if (isNaN(fieldValue)) {
                        return message;
                    }

                    if (fieldValue < 0) {
                        return message;
                    }
                }
            }
        }

        return '';
    };
}

export function weekVitalsLimits(vitals) {
    function getVitalById(vitalId) {
        for (let i = 0; i < vitals.length; i += 1) {
            const v = vitals[i];
            if (v.id === vitalId) {
                return v;
            }
        }
        return null;
    }

    return function(value) {
        const vitalsIds = Object.keys(value).map((val) => parseInt(val, 10));

        for (let i = 0; i < vitalsIds.length; i += 1) {
            const vitalId = vitalsIds[i];

            const vital = getVitalById(vitalId);

            if (!vital || vital.limits.length === 0) {
                continue;
            }

            const vitalValue = value[vitalId];

            for (let day in vitalValue) {
                for (let part in vitalValue[day]) {
                    if (vitalValue[day][part] === '') {
                        continue;
                    }

                    let valueSubs = [];

                    if (typeof vitalValue[day][part] === 'string') {
                        valueSubs = vitalValue[day][part].split('/');
                    }

                    if (valueSubs.length > 1) {
                        const valueLimits = [];

                        for (let j = 0; j < vital.limits.length; j += 1) {
                            const lim = vital.limits[j];
                            if (lim.min && lim.max) {
                                valueLimits.push(`${lim.min} - ${lim.max}`);
                            }
                        }

                        const valueLimitsStr = valueLimits.join(' / ');
                        const message = `${vital.name} values range is ${valueLimitsStr}`;

                        for (let j = 0; j < valueSubs.length; j += 1) {
                            const val = parseFloat(valueSubs[j]);
                            const lim = vital.limits[j];

                            if (lim && lim.min !== null && lim.max !== null) {
                                if (val < lim.min || val > lim.max) {
                                    return message;
                                }
                            }
                        }

                        continue;
                    }

                    const limit = vital.limits[0];
                    const message = `${vital.name} values range is ${limit.min} - ${limit.max}`;

                    const fieldValue = parseFloat(vitalValue[day][part]);

                    if (limit.min !== null && fieldValue < limit.min) {
                        return message;
                    }

                    if (limit.max !== null && fieldValue > limit.max) {
                        return message;
                    }
                }
            }
        }

        return '';
    };
}

export function positiveNumberThreshold(message) {
    return function(value) {
        if (/^[0-9]+(\.[0-9]{1})?$/.test(value.warningLow)) {
            if (value.warningLow < 0) {
                return message;
            }
        } else {
            return message;
        }

        if (/^[0-9]+(\.[0-9]{1})?$/.test(value.criticalLow)) {
            if (value.criticalLow < 0) {
                return message;
            }
        } else {
            return message;
        }

        if (/^[0-9]+(\.[0-9]{1})?$/.test(value.warningHigh)) {
            if (value.warningHigh < 0) {
                return message;
            }
        } else {
            return message;
        }

        if (/^[0-9]+(\.[0-9]{1})?$/.test(value.criticalHigh)) {
            if (value.criticalHigh < 0) {
                return message;
            }
        } else {
            return message;
        }

        return '';
    };
}

export function warningAndCriticalThreshold(messages) {
    // NOTE: criticalLow < warningLow < warningHigh < criticalHigh
    return function(value) {
        const criticalLow = parseFloat(value.criticalLow);
        const warningLow = parseFloat(value.warningLow);
        const warningHigh = parseFloat(value.warningHigh);
        const criticalHigh = parseFloat(value.criticalHigh);

        if (criticalLow >= warningLow) {
            return messages.criticalLow || '';
        }

        if (warningLow >= warningHigh) {
            return messages.warningLow || '';
        }

        if (warningHigh >= criticalHigh) {
            return messages.warningHigh || '';
        }

        return '';
    }
}

export function minMaxNumberThreshold(thresholdName, limits) {
    const message = `${thresholdName} values range is ${limits.min} - ${limits.max}`;

    return function(value) {
        const warningLow = parseFloat(value.warningLow);
        const warningHigh = parseFloat(value.warningHigh);

        const criticalLow = parseFloat(value.criticalLow);
        const criticalHigh = parseFloat(value.criticalHigh);

        if (limits && limits.min !== null) {
            if (warningLow < limits.min
             || warningHigh < limits.min
             || criticalLow < limits.min
             || criticalHigh < limits.min) {
                 return message;
            }
        }

        if (limits && limits.max !== null) {
            if (warningLow > limits.max
             || warningHigh > limits.max
             || criticalLow > limits.max
             || criticalHigh > limits.max) {
                 return message;
            }
        }

        return '';
    };
}

export function positiveNumberWeightThreshold(message) {
    return function(value) {
        if (isValidFloat(value.criticalLow)) {
            if (value.criticalLow <= 0) {
                return message;
            }
        } else {
            return message;
        }

        if (isValidFloat(value.criticalHigh)) {
            if (value.criticalHigh <= 0) {
                return message;
            }
        } else {
            return message;
        }

        if (isValidInt(value.days)) {
            if (value.days <= 0) {
                return message;
            }
        } else {
            return message;
        }

        return '';
    };
}

export function warningAndCriticalWeightThreshold(message) {
    // NOTE: criticalLow < criticalHigh
    return function(value) {
        const criticalLow = parseFloat(value.criticalLow);
        const criticalHigh = parseFloat(value.criticalHigh);

        if (criticalLow >= criticalHigh) {
            return message;
        }

        return '';
    }
}

export function dayWeightThreshold(message, minDay, maxDay) {
    return function(value) {
        if (value.days < minDay || value.days > maxDay) {
            return message;
        }

        return '';
    };
}

export function warningAndCriticalLowThreshold(message) {
    return function(value) {
        const criticalLow = parseFloat(value.criticalLow);
        const warningLow = parseFloat(value.warningLow);

        if (criticalLow >= warningLow) {
            return message;
        }

        return '';
    }
}

export function minMaxNumberLowThreshold(thresholdName, limits) {
    const message = `${thresholdName} values range is ${limits.min} - ${limits.max}`;

    return function(value) {
        const warningLow = parseFloat(value.warningLow);
        const criticalLow = parseFloat(value.criticalLow);

        if (limits && limits.min !== null) {
            if (warningLow < limits.min || criticalLow < limits.min) {
                return message;
            }
        }

        if (limits && limits.max !== null) {
            if (warningLow > limits.max || criticalLow > limits.max) {
                return message;
            }
        }

        return '';
    };
}

export function warningAndCriticalHighThreshold(message) {
    return function(value) {
        const warningHigh = parseFloat(value.warningHigh);
        const criticalHigh = parseFloat(value.criticalHigh);

        if (warningHigh >= criticalHigh) {
            return message;
        }

        return '';
    }
}

export function minMaxNumberHighThreshold(thresholdName, limits) {
    const message = `${thresholdName} values range is ${limits.min} - ${limits.max}`;

    return function(value) {
        const warningHigh = parseFloat(value.warningHigh);
        const criticalHigh = parseFloat(value.criticalHigh);

        if (limits && limits.min !== null) {
            if (warningHigh < limits.min || criticalHigh < limits.min) {
                return message;
            }
        }

        if (limits && limits.max !== null) {
            if (warningHigh > limits.max || criticalHigh > limits.max) {
                return message;
            }
        }

        return '';
    };
}
