import React from 'react';

import Lines from './../components/lines';

import userInitials from '../lib/user-initials';
import { formatShortDate } from '../lib/date';
import { setAssignedTodos } from '../actions/ccm/todo';

export default class TodosWithRemoveTable extends React.Component {
    static defaultProps = {
        showDoneAt: false,
        showTotalTime: false
    }

    render() {
        if (!this.props.isTodosLoaded) {
            return null;
        }

        let rows = null;

        if (this.props.todos.length === 0) {
            let colspan = 7;

            if (this.props.showDoneAt) {
                colspan+= 1;
            }
            if (this.props.showTotalTime) {
                colspan+= 1;
            }

            rows = (
                <tr className='empty-table-message'>
                    <td colSpan={colspan}>{this.props.emptyTableMessage}</td>
                </tr>
            );
        } else {

            rows = this.props.todos.map(todo => {

                let actionName = todo.actionName;
                let cssName = 'normal';

                if (todo.actionCode) {
                    actionName = `${todo.actionCode} - ${todo.actionName}`;
                }

                if (todo.actionName === 'Warning from RPM' && todo.status === 'WAITING') {
                    cssName = 'warning'
                }

                return (
                    <tr className={cssName}>
                        <td onClick={() => { this._goToTodo(todo.id, todo.type) }}>{this.renderStatus(todo)}</td>
                        <td onClick={() => { this._goToTodo(todo.id, todo.type) }}>{userInitials(todo.createdByFirstName, todo.createdByLastName)}</td>
                        <td onClick={() => { this._goToTodo(todo.id, todo.type) }}>{todo.patientFirstName + ' ' + todo.patientLastName}</td>
                        <td onClick={() => { this._goToTodo(todo.id, todo.type) }}>{actionName}</td>
                        {this.props.showTotalTime ? <td onClick={() => { this._goToTodo(todo.id, todo.type) }}>{todo.totalTime}</td> : null}
                        <td onClick={() => { this._goToTodo(todo.id, todo.type) }}>{todo.assignedToUserId ? todo.assignedToFirstName + ' ' + todo.assignedToLastName : null}</td>
                        <td onClick={() => { this._goToTodo(todo.id, todo.type) }}>{this.renderDescription(todo)}</td>
                        <td onClick={() => { this._goToTodo(todo.id, todo.type) }}>{formatShortDate(todo.createdAt)}</td>
                        {this.props.showDoneAt ? <td onClick={() => { this._goToTodo(todo.id, todo.type) }}>{formatShortDate(todo.doneAt)}</td> : null}
                        <td>
                            {todo.actionName === 'Warning from RPM' || todo.status !== 'DONE'
                                ? null
                                : todo.removed
                                    ? 'Removed'
                                    : <button
                                        className="strike-throught"
                                        onClick={() => this.props.setReportIdToRemove(todo.id)} title="Remove">+</button>
                            }
                        </td>
                    </tr>
                );
            });
        }

        return (
            <div className='dashboard-table'>
                <header>
                    {this.props.headerName}
                </header>
                <table className='table table-row-hover dashboard-todos'>
                    <thead>
                        <tr>
                            <th>{this._getWaitingTodosCount()}</th>
                            <th className='sortable'>
                                <span onClick={() => this.props.sortFunction('createdByLastName', 'assignedTodos', setAssignedTodos) }>By</span>
                            </th>
                            <th className='sortable'>
                                <span onClick={() => this.props.sortFunction('patientLastName', 'assignedTodos', setAssignedTodos) }>Patient</span>
                            </th>
                            <th className='sortable'>
                                <span onClick={() => this.props.sortFunction('actionName', 'assignedTodos', setAssignedTodos) }>Action</span>
                            </th>
                            {this.props.showTotalTime ? <th>Total time</th> : null}
                            <th className='sortable'>
                                <span onClick={() => this.props.sortFunction('assignedToLastName', 'assignedTodos', setAssignedTodos) }>Assigned to</span>
                            </th>
                            <th>Description / Resolution</th>
                            <th className='sortable'>
                                <span onClick={() => this.props.sortFunction('unixtime', 'assignedTodos', setAssignedTodos) }>Created</span>
                            </th>
                            {this.props.showDoneAt ? <th>Closed</th> : null}
                            <th>Remove</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows}
                    </tbody>
                </table>
            </div>
        );
    }

    renderStatus(status) {
        if (status === 'WAITING') {
            return (
                <img src='/static/img/icon-waiting.png' alt='WAITING' title='WAITING'/>
            );
        } else if (status === 'DONE') {
            return (
                <img src='/static/img/icon-done.png' alt='DONE' title='DONE'/>
            );
        }

        return null;
    }

    renderStatus(todo){
        if(todo.actionName === 'Warning from RPM'){
            if(todo.status === 'WAITING' || todo.status === 'DONE'){
                return(
                    <img src={`/static/img/icon-warning-${todo.status.toLowerCase()}.png`} alt={todo.status.toUpperCase()} title={todo.status.toUpperCase()}/>
                )
            }
        } else {
            if(todo.status){
                return (
                    <img src={`/static/img/icon-${todo.status.toLowerCase()}.png`} alt={todo.status.toUpperCase()} title={todo.status.toUpperCase()}/>
                )
            } else {
                return null;
            }
        }
    }

    renderDescription(todo) {
        const description = [];

        if (todo.description) {
            description.push((
                <div key={0} className='text-block'>
                    <Lines data={todo.description}/>
                </div>
            ));
        }

        if (todo.resolutions && todo.resolutions.length > 0) {
            for (let i = 0; i < todo.resolutions.length; i += 1) {
                description.push((
                    <div key={i+1} className='text-block'>
                        <Lines data={todo.resolutions[i]}/>
                    </div>
                ));
            }
        }

        return description;
    }

    _getWaitingTodosCount() {
        if (!this.props.isTodosLoaded) {
            return null;
        }

        let count = 0;

        for (let i = 0; i < this.props.todos.length; i++) {
            if (this.props.todos[i].status === 'WAITING') {
                count += 1;
            }
        }

        return (
            <span>{count}</span>
        );
    }

    _goToTodo(todoId, todoType) {
        if (todoType === 'ADDON') {
            this.props.history.push('/app/ccm/addon/' + todoId);
        } else {
            this.props.history.push('/app/ccm/todo/' + todoId);
        }
    }
}
