import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
    formatDayDate,
    formatShortDate,
    formatWeekdayName,
    getDaysOfWeekByDate
} from '../../../../lib/date';
import {
    clearDailyCarePlan,
    clearDailyCarePlanHistory,
    getDailyCarePlan,
    getDailyCarePlanHistory,
    markMeasurementAsWrong,
    setDailyCarePlan
} from '../../../../actions/rpm/care-plans';
import {
    clearDailyCarePlanAllEntries,
    clearDailyCarePlanEntries,
    clearDailyCarePlanLogs,
    createCarePlanLog,
    getCarePlanLogs,
    setDailyCarePlanAllEntries,
    setDailyCarePlanEntries,
    setDailyCarePlanLogs
} from '../../../../actions/rpm/care-plans-logs';
import RequestLoader from '../../../../components/request-loader';
import PatientVitalCharts from '../../../../containers/patient-vital-charts';
import RPMContactInfo from './contact-info';
import RPMPatientDiagnoses from './patient-diagnoses';
import RPMPatientMedications from './patient-medications';
import FormMessage from '../../../../components/form-message';
import Form from '../../../../lib/forms/form';
import WeekVitals from '../../../../lib/forms/week-vitals';
import {
    required,
    checkReportingTime,
    weekVitalsLimits,
    weekVitalsAllNumbersWithZero
} from '../../../../lib/forms/field-validators';
import SelectWithSearch2 from '../../../../lib/forms/select-with-search2';
import Textarea2 from '../../../../lib/forms/textarea2';
import Select from '../../../../lib/forms/select';
import Input from '../../../../lib/forms/input';
import CommonPopup from '../../../../components/common-popup';
import PatientCommunications from '../patient-communications';
import RPMPatientTimeline from './patient-timeline';
import FormLoader from '../../../../components/form-loader';
import RPMPatientLogs from './patient-logs';
import ECGList from '../../../../components/ecg-list';
import RPMPatientHistory from './patient-history';
import Checkboxes from '../../../../lib/forms/checkboxes';
import Lines from '../../../../components/lines';

//DEPRECATED
class RPMReportDailyCarePlan extends Component {

    state = {
        isSending: false,
        isSaved: false,
        isLoadingWeek: false,
        currentWeek: 0,
        firstDayOfLastWeek: '',
        reportingTimeActive: false,
        vitalValues: null,
        toggleLogs: false,
        toggleVitalsContent: true,
        comment: '',
        showMarkMeasurementAsWrongPopup: false,
        measurementToDelete: null,
        measurementToDeleteReason: null,
        measurementToDeleteError: null
    };

    resolutions = [
        { label: 'REVIEWED , WILL CONTINUE TO MONITOR', value: 'REVIEWED , WILL CONTINUE TO MONITOR. ' },
        { label: 'TC TO OBTAIN , REVIEW AND RECORD DATA', value: 'TC TO OBTAIN , REVIEW AND RECORD DATA' },
        { label: 'TC TO Pt TO REQ RECHECK AFTER REVIEW', value: 'TC TO Pt TO REQ RECHECK AFTER REVIEW' },
        { label: 'F/U REVIEW AFTER RECHECK', value: 'F/U REVIEW AFTER RECHECK' },
        { label: 'NOTIFIED DR OF TREND', value: 'NOTIFIED DR OF TREND' },
        { label: 'NOTIFIED APN OF TREND', value: 'NOTIFIED APN OF TREND' },
        { label: 'TC TO REVIEW MEDICATION AND DIET', value: 'TC TO REVIEW MEDICATION AND DIET' }
    ]

    constructor(props) {
        super(props);

        this.form = React.createRef();

        this.minWeek = -4;
        this.maxWeek = 0;
    }

    componentDidMount() {

        const planId = this._planId();
        const { clinicId } = this.props;

        this._loadCarePlan(
            clinicId, planId
        );

        this._loadCarePlanLogs(
            clinicId, planId
        );
    }

    componentWillUnmount() {
        const { dispatch } = this.props;

        dispatch(clearDailyCarePlan());
        dispatch(clearDailyCarePlanLogs());
        dispatch(clearDailyCarePlanEntries());
        dispatch(clearDailyCarePlanAllEntries());
    }

    render() {
        const { isDailyCarePlanLoaded, dailyCarePlan, history, clinicId } = this.props;

        if (!isDailyCarePlanLoaded) {

            return <RequestLoader center />
        }

        return (
            <div>
                { this._renderVitalValuesPopup() }
                { this._renderMarkMeasurementAsWrongPopup() }
                <div className='top-info-wrapper'>
                    <div className='patient-info-col fixed'>
                        <RPMContactInfo />
                        <RPMPatientDiagnoses clinicId={clinicId} patientId={dailyCarePlan.patientId} />
                        <RPMPatientMedications clinicId={clinicId} patientId={dailyCarePlan.patientId } />
                        <RPMPatientLogs
                            week={ this.state.currentWeek }
                            plan={ this._planId() }
                        />
                        <RPMPatientHistory
                            patientId={ dailyCarePlan.patientId }
                            history={ history }
                        />
                        <ECGList patient={dailyCarePlan.patientId} />
                    </div>
                    <PatientVitalCharts
                        patient={ dailyCarePlan.patientId }
                        showCarePlanLink={ false }
                    />
                </div>
                <RPMPatientTimeline />
                { this._renderForm() }
                { this._renderCommunication() }
            </div>
        );
    }

    _renderForm = () => {
        const {
            dailyCarePlan,
            isDailyCarePlanEntriesLoaded,
            dailyCarePlanEntries,
            editableVitals,
            allWaitingsTodos
        } = this.props;

        if (!isDailyCarePlanEntriesLoaded) {

            return <RequestLoader center />
        }

        const { weightInKg, tempInF } = this.props.clinic;
        const { firstDayOfLastWeek } = this.state;

        if (dailyCarePlan.vitals.length === 0) {
            return null;
        }

        if (!isDailyCarePlanEntriesLoaded || !firstDayOfLastWeek) {
            return null;
        }

        const weekVitalsValues = this._getWeekVitalsValues(
            dailyCarePlanEntries
        );

        const firstDay = moment(firstDayOfLastWeek);
        firstDay.add(this.state.currentWeek, 'weeks');

        const dates = getDaysOfWeekByDate(firstDay);

        const vitalsMap = Object.fromEntries(dailyCarePlan.vitals.map((vital) => ([vital.id, vital.name])));

        const waitingToClose = allWaitingsTodos.length ? allWaitingsTodos.map(i => {
            let resolutions = [];

            if (i.Resolutions) {

                resolutions.push('\nResolutions:')
                i.Resolutions.map(r => {
                    resolutions.push(`\n${r.resolution}\n By ${r.firstName} ${r.lastName}`);
                });

                resolutions.join('\n');
            }

            return {
                name: i.journalId,
                label: i.assignedToFirstName ? `${i.createdAtDate} Assigned to ${i.assignedToFirstName} ${i.assignedToLastName}` : `${i.createdAtDate}`,
                checked: false,
                bottomDescription: <Lines data={`Action: ${i.actionName}\nDescription: ${i.description}${resolutions}`} />
            };
        }) : [];

        return (
            <div>
                { this._renderMessage() }
                { this._renderWeekManager() }
                <Form ref={this.form} onSubmit={() => {}} className='form daily-care-plan-form'>
                    <WeekVitals
                        name='vitals'
                        toggleFunction={ this._toggleVitalsContent }
                        showVitalsContent={this.state.toggleVitalsContent}
                        incomingVitals={dailyCarePlan.vitals}
                        vitalMetrics={dailyCarePlan.vitalMetrics}
                        vitals={[]}
                        defaultValue={weekVitalsValues}
                        dates={dates}
                        editableVitals={editableVitals}
                        validators={[
                            weekVitalsAllNumbersWithZero('Please enter positive numbers', vitalsMap, {
                                weightInKg,
                                tempInF
                            }),
                            weekVitalsLimits(dailyCarePlan.vitals)
                        ]}
                        disableFuture={true}
                        onShowFullValues={ this._toggleVitalValuesPopup }
                    />

                    <SelectWithSearch2
                        label='Standard resolutions'
                        values={ this.resolutions }
                        value=''
                        inline={ true }
                        onChange={(resolution) => { this._onSelectResolutionChange(resolution) }}/>

                    <Textarea2
                        value={ this.state.comment }
                        onChange={ this._onResolutionChange }
                        name='comment'
                        label='Resolution'
                    />

                    <div className='waiting-todos'>
                        {waitingToClose ?
                            <Checkboxes
                                label='Additional Tasks for the patient :'
                                name='waitingToClose'
                                values={waitingToClose}
                                inline={false}
                                inlineFields={false} />
                            : <div />}
                    </div>

                    <div className='rpm-report-time-wrap'>
                        <div className='action'>
                            <Select
                                placeholder='Select CPT Code for action'
                                name='rpmAction'
                                values={ this._getActionsForSelect() }
                                onChange={ this._setAction }
                                validators={[
                                    required('Please select the action')
                                ]}
                            />
                        </div>

                        <div className='time'>
                            <Input
                                disabled={ !this.state.reportingTimeActive }
                                name='trackedTime'
                                label='Reporting time'
                                defaultValue={0}
                                horizontal={true}
                                validators={[
                                    checkReportingTime('Please enter positive number', {
                                        active: this.state.reportingTimeActive
                                    })
                                ]}
                            />
                        </div>
                    </div>

                    <div className='buttons'>
                        <button disabled={this.state.isSending}
                                onClick={() => { this._onFormSubmit(false); }}
                                className='button'>
                            Save
                        </button>
                        <button disabled={this.state.isSending}
                                onClick={() => { this._onFormSubmit(true); }}
                                className='button'>
                            Save and return
                        </button>
                        {this.state.isSending ? <FormLoader /> : null}
                    </div>
                </Form>
            </div>
        );
    }

    _planId = () => {
        const planId = parseInt(this.props.match.params.planId, 10);

        return isNaN(planId) ? this.props.history.push('/app/rpm') : planId;
    }

    _loadCarePlan = (clinicId, planId) => {

        const { dispatch } = this.props;

        getDailyCarePlan({ planId,  clinicId }).then(res => {

            const { carePlan, rpmActions, allWaitings } = res.data;

            dispatch(setDailyCarePlan(carePlan, rpmActions, allWaitings));
        });
    }

    _loadCarePlanLogs = (clinicId, planId) => {

        const { dispatch } = this.props;

        getCarePlanLogs(
            clinicId,
            planId,
            this.state.currentWeek
        ).then(res => {

            const { firstDayOfLastWeek, logs, entries, editableVitals, allEntries } = res.data;

            dispatch(setDailyCarePlanLogs(logs));
            dispatch(setDailyCarePlanEntries(entries, editableVitals));

            dispatch(setDailyCarePlanAllEntries(allEntries));

            this.setState({
                firstDayOfLastWeek,
                isLoadingWeek: false
            });
        });
    }

    _getWeekVitalsValues = (dailyCarePlanEntries) => {
        const weekVitalsValues = {};

        for (let i = 0; i < dailyCarePlanEntries.length; i += 1) {
            const entry = dailyCarePlanEntries[i];

            if (!weekVitalsValues[entry.vital]) {
                weekVitalsValues[entry.vital] = {};
            }

            const weekdayName = formatWeekdayName(entry.reportedDay).toLowerCase();

            if (!weekVitalsValues[entry.vital][weekdayName]) {
                weekVitalsValues[entry.vital][weekdayName] = {};
            }

            weekVitalsValues[entry.vital][weekdayName][entry.reportedPartOfDay] = {
                value: entry.reportedValue,
                values: []
            };
        }

        for (let vitalId in this.props.dailyCarePlanAllEntries) {
            const vitalEntries = this.props.dailyCarePlanAllEntries[vitalId];

            if (!weekVitalsValues[vitalId]) {
                weekVitalsValues[vitalId] = {};
            }

            for (let day in vitalEntries) {
                const weekdayName = formatWeekdayName(day).toLowerCase();
                const dayEntries = vitalEntries[day];

                if (!weekVitalsValues[vitalId][weekdayName]) {
                    weekVitalsValues[vitalId][weekdayName] = {};
                }

                for (let partOfDay in dayEntries) {
                    const entries = dayEntries[partOfDay] || [];

                    if (!weekVitalsValues[vitalId][weekdayName][partOfDay]) {
                        weekVitalsValues[vitalId][weekdayName][partOfDay] = {
                            value: '',
                            values: []
                        };
                    }

                    weekVitalsValues[vitalId][weekdayName][partOfDay].values = entries;
                }
            }
        }

        return weekVitalsValues;
    }

    _renderMessage = () => {
        if (!this.state.isSaved) {
            return null;
        }

        return (
            <FormMessage message={'Your data has been recorded'}/>
        );
    }

    _onFormSubmit = (isExit) => {
        if (!this.form.current) {
            return;
        }

        if (!this.form.current.isValid()) {
            return;
        }

        if (this.state.isSending) {
            return;
        }

        const data = this.form.current.getValues();
        data.comment = this.state.comment;

        data.week = this.state.currentWeek;

        this.props.dispatch(
            clearDailyCarePlanHistory()
        );

        const { clinicId } = this.props;

        this.setState({
            isSending: true,
            reportingTimeActive: false
        }, () => {
            createCarePlanLog(
                clinicId,
                this._planId(),
                data
            ).then(() => {

                if (isExit) {
                    this.props.history.push('/app/rpm');
                } else {
                    this.componentWillUnmount();

                    getDailyCarePlanHistory({ planId: this._planId(), clinicId }).then(e => {

                         this.componentDidMount();
                    });
                }

                this.setState({
                    isSending: false,
                    isSaved: true,
                    comment: ''
                });
            }).catch(e => {
                this.form.current.setErrors(e.response.data.errors);
                this.setState({ isSending: false });
            });
        });
    }

    _changeWeek = (direction) => {
        const { isLoadingWeek } = this.state;
        let { currentWeek } = this.state;

        if (isLoadingWeek) {
            return;
        }

        if (direction === 'prev') {
            if (currentWeek <= this.minWeek) {

                return;
            }

            currentWeek -= 1;
        } else {
            if (currentWeek >= this.maxWeek) {

                return;
            }

            currentWeek += 1;
        }

        const planId = this._planId();
        const { clinicId, dispatch } = this.props;

        this.setState({ reportingTimeActive: false, isLoadingWeek: true, currentWeek }, () => {
            dispatch(clearDailyCarePlanLogs());
            dispatch(clearDailyCarePlanEntries());
            dispatch(clearDailyCarePlanAllEntries());
            this._loadCarePlanLogs(
                clinicId,
                planId
            );
        });
    }

    _renderWeekManager = () => {
        const prevClasses = ['prev-week'];
        const nextClasses = ['next-week'];

        if (this.state.currentWeek === this.minWeek) {
            prevClasses.push('disabled');
        }

        if (this.state.currentWeek === this.maxWeek) {
            nextClasses.push('disabled');
        }

        return (
            <div className='daily-care-plan-week-manager'>
                <div className={prevClasses.join(' ')} onClick={ () => this._changeWeek('prev') }>
                    Previous week
                </div>
                <div className={nextClasses.join(' ')} onClick={ () => this._changeWeek('next') }>
                    Next week
                </div>
            </div>
        );
    }

    _getActionsForSelect = () => {

        const { carePlanActions } = this.props;

        return carePlanActions.map(item => {

            return { name: `${item.name} ${item.cptCode ? item.cptCode : ''}`, value: item.cptCode }
        });
    }

    _onSelectResolutionChange = (comment) => {
        let _comment = this.state.comment;

        if (_comment) {

            _comment = _comment.concat('\n');
        }

        this.setState({
            comment: `${_comment}${comment}`
        });
    }

    _setAction = (value) => {
        value = parseInt(value);

        this.setState({
            reportingTimeActive: (value !== '' && value !== 99453)
        });
    }

    _renderVitalValuesPopup = () => {
        const { vitalValues } = this.state;

        if (!vitalValues) {
            return null;
        }

        const {
            vitalId,
            vitalName,
            day,
            part
        } = vitalValues;
        const values = this._getVitalDayValues(vitalId, day, part);
        const rows = [];

        values.forEach(value => {

            rows.push(
                <tr key={value.id}>
                    <td>{value.value}</td>
                    <td>{formatShortDate(value.updatedDate)}</td>
                    <td style={{ textAlign: 'center' }}><span title='Remove' className='mark-as-wrong' onClick={() => this._markMeasurementAsWrong(value.id)}>X</span></td>
                </tr>
            );
        });

        return (
            <CommonPopup header={`${vitalName} - ${formatDayDate(day)} - ${part}`}
                         onClose={() => { this._toggleVitalValuesPopup(null); }}>
                <div className='care-plan-vital-popup'>
                    <div className='care-plan-vital-popup-content'>
                        <table className='table care-plan-vital-popup-table'>
                            <thead>
                                <tr>
                                    <th>Value</th>
                                    <th className='care-plan-vital-date'>Date</th>
                                    <th style={{ width: '60px' }}>Remove</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rows}
                            </tbody>
                        </table>
                    </div>
                </div>
            </CommonPopup>
        );
    }

    _renderMarkMeasurementAsWrongPopup = () => {

        const { showMarkMeasurementAsWrongPopup, measurementToDeleteError } = this.state;

        if (!showMarkMeasurementAsWrongPopup) {

            return null;
        }

        return (
            <CommonPopup
                classNames={['mark-measurement-as-wrong-popup']}
                header='Enter reason to remove'
                onClose={() => { this._cancelMarkMeasurementAsWrong() }}>
                <textarea
                    className='form-textarea'
                    onChange={(e) => {
                        this.setState({
                            measurementToDeleteError: null,
                            measurementToDeleteReason: e.target.value
                        });
                    }}
                    value={this.state.measurementToDeleteReason} />

                {measurementToDeleteError ? <div style={{ margin: '5px 0', textAlign: 'center', color: 'red' }}>{measurementToDeleteError}</div> : null}

                <div className='buttons'>
                    <button className='confirm' onClick={() => this._cancelMarkMeasurementAsWrong()}>Cancel</button>
                    <button className='cancel' onClick={() => this._confirmMarkMeasurementAsWrong()}>Remove</button>
                </div>
            </CommonPopup>
        );
    }

    _cancelMarkMeasurementAsWrong = () => {

        this.setState({
            showMarkMeasurementAsWrongPopup: false,
            measurementToDelete: null,
            measurementToDeleteReason: null
        });
    }

    _confirmMarkMeasurementAsWrong = () => {

        const { measurementToDelete, measurementToDeleteReason } = this.state;
        const { clinicId } = this.props;

        if (!measurementToDeleteReason || !measurementToDeleteReason.trim()) {

            this.setState({
                measurementToDeleteError: 'Enter reason to remove'
            });

            return null;
        }

        this._toggleVitalValuesPopup(null);
        this.componentWillUnmount();

        markMeasurementAsWrong(clinicId, {measurementId: measurementToDelete, reason: measurementToDeleteReason}).then(() => {
            this.setState({
                measurementToDelete: null,
                measurementToDeleteReason: null,
                showMarkMeasurementAsWrongPopup: false
            });

            this.componentDidMount();
        })
    }

    _markMeasurementAsWrong = (measurementId) => {

        this.setState({
            measurementToDelete: measurementId,
            showMarkMeasurementAsWrongPopup: true
        });
    }

    _getVitalDayValues = (vitalId, day, partOfDay) => {

        const {
            isDailyCarePlanAllEntriesLoaded,
            dailyCarePlanAllEntries
        } = this.props;

        if (!isDailyCarePlanAllEntriesLoaded) {
            return [];
        }

        if (!dailyCarePlanAllEntries[vitalId]) {
            return [];
        }

        if (!dailyCarePlanAllEntries[vitalId][day]) {
            return [];
        }

        return dailyCarePlanAllEntries[vitalId][day][partOfDay];
    }

    _toggleVitalValuesPopup = vitalValues => {
        this.setState({ vitalValues });
    }

    _onResolutionChange = (comment) => {
        this.setState({ comment });
    }

    _renderCommunication = () => {
        const { userId, clinic, dailyCarePlan } = this.props;

        return (
            <PatientCommunications
                userId={ userId }
                clinicId={ clinic.id }
                carePlan={ dailyCarePlan }
            />
        );
    }
}

export default connect((state) => {
    const { currentClinic, clinics } = state.user;
    let clinic = null;

    for (let i = 0; i < clinics.length; i += 1) {
        if (currentClinic == clinics[i].id) {
            clinic = clinics[i];
            break;
        }
    }

    return {
        clinic,
        clinicId: state.user.currentClinic,

        userId: state.user.userId,
        isDailyCarePlanLoaded: state.rpmCarePlans.isDailyCarePlanLoaded,
        dailyCarePlan: state.rpmCarePlans.dailyCarePlan,
        carePlanActions: state.rpmCarePlans.carePlanActions,
        allWaitingsTodos: state.rpmCarePlans.allWaitingsTodos,

        isDailyCarePlanLogsLoaded: state.rpmCarePlans.isDailyCarePlanLogsLoaded,
        dailyCarePlanLogs: state.rpmCarePlans.dailyCarePlanLogs,

        isDailyCarePlanEntriesLoaded: state.rpmCarePlans.isDailyCarePlanEntriesLoaded,
        dailyCarePlanEntries: state.rpmCarePlans.dailyCarePlanEntries,
        editableVitals: state.rpmCarePlans.editableVitals,

        isDailyCarePlanAllEntriesLoaded: state.rpmCarePlans.isDailyCarePlanAllEntriesLoaded,
        dailyCarePlanAllEntries: state.rpmCarePlans.dailyCarePlanAllEntries
    };
})(RPMReportDailyCarePlan);