
export const lines = function(str) {
    if (typeof str !== 'string') {
        return str;
    }

    const temp = str.split(/\\n|\n/);

    if (temp.length > 1) {
        return temp.map((line) => <p>{line || '\u00a0'}</p>);
    }

    return str;
}

export const textToLines = function(text) {
    if (typeof text !== 'string') {
        return [text];
    }

    return text.split(/\n|\\n/);
}
