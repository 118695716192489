import React, { createRef } from 'react';
import { connect } from 'react-redux';

import { matchTodoMeetingId, getTodoMeetingId } from '../../base/project/todo';

import {
    getPatientTodos,
    setPatientTodos,
    clearPatientTodos
} from '../../actions/patients';

import {
    getTodo,
    setTodo,
    clearTodo,
    createTodoResolution,
    deleteTodo,
    removeReport
} from '../../actions/ccm/todo';

import BackButton from './../../components/back-button';
import Lines from './../../components/lines';
import CommonPopup from './../../components/common-popup';

import CameraVideoIcon from './../../components/icons/camera-video';
import ChatSquareDotsIcon from './../../components/icons/chat-square-dots';
import ClipboardIcon from './../../components/icons/clipboard';
import PhoneIcon from './../../components/icons/phone';
import TrashIcon from './../../components/icons/trash';

import FormLoader from './../../components/form-loader';

import PatientVitalCharts from './../../containers/patient-vital-charts';
import ModalChatWrapper from './../../containers/modal-chat-wrapper';

import Form from './../../lib/forms/form';
import Input from './../../lib/forms/input';
import Checkbox from './../../lib/forms/checkbox';
import SelectWithSearch from './../../lib/forms/select-with-search';
import { required, positiveDouble } from '../../lib/forms/field-validators';

import userInitials from '../../lib/user-initials';
import { formatDate, formatShortDate } from '../../lib/date';
import { textToLines } from '../../lib/lines';
import Checkboxes from '../../lib/forms/checkboxes';
import Textarea2 from '../../lib/forms/textarea2';
import SelectWithSearch2 from '../../lib/forms/select-with-search2';

class CCMShowTodo extends React.Component {

    resolutions = [
        { label: 'REVIEWED , WILL CONTINUE TO MONITOR', value: 'REVIEWED , WILL CONTINUE TO MONITOR. ' },
        { label: 'TC TO OBTAIN , REVIEW AND RECORD DATA', value: 'TC TO OBTAIN , REVIEW AND RECORD DATA' },
        { label: 'TC TO Pt TO REQ RECHECK AFTER REVIEW', value: 'TC TO Pt TO REQ RECHECK AFTER REVIEW' },
        { label: 'F/U REVIEW AFTER RECHECK', value: 'F/U REVIEW AFTER RECHECK' },
        { label: 'NOTIFIED DR OF TREND', value: 'NOTIFIED DR OF TREND' },
        { label: 'NOTIFIED APN OF TREND', value: 'NOTIFIED APN OF TREND' },
        { label: 'TC TO REVIEW MEDICATION AND DIET', value: 'TC TO REVIEW MEDICATION AND DIET' }
    ]

    state = {
        removePopupIsOpen: false,
        reportIsRemoving: false,
        reasonToStrikeThrought: '',
        isClosing: true,
        isUpdatingTodo: false,
        patientCommunications: '',
        resolution: ''
    }

    todoForm = createRef();

    constructor(props) {
        super(props);

        this.forceSave = false;
        this.setPatientCommunications = this.setPatientCommunications.bind(this);
        this.tryVideoChat = this.tryVideoChat.bind(this);
    }

    componentDidMount() {
        const todoId = this.getTodoId();

        this.loadTodo(todoId, () => {
            this.loadPatientTodos();
        });
    }

    componentWillUnmount() {
        this.props.dispatch(clearTodo());
        this.props.dispatch(clearPatientTodos());
    }

    componentWillReceiveProps(nextProps) {
        if (!nextProps.isTodoLoaded) {
            return;
        }

        const todoId = parseInt(nextProps.match.params.todoId, 10);

        if (nextProps.todo.id !== todoId) {
            this.props.dispatch(clearTodo());
            this.props.dispatch(clearPatientTodos());

            this.loadTodo(todoId, () => {
                this.loadPatientTodos();
            });
        }
    }

    setPatientCommunications(patientCommunications) {
        this.setState({ patientCommunications });
    }

    tryVideoChat() {
        const { clinicId, userId, todo } = this.props;

        // NOTE: actions with meeting id
        const actionsWithMeeting = ['Telehealth call', 'Virtual Check-up'];

        let meetingId = null;
        if (actionsWithMeeting.indexOf(todo.actionName) !== -1) {
            meetingId = getTodoMeetingId(todo.description);
        }

        const params = [
            `patientUserId=${todo.patientUserId}`,
            `employeeUserId=${userId}&clinicId=${clinicId}`
        ];

        if (meetingId) {
            params.push(`meetingId=${meetingId}`);
        } else {
            params.push(`journalId=${todo.id}`);
        }

        const url = `${window.env.Video_Call_Url}?${params.join('&')}`;
        const win = window.open(url, '_blank');
        win.focus();
    }

    getDoctorOptions() {
        return this.props.doctors.map((doctor) => {
            return {
                value: doctor.id,
                label: doctor.firstName + ' ' + doctor.lastName
            };
        });
    }

    getWaitingTodosCount() {
        if (!this.props.isPatientTodosLoaded) {
            return null;
        }

        let count = 0;

        let { patientTodos } = this.props;
        patientTodos = patientTodos.filter(todo => todo.journalId !== this.props.todo.id);

        for (let i in patientTodos) {
            if (patientTodos[i].status === 'WAITING') {
                count += 1;
            }
        }

        return (
            <span>{count}</span>
        );
    }

    getTodoId() {
        const todoId = parseInt(this.props.match.params.todoId, 10);

        if (isNaN(todoId)) {
            this.props.history.push('/app/ccm');
            return;
        }

        return todoId;
    }

    loadTodo(todoId, callback) {

        callback = callback || function() {};
        const { dispatch, clinicId } = this.props;

        getTodo(clinicId, todoId).then((res) => {
            const { data: { todo } } = res;

            dispatch(
                setTodo(
                    todo
                )
            );

            callback();
        });
    }

    loadPatientTodos() {
        if (!this.props.isTodoLoaded) {
            return;
        }

        const patientId = this.props.todo.patientUserId;
        const clinicId = this.props.clinicId;

        getPatientTodos(clinicId, patientId).then((res) => {
            this.props.dispatch(setPatientTodos(res.data.todos));
        });
    }

    goToTodo(todoId, type) {
        this.props.history.push(`/app/ccm/${type.toLowerCase()}/${todoId}`);
    }

    onDoneChange(value) {
        this.setState({isClosing: value});
    }

    onCancel(evt) {
        evt.preventDefault();
        this.props.history.push('/app/ccm');
    }


    onSubmitTodo(todo) {
        if (this.state.isUpdatingTodo) {
            return;
        }

        const { clinicId } = this.props;

        this.setState({ isUpdatingTodo: true }, () => {
            todo.id = this.props.match.params.todoId;
            todo.done = todo.done ? 1 : 0;
            todo.resolution = this.state.resolution;
            if(this.props.todo.actionCode === '99453'){
                todo.time = 0;
                todo.actioncode = 99453;
            } else if (this.props.todo.actionName.toLowerCase() === 'discharged from service') {
                todo.time = 0;
            } else {
                todo.actioncode = this.props.todo.actionCode;
            }

            if (todo.doctor) {
                const doctor = this.props.doctors.find(doctor => doctor.id === todo.doctor);

                if (todo.resolution) {
                    todo.resolution += ' / ';
                }

                todo.resolution += `Assigned to: ${doctor.firstName} ${doctor.lastName}`;
            }

            const patientId = this.props.todo.patientUserId;
            const clinicId = this.props.clinicId;

            this.props.dispatch(
                clearPatientTodos()
            );

            createTodoResolution({
                todoId: parseInt(todo.id),
                doctorId: parseInt(todo.doctor) || null,
                actioncode: todo.actioncode || null,
                time: parseInt(todo.time),
                resolution: todo.resolution,
                waitingToClose: todo.waitingToClose || [],
                done: todo.done,
                clinicId
            }).then((res) => {

                this.setState({ isClosing: true });
                this.props.dispatch(clearTodo());
                this.loadTodo(todo.id);

                this.setState({ isUpdatingTodo: false, resolution: '' }, () => {
                    getPatientTodos(
                        clinicId,
                        patientId
                    ).then((res) => {

                        this.props.dispatch(setPatientTodos(res.data.todos));
                    });
                });
            });
        });
    }

    onDeleteTodo(evt) {
        evt.preventDefault();

        if (this.state.isUpdatingTodo) {
            return;
        }

        if (window.confirm('Are you sure you want to completely delete this ToDo Item?')) {
            deleteTodo(this.props.clinicId, this.props.todo.id).then(() => {
                this.props.history.push('/app/ccm');
            });
        }
    }

    renderTodoLine(line) {
        const match = matchTodoMeetingId(line);

        if (!match) {
            return (
                <p>
                    {line}
                </p>
            );
        }

        const meetingId = match[0] || '';
        const startLine = line.slice(0, match.index);
        const endLine = line.slice(match.index + meetingId.length);

        return (
            <p>
                {startLine}
                <span className='hl-description'>
                    {meetingId}
                </span>
                {endLine}
            </p>
        );
    }

    renderTodoLines(text) {
        const lines = textToLines(text);
        return lines.map((line) => this.renderTodoLine(line));
    }

    renderAssignedTo() {
        const { todo } = this.props;

        if (!todo.assignedToUserId) {
            return null;
        }

        return (
            <div className='todo-info-block'>
                <div>Assigned to</div>
                <div>{todo.assignedToFirstName + ' ' + todo.assignedToLastName}</div>
            </div>
        );
    }

    renderDone() {
        const { todo } = this.props;

        if (todo.time === 0 || todo.actionCode === '99453' || todo.actionName === 'Discharged From Service') {
            return null;
        }

        if (!todo.doneAt) {
            return (
                <div className='todo-info separator'>
                    <div className='todo-info-block'>
                        <div>Total time (in minutes) </div>
                        <div>{todo.totalTime}</div>
                    </div>
                </div>
            );
        }

        return (
            <div className='todo-info separator'>
                <div className='todo-info-block'>
                    <div>Done by</div>
                    <div>{todo.doneByFirstName + ' ' + todo.doneByLastName}</div>
                </div>
                <div className='todo-info-block'>
                    <div>Time (in minutes)</div>
                    <div>{todo.totalTime}</div>
                </div>
                <div className='todo-info-block'>
                    <div>Done at</div>
                    <div>{formatDate(todo.doneAt)}</div>
                </div>
            </div>
        );
    }

    renderCharts() {
        if (!this.props.isTodoLoaded) {
            return null;
        }

        return (
            <div className='separator'>
                <PatientVitalCharts patient={this.props.todo.patientUserId} />
            </div>
        );
    }

    renderProcedures() {
        if (this.props.todo.procedures.length === 0) {
            return null;
        }

        return this.props.todo.procedures.map((p, i) => {
            const classes = ['resolution'];

            if (i !== this.props.todo.procedures.length - 1) {
                classes.push('separator');
            }

            const proceduresTime = () => {
                if(this.props.todo.actionCode !== '99453'){
                    return (
                        <div className='todo-info-block'>
                            <div>Time</div>
                            <div>{p.time}</div>
                        </div>
                    )
                }
            }

            return (
                <div key={p.id} className={classes.join(' ')}>
                    <div className='todo-info'>
                        <div className='todo-info-block'>
                            <div>Procedure by</div>
                            <div>{p.createdByFirstName + ' ' + p.createdByLastName}</div>
                        </div>
                        {proceduresTime()}
                    </div>

                    <header>Resolution</header>
                    <div>
                        <Lines data={ p.resolution } />
                    </div>
                </div>
            );
        });
    }

    renderWaitingWarnings() {
        const { todo } = this.props;

        todo.allWaiting = todo.allWaiting.filter(waiting => waiting.journalId !== todo.id)

        if (todo.allWaiting.length) {
            if (this.state.isClosing) {
                const waitingToClose = todo.allWaiting.map(i => {
                    let resolutions = [];

                    if (i.Resolutions) {

                        resolutions.push('\nResolutions:')
                        i.Resolutions.map(r => {
                            resolutions.push(`\n${r.resolution}\n By ${r.firstName} ${r.lastName}`);
                        });

                        resolutions.join('\n');
                    }

                    return {
                        name: i.journalId,
                        label: `${i.createdAtDate} Assigned to ${i.assignedToFirstName} ${i.assignedToLastName}`,
                        checked: false,
                        bottomDescription: <Lines data={ `Action: ${i.actionName}\nDescription: ${i.description}${resolutions}`} />
                    };
                });

                return (
                    <div className='warnings-to-close'>
                        <Checkboxes
                            label='Additional Tasks for the patient :'
                            name='waitingToClose'
                            values={ waitingToClose }
                            inline={ false }
                            inlineFields={ false } />
                    </div>
                );
            }
        }
    }


    onSelectResolutionChange(comment) {
        let _comment = this.state.resolution;

        if (_comment) {

            _comment = _comment.concat('\n');
        }

        this.setState({
            resolution: `${_comment}${comment}`
        });
    }

    onResolutionChange = (resolution) => {
        this.setState({ resolution });
    }

    renderTimeInput(){
        const { actionCode, actionName, actionDuration} = this.props.todo;

        if (actionCode !== '99453' && actionName.toLowerCase() !== 'discharged from service') {
            return(
                <Input name='time'
                       label='Time (in minutes)'
                       validators={[
                           required('Please enter time'),
                           positiveDouble('Please enter positive number')
                       ]}
                       horizontal={true}
                       defaultValue={ actionDuration }/>
            )
        }
    }

    renderForm() {
        if (this.props.todo.status !== 'WAITING') {
            return null;
        }

        const classes = ['form'];
        if (this.props.todo.procedures.length !== 0) {
            classes.push('form-separator');
        }

        let deleteButton = null;

        if (this.props.todo.status === 'WAITING' && this.props.todo.procedures.length === 0) {
            deleteButton = (
                <button className='button'
                        disabled={this.state.isUpdatingTodo}
                        onClick={this.onDeleteTodo.bind(this)}>
                    <TrashIcon/>
                    Delete
                </button>
            );
        }

        let assignMenu = null;

        if (!this.state.isClosing) {
            assignMenu = (
                <SelectWithSearch
                    name='doctor'
                    label='Assigned to'
                    values={this.getDoctorOptions()}
                    inline={true}/>
            );
        }

        return (
            <Form ref={ this.todoForm } onSubmit={this.onSubmitTodo.bind(this)} className={classes.join(' ')}>
                {this.renderTimeInput()}
                <SelectWithSearch2
                    label='Standard resolutions'
                    values={ this.resolutions }
                    value=''
                    inline={ true }
                    onChange={(resolution) => { this.onSelectResolutionChange(resolution) }}/>

                <Textarea2
                    value={ this.state.resolution }
                    onChange={ this.onResolutionChange }
                    name='resolution'
                    label='Resolution'/>

                <Checkbox
                    name='done'
                    label='Close to do'
                    defaultChecked={true}
                    onChange={(value) => { this.onDoneChange(value); }}/>

                { this.renderWaitingWarnings() }

                <div>
                    {assignMenu}
                </div>

                <div className='buttons'>
                    <button disabled={ this.state.isUpdatingTodo } className='button'>
                        { this.state.isClosing ? 'Save': 'Follow up' }
                    </button>

                    <button disabled={this.state.isUpdatingTodo}
                            className='button'
                            onClick={this.onCancel.bind(this)}>Cancel</button>

                    {deleteButton}

                    {this.state.isUpdatingTodo ? <FormLoader /> : null}
                </div>
            </Form>
        );
    }

    renderCommunicationsActions() {
        const { clinicId, todo } = this.props;
        const { patientCommunications } = this.state;

        let communicationsActions = null;

        if (patientCommunications === 'call-patient') {
            let patientPhoneNumber = null;

            if (todo.patientPhoneNumber) {
                patientPhoneNumber = (
                    <a href={`tel:${todo.patientPhoneNumber}`} className='button'>
                        Call to {todo.patientPhoneNumber}
                    </a>
                );
            }

            let patientPhoneNumberAdditional = null;

            if (todo.patientPhoneNumberAdditional) {
                patientPhoneNumberAdditional = (
                    <a href={`tel:${todo.patientPhoneNumberAdditional}`} className='button'>
                        Call to {todo.patientPhoneNumberAdditional} (additional)
                    </a>
                );
            }

            communicationsActions = (
                <div className='communication-actions'>
                    {patientPhoneNumber}
                    {patientPhoneNumberAdditional}
                </div>
            );
        } else if (patientCommunications === 'chat-patient') {
            communicationsActions = (
                <CommonPopup header={todo.patientFirstName + ' ' + todo.patientLastName}
                             onClose={() => { this.setPatientCommunications(''); }}>
                    <ModalChatWrapper clinicId={clinicId}
                                      patientUserId={todo.patientUserId}/>
                </CommonPopup>
            );
        }

        return communicationsActions;
    }

    renderCommunications() {
        const { patientCommunications } = this.state;

        const isCall = patientCommunications === 'call-patient';
        const isChat = patientCommunications === 'chat-patient';

        const callPatientClasses = ['button'];
        if (isCall) {
            callPatientClasses.push('button-pressed');
        }

        const chatPatientClasses = ['button'];
        if (isChat) {
            chatPatientClasses.push('button-pressed');
        }

        return (
            <div>
                <div className='communication-buttons'>
                    <button className={callPatientClasses.join(' ')}
                            onClick={() => { this.setPatientCommunications(isCall ? '' : 'call-patient'); }}>
                        <PhoneIcon/>
                        Call patient
                    </button>
                    <button className={chatPatientClasses.join(' ')}
                            onClick={() => { this.setPatientCommunications(isChat ? '' : 'chat-patient'); }}>
                        <ChatSquareDotsIcon/>
                        Text patient
                    </button>
                    <button className='button'
                            onClick={() => { this.tryVideoChat(); }}>
                        <CameraVideoIcon/>
                        Start video call
                    </button>
                    <button className='button' disabled={true}>
                        <ClipboardIcon/>
                        Escalate
                    </button>
                </div>

                {this.renderCommunicationsActions()}
            </div>
        );
    }

    renderTodos() {
        if (!this.props.isPatientTodosLoaded) {
            return null;
        }

        let rows = null;
        let { patientTodos } = this.props;

        patientTodos = patientTodos.filter(todo => todo.journalId !== this.props.todo.id);

        if (patientTodos.length === 0) {
            rows = (
                <tr className='empty-table-message'>
                    <td colSpan='7'>Empty table</td>
                </tr>
            );
        } else {
            rows = patientTodos.map(todo => {
                let actionName = todo.actionName;
                let cssName = 'normal';

                if (todo.actionCode) {
                    actionName = `${todo.actionCode} - ${todo.actionName}`;
                }

                if (todo.actionName === 'Warning from RPM' && todo.status === 'WAITING') {
                    cssName = 'warning';
                }

                return (
                    <tr className={cssName} key={todo.id}>
                        <td onClick={() => { this.goToTodo(todo.id, todo.type) }}>{this.renderStatus(todo)}</td>
                        <td onClick={() => { this.goToTodo(todo.id, todo.type) }}>{userInitials(todo.originatorFirstName, todo.originatorLastName)}</td>
                        <td onClick={() => { this.goToTodo(todo.id, todo.type) }}>{todo.patientFirstName + ' ' + todo.patientLastName}</td>
                        <td onClick={() => { this.goToTodo(todo.id, todo.type) }}>{actionName}</td>
                        <td onClick={() => { this.goToTodo(todo.id, todo.type) }}>{todo.assignedToUserId ? todo.assignedToFirstName + ' ' + todo.assignedToLastName : null}</td>
                        <td onClick={() => { this.goToTodo(todo.id, todo.type) }}>
                            <div className='text-block'>
                                <Lines data={todo.description}/>
                            </div>
                        </td>
                        <td onClick={() => { this.goToTodo(todo.id, todo.type) }}>{formatShortDate(todo.createdAt)}</td>
                        <td>
                            {todo.actionName === 'Warning from RPM' || todo.status !== 'DONE'
                                ? null
                                : todo.removed
                                    ? 'Removed'
                                    : <button
                                        className="strike-throught"
                                        onClick={() => this._setReportIdToRemove(todo.id)} title="Remove">+</button>
                            }
                        </td>
                    </tr>
                );
            });
        }

        return (
            <table className='table table-row-hover dashboard-todos'>
                <thead>
                    <tr>
                        <th>{this.getWaitingTodosCount()}</th>
                        <th>By</th>
                        <th>Patient</th>
                        <th>Action</th>
                        <th>Assigned to</th>
                        <th>Description</th>
                        <th>Created</th>
                        <th>Remove</th>
                    </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </table>
        );
    }

    renderStatus(todo){
        if(todo.actionName === 'Warning from RPM'){
            if(todo.status === 'WAITING' || todo.status === 'DONE'){
                return(
                    <img src={`/static/img/icon-warning-${todo.status.toLowerCase()}.png`} alt={todo.status.toUpperCase()} title={todo.status.toUpperCase()}/>
                )
            }
        } else {
            if(todo.status){
                return (
                    <img src={`/static/img/icon-${todo.status.toLowerCase()}.png`} alt={todo.status.toUpperCase()} title={todo.status.toUpperCase()}/>
                )
            } else {
                return null;
            }
        }
    }

    render() {
        if (!this.props.isTodoLoaded) {
            return null;
        }

        const { lastPathname, todo } = this.props;
        const backPath = lastPathname ? lastPathname : '/app/ccm';

        let actionName = todo.actionName;

        if (todo.actionCode) {
            actionName = `${todo.actionCode} - ${todo.actionName}`;
        }

        return (
            <div>
                <BackButton to={backPath}/>
                { this._renderRemovePopup() }
                <div className='todo-view'>
                    <header>To do - {todo.status}</header>

                    <div className='todo-info separator'>
                        <div className='todo-info-block'>
                            <div>Originator</div>
                            <div>{todo.createdByFirstName + ' ' + todo.createdByLastName}</div>
                        </div>

                        {this.renderAssignedTo()}

                        <div className='todo-info-block'>
                            <div>Action</div>
                            <div>{actionName}</div>
                        </div>
                        <div className='todo-info-block'>
                            <div>Patient</div>
                            <div>{todo.patientFirstName + ' ' + todo.patientLastName}</div>
                        </div>
                        <div className='todo-info-block'>
                            <div>Created at</div>
                            <div>{formatDate(todo.createdAt)}</div>
                        </div>
                    </div>

                    {this.renderDone()}

                    <div className='description separator'>
                        <header>Description</header>
                        <div>
                            {this.renderTodoLines(todo.description)}
                        </div>
                    </div>

                    {this.renderCharts()}

                    {this.renderProcedures()}

                    {this.renderForm()}

                    {this.renderCommunications()}
                </div>

                <div className='other-todos'>
                    {this.renderTodos()}
                </div>
            </div>
        );
    }

    _renderRemovePopup = () => {

        const { reportIsRemoving, removePopupIsOpen } = this.state;

        if (!removePopupIsOpen) {

            return null;
        }

        return (
            <CommonPopup
                classNames={['mark-measurement-as-wrong-popup']}
                header='Enter reason to remove'
                onClose={() => { this._cancelStrikeThrough() }}>
                <textarea
                    disabled={reportIsRemoving}
                    className='form-textarea'
                    onChange={({ target: { value } }) => {
                        this.setState({
                            reasonToStrikeThrought: value
                        });
                    }}
                    value={this.state.reasonToStrikeThrought} />
                <div className='buttons'>
                    <button disabled={reportIsRemoving} className='confirm' onClick={() => this._cancelStrikeThrough()}>Cancel</button>
                    <button disabled={reportIsRemoving} className='cancel' onClick={() => this._confirmStrikeThrough()}>Remove</button>
                </div>
            </CommonPopup>
        );
    }

    _cancelStrikeThrough = () => {

        this.setState({
            reportIdToRemove: null,
            removePopupIsOpen: false,
            reasonToStrikeThrought: ''
        });
    }

    _confirmStrikeThrough = () => {

        const { reportIdToRemove, reasonToStrikeThrought } = this.state;
        const patientId = this.props.todo.patientUserId;
        const clinicId = this.props.clinicId;
        const { dispatch } = this.props;

        this.setState({
            reportIsRemoving: true
        });

        removeReport(clinicId, reportIdToRemove, reasonToStrikeThrought).then(() => {

            this.setState({ isPatientsLoading: true });

            dispatch(clearPatientTodos());

            getPatientTodos(clinicId, patientId).then((res) => {

                this.setState({
                    reportIdToRemove: null,
                    removePopupIsOpen: false,
                    reasonToStrikeThrought: '',
                    reportIsRemoving: false
                });

                dispatch(setPatientTodos(res.data.todos));
            });

        });
    }

    _setReportIdToRemove = id => {

        this.setState({
            reportIdToRemove: id,
            historyPopupIsOpen: false,
            removePopupIsOpen: true
        });
    }
}


function mapStateToProps(state) {
    return {
        userId: state.user.userId,
        clinicId: state.user.currentClinic,

        lastPathname: state.router.lastPathname,

        isDoctorsLoaded: state.doctors.isDoctorsLoaded,
        doctors: state.doctors.doctors,

        isPatientTodosLoaded: state.patients.isPatientTodosLoaded,
        patientTodos: state.patients.patientTodos,

        isTodoLoaded: state.ccmTodo.isTodoLoaded,
        todo: state.ccmTodo.todo
    };
}

export default connect(mapStateToProps)(CCMShowTodo);
