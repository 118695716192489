import React from 'react';

import {
    getWeekdays,
    getTodayWeekday,
    getDayparts,
    isOldWeek
} from '../date';

export default class WeekVitals extends React.Component {
    constructor(props) {
        super(props);

        this.weekdays = getWeekdays();
        this.dayparts = getDayparts();
        this.isOldWeek = isOldWeek(this.props.dates);

        const { vitals, vitalsValues, incomingVitals } = this.defaultPropsToData(this.props);

        this.state = {
            vitals,
            vitalsValues,
            error: '',
            incomingVitals,
            showVitalsContent: true
        };
    }

    componentWillReceiveProps(nextProps) {
        const { vitalsValues } = this.defaultPropsToData(nextProps);

        this.setState({
            vitalsValues
        });
    }

    defaultPropsToData(props) {
        const vitals = {};
        const vitalsValues = {};
        const incomingVitals = {};

        props.incomingVitals.forEach((vital) => {
            incomingVitals[vital.id] = {};
            vitals[vital.id] = {};
            vitalsValues[vital.id] = {};

            this.weekdays.map((day) => {
                incomingVitals[vital.id][day] = {};
                vitals[vital.id][day] = {};
                vitalsValues[vital.id][day] = {};
            });
        });

        if (props.defaultValue) {
            for (let vitalId in props.defaultValue) {
                for (let day in props.defaultValue[vitalId]) {
                    for (let partOfDay in props.defaultValue[vitalId][day]) {
                        if (incomingVitals[vitalId]) {
                            vitals[vitalId][day][partOfDay] = '';
                            incomingVitals[vitalId][day][partOfDay] = props.defaultValue[vitalId][day][partOfDay].value;
                            vitalsValues[vitalId][day][partOfDay] = props.defaultValue[vitalId][day][partOfDay].values;
                        }
                    }
                }
            }
        }

        return {
            vitals,
            vitalsValues,
            incomingVitals
        };
    }

    onValueChange(vitalId, day, part, value) {
        const vitals = this.state.vitals;

        if (!(vitalId in vitals)) {
            vitals[vitalId] = {};
        }

        if (!(day in vitals[vitalId])) {
            vitals[vitalId][day] = {};
        }

        vitals[vitalId][day][part] = value;

        this.setState({ vitals });
        this.validate(vitals);
    }

    getValue() {
        const value = {};

        for (let vitalId in this.state.vitals) {
            const vital = this.state.vitals[vitalId];
            const days = {};

            Object.keys(vital).map((day) => {
                Object.keys(vital[day]).map((part) => {
                    const dayDate = this.props.dates[day].format('YYYY-MM-DD');

                    if (vital[day][part]) {
                        if (!days[dayDate]) {
                            days[dayDate] = {};
                        }

                        days[dayDate][part] = vital[day][part];
                    }
                });
            });

            if (Object.keys(days).length > 0) {
                value[vitalId] = days;
            }
        }

        return value;
    }

    setValue(vitals) {
        this.setState({ vitals });
    }

    setError(error) {
        this.setState({ error });
    }

    isValid() {
        return this.validate(this.getValue());
    }

    validate(value) {

        for (let i in this.props.validators) {
            const v = this.props.validators[i];
            const error = v(value);

            if (error) {
                this.setError(error);
                return false;
            }
        }

        this.setState({ error: '' });
        return true;
    }

    renderError() {
        if (!this.state.error) {
            return null;
        }

        return (
            <span className='form-error'>
                {this.state.error}
            </span>
        );
    }

    renderWeekdays() {
        const weekdays = this.weekdays.map(weekday => {
            return (
                <div>
                    <div>{weekday}</div>
                    <div>{this.props.dates[weekday].format('MM/DD/YY')}</div>
                </div>
            );
        });

        return (
            <div className='vital-dates'>
                <div/>
                <div/>
                {weekdays}
            </div>
        );
    }

    renderField(vital, today) {

        const days = this.weekdays.map((day, i) => {

            const parts = this.dayparts.map((part, j) => {

                const dayStatus = vital.daysOfWeek[day];
                const partStatus = vital.partOfDay[part];

                let isAllowed = false;

                if (this.props.disableFuture) {
                    if (this.isOldWeek) {
                        isAllowed = dayStatus && partStatus;
                    } else {
                        isAllowed = dayStatus && partStatus && today >= i;
                    }
                } else {
                    isAllowed = dayStatus && partStatus;
                }

                const cellValue = this.state.incomingVitals[vital.id][day][part];
                const vitalCellValues = this.state.vitalsValues[vital.id][day][part] || [];
                let cellValues = null;

                if (vitalCellValues.length > 0) {
                    cellValues = (
                        <div className='vital-value-cell-values'
                             onClick={() => { this.props.onShowFullValues({
                                     vitalId: vital.id,
                                     vitalName: vital.name,
                                     day: this.props.dates[day].format('YYYY-MM-DD'),
                                     part
                                });
                             }}
                        >
                            {vitalCellValues.length}
                        </div>
                    );
                }

                const isEditable = this.props.editableVitals[vital.id];

                if (isAllowed) {

                    return (
                        <div key={j} className='vital-value-cell'>
                            {!isEditable || cellValue !== undefined ?
                                <div className='value'>{cellValue}</div> :
                                <input
                                    id={vital.id + '-' + day + '-' + part}
                                    name={vital.id + '-' + day + '-' + part}
                                    type='text'
                                    maxLength='255'
                                    value={this.state.vitals[vital.id][day][part]}
                                    onChange={(evt) => { this.onValueChange(vital.id, day, part, evt.target.value); }}
                                />
                            }

                            {cellValues}
                        </div>
                    );
                } else {

                    return (
                        <div key={j} className='vital-value-cell'>
                            <input type='text' disabled={true} value={cellValue} />

                            {cellValues}
                        </div>
                    );
                }
            });

            return (
                <div className='vital-row-cell'>
                    {parts}
                </div>
            );
        });

        const metricWeight = this.props.vitalMetrics.metricWeight;
        const metricTemp = this.props.vitalMetrics.temp_in_f;
        let metricName = '';

        if(parseInt(vital.vitalId) === 17 || parseInt(vital.vitalId) === 37){
            if(metricTemp === false){
                metricName = '(C)'
            } else if(metricTemp === true){
                metricName = '(F)'
            }
        }

        if(vital.vitalId === 22){
            if(metricWeight === false){
                metricName = '(lb)'
            } else if(metricWeight === true) {
                metricName = '(kg)'
            }
        }

        return (
            <div key={vital.id} className='vital-row'>
                <div>{vital.name} {metricName}</div>
                <div>
                    {this.renderVitalDayparts()}
                </div>
                <div>
                    { days }
                </div>
            </div>
        );
    }

    renderFields() {
        const today = getTodayWeekday();

        return this.props.incomingVitals.map(vital => this.renderField(vital, today));
    }

    renderVitalDayparts() {
        const dayparts = [];

        this.dayparts.forEach((part, i) => {
            dayparts.push(
                <div key={i}>{part}</div>
            );
        });

        return dayparts;
    }

    render() {
        const { incomingVitals } = this.props;
        const { showVitalsContent } = this.state;

        if (incomingVitals.length === 0) {
            return null;
        }

        return (
            <div className='form-field vital-field'>
                <div className='toggle-vital-fields-content' onClick={ this._toggleVitalsContent }>
                    { `${showVitalsContent ? 'HIDE' : 'SHOW'}` }
                </div>
                <div style={{
                    display: showVitalsContent ? 'block' : 'none'
                }}>
                    {this.renderWeekdays()}
                    {this.renderFields()}
                    {this.renderError()}
                </div>
            </div>
        );
    }

    _toggleVitalsContent = () => {
        this.setState((state) => {
            const { showVitalsContent } = state;

            return {
                showVitalsContent: !showVitalsContent
            }
        })
    }
}

WeekVitals.defaultProps = {
    disableFuture: false,
    validators: []
};
