import React from 'react';


export default class Index extends React.Component {
    componentDidMount() {
        this.props.history.push('/app/ccm');
    }

    render() {
        return (
            <div />
        );
    }
}
