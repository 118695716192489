import React from 'react';
import { connect } from 'react-redux';
import RequestLoader from './../../components/request-loader';
import Form from './../../lib/forms/form';
import SelectWithLoading from './../../lib/forms/select-with-loading';
import PatientTooltip from './../../components/patient-tooltip';
import { getPatientsByName } from './../../actions/patients';
import CalendarIcon from './../../components/icons/calendar';
import { getPatientCarePlan } from './../../actions/reports/patient-care-plans';
import { setCarePlan, clearCarePlan } from './../../actions/rpm/care-plans';
import { formatDayDate, getWeekdaysNames } from './../../lib/date';
import userInitials from './../../lib/user-initials';

class RPMCarePlans extends React.Component {

    componentWillUnmount() {
        this.props.dispatch(clearCarePlan());
    }

    constructor(props) {
        super(props);

        this.state = {
            selectedPatient: '',
            isCarePlansLoading: false
        };

        this._goToCreateCarePlan = this._goToCreateCarePlan.bind(this);

        this.loadPatientsByName = this.loadPatientsByName.bind(this);
        this.onPatientChange = this.onPatientChange.bind(this);
    }

    loadPatientsByName(name, callback) {
        const { currentClinic } = this.props;

        getPatientsByName(currentClinic, name).then(res => {
            const patients = res.data.patients.map((patient) => {
                let dob = '';

                if (patient.dob !== null) {
                    dob = `, ${patient.dob}`;
                }

                return {
                    value: patient.id,
                    label: patient.firstName + ' ' + patient.lastName + dob
                };
            });
            callback(patients);
        });
    }

    onPatientChange(patient) {
        if (patient === '') {
            this.props.dispatch(clearCarePlan());

            this.setState({ isCarePlansLoading: false, selectedPatient: '' });
        } else {
            this.props.dispatch(clearCarePlan());

            this.setState({ isCarePlansLoading: true, selectedPatient: patient });

            getPatientCarePlan(this.props.currentClinic, patient).then((res) => {
                this.props.dispatch(setCarePlan(res.data.carePlan));
            }).catch(err => {
            }).finally(() => {
                this.setState({ isCarePlansLoading: false });
            });
        }
    }

    renderSearch() {
        return (
            <Form onSubmit={() => {}} className='form care-plan-search-form'>
                <div className='form-horizontal-control'>
                    <SelectWithLoading
                        name='patient'
                        label='Search by patient'
                        onLoadMore={this.loadPatientsByName}
                        onChange={this.onPatientChange}
                        tooltip={<PatientTooltip />}
                        filterOption={() => true}
                        inline={true} />
                </div>
            </Form>
        );
    }

    renderTable() {
        if (this.state.isCarePlansLoading) {
            return (
                <RequestLoader center/>
            );
        }

        if (this.state.selectedPatient === '') {
            return null;
        }

        const rows = [];

        if (this.props.carePlan === null && this.state.selectedPatient !== '') {
            rows.push(
                <tr className='empty-table-message'>
                    <td colspan='7'>No monitoring plans</td>
                </tr>
            );
        } else if (this.props.carePlan !== null) {
            rows.push(
                <tr key={this.props.carePlan.id} onClick={() => { this._goToCarePlan(this.props.carePlan.id); }}>
                    <td>{this.props.carePlan.patientFirstName + ' ' + this.props.carePlan.patientLastName}</td>
                    <td>
                        <span>{this.props.carePlan.contactPhone}</span>
                        <span>{this.props.carePlan.patientPhoneNumber}</span>
                        <span>{this.props.carePlan.patientPhoneNumberAdditional}</span>
                    </td>
                    <td>{userInitials(this.props.carePlan.collectorFirstName, this.props.carePlan.collectorLastName)}</td>
                    <td>{userInitials(this.props.carePlan.doctorFirstName, this.props.carePlan.doctorLastName)}</td>
                    <td>{formatDayDate(this.props.carePlan.startDate)}</td>
                    <td>{this.renderLines(getWeekdaysNames(this.props.carePlan.daysOfWeek))}</td>
                    <td>{this.renderVitals(this.props.carePlan.vitals)}</td>
                </tr>
            );
        }

        return (
            <table className='table table-row-hover care-plans-table'>
                <thead>
                    <tr>
                        <th>Patient</th>
                        <th>Phone</th>
                        <th>Medical assistant</th>
                        <th>Prescribed by</th>
                        <th>Start by</th>
                        <th>How often</th>
                        <th>Vitals</th>
                    </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </table>
        );
    }

    renderVitals(vitals) {
        return vitals.map((vital) => {
            return (
                <span>{vital.name}</span>
            );
        });
    }

    renderLines(lines) {
        return lines.map((line, i) => (
            <span key={i}>{line}</span>
        ));
    }

    render() {
        return (
            <div>
                <div className='care-plans-navigation'>
                    <button onClick={this._goToCreateCarePlan} className='button'>
                        <CalendarIcon/>
                        Create monitoring plan
                    </button>

                    {this.renderSearch()}
                </div>

                <div>
                    {this.renderTable()}
                </div>
            </div>
        );
    }

    _goToCreateCarePlan() {
        this.props.history.push('/app/rpm/create-monitoring-plan');
    }

    _goToCarePlan(planId) {
        this.props.history.push('/app/rpm/monitoring-plans/' + planId);
    }
}


function mapStateToProps(state) {
    return {
        currentClinic: state.user.currentClinic,
        isCarePlanLoaded: state.rpmCarePlans.isCarePlanLoaded,
        carePlan: state.rpmCarePlans.carePlan
    };
}


export default connect(mapStateToProps)(RPMCarePlans);
