import ActionConstants from './../../constants/action';

const initialState = {
    ECGList: [],
    ECGListLoading: false,
    ECGListLoaded: false,
    ECGDetail: [],
    ECGDetailLoading: false,
    ECGDetailLoaded: false
}

export default (state, action) => {

    switch (action.type) {

        case ActionConstants.RPM_ECG_LIST_CLEAR:
            return Object.assign({}, state, {
                ECGListLoaded: false,
                ECGListLoading: false,
                ECGList: []
            });

        case ActionConstants.RPM_ECG_DETAIL_SET:
            return Object.assign({}, state, {
                ECGDetailLoading: false,
                ECGDetailLoaded: true,
                ECGDetail: action.payload.ECGDetailData
            });

        case ActionConstants.RPM_ECG_DETAIL_LOADING:
            return Object.assign({}, state, {
                ECGListLoaded: false,
                ECGDetailLoading: true,
                ECGDetailLoaded: false
            });

        case ActionConstants.RPM_ECG_DETAIL_CLEAR:
            return Object.assign({}, state, {
                ECGListLoaded: true,
                ECGDetailLoading: false,
                ECGDetailLoaded: false,
                ECGDetail: []
            });

        case ActionConstants.RPM_ECG_LIST_SET:

            return Object.assign({}, state, {
                ECGListLoaded: true,
                ECGListLoading: false,
                ECGList: action.payload.ECGList
            });

        case ActionConstants.RPM_ECG_LIST_LOADING_SET:

            return Object.assign({}, state, {
                ECGListLoaded: false,
                ECGListLoading: true
            });

        default:
            return Object.assign({}, initialState, state);
    }
}
