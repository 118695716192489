import axios from 'axios';
import ActionConstants from './../../constants/action';
import env from 'react-dotenv';

export function getAddons(clinicId) {

    return axios.get(`${env.API_URL}/api/v1/ccm/addons`, {
        headers: {
            Authorization: `Bearer ${window.getCookie('UserToken')}`
        },
        params: {
            clinicId
        }
    });
}

export function getAddon(clinicId, addonId) {

    return axios.get(`${env.API_URL}/api/v1/ccm/addon`, {
        headers: {
            Authorization: `Bearer ${window.getCookie('UserToken')}`
        },
        params: {
            clinicId,
            addonId
        }
    });
}

export function createAddon(clinicId, addon) {

    return axios.post(`${env.API_URL}/api/v1/ccm/addon`, {
        clinicId,
        ...addon
    }, {
        headers: {
            Authorization: `Bearer ${window.getCookie('UserToken')}`
        }
    });
}

export function setAddons(addons) {
    return {
        type: ActionConstants.CCM_SET_ADDONS,
        payload: {
            addons
        }
    };
}

export function clearAddons() {
    return {
        type: ActionConstants.CCM_CLEAR_ADDONS
    };
}

export function setAddon(addon) {
    return {
        type: ActionConstants.CCM_SET_ADDON,
        payload: {
            addon
        }
    };
}

export function clearAddon() {
    return {
        type: ActionConstants.CCM_CLEAR_ADDON
    };
}
