import React from 'react';

import Select from 'react-select';


export default class SelectWithSearch2 extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isFocused: false,
            value: this._getDefaultValue(props),
            error: ''
        };

        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);

        this._onChange = this._onChange.bind(this);
    }

    _getDefaultValue(props) {
        let value = null;

        if (props.defaultValue) {
            for (let i = 0; i < props.values.length; i += 1) {
                const val = props.values[i];
                if (val.value === props.defaultValue) {
                    value = { ...val };
                    break;
                }
            }
        }

        return value
    }

    _getOptions() {
        const options = [];

        for (let i = 0; i < this.props.values.length; i += 1) {
            options.push(this.props.values[i]);
        }

        return options;
    }

    _onChange(value) {
        this.setState({
            value,
            isFocused: false
        });

        if (value) {
            this._validate(value.value);
            this.props.onChange && this.props.onChange(value.value);
        } else {
            this._validate('');
            this.props.onChange && this.props.onChange('');
        }
    }

    _validate(value) {
        for (let i = 0; i < this.props.validators.length; i += 1) {
            const v = this.props.validators[i];
            const error = v(value);

            if (error) {
                this.setError(error);
                return false;
            }
        }

        this.setState({error: ''});
        return true;
    }

    onFocus() {
        this.setState({
            isFocused: true
        });
    }

    onBlur() {
        this.setState({
            isFocused: false
        });
    }

    setError(error) {
        this.setState({error});
    }

    getValue() {
        if (this.state.value) {
            return this.state.value.value;
        }

        return '';
    }

    setValue(value) {
        this.setState({value});
    }

    clearValue() {
        this.setState({
            value: this._getDefaultValue(this.props)
        });
    }

    isValid() {
        return this._validate(this.getValue());
    }

    renderTooltip() {
        const { tooltip } = this.props;
        const { isFocused } = this.state;

        if (!tooltip) {
            return null;
        }

        if (!isFocused) {
            return null;
        }

        return (
            <div className='tooltip'>
                <div className='tooltip-content'>
                    {tooltip}
                </div>
                <div className='tooltip-arrow' />
            </div>
        );
    }

    renderError() {
        if (!this.state.error) {
            return null;
        }

        return (
            <span className='form-error'>{this.state.error}</span>
        );
    }

    render() {
        const classes = ['form-field'];

        if (this.state.error) {
            classes.push('form-field-error');
        }

        let select = null;

        if (this.props.creatable) {
            select = (
                <Select.Creatable
                    name={this.props.name}
                    options={this._getOptions()}
                    onChange={this._onChange}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    placeholder=''
                    promptTextCreator={this.props.promptTextCreator}
                    disabled={this.props.disabled}
                    clearable />
            );
        } else {
            select = (
                <Select
                    name={this.props.name}
                    value={this.props.value !== null ? this.props.value : this.state.value}
                    options={this._getOptions()}
                    onChange={this._onChange}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    disabled={this.props.disabled}
                    placeholder=''
                    clearable/>
            );
        }

        if (this.props.inline) {
            return (
                <div className={classes.join(' ')}>
                    {this.renderTooltip()}
                    <div className='form-inline-select-field'>
                        <label htmlFor={this.props.name}>{this.props.label}</label>
                        <div className='form-select-block'>
                            {select}
                            {this.renderError()}
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className={classes.join(' ')}>
                {this.renderTooltip()}
                <label htmlFor={this.props.name}>{this.props.label}</label>
                {select}
                {this.renderError()}
            </div>
        );
    }
}

SelectWithSearch2.defaultProps = {
    defaultValue: null,
    values: [],
    validators: [],
    inline: false,
    creatable: false,
    disabled: false,
    promptTextCreator: () => { return ''; },
    tooltip: null,
    value: null
};
