import React from 'react';


export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hasError: false
        };
    }

    componentDidCatch(error, errorInfo) {
        console.error(error); // eslint-disable-line no-console
        console.error(errorInfo); // eslint-disable-line no-console

        this.setState({
            hasError: true
        });
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className='global-message'>
                    <div className='message warning-message'>
                        Ops! Error!
                    </div>
                </div>
            );
        }

        return this.props.children;
    }
}
