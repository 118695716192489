import React from 'react';
import moment from 'moment';

import DayPickerInput from 'react-day-picker/DayPickerInput';

import { newDate } from './../date';


export default class DatePicker extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: '',
            selectedDay: new Date(moment().format('YYYY/MM/DD'))
        };

        this._formatDayPickerDate = this._formatDayPickerDate.bind(this);
        this._onDayChange = this._onDayChange.bind(this);
    }

    render() {
        const classes = ['date-picker', 'form-field'];

        if (this.state.error) {
            classes.push('form-field-error');
        }

        if (this.props.inline) {
            return (
                <div className={classes.join(' ')}>
                    <div className='form-inline-field'>
                        <label htmlFor={this.props.name}>{this.props.label}</label>
                        <div className='form-input-block'>
                            {this.renderDayPicker()}
                            {this.renderError()}
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className={classes.join(' ')}>
                <label htmlFor={this.props.name}>{this.props.label}</label>
                {this.renderDayPicker()}
                {this.renderError()}
            </div>
        );
    }

    renderDayPicker() {
        const classNames = Object.assign({}, DayPickerInput.defaultProps.classNames);
        classNames.container += ' input-wrapper';

        const dayPickerProps = {
            selectedDays: this.state.selectedDay,
            modifiers: {
                disabled: {
                    before: this.props.disabledBefore,
                    after: this.props.disabledAfter
                }
            }
        };

        return (
            <DayPickerInput classNames={classNames}
                            overlayComponent={DatePickerOverlay}
                            value={this.state.selectedDay}
                            placeholder='...'
                            inputProps={{readOnly: true}}
                            formatDate={this._formatDayPickerDate}
                            onDayChange={this._onDayChange}
                            dayPickerProps={dayPickerProps}/>
        );
    }

    renderError() {
        if (!this.state.error) {
            return null;
        }

        return (
            <span className='form-error'>{this.state.error}</span>
        );
    }

    _onDayChange(day) {
        this.setState({selectedDay: day});
        this._validate(day);
    }

    _formatDayPickerDate(d) {
        const year = d.getFullYear();

        let month = d.getMonth() + 1;
        if (month < 10) {
            month = '0' + month;
        }

        let day = d.getDate();
        if (day < 10) {
            day = '0' + day;
        }

        return  month + '/' + day + '/' + year;
    }

    _validate(value) {
        for (let i in this.props.validators) {
            const v = this.props.validators[i];
            const error = v(value);

            if (error) {
                this.setError(error);
                return false;
            }
        }

        this.setState({error: ''});
        return true;
    }

    setError(error) {
        this.setState({error});
    }

    getValue() {
        return this.state.selectedDay;
    }

    setValue(value) {
        if (typeof value === 'object') {
            this.setState({selectedDay: value});
        } else {
            const d = newDate(value);

            if (d.toString() === 'Invalid Date') {
                this.setState({selectedDay: new Date(moment().format('Y-MM-D HH:mm:ss'))});
            } else {
                this.setState({selectedDay: d});
            }
        }
    }

    clearValue() {
        this.setState({
            selectedDay: new Date(moment().format('YYYY/MM/DD'))
        });
    }

    isValid() {
        return this._validate(this.getValue());
    }
}


function DatePickerOverlay({classNames, children}) {
    return (
        <div className={classNames.overlayWrapper}>
            <div className={classNames.overlay}>
                {children}
            </div>
        </div>
    );
}


DatePicker.defaultProps = {
    inline: false,
    disabledBefore: new Date('2017/12/01'),
    disabledAfter: null
};
