import React from 'react';
import RPMCarePlansFilters from './components/rpm-care-plans-filters';

import RPMCarePlansTable from './components/rpm-care-plans-table';

export default class RPMDailyCarePlans extends React.Component {

    render() {

        return (
            <div>
                <RPMCarePlansFilters />
                <RPMCarePlansTable history={ this.props.history } />
            </div>
        );
    }
}
