import ActionConstants from './../constants/action';
import axios from 'axios';
import env from 'react-dotenv';

const getPhoneCodes = () => axios.get(`${env.API_URL}/api/v1/user/CountryPhoneCode`, {
    headers: {
        Authorization: `Bearer ${window.getCookie('UserToken')}`
    }
});

const setPhoneCodes = phoneCodes => ({
    type: ActionConstants.PHONE_CODES_SET,
    payload: {
        phoneCodes
    }
});

const clearPhoneCodes = () => ({
    type: ActionConstants.PHONE_CODES_CLEAR
});

const getCompanyLogo = (clinicId) => axios.get(`${env.API_URL}/api/v1/company/logo`, {
    headers: {
        Authorization: `Bearer ${window.getCookie('UserToken')}`
    },
    params: {
        clinicId
    }

});
    //.then(({ data: { companyLogo } }) => axios.get(companyLogo, {
//    headers: {
//        Authorization: `Bearer ${window.getCookie('UserToken')}`
//    }
//}));

const setCompanyLogo = (companyLogoUrl) => ({
    type: ActionConstants.COMPANY_LOGO_LOADED,
    payload: {
        companyLogoUrl
    }
});

export {
    getPhoneCodes,
    setPhoneCodes,
    clearPhoneCodes,
    getCompanyLogo,
    setCompanyLogo
};
