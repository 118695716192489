import React from 'react';

const arrowBarLeft = () => {
    return (
        <img className='icon'
             alt='Arrow bar left'
             src='/static/bootstrap/icons/arrow-bar-left.svg' />
    );
}

export default arrowBarLeft;
