import React from 'react';
import { connect } from 'react-redux';

import { getAddon, setAddon, clearAddon } from '../../actions/ccm/addons';
import { formatDate, formatDayDate } from '../../lib/date';

import BackButton from './../../components/back-button';


class CCMShowAddon extends React.Component {
    componentDidMount() {
        this._loadAddon();
    }

    componentWillUnmount() {
        this.props.dispatch(clearAddon());
    }

    render() {
        if (!this.props.isAddonLoaded) {
            return null;
        }

        const { lastPathname, addon } = this.props;
        const backPath = lastPathname ? lastPathname : '/app/ccm/addon';

        let actionName = addon.actionName;

        if (addon.actionCode) {
            actionName = `${addon.actionCode} - ${addon.actionName}`;
        }

        return (
            <div>
                <BackButton to={backPath}/>

                <div className='todo-view'>
                    <header>Add-on</header>

                    <div className='todo-info separator'>
                        <div className='todo-info-block'>
                            <div>Originator</div>
                            <div>{addon.createdByUserName}</div>
                        </div>
                        <div className='todo-info-block'>
                            <div>Action</div>
                            <div>{actionName}</div>
                        </div>
                        <div className='todo-info-block'>
                            <div>Patient</div>
                            <div>{addon.patientUserName}</div>
                        </div>
                        <div className='todo-info-block'>
                            <div>Created at</div>
                            <div>{formatDate(addon.createdAt)}</div>
                        </div>
                    </div>

                    <div className='todo-info separator'>
                        <div className='todo-info-block'>
                            <div>Done by</div>
                            <div>{addon.doneByUserName}</div>
                        </div>
                        <div className='todo-info-block'>
                            <div>Time (in minutes)</div>
                            <div>{addon.totalTime}</div>
                        </div>
                        <div className='todo-info-block'>
                            <div>Done at</div>
                            <div>{formatDayDate(addon.doneAt)}</div>
                        </div>
                    </div>

                    {this.renderProcedures()}
                </div>
            </div>
        );
    }

    renderProcedures() {
        if (this.props.addon.procedures.length === 0) {
            return null;
        }

        return this.props.addon.procedures.map((p, i) => {
            const  classes = ['resolution'];

            if (i !== this.props.addon.procedures.length - 1) {
                classes.push('separator');
            }

            return (
                <div key={p.id} className={classes.join(' ')}>
                    <div className='todo-info'>
                        <div className='todo-info-block'>
                            <div>Procedure by</div>
                            <div>{p.createdByFirstName + ' ' + p.createdByLastName}</div>
                        </div>
                        <div className='todo-info-block'>
                            <div>Time</div>
                            <div>{p.time}</div>
                        </div>
                    </div>

                    <header>Resolution</header>
                    <div>
                        {p.resolution}
                    </div>
                </div>
            );
        });
    }

    _loadAddon() {
        const addonId = parseInt(this.props.match.params.addonId, 10);
        const { currentClinic } = this.props;

        if (isNaN(addonId)) {
            this.props.history.push('/ccm/addon');
            return;
        }

        getAddon(currentClinic, addonId).then(res => {

            this.props.dispatch(
                setAddon(
                    res.data.addon
                )
            );
        });
    }
}

export default connect(state => ({
    lastPathname: state.router.lastPathname,
    currentClinic: state.user.currentClinic,

    isAddonLoaded: state.ccmAddon.isAddonLoaded,
    addon: state.ccmAddon.addon
}))(CCMShowAddon);
