import React from 'react';

import Vital from './vital-field';


export default class VitalsField extends React.Component {
    constructor(props) {
        super(props);

        let counter = [];

        for (let i = 0; i < this.props.min; i += 1) {
            counter.push(true);
        }

        this.state = {
            counter,
            vitals: {},
            error: ''
        };

        this.vitals = {};

        this.addForm = this.addForm.bind(this);
    }

    onVitalChange(fieldId, value) {
        const { vitals } = this.state;

        if (value === '') {
            delete vitals[fieldId];
        } else {
            vitals[fieldId] = value;
        }

        this.setState({ vitals });
    }

    addForm(evt) {
        evt.preventDefault();

        const { counter } = this.state;
        counter.push(true);

        this.setState({ counter });
    }

    delForm(fieldId) {
        return (evt) => {
            evt.preventDefault();

            const { counter, vitals } = this.state;

            counter[fieldId] = false;
            delete vitals[fieldId];

            this.setState({ counter, vitals });
        };
    }

    isVitalSelected(vitalId) {
        const { vitals } = this.state;
        const vitalsIds = Object.values(vitals);

        for (let i = 0; i < vitalsIds.length; i += 1) {
            if (vitalsIds[i] === vitalId) {
                return true;
            }
        }

        return false;
    }

    getValue() {
        const values = [];

        Object.keys(this.vitals).forEach((vitalId) => {
            const vital = this.vitals[vitalId];
            if (vital && vital.current && vital.current.getValues) {
                values.push(vital.current.getValues());
            }
        });

        return values;
    }

    setValue(value) {
        const counter = [];

        for (let i = 0; i < value.length; i += 1) {
            counter.push(true);
        }

        const thresholdLimits = {};

        this.props.vitals.forEach((vital) => {
            thresholdLimits[vital.id] = vital.limits;
        });

        this.setState({ counter }, () => {
            const vitals = [];

            for (let i = 0; i < value.length; i += 1) {
                const vital = value[i];
                const daysOfWeek = {};

                Object.keys(vital.daysOfWeek).map((day) => {
                    daysOfWeek[day] = vital.daysOfWeek[day];
                });

                Object.keys(vital.partOfDay).map((part) => {
                    daysOfWeek[part] = vital.partOfDay[part];
                });

                if (!this.vitals[i]) {
                    continue;
                }

                this.vitals[i].current.setValues({
                    vital: {
                        value: vital.vitalId,
                        label: vital.name,
                        thresholdsType: vital.thresholdsType,
                        thresholds: vital.thresholds,
                        limits: thresholdLimits[vital.vitalId] || []
                    },
                    daysOfWeek: daysOfWeek
                });

                this.vitals[i].current.setDisabled();

                vitals[i] = vital.vitalId;
            }

            this.setState({ vitals });
        });
    }

    setError(error) {
        this.setState({ error });
    }

    isValid() {
        let isValid = true;

        const vitalIds = Object.keys(this.vitals);

        for (let i = 0; i < vitalIds.length; i += 1) {
            const vitalId = vitalIds[i];
            const vital = this.vitals[vitalId];

            if (vital && vital.current && vital.current.isValid) {
                if (!vital.current.isValid()) {
                    isValid = false;
                    break;
                }
            }
        }

        if (isValid) {
            this.setState({ error: '' });
        }

        return isValid;
    }

    getVitalsValues() {
        const { vitals } = this.props;
        const values = [];

        for (let i = 0; i < vitals.length; i += 1) {
            const vital = vitals[i];

            if (this.isVitalSelected(vital.id)) {
                continue;
            }

            values.push({
                value: vital.id,
                label: vital.name,
                thresholdsType: vital.thresholdsType,
                thresholds: vital.thresholds,
                limits: vital.limits
            });
        }

        return values;
    }

    renderVitals() {
        const result = [];

        const {
            weightInKg,
            tempInF
        } = this.props;

        const showDelete = this.state.counter.filter((val) => val).length > 1;

        for (let i = 0; i < this.state.counter.length; i += 1) {
            if (!this.state.counter[i]) {
                continue;
            }

            const onDel = this.delForm(i);

            if (this.vitals[i] === undefined) {
                this.vitals[i] = React.createRef();
            }

            result.push(
                <Vital
                    key={i}
                    ref={this.vitals[i]}
                    uniqueId={i}
                    values={this.getVitalsValues()}
                    weightInKg={weightInKg}
                    tempInF={tempInF}
                    onChange={(value) => { this.onVitalChange(i, value) }}
                    showDelete={showDelete}
                    onDelete={onDel} />
            );
        }

        return result;
    }

    renderError() {
        if (!this.state.error) {
            return null;
        }

        return (
            <span className='form-field-list-error'>{this.state.error}</span>
        );
    }

    renderAddButton() {
        const vitalsCount = this.state.counter.filter((f) => f).length;

        if (vitalsCount >= this.props.vitals.length) {
            return null;
        }

        const view = (
            <div>
                <button onClick={ this.addForm } className='button'>
                    Add new vital parameter
                </button>
            </div>
        );

        return view;
    }

    render() {
        return (
            <div className='field-list'>
                {this.renderVitals()}
                {this.renderError()}
                {this.renderAddButton()}
            </div>
        );
    }
}

VitalsField.defaultProps = {
    disableDelete: false,
    min: 1
};
