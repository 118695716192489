import ActionConstants from '../../constants/action';

const setPreloadedPatient = patient => ({
    type: ActionConstants.RPM_CREATE_CARE_PLAN_SET_PRELOADED_PATIENT,
    payload: {
        patient
    }
});

const clearPreloadedPatient = () => ({
    type: ActionConstants.RPM_CREATE_CARE_PLAN_CLEAR_PRELOADED_PATIENT
});

export {
    clearPreloadedPatient,
    setPreloadedPatient
}
