import ActionConstants from './../../constants/action';

const initialState = {
    isJournalPatientLoaded: false,
    journalPatient: null,

    isPatientJournalLoaded: false,
    patientJournal: []
};

const setState = (state, action) => {
    if (action.type === ActionConstants.CCM_SET_JOURNAL) {
        return Object.assign({}, state, {
            isPatientJournalLoaded: true,
            patientJournal: action.payload.journal
        });
    }

    if (action.type === ActionConstants.CCM_CLEAR_JOURNAL) {
        return Object.assign({}, state, {
            isPatientJournalLoaded: false,
            patientJournal: []
        });
    }

    if (action.type === ActionConstants.CCM_SET_JOURNAL_PATIENT) {
        return Object.assign({}, state, {
            isJournalPatientLoaded: true,
            journalPatient: action.payload.patient
        });
    }

    if (action.type === ActionConstants.CCM_CLEAR_JOURNAL_PATIENT) {
        return Object.assign({}, state, {
            isJournalPatientLoaded: false,
            journalPatient: null
        });
    }

    return state || Object.assign({}, initialState);
}

export default setState;
