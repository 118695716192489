import ActionConstants from './../../constants/action';

const initialState = {
    isActionsLoaded: false,
    actions: []
};

const setState = (state, action) => {
    if (action.type === ActionConstants.SET_ACTIONS) {
        return Object.assign({}, state, {
            isActionsLoaded: true,
            actions: action.payload.actions,
            actionsWOTime: action.payload.actionsWOTime
        });
    }

    if (action.type === ActionConstants.CLEAR_ACTIONS) {
        return Object.assign({}, state, {
            isActionsLoaded: false,
            actions: [],
            actionsWOTime: []
        });
    }

    return state || Object.assign({}, initialState);
}

export default setState;
