import axios from 'axios';
import ActionConstants from './../../constants/action';
import env from 'react-dotenv';

export function getPatientJournal(clinicId, patientId, year, month) {

    return axios.get(`${env.API_URL}/api/v1/ccm/todo/all/done`, {
        headers: {
            Authorization: `Bearer ${window.getCookie('UserToken')}`
        },
        params: {
            clinicId,
            patientId,
            year,
            month
        }
    });
}

export function getJournalPatient(clinicId, patientId) {
    return axios.get(`${env.API_URL}/api/v1/patient`, {
        headers: {
            Authorization: `Bearer ${window.getCookie('UserToken')}`
        },
        params: {
            clinicId,
            patientId
        }
    });
}

export function setPatientJournal(journal) {
    return {
        type: ActionConstants.CCM_SET_JOURNAL,
        payload: {
            journal
        }
    };
}

export function clearPatientJournal() {
    return {
        type: ActionConstants.CCM_CLEAR_JOURNAL
    };
}

export function setJournalPatient(patient) {
    return {
        type: ActionConstants.CCM_SET_JOURNAL_PATIENT,
        payload: {
            patient
        }
    };
}

export function clearJournalPatient() {
    return {
        type: ActionConstants.CCM_CLEAR_JOURNAL_PATIENT
    };
}
