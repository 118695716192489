import React, { Component } from 'react';
import { connect } from 'react-redux';
import RequestLoader from '../../../../../components/request-loader';

class RPMPatientTimeline extends Component {

    state = {
        isPatientWeekFailed: false,
        isPatientMonthFailed: false,
        isPatientYearFailed: false
    };

    render() {

        const { isDailyCarePlanLoaded, dailyCarePlan } = this.props;

        if (!isDailyCarePlanLoaded) {

            return <RequestLoader center />
        }

        const minutes = parseInt(dailyCarePlan['99457_reported_minutes']);

        const reportedDays = dailyCarePlan['99454_reported_days'];
        const min454ReportedDays = dailyCarePlan['99454_reported_days']['min'];
        const currend454ReportedDays = dailyCarePlan['99454_reported_days']['current'];

        let fillColor = 100 * minutes / 60;
        let colorHEX = minutes < 20 ? '#fd2818' : '#41a23f';

        return (
            <div className='timeline-container'>
                <div className='info-99454'>
                    <div>{ currend454ReportedDays < min454ReportedDays ? currend454ReportedDays : min454ReportedDays } of { min454ReportedDays }</div>
                    <div>Required days of vital reading</div>
                    <div>Period {reportedDays.startDate} - {reportedDays.endDate}</div>
                </div>
                <div className='info-99457-wrapper'>
                    <div className='info-total-minutes'>Remote patient monitoring<br /> { dailyCarePlan['99457_reported_minutes'] } minutes this month</div>
                    <div className='timeline' style={{
                        background: `linear-gradient(90deg, ${colorHEX} ${fillColor}%, #ebebeb 0%)`
                    }}>
                        <div className={ minutes < 20 ? 'timeline-item empty' : 'timeline-item' }>20 minutes</div>
                        <div className={ minutes < 40 ? 'timeline-item empty' : 'timeline-item' }>40 minutes</div>
                        <div className={ minutes < 60 ? 'timeline-item empty' : 'timeline-item' }>1 hour</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect((state) => ({
    isDailyCarePlanLoaded: state.rpmCarePlans.isDailyCarePlanLoaded,
    dailyCarePlan: state.rpmCarePlans.dailyCarePlan,
}))(RPMPatientTimeline);
