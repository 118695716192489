import React from 'react';


export default class Checkboxes extends React.Component {
    constructor(props) {
        super(props);

        var values = {};
        for (var i = 0; i < this.props.values.length; i++) {
            var v = this.props.values[i];
            values[v.name] = v.checked ? true : false;
        }

        this.state = {
            values,
            error: ''
        };

        this._onChange = this._onChange.bind(this);
    }

    render() {
        var classes = ['form-field', 'form-checkboxes'];
        if (this.state.error) {
            classes.push('form-field-error');
        }

        var fields = this.props.values.map((value) => {
            return (
                <div className='checkbox-row'>
                    <label htmlFor={value.name}>
                        <input id={value.name}
                               name={value.name}
                               type='checkbox'
                               checked={this.state.values[value.name]}
                               onChange={(evt) => { this._onChange(evt, value.name) }}
                               disabled={value.disabled}
                               className='form-checkbox'/>
                            <span className='form-checkbox-text'>{value.label}</span>
                    </label>

                    { value.bottomDescription ? <div> { value.bottomDescription } </div> : '' }
                </div>
            );
        });

        if (this.props.inlineFields) {
            fields = (
                <div className='form-inline-field-fields'>
                    {fields}
                </div>
            );
        }

        if (this.props.inline) {
            return (
                <div className={classes.join(' ')}>
                    <div className='form-inline-field'>
                        <label htmlFor={this.props.name}>{this.props.label}</label>
                        <div className='form-field-checkbox'>
                            {fields}
                        </div>
                    </div>

                    {this.renderError()}
                </div>
            );
        }

        return (
            <div className={classes.join(' ')}>
                <div className='form-field-checkbox'>
                    <label htmlFor={this.props.name}>{this.props.label}</label>
                    {fields}
                </div>

                {this.renderError()}
            </div>
        );
    }

    renderError() {
        if (!this.state.error) {
            return null;
        }

        return (
            <span className='form-error'>{this.state.error}</span>
        );
    }

    _onChange(evt, name) {
        var value = evt.target.checked;
        var values = this.state.values;

        values[name] = value;

        if (name === 'sms' && value === true)
            values['notification'] = true

        this.setState({values});
        this._validate(values);

        if (this.props.onChange) {
            this.props.onChange(value);
        }
    }

    _validate(value) {
        for (var i in this.props.validators) {
            var v = this.props.validators[i];
            var error = v(value);

            if (error) {
                this.setError(error);
                return false;
            }
        }

        this.setState({error: ''});
        return true;
    }

    setError(error) {
        this.setState({error});
    }

    getValue() {
        return this.state.values;
    }

    setValue(values) {
        this.setState({values});
    }

    clearValue() {
        this.setState({
            value: this.props.defaultChecked,
            error: ''
        });
    }

    isValid() {
        return this._validate(this.getValue());
    }
}


Checkboxes.defaultProps = {
    values: [],
    validators: [],
    inline: false,
    inlineFields: false,
    onChange: null
};
