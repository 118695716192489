import React from 'react';


export default class FormMessage extends React.Component {
    componentDidMount() {
        setTimeout(() => {
            this.setState({isClosed: true});
        }, 5000);
    }

    constructor(props) {
        super(props);

        this.state = {
            isClosed: false
        };
    }

    render() {
        if (this.state.isClosed) {
            return null;
        }

        return (
            <div className='form-message'>
                {this.props.message}
            </div>
        );
    }
}


FormMessage.defaultProps = {
    message: ''
};
