import ActionConstants from './../../constants/action';


const initialState = {
    isAssignedTodosLoaded: false,
    assignedTodos: [],

    isTodosLoaded: false,
    todos: [],

    isTodoLoaded: false,
    todo: null
};


export default function(state, action) {
    if (action.type === ActionConstants.CCM_SET_ASSIGNED_TODOS) {
        return Object.assign({}, state, {
            isAssignedTodosLoaded: true,
            assignedTodos: action.payload.assignedTodos
        });
    }

    if (action.type === ActionConstants.CCM_CLEAR_ASSIGNED_TODOS) {
        return Object.assign({}, state, {
            isAssignedTodosLoaded: false,
            assignedTodos: []
        });
    }

    if (action.type === ActionConstants.CCM_SET_TODOS) {
        return Object.assign({}, state, {
            isTodosLoaded: true,
            todos: action.payload.todos
        });
    }

    if (action.type === ActionConstants.CCM_CLEAR_TODOS) {
        return Object.assign({}, state, {
            isTodosLoaded: false,
            todos: []
        });
    }

    if (action.type === ActionConstants.CCM_SET_TODO) {
        return Object.assign({}, state, {
            isTodoLoaded: true,
            todo: action.payload.todo
        });
    }

    if (action.type === ActionConstants.CCM_CLEAR_TODO) {
        return Object.assign({}, state, {
            isTodoLoaded: false,
            todo: null
        });
    }

    return state || Object.assign({}, initialState);
}
