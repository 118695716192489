import React from 'react';
import {connect} from 'react-redux';

import {
    getPatientVitals,
    getPatientWeekVitals,
    setPatientWeekVitals,
    addPatientWeekVitals,

    getPatientMonthVitals,
    setPatientMonthVitals,
    addPatientMonthVitals,

    getPatientYearVitals,
    setPatientYearVitals,
    addPatientYearVitals,

    clearPatientAllVitals
} from './../actions/charts/patient-vitals';

import RequestLoader from './../components/request-loader';
import PatientVitalCharts from './../components/patient-vital-charts';

const defaultProps = {
    showCarePlanLink: true
};

class PatientAllVitalCharts extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isPatientWeekFailed: false,
            isPatientMonthFailed: false,
            isPatientYearFailed: false
        };

        this._loadMorePatientVitals = this._loadMorePatientVitals.bind(this);
    }

    componentDidMount() {
        this._loadPatientWeekVitals();
        this._loadPatientMonthVitals();
        this._loadPatientYearVitals();
    }

    componentDidUpdate(prevProps) {
        if (this.props.isChartsUpdate !== prevProps.isChartsUpdate) {
            this._loadPatientWeekVitals();
            this._loadPatientMonthVitals();
            this._loadPatientYearVitals();
        }
    }

    componentWillUnmount() {
        this.props.dispatch(clearPatientAllVitals());
    }

    render() {
        const {
            isPatientWeekFailed,
            isPatientMonthFailed,
            isPatientYearFailed
        } = this.state;

        if (isPatientWeekFailed && isPatientMonthFailed && isPatientYearFailed) {
            return (
                <div className='patient-vital-charts'>
                    <h1 className='patient-vital-charts-header'>
                        Monitoring Data
                    </h1>
                    <div>
                        This patient doesn't have monitoring plans to show
                    </div>
                </div>
            );
        }

        if (!this.props.isPatientWeekVitalsLoaded ||
            !this.props.isPatientMonthVitalsLoaded ||
            !this.props.isPatientYearVitalsLoaded) {
            return (
                <RequestLoader center />
            );
        }

        let link = null;

        if (this.props.showCarePlanLink && this.props.patientWeekVitalsCarePlanId) {
            link = (
                <div className='chart-data-link'>
                    <a rel='noreferrer' href={'/#/app/rpm/daily-monitoring-plans/' + this.props.patientWeekVitalsCarePlanId + '/report'} target='_blank'>
                        Care plan data
                    </a>
                </div>
            );
        }

        return (
            <div className='patient-vital-charts'>
                <h1 className='patient-vital-charts-header'>
                    Monitoring Data
                </h1>

                {link}

                <div className='vitals-wrap'>
                    <PatientVitalCharts
                        weeksVitals={this.props.patientWeekVitals}
                        monthsVitals={this.props.patientMonthVitals}
                        yearsVitals={this.props.patientYearVitals}
                        isChartsUpdate={this.props.isChartsUpdate}
                        onLoad={this._loadMorePatientVitals}/>
                </div>
            </div>
        );
    }

    _loadPatientWeekVitals() {
        const { dispatch, patient, currentClinic } = this.props;

        getPatientVitals(currentClinic, patient, 'week').then(res => {

            dispatch(
                setPatientWeekVitals(
                    res.data
                )
            );
        }).catch(e => {
            this.setState({
                isPatientWeekFailed: true
            });
        });
    }

    _loadPatientMonthVitals() {

        const { dispatch, patient, currentClinic } = this.props;

        getPatientVitals(currentClinic, patient, 'month').then(res => {

            dispatch(
                setPatientMonthVitals(
                    res.data
                )
            );
        }).catch(e => {
            this.setState({
                isPatientMonthFailed: true
            });
        });
    }

    _loadPatientYearVitals() {

        const { dispatch, patient, currentClinic } = this.props;

        getPatientVitals(currentClinic, patient, 'year').then(res => {

            dispatch(
                setPatientYearVitals(
                    res.data
                )
            );
        }).catch(e => {
            this.setState({
                isPatientYearFailed: true
            });
        });
    }

    _loadMorePatientVitals(time, date, callback) {        

        if (time === 'weeks') {

            const { dispatch, patient, currentClinic } = this.props;

            getPatientVitals(currentClinic, patient, 'week', date).then(res => {

                dispatch(
                    addPatientWeekVitals(
                        res.data
                    )
                );

                if (res.data.vitalsChartData.length > 0) {
                    callback(false);
                } else {
                    callback(true);
                }
            })  

        } else if (time === 'months') {

            const { dispatch, patient, currentClinic } = this.props;

            getPatientVitals(currentClinic, patient, 'month', date).then(res => {                

                dispatch(
                    addPatientMonthVitals(
                        res.data
                    )
                );

                if (res.data.vitalsChartData.length > 0) {
                    callback(false);
                } else {
                    callback(true);
                }
            })

        } else if (time === 'years') {            
            
            const { dispatch, patient, currentClinic} = this.props;            

            getPatientVitals(currentClinic, patient, 'year', date).then(res => {

                dispatch(
                    addPatientYearVitals(
                        res.data
                    )
                );

                if (res.data.vitalsChartData.length > 0) {
                    callback(false);
                } else {
                    callback(true);
                }
            })

        }
    }
}

PatientAllVitalCharts.defaultProps = defaultProps;

const mapStateToProps = function(state) {
    const {
        patientWeekVitalsCarePlanId,

        isPatientWeekVitalsLoaded,
        patientWeekVitals,

        isPatientMonthVitalsLoaded,
        patientMonthVitals,

        isPatientYearVitalsLoaded,
        patientYearVitals,

        isChartsUpdate
    } = state.chartsPatientVitals;

    const { currentClinic } = state.user;

    return {
        currentClinic,
        patientWeekVitalsCarePlanId,

        isPatientWeekVitalsLoaded,
        patientWeekVitals,

        isPatientMonthVitalsLoaded,
        patientMonthVitals,

        isPatientYearVitalsLoaded,
        patientYearVitals,
        isChartsUpdate
    };
};

export default connect(mapStateToProps)(PatientAllVitalCharts);
