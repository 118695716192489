import axios from 'axios';
import ActionConstants from './../../constants/action';
import { getWithClinic } from './../../lib/rest';
import env from 'react-dotenv';

export function createCarePlanLog(clinicId, planId, form) {

    const waitingToClose = [];

    for (const [key, value] of Object.entries(form.waitingToClose)) {
        if (value) {
            waitingToClose.push(
                parseInt(key)
            );
        }
    }

    form.waitingToClose = waitingToClose;

    return axios.post(`${env.API_URL}/api/v1/rpm/logs`, {
        clinicId,
        planId,
        ...form
    }, {
        headers: {
            Authorization: `Bearer ${window.getCookie('UserToken')}`
        }
    });
}

export function getCarePlanLogs(clinicId, planId, week) {

    return axios.get(`${env.API_URL}/api/v1/rpm/logs`, {
        headers: {
            Authorization: `Bearer ${window.getCookie('UserToken')}`
        },
        params: {
            clinicId,
            planId,
            week
        }
    });
}

export function getCarePlanLogsByDate(data) {
    return getWithClinic('/api/rpm/care-plans-logs/all-plan-by-date.php', data);
}

export function setDailyCarePlanLogs(logs) {
    return {
        type: ActionConstants.RPM_SET_DAILY_CARE_PLAN_LOGS,
        payload: {
            logs
        }
    };
}

export function clearDailyCarePlanLogs() {
    return {
        type: ActionConstants.RPM_CLEAR_DAILY_CARE_PLAN_LOGS
    };
}

export function setDailyCarePlanEntries(entries, editableVitals) {
    return {
        type: ActionConstants.RPM_SET_DAILY_CARE_PLAN_ENTRIES,
        payload: {
            entries,
            editableVitals
        }
    };
}

export function clearDailyCarePlanEntries() {
    return {
        type: ActionConstants.RPM_CLEAR_DAILY_CARE_PLAN_ENTRIES
    };
}

export function setDailyCarePlanAllEntries(allEntries) {
    return {
        type: ActionConstants.RPM_SET_DAILY_CARE_PLAN_ALL_ENTRIES,
        payload: {
            allEntries
        }
    };
}

export function clearDailyCarePlanAllEntries() {
    return {
        type: ActionConstants.RPM_CLEAR_DAILY_CARE_PLAN_ALL_ENTRIES
    };
}
