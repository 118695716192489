import React from 'react';

import { ccmLayoutRoutes } from './../../routes';


export default class CCMLayout extends React.Component {
    render() {
        return (
            <div className='dashboard'>
                <div className='dashboard-content'>
                    {ccmLayoutRoutes}
                </div>
            </div>
        );
    }
}
